import * as _ from "lodash";
import moment from "moment";
import { close, closeOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonIcon,
  // IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonThumbnail,
  IonToast,
  IonToggle,
} from "@ionic/react";

import "./JobScheduleForm.scss";
import * as api from "../../api";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import {
  daysSinceDate,
  getDateFromTimezoneIgnoredTimestamp,
  isDayShift,
  TimezoneIgnoredTimeStamp,
  uploadAttachments,
  useEffectOnlyOnce,
} from "../../functions/common";
import { EWPClientForm } from "../EWPClientForm/EWPClientForm";
import { EWPContactForm } from "../EWPContactForm/EWPContactForm";
import { EWPDatePicker } from "../EWPDatePicker/EWPDatePicker";
// import { EWPDivider } from "../EWPDivider/EWPDivider";
import { EWPInput } from "../EWPInput/EWPInput";
import { EWPLink } from "../EWPLink/EWPLInk";
import { EWPSearchInput } from "../EWPSearchInput/EWPSearchInput";
import { EWPProps } from "../../config/global";
import { EWPTimePicker } from "../EWPTimePicker/EWPTimePicker";
import { EWPWebDialog } from "../EWPWebDialog/EWPWebDialog";
import { SoftDeleteJobSchedule } from "../SoftDeleteJobSchedule/SoftDeleteJobSchedule";
import { JobScheduleFor } from "../../pages/Home/Home";
import {
  EWPWebDropdownSelect,
  DropdownItem,
} from "../EWPWebDropdownSelect/EWPWebDropdownSelect";
import { DriverVOC, Truck, TruckWithDepot } from "../../models/drivers";
import {
  driverJobScheduleUpdated,
  driverNewJobSchedule,
  MSGS_COMMON,
  MSGS_JOB_SCHEDULE,
} from "../../constants/messages";
import {
  JobSchedule,
  JobScheduleView,
  JOBS_WITH_KEYS,
  NOTIFICATION_STATUS,
} from "../../models";
import { EWPCOLORS } from "../../constants/config";
import Bugsnag from "@bugsnag/js";
import { storage } from "../../firebase";

import { Loader } from "@googlemaps/js-api-loader";

interface JobScheduleFormProps extends EWPProps {
  jobFor: JobScheduleFor;
  jobSchedule?: JobScheduleView;
  isEdit?: boolean;
}

interface JobScheduleExistingData {
  docId?: string;
  // isRemote: boolean;
  startDate: null | firebase.firestore.Timestamp;
  startDateNoTimeZone: null | TimezoneIgnoredTimeStamp;
  endDate: null | firebase.firestore.Timestamp;
  endDateNoTimeZone: null | TimezoneIgnoredTimeStamp;
  onsiteTime: null | firebase.firestore.Timestamp;
  onsiteTimeNoTimeZone: null | TimezoneIgnoredTimeStamp;
  finishTime?: null | TimezoneIgnoredTimeStamp;
  clientId: string;
  contactPersonId: string;
  bookingContactName?: string;
  bookingContactNumber?: string;
  truckId: string;
  depotId: string;
  truckSize: string;
  driverId?: string;
  address: string;
  notes?: string;
  notifyDriver: boolean;
  travelJobTime?: string;
  distanceJob?: string;
  travelBackTime?: string;
  stopPoints?: string[];
  attachments?: string[];
  pencilledIn?: boolean;
}

export const JobScheduleForm = (props: JobScheduleFormProps) => {
  const { jobFor, jobSchedule, isEdit } = props;
  // const searchEle: React.RefObject<HTMLIonInputElement> = React.createRef();
  const searchAddressEle: React.RefObject<HTMLIonSearchbarElement> =
    React.createRef();

  let existingData = {
    docId: "",
    // isRemote: false,
    startDate: null,
    startDateNoTimeZone: null,
    endDate: null,
    endDateNoTimeZone: null,
    onsiteTime: null,
    onsiteTimeNoTimeZone: null,
    finishTime: null,
    clientId: "",
    contactPersonId: "",
    bookingContactName: "",
    bookingContactNumber: "",
    truckId: "",
    depotId: "",
    truckSize: "",
    driverId: "",
    address: "",
    notes: "",
    notifyDriver: false,
    pencilledIn: false,
    travelJobTime: "",
    distanceTimeJob: "",
    travelBackTime: "",
    stopPoints: [] as string[],
    attachments: [] as string[],
  } as JobScheduleExistingData;

  if (!_.isEmpty(jobSchedule) && !!jobSchedule) {
    existingData = _.clone(jobSchedule);
  }
  const [showCreateNewContact, setShowCreateNewContact] = useState(false);
  const [showCreateNewClient, setShowCreateNewClient] = useState(false);

  // const [isRemoteJob, setIsRemoteJob] = useState(
  //   !!existingData && !_.isEmpty(existingData) ? existingData.isRemote : false
  // );
  const [selectedStartDate, setSelectedStartDate] = useState(
    !!existingData && !_.isNull(existingData.startDateNoTimeZone) && !!isEdit
      ? getDateFromTimezoneIgnoredTimestamp(existingData.startDateNoTimeZone)
      : (null as Date | null)
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    !!existingData && !_.isNull(existingData.endDateNoTimeZone) && !!isEdit
      ? getDateFromTimezoneIgnoredTimestamp(existingData.endDateNoTimeZone)
      : (null as Date | null)
  );

  let defautOnSite = new Date();
  defautOnSite.setHours(12);
  defautOnSite.setMinutes(0);
  defautOnSite.setSeconds(0);
  defautOnSite.setMilliseconds(0);
  const [selectedOnSiteTime, setSelectedOnSiteTime] = useState(
    !!existingData && !_.isNull(existingData.onsiteTimeNoTimeZone)
      ? getDateFromTimezoneIgnoredTimestamp(existingData.onsiteTimeNoTimeZone)
      : defautOnSite
  );

  const [selectedFinishTime, setSelectedFinishTime] = useState(
    !!existingData && !_.isEmpty(existingData.finishTime)
      ? getDateFromTimezoneIgnoredTimestamp(existingData.finishTime!)
      : defautOnSite
  );

  const [selectedClient, setSelectedClient] = useState(
    !!existingData && !_.isEmpty(existingData) ? existingData.clientId : ""
  );
  const [selectedContactPerson, setSelectedContactPerson] = useState(
    !!existingData && !_.isEmpty(existingData)
      ? existingData.contactPersonId
      : ""
  );
  const [bookingContactName, setBookingContactName] = useState(
    !!existingData && !!existingData.bookingContactName
      ? existingData.bookingContactName
      : ""
  );

  const [bookingContactNumber, setBookingContactNumber] = useState(
    !!existingData && !!existingData.bookingContactNumber
      ? existingData.bookingContactNumber
      : ""
  );
  const [selectedTruck, setSelectedTruck] = useState(
    !!existingData && !_.isEmpty(existingData) ? existingData.truckId : ""
  );
  const [selectedDriver, setSelectedDriver] = useState(
    !!existingData && !_.isEmpty(existingData) ? existingData.driverId : ""
  );
  const [address, setAddress] = useState(
    !!existingData && !_.isEmpty(existingData) ? existingData.address : ""
  );
  const [jobNotes, setJobNotes] = useState(
    !!existingData && !_.isEmpty(existingData) ? existingData.notes : ""
  );
  const [notifyDriver, setNotifyDriver] = useState(
    !!existingData && !_.isEmpty(existingData)
      ? existingData.notifyDriver
      : false
  );
  const [pencilledIn, setPencilledIn] = useState(
    !!existingData && !_.isEmpty(existingData) && existingData.pencilledIn
      ? existingData.pencilledIn
      : false
  );
  const [travelJobTime] = useState(
    !!existingData && !!existingData.travelJobTime
      ? existingData.travelJobTime
      : ""
  );
  const [distanceTimeJob] = useState(
    !!existingData && !!existingData.distanceJob ? existingData.distanceJob : ""
  );
  const [travelBackTime] = useState(
    !!existingData && !!existingData.travelBackTime
      ? existingData.travelBackTime
      : ""
  );
  const [stopPoints] = useState(
    !!existingData && !!existingData.stopPoints
      ? existingData.stopPoints
      : ([] as string[])
  );
  // const [stopPointInputText, setStopPointInputText] = useState("");

  const [clientOptions, setClientOptions] = useState(
    null as DropdownItem[] | null
  );

  const [contactPersonOptions, setContactPersonOptions] = useState(
    null as DropdownItem[] | null
  );

  const [trucksWithDepotOptions, setTrucksWithDepotOptions] = useState(
    null as DropdownItem[] | null
  );

  const [driverOptions, setDriverOptions] = useState(
    null as DropdownItem[] | null
  );
  const [driverVOCs, setDriverVOCs] = useState(null as null | DriverVOC[]);
  const [filteredJobSchedules, setFilteredJobSchedules] = useState(
    null as null | JobSchedule[]
  );

  const [listOfAttachment, setListOfAttachment] = useState(
    !!existingData && !!existingData.attachments
      ? existingData.attachments
      : ([] as any[])
  );

  // const [listOfAttachment, setListOfAttachment] = useState([] as any[]);

  const [removedUrl, setRemovedUrl] = useState([] as any[]);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [errorMsgStartDate, setErrorMsgStartDate] = useState("");
  const [errorMsgEndDate, setErrorMsgEndDate] = useState("");
  const [errorMsgOnSiteTime, setErrorOnSiteTime] = useState("");
  const [errorMsgFinishTime, setErrorFinishTime] = useState("");
  const [errorMsgClient, setErrorMsgClient] = useState("");
  const [errorMsgContactPerson, setErrorMsgContactPerson] = useState("");
  const [erroMsgTruckNumber, setErrorMsgTruckNumber] = useState("");
  const [erroMsgDriver, setErrorMsgDriver] = useState("");
  const [erroMsgAddress, setErrorMsgAddress] = useState("");
  const [errorMsgJobNotes, setErrorMsgJobNotes] = useState("");
  // const [errorMsgTravelJobTime, setErrorMsgTravelJobTime] = useState("");
  // const [errorMsgDistanceJobTime, setErrorMsgDistanceJobTime] = useState("");
  // const [errorMsgTravelBackTime, setErrorMsgTravelBackTime] = useState("");
  // const [errorMsgStopPoint, setErrorMsgStopPoint] = useState("");

  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const ref: React.RefObject<HTMLInputElement> = React.createRef();

  const onClickUploadPhoto = () => {
    if (!!listOfAttachment) {
      if (listOfAttachment.length < 3 && !!ref) {
        ref.current!.click(); // ref for input field file
      } else {
        setError("Attachment exceeds the maximum number.");
      }
    }
  };

  useEffectOnlyOnce(() => {
    console.log("data ---", {
      existingData,
      jobSchedule,
    });
    if (!_.isEmpty(selectedClient)) {
      getClientContacts(selectedClient);
    }
    getClientOptions();
    getDriverOptions();
    if (!_.isEmpty(jobFor.truckId)) {
      getTrucksOptions();
    }
    if (!_.isNull(selectedStartDate) && !_.isNull(selectedEndDate)) {
      getFiltertedJobSchedules(selectedStartDate, selectedEndDate);
    }
    if (!_.isNull(searchAddressEle)) {
      mountSearchAddressLocation();
    }
  });
  const onRemoveAttachment = (attachment: any) => {
    const updatedListOfAttachment = _.remove(listOfAttachment, (item) => {
      return item !== attachment;
    });
    setListOfAttachment(updatedListOfAttachment);
    setRemovedUrl([...removedUrl, attachment]);
  };

  const createJob = async () => {
    // console.log("CREATE JOB!!");
    let errorMessage = null;
    if (!selectedStartDate) {
      errorMessage = MSGS_JOB_SCHEDULE.startDate.required;
      setErrorMsgStartDate(errorMessage);
    }
    if (!selectedEndDate) {
      errorMessage = MSGS_JOB_SCHEDULE.endDate.required;
      setErrorMsgEndDate(errorMessage);
    }
    if (moment(selectedEndDate).isBefore(selectedStartDate)) {
      errorMessage = MSGS_JOB_SCHEDULE.invalidScheduleDate;
      setErrorMsgEndDate(errorMessage);
    }
    if (!selectedOnSiteTime) {
      errorMessage = MSGS_JOB_SCHEDULE.onSiteTime;
      setErrorOnSiteTime(errorMessage);
    }
    if (!selectedFinishTime) {
      errorMessage = MSGS_JOB_SCHEDULE.finishTime;
      setErrorFinishTime(errorMessage);
    }
    if (_.isEmpty(selectedClient)) {
      errorMessage = MSGS_JOB_SCHEDULE.client;
      setErrorMsgClient(errorMessage);
    }
    // if (_.isEmpty(selectedContactPerson)) {
    //   errorMessage = MSGS_JOB_SCHEDULE.contactPerson;
    //   setErrorMsgContactPerson(errorMessage);
    // }
    // if (_.isEmpty(selectedTruck)) {
    //   errorMessage = MSGS_JOB_SCHEDULE.truckNumber;
    //   setErrorMsgTruckNumber(errorMessage);
    // }
    if (_.isEmpty(address)) {
      errorMessage = MSGS_JOB_SCHEDULE.address;
      setErrorMsgAddress(errorMessage);
    }
    if (_.isEmpty(errorMessage)) {
      setLoading(true);
      const findClient = _.find(
        clientOptions,
        (client) => client.id === selectedClient
      );
      const clientName =
        !!findClient && !_.isEmpty(findClient) ? findClient.name : "N/A";

      try {
        const selectedTruckWithDepot = _.find(
          trucksWithDepotOptions,
          (truck) => truck.id === selectedTruck
        );
        const truckWithDepot = !_.isEmpty(selectedTruckWithDepot)
          ? (selectedTruckWithDepot!.metadata as TruckWithDepot)
          : null;
        const notificationStatusId = notifyDriver
          ? NOTIFICATION_STATUS.pending.id
          : NOTIFICATION_STATUS.notSent.id;
        if (!!isEdit) {
          const formattedSelectedStartDate = _.cloneDeep(selectedStartDate);
          formattedSelectedStartDate!.setHours(0);
          formattedSelectedStartDate!.setMinutes(0);
          formattedSelectedStartDate!.setSeconds(0);
          formattedSelectedStartDate!.setMilliseconds(0);

          const formattedSelectedEndDate = _.cloneDeep(selectedEndDate);
          formattedSelectedEndDate!.setHours(0);
          formattedSelectedEndDate!.setMinutes(0);
          formattedSelectedEndDate!.setSeconds(0);
          formattedSelectedEndDate!.setMilliseconds(0);
          let attachmentUrlList = [] as string[];
          if (listOfAttachment.length > 0) {
            const urlList = await uploadAttachments(
              listOfAttachment,
              props.authUser.lastName,
              props.authUser.docId,
              "jobScheduleAttachments"
            );
            // console.log("urlList ---- ", urlList);
            attachmentUrlList = urlList;
          }

          // console.log("attachmentUrlList ----- ", attachmentUrlList);
          const updateAttachments = _.filter(
            _.compact(
              _.concat(listOfAttachment, attachmentUrlList) as string[]
            ),
            (attachments) => typeof attachments === "string"
          );

          // console.log("updateAttachments -- update --- ", updateAttachments);

          await services.updateJobSchedule(
            existingData.docId as string,
            // isRemoteJob,
            formattedSelectedStartDate!,
            formattedSelectedEndDate!,
            selectedOnSiteTime!,
            selectedFinishTime!,
            selectedClient,
            selectedContactPerson,
            bookingContactName,
            bookingContactNumber,
            !_.isEmpty(truckWithDepot) ? (truckWithDepot!.docId as string) : "",
            !_.isEmpty(truckWithDepot) ? truckWithDepot!.depotId : "",
            !_.isEmpty(truckWithDepot) ? truckWithDepot!.type : -1,
            !_.isEmpty(truckWithDepot) ? truckWithDepot!.size : "",
            address,
            jobNotes,
            notifyDriver,
            notificationStatusId,
            travelJobTime,
            distanceTimeJob,
            travelBackTime,
            stopPoints,
            selectedDriver,
            pencilledIn,
            updateAttachments
          );

          // notify
          const selectedDriverDetails = _.find(
            driverOptions,
            (driver) => driver.id === selectedDriver
          );

          if (!_.isEmpty(selectedDriverDetails) && notifyDriver) {
            if (selectedDriver !== existingData.driverId) {
              try {
                await api.sendSMS(
                  selectedDriverDetails!.metadata.phoneNumber,
                  driverNewJobSchedule(selectedStartDate!, clientName)
                );
              } catch (eUnknown) {
                const e = eUnknown as any;
                // console.log("ERROR SENDING NOTIF", e);
                setError(`Sending notifications failed: ${e}`);
              }
              // notify
              const cancelledDriverDetails = _.find(
                driverOptions,
                (driver) => driver.id === existingData.driverId
              );
              if (!_.isEmpty(cancelledDriverDetails)) {
                try {
                  await api.sendSMS(
                    cancelledDriverDetails!.metadata.phoneNumber,
                    driverJobScheduleUpdated(
                      getDateFromTimezoneIgnoredTimestamp(
                        existingData.startDateNoTimeZone!
                      ),
                      "cancelled",
                      clientName
                    )
                  );
                } catch (eUnknown) {
                  const e = eUnknown as any;
                  console.log("ERROR SENDING NOTIF", e);
                  setError(`Sending notifications failed: ${e}`);
                }
              }
            } else {
              if (notifyDriver) {
                try {
                  await api.sendSMS(
                    selectedDriverDetails!.metadata.phoneNumber,
                    driverJobScheduleUpdated(
                      getDateFromTimezoneIgnoredTimestamp(
                        existingData.startDateNoTimeZone!
                      ),
                      "updated",
                      clientName
                    )
                  );
                } catch (eUnknown) {
                  const e = eUnknown as any;
                  console.log("ERROR SENDING NOTIF", e);
                  setError(`Sending notifications failed: ${e}`);
                }
              }
            }
          }
          // remove the file in our storage
          await Promise.resolve(
            removedUrl.forEach((url) => {
              new Promise(async (resolve) => {
                try {
                  const refFromUrl = storage.refFromURL(url);
                  const deletedUrl = await refFromUrl.delete();
                  resolve(deletedUrl);
                } catch (errorUnknown) {
                  const error = errorUnknown as any;
                  resolve(null);
                  Bugsnag.notify(new Error(error));
                }
              });
            })
          );
          setLoading(false);
          setSuccess("Job Schedule Successfully Updated");
        } else {
          const formattedSelectedStartDate = _.cloneDeep(selectedStartDate);
          formattedSelectedStartDate!.setHours(0);
          formattedSelectedStartDate!.setMinutes(0);
          formattedSelectedStartDate!.setSeconds(0);
          formattedSelectedStartDate!.setMilliseconds(0);

          const formattedSelectedEndDate = _.cloneDeep(selectedEndDate);
          formattedSelectedEndDate!.setHours(0);
          formattedSelectedEndDate!.setMinutes(0);
          formattedSelectedEndDate!.setSeconds(0);
          formattedSelectedEndDate!.setMilliseconds(0);

          let attachmentUrlList =
            !!existingData.attachments && !_.isEmpty(existingData.attachments)
              ? existingData.attachments
              : ([] as string[]);
          if (listOfAttachment.length > 0) {
            const urlList = await uploadAttachments(
              listOfAttachment,
              props.authUser.lastName,
              props.authUser.docId,
              "jobScheduleAttachments"
            );
            if (
              !!existingData.attachments &&
              !_.isEmpty(existingData.attachments)
            ) {
              attachmentUrlList = attachmentUrlList.concat(urlList);
            } else {
              attachmentUrlList = urlList;
            }
          }

          await services.createJobSchedule(
            // isRemoteJob,
            formattedSelectedStartDate!,
            formattedSelectedEndDate!,
            selectedOnSiteTime!,
            selectedFinishTime!,
            selectedClient,
            selectedContactPerson,
            bookingContactName,
            bookingContactNumber,
            !_.isEmpty(truckWithDepot) ? (truckWithDepot!.docId as string) : "",
            !_.isEmpty(truckWithDepot) ? truckWithDepot!.depotId : "",
            !_.isEmpty(truckWithDepot) ? truckWithDepot!.type : -1,
            !_.isEmpty(truckWithDepot) ? truckWithDepot!.size : "",
            address,
            jobNotes,
            notifyDriver,
            notificationStatusId,
            travelJobTime,
            distanceTimeJob,
            travelBackTime,
            stopPoints,
            selectedDriver,
            pencilledIn,
            attachmentUrlList
          );

          // notify
          const selectedDriverDetails = _.find(
            driverOptions,
            (driver) => driver.id === selectedDriver
          );
          if (!_.isEmpty(selectedDriverDetails) && notifyDriver) {
            try {
              await api.sendSMS(
                selectedDriverDetails!.metadata.phoneNumber,
                driverNewJobSchedule(selectedStartDate!, clientName)
              );
            } catch (eUnknown) {
              const e = eUnknown as any;
              console.log("ERROR SENDING NOTIF", e);
              setError(`Sending notifications failed: ${e}`);
            }
          }

          //! add logic for Departure & Travel Details here
          setLoading(false);
          setSuccess("Job Schedule Successfully Created");
        }
      } catch (eUnknown) {
        const e = eUnknown as any;
        setLoading(false);
        setError(e);
      }
    }
  };

  const getClientOptions = async () => {
    try {
      const clients = await services.getClients();
      // console.log("GOT CLIENTS", clients);
      setClientOptions(
        _.orderBy(
          clients.map((client) => {
            return {
              id: client.docId || "",
              name: client.name,
              metadata: { preferredTruckIds: client.preferredTruckIds },
            };
          }),
          "name",
          "asc"
        )
      );
      if (!_.isEmpty(jobFor.clientId)) {
        setSelectedClient(jobFor.clientId!);
        getClientContacts(jobFor.clientId!);
      }
    } catch (eUnknown) {
      const e = eUnknown as any;
      setError(e);
      setClientOptions([]);
    }
  };

  const getClientContacts = async (clientId: string) => {
    // setSelectedContactPerson("");

    try {
      const contacts = await services.getClientsContacts(clientId);
      console.log("DOID WE GET CONTACTS?", contacts);
      setContactPersonOptions(
        _.orderBy(
          contacts.map((contact) => {
            return {
              id: contact.docId || "",
              name: `${contact.firstName} ${contact.lastName}`,
              metadata: contact,
            };
          }),
          "name",
          "asc"
        )
      );
    } catch (eUnknown) {
      const e = eUnknown as any;
      setError(e);
      setContactPersonOptions([]);
    }
  };

  const getTrucksOptions = async (clientId?: string) => {
    setTrucksWithDepotOptions(null);
    try {
      let listOfBookedTrucks = [] as Truck[];
      if (!_.isNull(selectedStartDate) && !_.isNull(selectedEndDate)) {
        listOfBookedTrucks = await services.getBookedTrucks(
          selectedStartDate as Date,
          selectedEndDate as Date
        );
      }
      const selectedClientId = !_.isEmpty(selectedClient)
        ? selectedClient
        : clientId;
      services.getTrucksWithDepotRealTime((trucksWithDepots, error) => {
        setTrucksWithDepotOptions(
          _.orderBy(
            _.filter(trucksWithDepots, (truck) => !truck.archived).map(
              (truckWithDepot) => {
                const hasExpiredLicence = !_.isEmpty(
                  _.find(truckWithDepot!.truckExpiries || [], (expiryData) => {
                    return (
                      !!expiryData.expirationDate &&
                      daysSinceDate(
                        new Date(),
                        expiryData.expirationDate.toDate()
                      ) <= 0
                    );
                  })
                );
                return {
                  id: truckWithDepot.docId || "",
                  name: `${JOBS_WITH_KEYS[truckWithDepot.type].name} - ${
                    truckWithDepot.name
                  } - ${truckWithDepot.depot.name}`,
                  metadata: {
                    ...truckWithDepot,
                    hasBooking: !_.isEmpty(
                      _.find(listOfBookedTrucks, (bookedTruck) => {
                        return bookedTruck.docId === truckWithDepot.docId; //match id to return if booked
                      })
                    ),
                    isPreferredByClient:
                      !!_.find(
                        clientOptions,
                        (client) => client.id === selectedClientId
                      ) &&
                      _.find(
                        _.find(
                          clientOptions,
                          (client) => client.id === selectedClientId
                        )!.metadata.preferredTruckIds || [],
                        (truckId) => {
                          return truckWithDepot.docId === truckId;
                        }
                      ),
                    hasExpiredLicence,
                  },
                };
              }
            ),
            "name",
            "asc"
          )
        );
        if (!_.isEmpty(jobFor.truckId)) {
          setSelectedTruck(jobFor.truckId!);
        }

        setError(error || "");
      });
      // unsubscribe();
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      console.log("error -- getTruckOptions -- ", error);
      setError(error);
      Bugsnag.notify(new Error(error));
    }
  };

  const getDriverOptions = async (
    selectedTruckId?: string | null,
    driverSchedules?: JobSchedule[]
  ) => {
    console.log("selectedTruck ", selectedTruck);
    console.log("this is selectedTruckId --- ", selectedTruckId);

    const truckDriverVOC =
      !!driverVOCs &&
      _.filter(driverVOCs, (voc) => voc.truckId === selectedTruckId);

    try {
      const drivers = await services.getDrivers();
      setDriverOptions(
        _.orderBy(
          drivers.map((driver) => {
            const hasExpiredLicence = !_.isEmpty(
              _.find(driver!.driverExpiries || [], (expiryData) => {
                return (
                  !!expiryData.expirationDate &&
                  daysSinceDate(
                    new Date(),
                    expiryData.expirationDate.toDate()
                  ) <= 0
                );
              })
            );
            return {
              id: driver.docId || "",
              name: `${driver.firstName} ${driver.lastName}`,
              metadata: {
                phoneNumber: driver.phoneNumber,
                selectedTruckVOC:
                  !!truckDriverVOC &&
                  !_.isEmpty(
                    _.find(
                      truckDriverVOC,
                      (voc) => voc.driverId === driver.docId
                    )
                  ),
                hasBooking: !_.isEmpty(
                  _.find(
                    driverSchedules,
                    (filteredJobSchedule) =>
                      filteredJobSchedule.driverId === driver.docId
                  )
                ),
                hasExpiredLicence,
              },
            };
          }),
          "name",
          "asc"
        )
      );

      if (!_.isEmpty(jobFor.driverId)) {
        setSelectedDriver(jobFor.driverId!);
      }
    } catch (eUnknown) {
      const e = eUnknown as any;
      setError(e);
      setDriverOptions([]);
    }
  };

  const getFiltertedJobSchedules = async (
    startDate: Date | null,
    endDate: Date | null
  ) => {
    try {
      if (!!startDate && !!endDate) {
        const driverJobSchedules = await services.getFiltertedJobSchedules(
          startDate,
          endDate
        );
        console.log("THIS IS driverJobSchedules --- ", driverJobSchedules);
        setFilteredJobSchedules(driverJobSchedules);
        getDriverOptions(selectedTruck, driverJobSchedules);
      }
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      console.log("error - getFiltertedJobSchedules -- ", error);
      Bugsnag.notify(new Error(error));
    }
  };

  useEffect(() => {
    if (
      !_.isNull(selectedStartDate) &&
      !_.isNull(selectedEndDate) &&
      _.isNull(trucksWithDepotOptions)
    ) {
      getTrucksOptions();
    }

    if (_.isNull(driverVOCs)) {
      getDriverVOCs();
    }
  });

  const getDriverVOCs = async () => {
    console.log("getDriverVOCs");
    try {
      const results = await services.getDriverVOCs();
      setDriverVOCs(results);
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      console.log("error - driverVOCs -- ", error);
      Bugsnag.notify(new Error(error));
    }
  };

  // const mountSearchLocation = () => {
  //   if (!_.isNull(searchEle.current)) {
  //     // console.log("mountSearchLocation ---");
  //     const elem = searchEle.current.getElementsByTagName("input")[0];
  //     const loader = new Loader({
  //       apiKey: "AIzaSyDPJBo6wnA4UWl7RjO4demBHYX2NtUWzIc",
  //       version: "weekly",
  //     });
  //     loader.load().then(() => {
  //       const autoComplete = new google.maps.places.Autocomplete(elem, {
  //         componentRestrictions: { country: "au" },
  //       });
  //       var geocoder = new google.maps.Geocoder();
  //       autoComplete.setFields(["geometry", "place_id"]);

  //       google.maps.event.addListener(autoComplete, "place_changed", () => {
  //         let place = autoComplete.getPlace();

  //         geocoder.geocode({ placeId: place.place_id }, (results, status) => {
  //           if (status === "OK" && !_.isEmpty(results)) {
  //             setStopPointInputText(results[0].formatted_address);
  //           }
  //         });
  //       });
  //     });
  //   }
  // };

  let addressTries = 0;
  const mountSearchAddressLocation = () => {
    // console.log(
    //   "will check00",
    //   !_.isNull(searchAddressEle.current),
    //   searchAddressEle.current
    // );
    if (
      !_.isNull(searchAddressEle.current) &&
      searchAddressEle.current.getElementsByTagName("input").length > 0
    ) {
      // console.log("mountSearchAddressLocation ---");
      const elem = searchAddressEle.current.getElementsByTagName("input")[0];
      console.log(
        "CHECKED ELEM --",
        searchAddressEle.current.getElementsByTagName("input"),
        elem
      );
      const loader = new Loader({
        apiKey: "AIzaSyDPJBo6wnA4UWl7RjO4demBHYX2NtUWzIc",
        version: "weekly",
      });
      loader.load().then(async () => {
        const autoComplete = new google.maps.places.Autocomplete(elem, {
          componentRestrictions: { country: "au" },
        });
        var geocoder = new google.maps.Geocoder();
        autoComplete.setFields(["geometry", "place_id"]);

        google.maps.event.addListener(autoComplete, "place_changed", () => {
          let place = autoComplete.getPlace();

          geocoder.geocode({ placeId: place.place_id }, (results, status) => {
            if (status === "OK" && !_.isEmpty(results)) {
              setAddress(results[0].formatted_address);
            }
          });
        });
      });
    } else {
      if (addressTries <= 3) {
        setTimeout(() => {
          addressTries += 1;
          mountSearchAddressLocation();
        }, 100);
      } else {
        setError(
          "Failed to load google address. Please refresh and try again."
        );
      }
    }
  };

  const onAttachPhoto = async (file: any) => {
    if (listOfAttachment.length + file.target.files.length <= 3) {
      if (!!file && file.target.files.length > 0) {
        const attachments = file.target.files;
        const allFilesAreImgs = Object.values(attachments).map(
          (attachment: any) => {
            return !!attachment.type.match("image.*");
          }
        );
        if (_.includes(allFilesAreImgs, false)) {
          setError("Please reupload, file is not an image.");
        } else {
          const toBeUploadedAttachments = _.concat(
            Object.values(attachments),
            listOfAttachment
          );
          setListOfAttachment(toBeUploadedAttachments);
        }
      }
    } else {
      setError("Attachment exceeds the maximum number.");
    }
  };

  // const attachmentsData =
  //   !_.isEmpty(existingData) && !_.isEmpty(existingData!.attachments)
  //     ? _.concat(existingData!.attachments, listOfAttachment)
  //     : listOfAttachment;

  return (
    <>
      <div className="job-schedule-form-inputs-container">
        <IonLabel className="job-schedule-header ewp-h3 primary">
          Job Details
        </IonLabel>
        {/* <IonItem
          lines="none"
          className="job-schedule-remote-item ion-no-padding ion-no-margin"
        >
          <IonLabel className="ewp-h4">Remote Job</IonLabel>
          <IonToggle
            mode="ios"
            onIonChange={(event) => {
              setIsRemoteJob(event.detail.checked);
            }}
            checked={isRemoteJob}
          />
        </IonItem> */}

        <div className="job-schedule-dates-container">
          <IonLabel className="ewp-h4">Dates</IonLabel>
          <div className="job-schedule-dates-input-container">
            <EWPDatePicker
              className="job-schedule-start-date"
              datePickerLabel="Starts On"
              selectedDate={selectedStartDate}
              onDateChange={(selectedDate: Date) => {
                setSelectedStartDate(selectedDate);
                if (
                  !_.isNull(selectedStartDate) ||
                  !_.isNull(selectedEndDate)
                ) {
                  getTrucksOptions(selectedClient);
                }
                getFiltertedJobSchedules(selectedDate, selectedEndDate);
                setErrorMsgStartDate("");
              }}
              minDate={new Date()}
              {...(!_.isNull(selectedEndDate) && { maxDate: selectedEndDate })}
              errorMsg={errorMsgStartDate}
            />
            <EWPDatePicker
              className="job-schedule-end-date"
              datePickerLabel="Ends On"
              selectedDate={selectedEndDate}
              onDateChange={(selectedDate: Date) => {
                setSelectedEndDate(selectedDate);
                if (
                  !_.isNull(selectedStartDate) ||
                  !_.isNull(selectedEndDate)
                ) {
                  getTrucksOptions(selectedClient);
                }
                getFiltertedJobSchedules(selectedStartDate, selectedDate);
                setErrorMsgEndDate("");
              }}
              minDate={
                !_.isNull(selectedStartDate) ? selectedStartDate : new Date()
              }
              errorMsg={errorMsgEndDate}
            />
          </div>
        </div>
        <div className="job-schedule-time-container">
          <IonLabel className="ewp-h4">Onsite Time</IonLabel>
          <div className="job-schedule-time-input-container">
            <EWPTimePicker
              className="job-schedule-time-picker"
              selectedTime={selectedOnSiteTime}
              onTimeChange={(selectedTime: Date) => {
                console.log("got them wohooo time", selectedTime);
                setSelectedOnSiteTime(selectedTime);
                setErrorOnSiteTime("");
              }}
              errorMsg={errorMsgOnSiteTime}
            />
            <div
              className={`job-schedule-shift-container ${
                !!selectedOnSiteTime
                  ? isDayShift(selectedOnSiteTime)
                    ? "day-shift"
                    : "night-shift"
                  : ""
              }`}
            >
              <IonLabel className="ewp-h4 white">
                {!!selectedOnSiteTime
                  ? isDayShift(selectedOnSiteTime)
                    ? "Day Shift"
                    : "Night Shift"
                  : ""}
              </IonLabel>
            </div>
          </div>
        </div>
        <div className="job-schedule-time-container">
          <IonLabel className="ewp-h4">Finish Time</IonLabel>
          <div className="job-schedule-time-input-container">
            <EWPTimePicker
              className="job-schedule-time-picker finish"
              selectedTime={selectedFinishTime}
              onTimeChange={(selectedTime: Date) => {
                setSelectedFinishTime(selectedTime);
                setErrorFinishTime("");
              }}
              errorMsg={errorMsgFinishTime}
            />
          </div>
        </div>
        <div className="job-schedule-common-dropdown-container">
          <IonLabel className="ewp-h4">Client</IonLabel>
          <div className="job-schedule-common-dropdown-dropdown-container">
            <EWPWebDropdownSelect
              disabled={_.isNull(clientOptions)}
              dropdownItems={clientOptions || []}
              onSelectItem={(id) => {
                setSelectedClient(id as string);
                setErrorMsgClient("");

                getTrucksOptions(id as string);
                getClientContacts(id as string);
              }}
              placeholder="Choose Client"
              value={selectedClient}
              errorMsg={errorMsgClient}
              hasSearch={true}
            />
          </div>
        </div>
        <EWPLink
          color={EWPCOLORS.primary}
          title="Create New Client"
          className="job-schedule-create-client ewp-paragraph"
          onClick={() => {
            console.log("SHOW THE CREATE Client DIALOG");
            setShowCreateNewClient(true);
          }}
        />
        <div className="job-schedule-common-dropdown-container">
          <IonLabel className="ewp-h4">Contact Person</IonLabel>
          <div className="job-schedule-common-dropdown-dropdown-container">
            <EWPWebDropdownSelect
              disabled={_.isNull(contactPersonOptions)}
              dropdownItems={contactPersonOptions || []}
              onSelectItem={(id) => {
                setSelectedContactPerson(id as string);
                setErrorMsgContactPerson("");
              }}
              placeholder="Choose Contact"
              value={selectedContactPerson}
              errorMsg={errorMsgContactPerson}
              hasSearch={true}
            />
          </div>
        </div>
        {!_.isEmpty(selectedClient) && (
          <EWPLink
            color={EWPCOLORS.primary}
            title="Create New Contact"
            className="job-schedule-create-contact ewp-paragraph"
            onClick={() => {
              console.log("SHOW THE CREATE CONTACT DIALOG");
              setShowCreateNewContact(true);
            }}
          />
        )}

        <div className="job-schedule-booking-contact-container">
          <IonLabel className="ewp-h4">Booking Contact Name</IonLabel>
          <div className="job-schedule-form-input-container">
            <EWPInput
              inputLabel="Booking Contact Name"
              inputValue={bookingContactName}
              name="bookingContactName"
              inputType="text"
              className="job-schedule-common-input"
              onInputChange={(bookingContactName) => {
                setBookingContactName(bookingContactName);
              }}
            />
          </div>
        </div>

        <div className="job-schedule-booking-contact-container">
          <IonLabel className="ewp-h4">Booking Contact Method</IonLabel>
          <div className="job-schedule-form-input-container">
            <EWPInput
              inputLabel="Booking Contact Method"
              inputValue={bookingContactNumber}
              name="bookingContactNumber"
              inputType="text"
              className="job-schedule-common-input"
              onInputChange={(bookingContactNumber) => {
                setBookingContactNumber(bookingContactNumber);
              }}
            />
          </div>
        </div>

        <div className="job-schedule-common-dropdown-container">
          <IonLabel className="ewp-h4">Truck</IonLabel>
          <div className="job-schedule-common-dropdown-dropdown-container">
            <EWPWebDropdownSelect
              disabled={
                (_.isNull(trucksWithDepotOptions) ||
                  _.isNull(selectedStartDate) ||
                  _.isNull(selectedEndDate) ||
                  _.isEmpty(selectedClient)) &&
                (_.isEmpty(jobFor.truckId) || _.isNull(trucksWithDepotOptions))
              }
              dropdownItems={trucksWithDepotOptions || []}
              onSelectItem={(id) => {
                setSelectedTruck(id as string);
                getDriverOptions(
                  id as string,
                  filteredJobSchedules as JobSchedule[]
                );
                setErrorMsgTruckNumber("");
              }}
              placeholder="Choose Truck"
              value={selectedTruck}
              errorMsg={erroMsgTruckNumber}
              hasSearch={true}
            />
          </div>
        </div>
        <div className="job-schedule-common-dropdown-container">
          <IonLabel className="ewp-h4">Driver</IonLabel>
          <div className="job-schedule-common-dropdown-dropdown-container">
            <EWPWebDropdownSelect
              disabled={_.isNull(driverOptions)}
              dropdownItems={driverOptions || []}
              onSelectItem={(id) => {
                setSelectedDriver(id as string);
                setErrorMsgDriver("");
              }}
              placeholder="Choose Driver"
              value={selectedDriver}
              errorMsg={erroMsgDriver}
              hasSearch={true}
            />
          </div>
          {!_.isEmpty(selectedDriver) && (
            <IonButton
              className="remove-driver"
              fill="clear"
              color={EWPCOLORS.light}
              onClick={() => {
                setSelectedDriver(undefined);
              }}
            >
              <IonIcon icon={closeOutline} color={EWPCOLORS.light} />
            </IonButton>
          )}
        </div>
        <div className="job-schedule-address-container">
          <IonLabel className="ewp-h4">Address</IonLabel>
          <div className="job-schedule-form-input-container">
            <EWPSearchInput
              value={address}
              onChange={(address) => {
                setAddress(address);
                setErrorMsgAddress("");
              }}
              elementRef={searchAddressEle}
              errorMessage={erroMsgAddress}
            />
          </div>
        </div>
        <div className="job-schedule-notes-container">
          <IonLabel className="ewp-h4">Add Notes</IonLabel>
          <div className="job-schedule-form-input-container">
            <EWPInput
              errorMessage={errorMsgJobNotes}
              inputValue={jobNotes}
              name="notes"
              mode="textArea"
              inputType="text"
              hideLabel={true}
              className="job-schedule-notes-input"
              onInputChange={(jobNotes) => {
                setJobNotes(jobNotes);
                setErrorMsgJobNotes("");
              }}
            />
          </div>
        </div>
        <div className="job-schedule-attachments-container">
          <IonLabel className="ewp-h4">Job Attachments</IonLabel>
          <div className="job-schedule-form-input-container">
            <div className="job-schedule-button-attach-list-container">
              <div className="job-schedule-attachment-list-attachment-container">
                <IonGrid>
                  {!!listOfAttachment &&
                    !_.isEmpty(listOfAttachment) &&
                    _.chunk(listOfAttachment, 6).map(
                      (row: any, indexRow: any) => {
                        return (
                          <IonRow key={`row_${indexRow}`}>
                            {row.map((attachment: any, indexCol: any) => {
                              return (
                                <IonCol size="4">
                                  <div
                                    key={`col_${indexCol}`}
                                    className="job-schedule-attachment-attachment-container"
                                  >
                                    <IonButton
                                      className="remove-attachment-button ion-no-margin ion-no-padding"
                                      shape="round"
                                      color="primary"
                                      onClick={() =>
                                        onRemoveAttachment!(attachment)
                                      }
                                    >
                                      <IonIcon
                                        className="job-schedule-attachment-attachment-close-icon ion-no-margin ion-no-padding"
                                        icon={close}
                                      />
                                    </IonButton>
                                    <IonThumbnail
                                      className="job-schedule-attachment-attachment-avatar ion-no-margin ion-no-padding"
                                      slot="start"
                                    >
                                      <img
                                        id="attachmentPhoto"
                                        src={
                                          typeof attachment === "string"
                                            ? attachment
                                            : URL.createObjectURL(attachment)
                                        }
                                        alt="attachmentPhoto"
                                      />
                                    </IonThumbnail>
                                  </div>
                                </IonCol>
                              );
                            })}
                          </IonRow>
                        );
                      }
                    )}
                </IonGrid>
              </div>
              <IonButton
                mode="ios"
                className="add-attachment-button ewp-h6 ewp-web-gray-button"
                onClick={() => onClickUploadPhoto()}
              >
                + Add Attachment
              </IonButton>
            </div>
            {/*end of thumbnail and button*/}
          </div>
        </div>
        {/* {!!isRemoteJob && (
         
        )} */}
        {/* <div className="job-schedule-remote-details-container">
          <EWPDivider />
          <IonLabel className="job-schedule-remote-header ewp-h3 primary">
            Departure & Travel Details
          </IonLabel>
          <div className="job-schedule-common-input-container">
            <IonLabel className="ewp-h4">Depot Departure Time</IonLabel>
            <div className="job-schedule-form-input-container">
              <EWPInput
                errorMessage={errorMsgTravelJobTime}
                inputLabel="Travel Time"
                inputValue={travelJobTime}
                name="remoteTravelTime"
                inputType="text"
                className="job-schedule-common-input"
                onInputChange={(travelJobTime) => {
                  setTravelJobTime(travelJobTime);
                  setErrorMsgTravelJobTime("");
                }}
              />
            </div>
          </div>
          <div className="job-schedule-common-input-container">
            <IonLabel className="ewp-h4">Distance and ETA to Job</IonLabel>
            <div className="job-schedule-form-input-container">
              <EWPInput
                errorMessage={errorMsgDistanceJobTime}
                inputLabel="Enter Time"
                inputValue={distanceTimeJob}
                name="distanceToJob"
                inputType="text"
                className="job-schedule-common-input"
                onInputChange={(distanceTimeJob) => {
                  setDistanceTimeJob(distanceTimeJob);
                  setErrorMsgDistanceJobTime("");
                }}
              />
            </div>
          </div>
          <div className="job-schedule-common-input-container">
            <IonLabel className="ewp-h4">Travel Back Time</IonLabel>
            <div className="job-schedule-form-input-container">
              <EWPInput
                errorMessage={errorMsgTravelBackTime}
                inputLabel="Enter Time"
                inputValue={travelBackTime}
                name="travelBackTime"
                inputType="text"
                className="job-schedule-common-input"
                onInputChange={(travelBackTime) => {
                  setTravelBackTime(travelBackTime);
                  setErrorMsgTravelBackTime("");
                }}
              />
            </div>
          </div>
          <div className="job-schedule-stop-point-container">
            <IonLabel className="job-schedule-stop-point-label ewp-h4">
              Recommended Stop Points
            </IonLabel>
            <div className="job-schedule-stop-point-input-container">
              {!_.isEmpty(stopPoints) &&
                stopPoints.map((stopPoint, index) => {
                  return (
                    <IonItem
                      className="job-schedule-stop-point-item"
                      lines="none"
                      color={EWPCOLORS.tertiary}
                      key={`stop-${index}`}
                    >
                      <IonInput
                        className="job-schedule-stop-point-input"
                        placeholder="+ Add Stop Point"
                        value={stopPoint}
                        readonly={true}
                      ></IonInput>
                      <IonButton
                        mode="ios"
                        fill="clear"
                        className="job-schedule-stop-point-add-button ewp-h6"
                        onClick={() => {
                          const clonedStopPoints = _.cloneDeep(stopPoints);
                          const newValue = _.remove(
                            clonedStopPoints,
                            (clonedStopPoint) =>
                              !(clonedStopPoint === stopPoint)
                          );
                          setStopPoints(newValue);
                          setStopPointInputText("");
                        }}
                      >
                        <IonIcon icon={close} />
                      </IonButton>
                    </IonItem>
                  );
                })}
              <IonItem
                className="job-schedule-stop-point-item"
                lines="none"
                color={EWPCOLORS.tertiary}
              >
                <IonInput
                  className="job-schedule-stop-point-input"
                  placeholder="+ Add Stop Point"
                  autocomplete="on"
                  value={stopPointInputText}
                  ref={searchEle}
                  onIonChange={(event) => {
                    mountSearchLocation();
                    setStopPointInputText(event.detail.value || "");
                  }}
                ></IonInput>
                <IonButton
                  mode="ios"
                  className={`job-schedule-stop-point-add-button ewp-web-gray-button ${
                    !_.isEmpty(stopPointInputText)
                      ? "onFocus ewp-h6 white"
                      : "ewp-h6"
                  }`}
                  onClick={() => {
                    if (
                      !!stopPointInputText &&
                      !_.isEmpty(stopPointInputText)
                    ) {
                      const clonedStopPoints = _.cloneDeep(stopPoints);
                      setStopPoints([...clonedStopPoints, stopPointInputText]);
                      setStopPointInputText("");
                    }
                  }}
                >
                  Add
                </IonButton>
              </IonItem>
            </div>
          </div>
          <EWPDivider />
        </div> */}

        <IonItem
          lines="none"
          className="job-schedule-notify-item ion-no-padding ion-no-margin"
        >
          <IonLabel className="ewp-h4">Notify Driver</IonLabel>
          <IonToggle
            mode="ios"
            disabled={_.isEmpty(selectedDriver)}
            onIonChange={(event) => {
              setNotifyDriver(event.detail.checked);
            }}
            checked={notifyDriver}
          />
        </IonItem>
        <IonItem
          lines="none"
          className="job-schedule-notify-item ion-no-padding ion-no-margin"
        >
          <IonLabel className="ewp-h4">Pencilled In</IonLabel>
          <IonCheckbox
            mode="ios"
            onIonChange={(event) => {
              setPencilledIn(event.detail.checked);
            }}
            checked={pencilledIn}
          />
        </IonItem>
        <div className="job-schedule-form-button-container">
          <IonButton
            mode="ios"
            fill="clear"
            className="job-schedule-form-cancel-button ewp-h4"
            color={EWPCOLORS.dark}
            routerLink={routes.HOME_JOB_SCHEDULE}
          >
            Cancel
          </IonButton>
          <div className="job-schedule-form-delete-save-container">
            {!!isEdit && (
              <IonButton
                mode="ios"
                fill="clear"
                className="job-schedule-form-submit-button delete ewp-h3 small primary ion-no-padding ion-no-margin"
                onClick={() => {
                  setShowDeleteDialog(true);
                }}
              >
                Delete Job
              </IonButton>
            )}
            <IonButton
              mode="ios"
              color={EWPCOLORS.primary}
              className="job-schedule-form-submit-button ewp-h3 small"
              onClick={() => {
                createJob();
              }}
            >
              {!!isEdit ? "Save Changes" : "Create Job"}
            </IonButton>
          </div>
        </div>
        {/* end of job-schedule-form-container */}
      </div>
      <input
        hidden
        type="file"
        ref={ref}
        accept="image/*"
        onChange={onAttachPhoto}
        multiple
      />

      <EWPWebDialog
        headerTitle="Create New Client"
        isOpen={showCreateNewClient}
        onDidDismiss={() => {
          setShowCreateNewClient(false);
        }}
      >
        <EWPClientForm
          {...(props as any)}
          isEdit={false}
          onModalDidDismiss={() => {
            getClientOptions();
            setShowCreateNewClient(false);
          }}
        />
      </EWPWebDialog>
      <EWPWebDialog
        headerTitle="Create New Contact"
        isOpen={showCreateNewContact}
        onDidDismiss={() => {
          setShowCreateNewContact(false);
        }}
      >
        <EWPContactForm
          {...(props as EWPProps)}
          isEdit={false}
          onModalDidDismiss={() => {
            getClientContacts(selectedClient);
            setShowCreateNewContact(false);
          }}
          clientId={selectedClient}
        />
      </EWPWebDialog>

      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.saving}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          props.history.push(routes.HOME_JOB_SCHEDULE);
          setSuccess("");
          setLoading(false);
        }}
        color={EWPCOLORS.success}
      />

      {showDeleteDialog && !_.isEmpty(jobSchedule) && isEdit && (
        <SoftDeleteJobSchedule
          isOpen={showDeleteDialog}
          notify={notifyDriver}
          jobSchedule={jobSchedule!}
          onDidDismiss={() => {
            setShowDeleteDialog(false);
          }}
          onSuccess={() => {
            props.history.push(routes.HOME_JOB_SCHEDULE);
          }}
        />
      )}
    </>
  );
};
