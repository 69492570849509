export const ENVIRONMENTS = {
  development: "development",
  production: "production",
  local: "local",
};

export const LOCAL_STORAGE = {
  accessToken: "access_token",
  fbIdToken: "id_token",
  userId: "user_id",
  coupon: "coupon",
  recentMobileVerification: "recent_mobile_verification",
  hasAcceptedNotifPermission: "hasAcceptedNotifPermission",
  hasAcceptedLocationPermission: "hasAcceptedLocationPermission",
  loggedInDriver: "loggedInDriver",
};
