import _ from "lodash";
import Bugsnag from "@bugsnag/js";
import React, { useState } from "react";
import {
  IonContent,
  IonImg,
  IonLabel,
  IonPage,
  IonSpinner,
} from "@ionic/react";

import "./SplashScreen.scss";
import dubuLogo from "../../../assets/dubu-header-logo.png";
import { isMobile, useEffectOnlyOnce } from "../../../functions/common";
import { Deploy } from "cordova-plugin-ionic";

interface EWPSplashScreenProps {
  onDidDismiss: () => void;
}
export const EWPSplashScreen = (props: EWPSplashScreenProps) => {
  const { onDidDismiss } = props;
  const [updateMessage, setUpdateMessage] = useState("Checking for updates...");
  const [doneUpdating, setDoneUpdating] = useState(false);

  useEffectOnlyOnce(() => {
    initiateOTAUpdateCheck();
  });

  const initiateOTAUpdateCheck = async () => {
    if (isMobile()) {
      try {
        // const OTAchannel = process.env.REACT_APP_APPENV;
        const OTAchannel = "production";
        if (OTAchannel !== undefined && !_.isEmpty(OTAchannel)) {
          await Deploy.configure({ channel: OTAchannel });
          const update = await Deploy.checkForUpdate();
          if (update.available) {
            // We have an update!
            await Deploy.downloadUpdate((progress) => {
              setUpdateMessage(`Downloading... ${progress}%`);
            });
            await Deploy.extractUpdate((progress) => {
              setUpdateMessage(`Extracting... ${progress}%`);
            });
            await Deploy.reloadApp();
          } else {
            dismissAllSet();
          }
        } else {
          dismissAllSet();
        }
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        // send to bugsnag ---
        dismissAllSet();
        Bugsnag.notify(new Error(error));
      }
    } else {
      dismissAllSet();
    }
  };

  const dismissAllSet = () => {
    setTimeout(() => {
      setUpdateMessage("All Set!");
      setDoneUpdating(true);
    }, 1000);
    setTimeout(() => {
      onDidDismiss();
    }, 1300);
  };

  return (
    <IonPage className="welcome-page">
      <IonContent scrollY={false} className="welcome-content">
        <div className="welcome-container">
          <IonImg src={dubuLogo} className="welcome-logo" />

          <div className="welcome-update-container">
            {!doneUpdating && <IonSpinner />}
            <IonLabel className="ewp-h5 bold">{updateMessage}</IonLabel>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
