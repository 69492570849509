import * as _ from "lodash";
import React, { Component } from "react";
import { Keyboard, KeyboardInfo } from "@capacitor/keyboard";
import {
  IonPage,
  IonContent,
  withIonLifeCycle,
  IonLoading,
  IonToast,
} from "@ionic/react";

import "./SignUp.scss";
import * as routes from "../../../constants/routes";
import { EWPHeader } from "../../../components/Mobile/EWPHeader/EWPHeader";
import { EWPButton } from "../../../components/EWPButton/EWPButton";
import { EWPProps } from "../../../config/global";
import { EWPPageTitleAndDescription } from "../../../components/EWPPageTitleAndDescription/EWPPageTitleAndDescription";
import { EWPCOLORS } from "../../../constants/config";
import { MSGS_COMMON } from "../../../constants/messages";
import { EWPInput } from "../../../components/EWPInput/EWPInput";

class SignUp extends Component<EWPProps> {
  state = {
    safeToView: false,
    segment: "tradesmen" as "tradesmen" | "companyrep",
    error: "",
    password: "",
    passwordVisibility: false,
    isLoading: false,
    errorMsgEmaillAddress: "",
    errorMsgPassword: "",
    keyboardHeight: 0,
    email: "",
    signedUp: false,
  };

  ionViewDidEnter = () => {
    setTimeout(() => {
      this.setState({ safeToView: true });
    });
  };

  componentDidMount = () => {
    this.listenForKeyboard();
  };

  listenForKeyboard = () => {
    Keyboard.addListener("keyboardDidShow", (info: KeyboardInfo) => {
      this.setState({ keyboardHeight: info.keyboardHeight });
    });

    Keyboard.addListener("keyboardWillHide", () => {
      this.setState({ keyboardHeight: 0 });
    });
  };

  render() {
    const { safeToView, error, isLoading, email, signedUp, keyboardHeight } =
      this.state;

    return (
      <>
        <IonPage
          className={`driver-sign-in-page ${safeToView ? "safe-to-view" : ""}`}
        >
          <EWPHeader title="Sign Up" onBackRoute={routes.DRIVER_LANDING} />
          <IonContent
            className={`sign-in-content ${
              keyboardHeight !== 0 ? "has-keyboard" : ""
            }`}
          >
            <EWPPageTitleAndDescription
              className="sign-in-title-container"
              title="Sign up to EWP"
              // description="If you are a Tradesmen, please log on using the form below"
            />

            <form className="sign-in-form">
              <div>
                <EWPInput
                  inputLabel="Email"
                  inputValue={email}
                  errorMessage={""}
                  name="emailAddress"
                  inputType="text"
                  onInputChange={(emailAddress) => {
                    this.setState({
                      email: emailAddress,
                    });
                  }}
                />
              </div>
              <div>
                <EWPButton
                  title="Submit"
                  onClick={() => {
                    this.setState({
                      signedUp: true,
                    });
                  }}
                />
              </div>
            </form>
          </IonContent>
          <IonLoading
            spinner="circular"
            translucent={true}
            mode="ios"
            isOpen={isLoading}
            message={MSGS_COMMON.loading}
          />
          <IonToast
            isOpen={!_.isEmpty(error)}
            onDidDismiss={() => this.setState({ error: "" })}
            message={error}
            duration={1000}
            color={EWPCOLORS.danger}
          />
          <IonToast
            isOpen={signedUp}
            message={"Sign up success! We will contact you shortly"}
            duration={2000}
            onDidDismiss={() => {
              this.setState({ signedUp: false, email: "" });
              this.props.history.push(routes.DRIVER_SIGN_IN);
            }}
            color={EWPCOLORS.success}
          />
        </IonPage>
      </>
    );
  }
}

export default withIonLifeCycle(SignUp);
