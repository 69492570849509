import * as _ from "lodash";
import * as faker from "faker";
import Bugsnag from "@bugsnag/js";

import * as capacitorStorage from "../functions/localStorageCapacitor";
import { auth, fb, firestore } from "../firebase";
import { Admin, JOBS, User, UserRole } from "../models";
import { LOCAL_STORAGE } from "../config";
import { ADMINS, USER_ROLE } from "../constants/dbCollections";
import { Role } from "../config/global";
import { getServerTimestamp } from "../functions/common";
import { createClient } from "./client";
import { createDepot } from "./depot";
import { createTruck } from "./driver";

export const updateEmail = async (emailAddress: string) => {
  let user = auth.currentUser;
  if (!!user) {
    await user.updateEmail(emailAddress);
  }
  await doSendVerificationEmail();
};

export const signIn = (email: string, password: string) => {
  return auth.signInWithEmailAndPassword(email, password);
};

export const getUserRole = async (userId: string) => {
  const result = await firestore
    .collection(USER_ROLE)
    .where("userId", "==", userId)
    .get();

  const userRole = result.docs[0];

  if (_.isEmpty(userRole) || !userRole.exists) return null;

  return (userRole.data() as UserRole).role;
};

export const signOut = async () => {
  await capacitorStorage.removeItem(LOCAL_STORAGE.accessToken);
  await capacitorStorage.removeItem(LOCAL_STORAGE.fbIdToken);
  await capacitorStorage.removeItem(LOCAL_STORAGE.userId);
  await capacitorStorage.removeItem(LOCAL_STORAGE.coupon);
  await capacitorStorage.removeItem(LOCAL_STORAGE.recentMobileVerification);
  auth.signOut();
};

export const updateAccountDetails = async (
  userId: string,
  userRole: Role,
  userDetails: Partial<User>
) => {
  const result = await firestore
    .collection(userRole)
    .doc(userId)
    .update(userDetails);

  return result;
};

export const doSendVerificationEmail = async () => {
  let user = auth.currentUser;

  try {
    if (user) {
      await user.sendEmailVerification();
    }
  } catch (errorUnknown) {
    const error = errorUnknown as any;
    console.log("ERROR: ", error);
  }
};

export const updatePassword = async (
  oldPassword: string,
  newPassword: string
) => {
  let user = auth.currentUser;
  if (user && user.email) {
    await user.reauthenticateWithCredential(
      fb.auth.EmailAuthProvider.credential(user.email, oldPassword)
    );
    await user.updatePassword(newPassword);
  }
};

export const doApplyActionCode = async (actionCode: string) => {
  await auth.applyActionCode(actionCode);
};

export const doSendResetPasswordEmail = async (email: string) => {
  await auth.sendPasswordResetEmail(email);
};

export const doResetPassword = async (code: string, password: string) => {
  await auth.confirmPasswordReset(code, password);
};

export const getPhotoUrl = async () => {
  const user = auth.currentUser;
  if (user) {
    return user.photoURL;
  }
};

//FOR ADMIN ONLY
export const updateFirebaseUserPhotoUrl = async (photoUrl: string) => {
  const user = auth.currentUser;
  if (!!user && !!photoUrl) {
    await user.updateProfile({ photoURL: photoUrl });
    await firestore.collection(ADMINS).doc(user.uid).update({
      profilePhoto: photoUrl,
    });
  }
};

export const removedFirebaseUserPhotoUrl = async () => {
  const user = auth.currentUser;
  if (!!user) {
    await user.updateProfile({ photoURL: null });
    await firestore.collection(ADMINS).doc(user.uid).update({
      profilePhoto: null,
    });
  }
};

export const getAllAdminUser = (
  callback: (adminUsers: Admin[], error?: string) => void
) => {
  try {
    const unsubscribe = firestore.collection(ADMINS).onSnapshot((query) => {
      if (!query.empty) {
        callback(
          query.docs.map((admin) => {
            return {
              docId: admin.id,
              ...admin.data(),
            } as Admin;
          })
        );
      } else {
        callback([]);
      }
    });
    return unsubscribe;
  } catch (eUnknown) {
    const e = eUnknown as any;
    callback([], e);
    Bugsnag.notify(new Error(e));
    return () => {};
  }
};

export const archiveAdmin = async (adminId: string) => {
  const updatedBy = auth.currentUser;
  if (!_.isNull(updatedBy)) {
    await firestore.collection(ADMINS).doc(adminId).update({
      archived: true,
      updatedBy: updatedBy.uid,
      updatedDt: getServerTimestamp(),
    });
  } else {
    throw new Error("No user logged in");
  }
};

export const restoreAdmin = async (adminId: string) => {
  const updatedBy = auth.currentUser;
  if (!_.isNull(updatedBy)) {
    await firestore.collection(ADMINS).doc(adminId).update({
      archived: false,
      updatedBy: updatedBy.uid,
      updatedDt: getServerTimestamp(),
    });
  } else {
    throw new Error("No user logged in");
  }
};

export const createDummyData = async () => {
  //create depot
  const depotIds = await Promise.all(
    [
      "Goulburn",
      "Gold Coast",
      "Sunshine Coast",
      "Rockhampton",
      "Townsville",
    ].map((depotName) => {
      return createDepot(
        depotName,
        faker.address.city(),
        `${faker.name.firstName()} ${faker.name.lastName()}`,
        `+614${faker.random.number(99999999).toString()}`
      );
    })
  );

  //create trucks
  //UBM
  const truckIds = await Promise.all(
    [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
    ].map((truckNo) => {
      return createTruck(
        `UBM ${truckNo}`,
        truckNo,
        JOBS.ubm.id,
        faker.vehicle.manufacturer(),
        faker.vehicle.model(),
        faker.random.number(9999).toString(),
        faker.random.alphaNumeric(3) + faker.random.number(9999999).toString(),
        faker.random.alphaNumeric(3) + faker.random.number(9999999).toString(),
        faker.random.alphaNumeric(3) + faker.random.number(9999999).toString(),
        faker.helpers.randomize(depotIds),
        faker.commerce.productDescription()
      );
    })
  );

  //create clients
  await Promise.all(
    ["Roadtek Sunny Coast", "Roadtek Gold Coast", "Roadtek Townsville"].map(
      (clientName) => {
        return createClient(
          clientName,
          faker.random.number(99999999999).toString(),
          [faker.helpers.randomize(_.compact(truckIds))],
          [],
          faker.name.firstName(),
          faker.name.lastName(),
          `+614${faker.random.number(99999999).toString()}`,
          faker.internet.email()
        );
      }
    )
  );
};
