import * as _ from "lodash";
import React, { useState } from "react";
import { IonItem, IonSearchbar, IonLabel } from "@ionic/react";

import "./EWPSearchInput.scss";

interface EWPSearchInputProps {
  elementRef: React.RefObject<HTMLIonSearchbarElement>;
  value: string;
  onChange?: (query: string) => void;
  disabled?: boolean;
  errorMessage?: string;
}
export const EWPSearchInput = (props: EWPSearchInputProps) => {
  const { elementRef, value, onChange, disabled, errorMessage } = props;
  const [hasFocus, setHasFocus] = useState(false);

  const [inputValue, setInputValue] = useState(value);
  // useEffectOnlyOnce(() => {
  //   setInputValue(value);
  // });
  console.log("got inut value --- ", value);

  return (
    <>
      <IonItem
        className={`address-search-input-container ewp-input-item-container ${
          !_.isEmpty(inputValue) && "item-has-value"
        }`}
        lines="none"
      >
        {(hasFocus || !_.isEmpty(inputValue)) && (
          <IonLabel
            className={`address-search-input-label ewp-paragraph primary ion-no-margin ${
              !_.isEmpty(errorMessage) && "danger"
            }`}
          >
            Type Address
          </IonLabel>
        )}
        <IonSearchbar
          className={`address-search-input ewp-web-input ion-no-padding  ${
            !_.isEmpty(errorMessage) && "searchbar-has-error-class"
          }`}
          autocomplete="on"
          placeholder="Type Address"
          defaultValue={value}
          value={inputValue}
          ref={elementRef}
          onIonFocus={(event) => {
            setHasFocus(true);
          }}
          onIonBlur={(event) => {
            setHasFocus(false);
          }}
          debounce={100}
          onIonChange={(event) => {
            const value = event.detail.value || "";
            setInputValue(value);
            if (!!onChange) {
              onChange(value);
            }
          }}
          // {...(!!onChange && {
          //   onIonChange: (event) => {
          //     const value = event.detail.value || "";
          //     setInputValue(value);
          //     onChange(value);
          //   },
          // })}
          {...(disabled !== undefined && { disabled })}
        />
      </IonItem>
      {!_.isEmpty(errorMessage) && (
        <div className="ewp-input-error-container">
          <IonLabel className="ewp-input-error-msg">{errorMessage}</IonLabel>
        </div>
      )}
    </>
  );
};
