import * as _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  arrowBackOutline,
  arrowForwardOutline,
  createOutline,
  eyeOutline,
  fileTrayFullOutline,
} from "ionicons/icons";
import {
  IonToast,
  IonLoading,
  IonSpinner,
  IonItemDivider,
  IonLabel,
  IonItem,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
  IonThumbnail,
  IonIcon,
  IonSearchbar,
} from "@ionic/react";

import "./FaultsAndDefects.scss";
import * as services from "../../../services";
import { isTablet, useEffectOnlyOnce } from "../../../functions/common";
import { EWPTitleSearchWithButton } from "../../../components/EWPTitleSearchWithButton/EWPTitleSearchWithButton";
import { AttachmentWithIndex } from "../../../components/Web/EWPNoteCard/EWPNoteCard";
import { AttachmentDialog } from "../../../components/Web/AttachmentDialog/AttachmentDialog";
import { EWPDivider } from "../../../components/EWPDivider/EWPDivider";
import { EWPWebDropdownSelect } from "../../../components/EWPWebDropdownSelect/EWPWebDropdownSelect";
import { ClosedFaultsAndDefectsDialog } from "../../../components/ClosedFaultsAndDefectsDialog/ClosedFaultsAndDefectsDialog";
import { RequestPartsDialog } from "../../../components/RequestPartsDialog/RequestPartsDialog";
import { FaultsAndDefectsDialog } from "../../../components/FaultsAndDefectsDialog/FaultsAndDefectsDialog";
import { FaultsAndDefectsNotesDialog } from "../../../components/FaultsAndDefectsNotesDialog/FaultsAndDefectsNotesDialog";
import {
  CompletionDetails,
  FaultDefectListView,
  FaultDefectNotes,
} from "../../../models";
import { EWPCOLORS } from "../../../constants/config";
import { MSGS_COMMON } from "../../../constants/messages";
import {
  EWPProps,
  FaultDefectStatus,
  FaultDefectUrgency,
  FAULT_DEFECT_STATUS,
  FAULT_DEFECT_URGENCY,
} from "../../../config/global";

interface FaultDefectListViewWithUpdateFunction extends FaultDefectListView {
  updateStatus: (ticketId: string, status: FaultDefectStatus) => Promise<void>;
  updateUrgency: (
    ticketId: string,
    urgency: FaultDefectUrgency,
    faultDefect: FaultDefectListView
  ) => Promise<void>;
  setToEditFFaultsAndDefects: (faultDefect: FaultDefectListView) => void;
  faultDefectNotesMap: { [faultDefectId: string]: FaultDefectNotes[] };
  setNotesDialogOpen: (faultAndDefect: FaultDefectListView) => void;
  parentProps: EWPProps;
}
export const FaultsAndDefects = (props: EWPProps) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [showClosed, setShowClosed] = useState(false);
  const [selectedTruck, setSelectedTruck] = useState("all");
  const [selectedSort, setSelectedSort] = useState("createdBy");

  const [faultsAndDefects, setFaultsAndDefects] = useState(
    null as FaultDefectListView[] | null
  );
  const [filteredFaultsAndDefects, setFilteredFaultsAndDefects] = useState(
    null as FaultDefectListView[] | null
  );
  const [toEditFaultsAndDefects, setToEditFFaultsAndDefects] = useState(
    {} as FaultDefectListView
  );
  const [faultDefectNotesMap, setFaultDefectNotesMap] = useState(
    {} as { [faultDefectId: string]: FaultDefectNotes[] }
  );
  const [toUpdateNotesFaultAndDefect, setToUpdateNotesFaultAndDefects] =
    useState({} as FaultDefectListView);
  const [searchQuery, setSearchQuery] = useState("");
  const [newEditFaultAndDefectDialogOpen, setNewEditFaultAndDefectDialogOpen] =
    useState(false);

  useEffectOnlyOnce(() => {
    getFaultsAndDefects();
  });

  const getFaultsAndDefects = () => {
    services.getFaultAndDefectsRealTime((faultsAndDefects, error = "") => {
      getFaultsAndDefectsNotes(
        _.compact(faultsAndDefects.map((faultDefect) => faultDefect.docId)),
        faultsAndDefects
      );
      setError(error);
    });
  };

  const getFaultsAndDefectsNotes = async (
    faultDefectIds: string[],
    faultDefects: FaultDefectListView[]
  ) => {
    if (faultDefectIds.length > 0) {
      const faultDefectNotesMap = {} as {
        [faultDefectId: string]: FaultDefectNotes[];
      };
      await Promise.all(
        faultDefectIds.map((faultDefectId) => {
          return new Promise(async (resolve) => {
            services.getFaultDefectNotes(faultDefectId, (notes, error = "") => {
              faultDefectNotesMap[faultDefectId] = notes;
              setFaultDefectNotesMap(faultDefectNotesMap);
              console.log("SILENT ERROR IN GETTING NOTES", error);
              console.log("LATEST NOTES", faultDefectNotesMap);
              resolve(true);
            });
          });
        })
      );

      setFaultsAndDefects(faultDefects);
    }
  };

  const updateTicketStatus = async (
    ticketId: string,
    status: FaultDefectStatus
  ) => {
    try {
      setLoading(true);
      await services.updateFaultAndDefects(ticketId, {
        status,
      });
      setLoading(false);
    } catch (eUnknown) {
      const e = eUnknown as any;
      setLoading(false);
      setError(e);
    }
  };

  const updateTicketUrgency = async (
    ticketId: string,
    urgency: FaultDefectUrgency,
    faultDefect: FaultDefectListView
  ) => {
    try {
      setLoading(true);
      const { authUser } = props;
      await services.updateFaultAndDefects(
        ticketId,
        {
          urgency,
        },
        true,
        faultDefect,
        `${authUser.firstName} ${authUser.lastName}`
      );

      setLoading(false);
    } catch (eUnknown) {
      const e = eUnknown as any;
      setLoading(false);
      setError(e);
    }
  };

  const search = (searchQuery: string) => {
    if (searchQuery.trim().length > 0) {
      const filteredTickets = _.filter(
        filteredFaultsAndDefects || faultsAndDefects || [],
        (faultDefect: FaultDefectListView) => {
          const formattedQuery = searchQuery.trim().toLowerCase();

          return (
            faultDefect.truck.name.toLowerCase().indexOf(formattedQuery) !==
              -1 ||
            faultDefect.createdByUser.firstName
              .toLowerCase()
              .indexOf(formattedQuery) !== -1 ||
            faultDefect.createdByUser.lastName
              .toLowerCase()
              .indexOf(formattedQuery) !== -1 ||
            faultDefect.description.toLowerCase().indexOf(formattedQuery) !==
              -1 ||
            (faultDefect.hours || "").toLowerCase().indexOf(formattedQuery) !==
              -1 ||
            (faultDefect.kilometers || "")
              .toLowerCase()
              .indexOf(formattedQuery) !== -1
          );
        }
      );
      setFilteredFaultsAndDefects(filteredTickets);
    } else {
      setFilteredFaultsAndDefects(null);
    }
  };

  return (
    <div className="faults-and-defects-container">
      <EWPTitleSearchWithButton
        {...props}
        pageTitle="Faults & Defects"
        toggleButtonFunction={(isChecked) => {
          setShowClosed(isChecked);
        }}
        toggleButtonLabel="Show Closed Tickets"
        disabled={!_.isNull(faultsAndDefects) ? false : true}
      />
      {!_.isEmpty(faultsAndDefects) && (
        <div className="faults-and-defects-header">
          <div className="search-button-input-container">
            <EWPWebDropdownSelect
              className="search-category-button"
              widthSize="half-dialog-form"
              dropdownItems={_.flatten([
                [{ id: "all", name: "All Trucks" }],
                faultsAndDefects!.map((faultAndDefect) => {
                  return {
                    id: faultAndDefect.truckId,
                    name: faultAndDefect.truck.name,
                  };
                }),
              ])}
              onSelectItem={(id) => {
                setSearchQuery("");
                setSelectedTruck(id.toString());
                if (id === "all") {
                  setFilteredFaultsAndDefects(null);
                } else {
                  setFilteredFaultsAndDefects(
                    _.filter(
                      faultsAndDefects,
                      (faultAndDefect) => faultAndDefect.truckId === id
                    )
                  );
                }
              }}
              value={selectedTruck}
              placeholder="Choose Truck"
            />
            <IonSearchbar
              mode="md"
              value={searchQuery}
              className="ewp-title-search-button-search-bar ewp-body ion-text-left ion-no-padding"
              onIonChange={(event: CustomEvent) => {
                setSearchQuery(event.detail.value);
                search(event.detail.value);
              }}
            />
          </div>
          <div className="faults-and-defects-right-options-container">
            <IonLabel className="ewp-h3 ion-margin-right">
              {`Sort by: `}{" "}
            </IonLabel>
            <EWPWebDropdownSelect
              className="sort-button"
              widthSize="half-dialog-form"
              dropdownItems={[
                { id: "createdBy", name: "Lodgement Time" },
                { id: "urgency", name: "Urgency" },
                { id: "truck", name: "Truck" },
              ]}
              onSelectItem={(id) => {
                const selectedSort = id.toString();
                setSelectedSort(selectedSort);

                if (!_.isEmpty(filteredFaultsAndDefects)) {
                  setFilteredFaultsAndDefects(
                    _.orderBy(filteredFaultsAndDefects, (defect) => {
                      if (selectedSort === "createdBy") {
                        return defect.createdDt.toDate();
                      } else if (selectedSort === "urgency") {
                        return defect.urgency;
                      } else {
                        const dataValue = defect.truck.name;
                        let regex =
                          !_.isEmpty(dataValue) && _.isString(dataValue)
                            ? dataValue.toString().match(/\d+/)
                            : null;

                        return !_.isNull(regex)
                          ? regex.toString().padStart(16, "0")
                          : -1;
                      }
                    })
                  );
                } else {
                  setFaultsAndDefects(
                    _.orderBy(faultsAndDefects, (defect) => {
                      if (selectedSort === "createdBy") {
                        return defect.createdDt.toDate();
                      } else if (selectedSort === "urgency") {
                        return defect.urgency;
                      } else {
                        const dataValue = defect.truck.name;
                        let regex =
                          !_.isEmpty(dataValue) && _.isString(dataValue)
                            ? dataValue.toString().match(/\d+/)
                            : null;

                        return !_.isNull(regex)
                          ? regex.toString().padStart(16, "0")
                          : -1;
                      }
                    })
                  );
                }
              }}
              value={selectedSort}
              placeholder="Choose Sort"
            />
            <IonButton
              mode="ios"
              className="faults-and-defects-add-button ewp-h5 white ion-text-capitalize ion-no-margin"
              onClick={() => {
                setNewEditFaultAndDefectDialogOpen(true);
              }}
            >
              <IonIcon className="faults-and-defects-add-icon" />
              New Fault/Defect
            </IonButton>
          </div>
        </div>
      )}

      <div
        className="faults-and-defects-tickets-container"
        // disabled={_.isEmpty(faultsAndDefects)}
      >
        {_.isNull(faultsAndDefects) ? (
          <div className="faults-and-defects-spinner-container">
            <IonSpinner className="ewp-spinner" color={EWPCOLORS.primary} />
          </div>
        ) : (
          <>
            {/* Opened */}
            <div
              className={`opened-tickets ion-padding ${
                isTablet() ? "ipad" : ""
              }`}
            >
              <IonItemDivider className="ticket-header opened" sticky={true}>
                <IonLabel className="ewp-h2 bold white">Open</IonLabel>
              </IonItemDivider>
              {_.sortBy(
                _.filter(
                  filteredFaultsAndDefects || faultsAndDefects,
                  (faultAndDefect) =>
                    faultAndDefect.status === FAULT_DEFECT_STATUS.open.id
                ),
                (faultDefect) =>
                  faultDefect.urgency !== undefined ? faultDefect.urgency : 1
              ).map((faultAndDefect) => {
                return (
                  <IonItem
                    className="ticket-card-item ion-no-padding"
                    lines="none"
                  >
                    <TicketCard
                      {...faultAndDefect}
                      updateStatus={updateTicketStatus}
                      updateUrgency={updateTicketUrgency}
                      setToEditFFaultsAndDefects={(faultDefect) => {
                        setToEditFFaultsAndDefects(faultDefect);
                        setNewEditFaultAndDefectDialogOpen(true);
                      }}
                      faultDefectNotesMap={faultDefectNotesMap}
                      setNotesDialogOpen={(faultAndDefect) => {
                        setToUpdateNotesFaultAndDefects(faultAndDefect);
                      }}
                      parentProps={props}
                    />
                  </IonItem>
                );
              })}
            </div>

            {/* In Progress */}
            <div
              className={`in-progress-tickets ion-padding ${
                isTablet() ? "ipad" : ""
              }`}
            >
              <IonItemDivider
                className="ticket-header in-progress"
                sticky={true}
              >
                <IonLabel className="ewp-h2 bold white">In Progress</IonLabel>
              </IonItemDivider>
              {_.sortBy(
                _.filter(
                  filteredFaultsAndDefects || faultsAndDefects,
                  (faultAndDefect) =>
                    faultAndDefect.status === FAULT_DEFECT_STATUS.inProgress.id
                ),
                (faultDefect) =>
                  faultDefect.urgency !== undefined ? faultDefect.urgency : 1
              ).map((faultAndDefect) => {
                return (
                  <IonItem
                    className="ticket-card-item ion-no-padding"
                    lines="none"
                  >
                    <TicketCard
                      {...faultAndDefect}
                      updateStatus={updateTicketStatus}
                      updateUrgency={updateTicketUrgency}
                      setToEditFFaultsAndDefects={(faultDefect) => {
                        setToEditFFaultsAndDefects(faultDefect);
                        setNewEditFaultAndDefectDialogOpen(true);
                      }}
                      faultDefectNotesMap={faultDefectNotesMap}
                      setNotesDialogOpen={(faultAndDefect) => {
                        setToUpdateNotesFaultAndDefects(faultAndDefect);
                      }}
                      parentProps={props}
                    />
                  </IonItem>
                );
              })}
            </div>

            {/* Closed */}
            {showClosed && (
              <div
                className={`closed-tickets ion-padding ${
                  isTablet() ? "ipad" : ""
                }`}
              >
                <IonItemDivider className="ticket-header closed" sticky={true}>
                  <IonLabel className="ewp-h2 bold white">Closed</IonLabel>
                </IonItemDivider>
                {_.sortBy(
                  _.filter(
                    filteredFaultsAndDefects || faultsAndDefects,
                    (faultAndDefect) =>
                      faultAndDefect.status === FAULT_DEFECT_STATUS.closed.id
                  ),
                  (faultDefect) =>
                    faultDefect.urgency !== undefined ? faultDefect.urgency : 1
                ).map((faultAndDefect) => {
                  return (
                    <IonItem
                      className="ticket-card-item ion-no-padding"
                      lines="none"
                    >
                      <TicketCard
                        {...faultAndDefect}
                        updateStatus={updateTicketStatus}
                        updateUrgency={updateTicketUrgency}
                        setToEditFFaultsAndDefects={(faultDefect) => {
                          setToEditFFaultsAndDefects(faultDefect);
                          setNewEditFaultAndDefectDialogOpen(true);
                        }}
                        faultDefectNotesMap={faultDefectNotesMap}
                        setNotesDialogOpen={() => {}}
                        parentProps={props}
                      />
                    </IonItem>
                  );
                })}
              </div>
            )}

            {/* Waiting on Parts */}
            <div
              className={`waiting-on-parts-tickets ion-padding ${
                isTablet() ? "ipad" : ""
              }`}
            >
              <IonItemDivider
                className="ticket-header waiting-on-parts"
                sticky={true}
              >
                <IonLabel className="ewp-h2 bold white">
                  Waiting on Parts
                </IonLabel>
              </IonItemDivider>
              {_.sortBy(
                _.filter(
                  filteredFaultsAndDefects || faultsAndDefects,
                  (faultAndDefect) =>
                    faultAndDefect.status ===
                    FAULT_DEFECT_STATUS.waitingOnParts.id
                ),
                (faultDefect) =>
                  faultDefect.urgency !== undefined ? faultDefect.urgency : 1
              ).map((faultAndDefect) => {
                return (
                  <IonItem
                    className="ticket-card-item ion-no-padding"
                    lines="none"
                  >
                    <TicketCard
                      {...faultAndDefect}
                      updateStatus={updateTicketStatus}
                      updateUrgency={updateTicketUrgency}
                      setToEditFFaultsAndDefects={(faultDefect) => {
                        setToEditFFaultsAndDefects(faultDefect);
                        setNewEditFaultAndDefectDialogOpen(true);
                      }}
                      faultDefectNotesMap={faultDefectNotesMap}
                      setNotesDialogOpen={(faultAndDefect) => {
                        setToUpdateNotesFaultAndDefects(faultAndDefect);
                      }}
                      parentProps={props}
                    />
                  </IonItem>
                );
              })}
            </div>
          </>
        )}
      </div>

      <FaultsAndDefectsDialog
        {...props}
        isOpen={newEditFaultAndDefectDialogOpen}
        onDidDismiss={() => {
          setNewEditFaultAndDefectDialogOpen(false);
        }}
      />

      {!_.isEmpty(toEditFaultsAndDefects) && (
        <FaultsAndDefectsDialog
          {...props}
          isOpen={
            newEditFaultAndDefectDialogOpen &&
            !_.isEmpty(toEditFaultsAndDefects)
          }
          editData={toEditFaultsAndDefects}
          onDidDismiss={() => {
            setNewEditFaultAndDefectDialogOpen(false);
            setToEditFFaultsAndDefects({} as FaultDefectListView);
          }}
        />
      )}

      {!_.isEmpty(toUpdateNotesFaultAndDefect) &&
        !_.isEmpty(faultDefectNotesMap) && (
          <FaultsAndDefectsNotesDialog
            {...props}
            isOpen={
              !_.isEmpty(toUpdateNotesFaultAndDefect) &&
              !_.isEmpty(faultDefectNotesMap)
            }
            onDidDismiss={() => {
              setToUpdateNotesFaultAndDefects({} as FaultDefectListView);
            }}
            existingNotes={
              faultDefectNotesMap[toUpdateNotesFaultAndDefect.docId || ""]
            }
            faultAndDefect={toUpdateNotesFaultAndDefect}
          />
        )}

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => {
          setError("");
        }}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={2000}
        onDidDismiss={() => {
          setSuccess("");
        }}
        color={EWPCOLORS.success}
      />

      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />
    </div>
  );
};

const TicketCard = (faultAndDefect: FaultDefectListViewWithUpdateFunction) => {
  const [attachmentDialogOpen, setAttachmentDialogOpen] = useState(false);
  const [attachmentIndexToOpen, setAttachmentIndexToOpen] = useState(
    null as null | number
  );
  const [closeTicketDialogIsOpen, setCloseTicketDialogIsOpen] = useState(false);
  const [requestPartsDialogIsOpen, setRequestPartsDialogIsOpen] =
    useState(false);

  const attachmentsWithIndexList = faultAndDefect.attachments.map(
    (attachment, index) => {
      return { attachment, index } as AttachmentWithIndex;
    }
  );
  const [faultDefectNotesMap, setFaultDefectNotesMap] = useState(
    {} as { [faultDefectId: string]: FaultDefectNotes[] }
  );

  useEffect(() => {
    const currentNotesIdentifier = Object.keys(faultDefectNotesMap)
      .map(
        (key) =>
          `${key}_${
            !_.isEmpty(faultDefectNotesMap[key])
              ? faultDefectNotesMap[key].length
              : 0
          }`
      )
      .join(";");

    const incomingNotesIdentifier = Object.keys(
      faultAndDefect.faultDefectNotesMap
    )
      .map(
        (key) =>
          `${key}_${
            !_.isEmpty(faultDefectNotesMap[key])
              ? faultDefectNotesMap[key].length
              : 0
          }`
      )
      .join(";");

    console.log(
      "NOTES UPDATING",
      currentNotesIdentifier,
      incomingNotesIdentifier
    );

    if (currentNotesIdentifier !== incomingNotesIdentifier) {
      setFaultDefectNotesMap(faultAndDefect.faultDefectNotesMap);
    }
  }, [faultDefectNotesMap, faultAndDefect]);
  return (
    <>
      <IonCard
        className="ticket-card ion-no-margin ion-no-padding"
        color={EWPCOLORS.tertiary}
      >
        <IonCardHeader className="ticket-card-header">
          <IonLabel className="ewp-h4 bold ion-padding-bottom">
            {faultAndDefect.truck.name}
          </IonLabel>

          <EWPWebDropdownSelect
            className="status-chip"
            small={true}
            {...(faultAndDefect.urgency !== undefined && {
              value: faultAndDefect.urgency.toString(),
            })}
            dropdownItems={Object.values(FAULT_DEFECT_URGENCY).map(
              (faultDefectType) => {
                return {
                  id: faultDefectType.id,
                  name: faultDefectType.urgency,
                };
              }
            )}
            onSelectItem={(selectedUrgency) => {
              faultAndDefect.updateUrgency(
                faultAndDefect.docId || "",
                selectedUrgency as FaultDefectUrgency,
                faultAndDefect
              );
            }}
            placeholder="Assign Urgency"
          />
          <IonButton
            fill="clear"
            color={EWPCOLORS.light}
            className="ticket-card-edit"
            onClick={() => {
              faultAndDefect.setToEditFFaultsAndDefects(faultAndDefect);
            }}
          >
            <IonIcon icon={createOutline}></IonIcon>
          </IonButton>
          <IonButton
            fill="clear"
            color={EWPCOLORS.light}
            className="ticket-card-notes"
            onClick={() => {
              faultAndDefect.setNotesDialogOpen(faultAndDefect);
            }}
          >
            <IonIcon icon={fileTrayFullOutline}></IonIcon>
          </IonButton>
          <div className="ticket-card-notes-counter">
            <IonLabel className="ewp-paragraph bold white">
              {!_.isEmpty(faultDefectNotesMap[faultAndDefect.docId || ""])
                ? faultDefectNotesMap[faultAndDefect.docId || ""].length
                : 0}
            </IonLabel>
          </div>
        </IonCardHeader>
        <IonCardContent>
          <IonLabel className="ewp-h5 ion-margin-bottom">
            <b>Reported by: </b>
            {`${faultAndDefect.createdByUser.firstName} ${faultAndDefect.createdByUser.lastName}`}
          </IonLabel>
          <IonLabel className="ewp-h5 ion-margin-bottom">
            <b>Reported date: </b>
            {moment(faultAndDefect.createdDt.toDate()).format(
              "MMM DD, YYYY hh:mmA"
            )}
          </IonLabel>

          {!_.isEmpty(faultAndDefect.urgencyUpdatedByUser) && (
            <IonLabel className="ewp-h5 ion-margin-bottom">
              <b>Urgency Last Changed By: </b>
              {`${faultAndDefect.urgencyUpdatedByUser!.firstName} ${
                faultAndDefect.urgencyUpdatedByUser!.lastName
              }`}
            </IonLabel>
          )}

          <IonLabel className="ewp-h5 ion-margin-bottom">
            <b>Mechanic: </b>
            {!_.isEmpty(faultAndDefect.updatedByUser)
              ? `${faultAndDefect.updatedByUser.firstName} ${faultAndDefect.updatedByUser.lastName}`
              : "N/A"}
          </IonLabel>
          {[
            FAULT_DEFECT_STATUS.open.id,
            FAULT_DEFECT_STATUS.inProgress.id,
          ].indexOf(faultAndDefect.status) !== -1 ? (
            <IonButton
              className="request-parts-button ion-no-margin"
              color={EWPCOLORS.medium}
              // expand="full"
              onClick={() => {
                setRequestPartsDialogIsOpen(true);
              }}
            >
              Request For Parts
            </IonButton>
          ) : (
            <IonLabel className="ewp-h5 ion-margin-bottom">
              <b>Requested Parts: </b>
              {faultAndDefect.requestedParts || "N/A"}
            </IonLabel>
          )}

          <EWPDivider />

          <IonLabel className="ticket-description ewp-body ion-margin-top more">
            {faultAndDefect.description}
          </IonLabel>
          {/* <IonButton
            size="small"
            fill="clear"
            className="ewp-paragraph view-more"
            onClick={() => {
              setViewMore(!viewMore);
            }}
          >
            <u>View {viewMore ? "less" : "more"}</u>
          </IonButton> */}

          <IonLabel className="ewp-body text-break-spaces">
            <b>Kilometers: </b> {faultAndDefect.kilometers || "N/A"}
          </IonLabel>
          <IonLabel className="ewp-body text-break-spaces">
            <b>Hours: </b> {faultAndDefect.hours || "N/A"}
          </IonLabel>
          {attachmentsWithIndexList.length > 0 && (
            <IonGrid className="ion-no-margin ion-no-padding">
              {_.chunk(attachmentsWithIndexList, isTablet() ? 2 : 3).map(
                (row) => {
                  return (
                    <IonRow>
                      {row.map(
                        (attachment: AttachmentWithIndex, indexCol: any) => {
                          return (
                            <IonCol
                              key={`ticket_attachment_col_${indexCol}`}
                              size={isTablet() ? "6" : "4"}
                              className="ion-no-margin ion-no-padding"
                            >
                              <div
                                key={indexCol}
                                className="ticket-attachment-container"
                              >
                                <IonButton
                                  className="ticket-attachment-avatar-button ion-no-margin ion-no-padding"
                                  fill="clear"
                                  color={EWPCOLORS.light}
                                >
                                  <IonThumbnail
                                    className="ticket-attachment-avatar ion-no-margin ion-no-padding"
                                    slot="start"
                                    onClick={() => {
                                      console.log(attachment);
                                      setAttachmentIndexToOpen(
                                        attachment.index
                                      );
                                      setAttachmentDialogOpen(true);
                                    }}
                                  >
                                    <img
                                      id="attachmentPhoto"
                                      src={attachment.attachment}
                                      alt="attachmentPhoto"
                                    />
                                  </IonThumbnail>
                                </IonButton>
                              </div>
                            </IonCol>
                          );
                        }
                      )}
                    </IonRow>
                  );
                }
              )}
            </IonGrid>
          )}
          <div className="action-buttons">
            {[FAULT_DEFECT_STATUS.open.id].indexOf(faultAndDefect.status) !==
              -1 && (
              <IonButton
                className="action-button ewp-paragraph bold white"
                color={EWPCOLORS.medium}
                onClick={() => {
                  faultAndDefect.updateStatus(
                    faultAndDefect.docId || "",
                    FAULT_DEFECT_STATUS.inProgress.id
                  );
                }}
              >
                Start <IonIcon icon={arrowForwardOutline} />
              </IonButton>
            )}
            {[
              FAULT_DEFECT_STATUS.inProgress.id,
              FAULT_DEFECT_STATUS.waitingOnParts.id,
            ].indexOf(faultAndDefect.status) !== -1 && (
              <IonButton
                className="action-button ewp-paragraph bold white"
                color={EWPCOLORS.success}
                onClick={() => {
                  // faultAndDefect.updateStatus(
                  //   faultAndDefect.docId || "",
                  //   FAULT_DEFECT_STATUS.closed.id
                  // );
                  setCloseTicketDialogIsOpen(true);
                }}
              >
                Close <IonIcon icon={arrowForwardOutline} />
              </IonButton>
            )}
            {[FAULT_DEFECT_STATUS.closed.id].indexOf(faultAndDefect.status) !==
              -1 &&
              !_.isEmpty(faultAndDefect.completionDetails) && (
                <IonButton
                  className="action-button ewp-paragraph bold white"
                  color={EWPCOLORS.medium}
                  onClick={() => {
                    // faultAndDefect.updateStatus(
                    //   faultAndDefect.docId || "",
                    //   FAULT_DEFECT_STATUS.open.id
                    // );
                    setCloseTicketDialogIsOpen(true);
                  }}
                >
                  <IonIcon icon={eyeOutline} />
                  View
                </IonButton>
              )}
            {[
              FAULT_DEFECT_STATUS.inProgress.id,
              FAULT_DEFECT_STATUS.closed.id,
            ].indexOf(faultAndDefect.status) !== -1 && (
              <IonButton
                className="action-button ewp-paragraph bold white"
                color={EWPCOLORS.medium}
                onClick={() => {
                  faultAndDefect.updateStatus(
                    faultAndDefect.docId || "",
                    FAULT_DEFECT_STATUS.open.id
                  );
                }}
              >
                <IonIcon icon={arrowBackOutline} />
                Re-open
              </IonButton>
            )}
          </div>
        </IonCardContent>
      </IonCard>

      {faultAndDefect.attachments.length > 0 && (
        <AttachmentDialog
          attachments={faultAndDefect.attachments.map((url) => {
            return { attachmentUrl: url };
          })}
          title={faultAndDefect.truck.name}
          showModal={attachmentDialogOpen}
          isTitleHTML={true}
          {...(!_.isNull(attachmentIndexToOpen) && {
            selectedIndex: attachmentIndexToOpen,
          })}
          onModalDidDismiss={() => {
            setAttachmentDialogOpen(false);
          }}
        />
      )}

      {closeTicketDialogIsOpen && (
        <ClosedFaultsAndDefectsDialog
          {...faultAndDefect.parentProps}
          isOpen={closeTicketDialogIsOpen}
          onDidDismiss={() => {
            setCloseTicketDialogIsOpen(false);
          }}
          toCloseFaultDefectId={faultAndDefect.docId || ""}
          readonlyData={
            faultAndDefect.status !== FAULT_DEFECT_STATUS.inProgress.id
              ? faultAndDefect.completionDetails || ({} as CompletionDetails)
              : ({} as CompletionDetails)
          }
        />
      )}

      {requestPartsDialogIsOpen && (
        <RequestPartsDialog
          {...faultAndDefect.parentProps}
          isOpen={requestPartsDialogIsOpen}
          onDidDismiss={() => {
            setRequestPartsDialogIsOpen(false);
          }}
          faultAndDefectData={faultAndDefect}
          mechanicFullName={`${faultAndDefect.updatedByUser.firstName} ${faultAndDefect.updatedByUser.lastName}`}
        />
      )}
    </>
  );
};
