import * as _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonLabel,
  IonThumbnail,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";

import "./EWPNoteCard.scss";
import { AttachmentDialog } from "../AttachmentDialog/AttachmentDialog";
import {
  ClientNotes,
  FaultDefectNotes,
  JobAdminNotesView,
} from "../../../models";
import { useEffectOnlyOnce, getNotebookHoles } from "../../../functions/common";
import { DriverNotes, TruckNotes } from "../../../models/drivers";
import { EWPCOLORS } from "../../../constants/config";

interface EWPNoteCardProps {
  noteEntry:
    | ClientNotes
    | DriverNotes
    | TruckNotes
    | FaultDefectNotes
    | JobAdminNotesView;
  onEdit?: (
    noteEntry:
      | ClientNotes
      | DriverNotes
      | TruckNotes
      | FaultDefectNotes
      | JobAdminNotesView,
    index: number
  ) => void;
  onDelete?: (
    noteEntry:
      | ClientNotes
      | DriverNotes
      | TruckNotes
      | FaultDefectNotes
      | JobAdminNotesView
  ) => void;
  index: number;
  isArchivedSite?: boolean;
}

export interface AttachmentWithIndex {
  index: number;
  attachment: string;
}

export const EWPNoteCard = (props: EWPNoteCardProps) => {
  const { noteEntry, onEdit, index, isArchivedSite, onDelete } = props;

  const [attachmentDialogOpen, setAttachmentDialogOpen] = useState(false);
  const [attachmentIndexToOpen, setAttachmentIndexToOpen] = useState(
    null as null | number
  );
  const [attachmentsWithIndexList, setAttachmentsWithIndexList] = useState(
    [] as AttachmentWithIndex[]
  );
  const postByAdmin =
    !!noteEntry && (noteEntry as JobAdminNotesView).adminDetails
      ? `${
          (noteEntry as JobAdminNotesView).adminDetails.firstName +
          " " +
          (noteEntry as JobAdminNotesView).adminDetails.lastName
        }`
      : null;

  useEffectOnlyOnce(() => {
    getAttachments();
  });

  const getAttachments = async () => {
    if (noteEntry.attachments !== undefined) {
      setAttachmentsWithIndexList(
        noteEntry.attachments.map((value: string, index: number) => {
          return { index, attachment: value };
        })
      );
    }
  };

  return (
    <IonCard
      color={EWPCOLORS.tertiary}
      className="ewp-note-entry-card ion-no-margin"
    >
      <IonCardHeader className="ewp-note-entry-card-header">
        <div className="ewp-note-entry-card-header-design-container">
          {getNotebookHoles().map((hole: number) => {
            return (
              <div
                key={`notebookHole_${hole}`}
                className="ewp-note-entry-card-header-hole"
              />
            );
          })}
        </div>
        <div className="ewp-note-entry-card-header-title-and-button-container">
          <div
            className="ewp-h4"
            dangerouslySetInnerHTML={{ __html: noteEntry.notes.title }}
          />
          <div>
            {!isArchivedSite && onEdit !== undefined && (
              <IonButton
                onClick={() => {
                  onEdit(noteEntry, index);
                }}
                className="ewp-note-entry-card-edit-button ewp-h6 ewp-web-white-button ion-text-capitalize ion-no-margin"
              >
                Edit
              </IonButton>
            )}
            {!isArchivedSite && onDelete !== undefined && (
              <IonButton
                onClick={() => {
                  onDelete(noteEntry);
                }}
                className="ewp-note-entry-card-delete-button ewp-h6 ewp-web-white-button ion-text-capitalize ion-no-margin"
              >
                Delete
              </IonButton>
            )}
          </div>
        </div>
      </IonCardHeader>
      <IonCardContent className="ewp-note-entry-card-content ion-no-padding">
        <div className="ewp-note-entry-content-container">
          <div className="daily-diary-card-date-time-container">
            {postByAdmin && (
              <IonLabel
                className="daily-diary-card-date-time ewp-paragraph bold primary"
                color={EWPCOLORS.primary}
              >
                {postByAdmin}
              </IonLabel>
            )}
            <IonLabel
              className="daily-diary-card-date-time ewp-paragraph primary"
              color={EWPCOLORS.primary}
            >
              {!!noteEntry && !!noteEntry.createdDate
                ? moment(noteEntry.createdDate.toDate()).format(
                    "h:mm a - ddd D MMM YYYY"
                  )
                : ""}
            </IonLabel>
            {!!noteEntry && !!noteEntry.updatedDate && (
              <IonLabel
                className="daily-diary-card-date-time ewp-paragraph small italic"
                color={EWPCOLORS.primary}
              >
                edited on
                {!!noteEntry && !!noteEntry.createdDate
                  ? moment(noteEntry.createdDate.toDate()).format(
                      " h:mm a - ddd D MMM YYYY"
                    )
                  : ""}
              </IonLabel>
            )}
          </div>
          <div
            className="ewp-note-entry-details"
            dangerouslySetInnerHTML={{
              __html: noteEntry.notes.description,
            }}
          />
        </div>

        <div className="daily-diary-card-attachment-list-container">
          <IonGrid className="ion-no-margin ion-no-padding">
            {!!attachmentsWithIndexList &&
              attachmentsWithIndexList.length > 0 &&
              _.chunk(attachmentsWithIndexList, 6).map(
                (row: any, indexRow: any) => {
                  return (
                    <IonRow
                      key={`row_${indexRow}`}
                      className="ion-no-margin ion-no-padding"
                    >
                      {row.map(
                        (
                          attachmentWithIndex: AttachmentWithIndex,
                          indexCol: any
                        ) => {
                          return (
                            <IonCol
                              size="2"
                              className="ion-no-margin ion-no-padding"
                            >
                              <div
                                key={indexCol}
                                className="daily-diary-card-attachment-container"
                              >
                                <IonButton
                                  className="daily-diary-card-attachment-avatar-button ion-no-margin ion-no-padding"
                                  fill="clear"
                                  color={EWPCOLORS.light}
                                >
                                  <IonThumbnail
                                    className="daily-diary-card-attachment-avatar ion-no-margin ion-no-padding"
                                    slot="start"
                                    onClick={() => {
                                      console.log(attachmentWithIndex);
                                      setAttachmentIndexToOpen(
                                        attachmentWithIndex.index
                                      );
                                      setAttachmentDialogOpen(true);
                                    }}
                                  >
                                    <img
                                      id="attachmentPhoto"
                                      src={attachmentWithIndex.attachment}
                                      alt="attachmentPhoto"
                                    />
                                  </IonThumbnail>
                                </IonButton>
                              </div>
                            </IonCol>
                          );
                        }
                      )}
                    </IonRow>
                  );
                }
              )}
          </IonGrid>
        </div>
      </IonCardContent>

      {!_.isEmpty(noteEntry) &&
        !!noteEntry &&
        !!noteEntry.attachments &&
        noteEntry.attachments.length > 0 && (
          <AttachmentDialog
            attachments={noteEntry.attachments.map((url) => {
              return { attachmentUrl: url };
            })}
            title={noteEntry.notes.title}
            showModal={attachmentDialogOpen}
            isTitleHTML={true}
            {...(!_.isNull(attachmentIndexToOpen) && {
              selectedIndex: attachmentIndexToOpen,
            })}
            onModalDidDismiss={() => {
              setAttachmentDialogOpen(false);
            }}
          />
        )}
    </IonCard>
  );
};
