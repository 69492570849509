import _ from "lodash";
import React from "react";
import { auth } from "firebase";
import {
  IonToolbar,
  IonHeader,
  IonLabel,
  IonIcon,
  IonItem,
  IonAvatar,
  IonPopover,
  IonList,
  IonButtons,
  IonMenuButton,
} from "@ionic/react";

import "./EWPHeader.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { isTablet } from "../../functions/common";
import { EWPCOLORS } from "../../constants/config";
import { EWPProps } from "../../config/global";
import { caretDown } from "ionicons/icons";
import { EWPDivider } from "../EWPDivider/EWPDivider";
import { EWPPageTitleAndDescription } from "../EWPPageTitleAndDescription/EWPPageTitleAndDescription";

interface EWPHeaderProps extends EWPProps {
  overridePhotURL?: string;
}

class EWPHeader extends React.Component<EWPHeaderProps> {
  state = {
    showProfileDropdown: false,
    profileDropdownEvent: undefined,
    userPhotoURL: "",
    success: "",
  };

  componentDidMount = () => {
    if (!!auth().currentUser) {
      this.setState({ userPhotoURL: auth().currentUser!.photoURL });
    }
  };

  render = () => {
    const { showProfileDropdown, profileDropdownEvent, userPhotoURL } =
      this.state;
    const { authUser, overridePhotURL } = this.props;
    const initials = !!authUser
      ? (authUser.firstName[0] + authUser.lastName[0]).toUpperCase()
      : ""; //todo: change this once sign up ready
    let userImgSource = overridePhotURL ? overridePhotURL : userPhotoURL;
    return (
      <>
        <IonHeader mode="ios" className="ion-no-border">
          <IonToolbar
            mode="ios"
            className="ewp-header-toolbar ion-no-padding"
            color={EWPCOLORS.tertiary}
          >
            <div className="ewp-header-links-container">
              <div
                className={`ewp-header-logo-container ${
                  isTablet() ? "is-tablet" : ""
                }`}
              >
                {isTablet() && (
                  <IonButtons>
                    <IonMenuButton></IonMenuButton>
                  </IonButtons>
                )}
                <IonIcon
                  className="ewp-header-logo mb-h2 ion-no-padding ion-no-margin"
                  slot="start"
                  onClick={() => {
                    this.props.history.push(routes.HOME_JOB_SCHEDULE);
                  }}
                />
                <IonLabel className="ewp-header-logo-label">DUBU</IonLabel>
              </div>

              {!!authUser && (
                <div className="ewp-header-profile-dropdown-container">
                  <IonItem
                    mode="md"
                    button={true}
                    color={EWPCOLORS.tertiary}
                    className="ewp-nav-button mb-h4 bold ion-no-margin ion-no-padding ion-align-items-start ion-text-capitalize"
                    lines="none"
                    onClick={(e) => {
                      this.setState({
                        showProfileDropdown: true,
                        profileDropdownEvent: e.nativeEvent,
                      });
                    }}
                  >
                    <div className="nav-button-container">
                      {/* //todo: once sign up is okay make this dynamic */}
                      {_.isEmpty(userImgSource) ? (
                        <IonLabel className="ewp-header-image-placeholder-initials">
                          <span className="ewp-header-image-placeholder-initials-text ewp-h5 white">
                            {initials}
                          </span>
                        </IonLabel>
                      ) : (
                        <IonAvatar className="ewp-header-profile-picture">
                          <img src={userImgSource} alt="account-profile" />
                        </IonAvatar>
                      )}
                      <IonLabel className="ewp-nav-dropdown-label mb-h4 medium">
                        {`${authUser.firstName} ${authUser.lastName}`}
                      </IonLabel>
                      <IonIcon
                        className="ewp-nav-button-drop-down-icon"
                        icon={caretDown}
                      />
                    </div>
                  </IonItem>
                </div>
              )}
            </div>
          </IonToolbar>
        </IonHeader>
        <IonPopover
          isOpen={showProfileDropdown}
          event={profileDropdownEvent}
          className="ewp-header-profile-popover-container"
          showBackdrop={false}
          onDidDismiss={(e) =>
            this.setState({
              showProfileDropdown: false,
            })
          }
        >
          <IonItem
            className="ewp-header-profile-popover-item-name"
            lines="none"
          >
            <EWPPageTitleAndDescription
              className="ewp-header-profile-label"
              title={
                !!authUser ? `${authUser.firstName} ${authUser.lastName}` : ""
              }
            />
          </IonItem>
          <EWPDivider />
          <IonList mode="md">
            <div className="ewp-header-profile-popover-item-container">
              <IonItem
                className="ewp-header-account-settings-modal-item ion-justify-content-center"
                button={true}
                lines="none"
                routerLink={routes.ACCOUNT_SETTINGS_PROFILE}
              >
                <IonLabel className="ewp-header-account-settings-label ewp-h5">
                  <IonIcon
                    className="ewp-header-account-settings-modal-item-icon account-settings"
                    slot="start"
                  />
                  Account Settings
                </IonLabel>
              </IonItem>

              <IonItem
                className="ewp-header-account-settings-modal-item ion-justify-content-center"
                button={true}
                onClick={services.signOut}
                lines="none"
              >
                <IonLabel className="ewp-header-account-settings-label ewp-h5">
                  <IonIcon
                    className="ewp-header-account-settings-modal-item-icon logout"
                    slot="start"
                  />
                  Logout
                </IonLabel>
              </IonItem>
            </div>
          </IonList>
        </IonPopover>
      </>
    );
  };
}

export default EWPHeader;
