import * as _ from "lodash";
import {
  IonContent,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonToast,
  withIonLifeCycle,
} from "@ionic/react";
import React from "react";

import "./DriverAccount.scss";
import * as routes from "../../../constants/routes";
import * as services from "../../../services";
import { Driver } from "../../../models/drivers";
import { EWPInput } from "../../../components/EWPInput/EWPInput";
import { EWPHeader } from "../../../components/Mobile/EWPHeader/EWPHeader";
import { EWPProps } from "../../../config/global";
import { EWPPasswordInput } from "../../../components/EWPPasswordInput/EWPPasswordInput";
import { EWPProfilePhoto } from "../../../components/EWPProfilePhoto/EWPProfilePhoto";
import { EWPUserAccountFooter } from "../../../components/Mobile/EWPUserAccountFooter/EWPUserAccountFooter";
import { EWPCOLORS } from "../../../constants/config";
import { DropdownItem } from "../../../components/EWPWebDropdownSelect/EWPWebDropdownSelect";
import { MSGS_COMMON } from "../../../constants/messages";
import { EWPButton } from "../../../components/EWPButton/EWPButton";
import { EWPConfirmDialog } from "../../../components/Mobile/EWPConfirmDialog/EWPConfirmDialog";
class DriverAccount extends React.Component<EWPProps> {
  contentRef: React.RefObject<HTMLIonContentElement>;

  state = {
    safeToView: false,
    loading: false,
    confirmRedirect: false,
    error: "",
    success: "",
    truckOptions: null as null | DropdownItem[],
    selectedTruckId: "",
    errorMsgTruck: "",
    errorMsgDescription: "",
    defectDescription: "",
    errorMsgHours: "",
    defectHours: "",
    errorMsgKilometres: "",
    defectKilometres: "",
    listOfAttachment: [] as any[],
  };

  constructor(props: EWPProps) {
    super(props);
    this.contentRef = React.createRef();
  }

  ionViewDidEnter = () => {
    setTimeout(() => {
      this.setState({ safeToView: true });
    });
    this.getTruckOptions();
  };

  getTruckOptions = async () => {
    try {
      const trucks = await services.getTrucks();
      this.setState({
        truckOptions: trucks.map((truck) => {
          return {
            id: truck.docId || "",
            name: truck.name,
          };
        }),
      });
    } catch (eUnknown) {
      const e = eUnknown as any;
      this.setState({ error: e, truckOptions: [] });
    }
  };

  render = () => {
    const { safeToView, error, success, loading, confirmRedirect } = this.state;
    const { authUser } = this.props;

    return (
      <IonPage className={`driver-account ${safeToView ? "safe-to-view" : ""}`}>
        <EWPHeader
          title="Account Settings"
          hideBackButton={true}
          color={EWPCOLORS.primary}
        />
        <IonContent
          // className="driver-account-profile ion-no-padding ion-no-margin"
          ref={this.contentRef}
        >
          <div className="driver-account-profile ion-no-padding ion-no-margin">
            <IonItem className="driver-account-profile-photo-container ion-no-padding">
              <EWPProfilePhoto
                isMobile={true}
                imgSrc={""}
                initials={`${authUser.firstName[0]}${authUser.lastName[0]}`}
              />
            </IonItem>
            <div className="ion-text-center">
              <IonLabel className="ewp-h4">{`${authUser.firstName} ${authUser.lastName}`}</IonLabel>
            </div>
            <div className="driver-account-container first">
              <EWPInput
                inputLabel="First Name"
                inputValue={authUser.firstName}
                inputType="text"
                readOnly={true}
                name="firstName"
              />
            </div>
            <div className="driver-account-container">
              <EWPInput
                inputLabel="Last Name"
                inputValue={authUser.lastName}
                inputType="text"
                readOnly={true}
                name="lastName"
              />
            </div>
            <div className="driver-account-container">
              <EWPInput
                inputLabel="Email Address"
                inputValue={authUser.emailAddress}
                inputType="text"
                readOnly={true}
                name="email"
              />
            </div>
            <div className="driver-account-container">
              <EWPPasswordInput
                readonly={true}
                passwordLabel="Password"
                passwordValue={(authUser as unknown as Driver).password}
              />
            </div>
            <div className="driver-account-container">
              <EWPButton
                title="Delete Account"
                color="danger"
                className="ion-padding-horizontal"
                onClick={() => {
                  this.setState({
                    confirmRedirect: true,
                  });
                }}
              />
            </div>
          </div>
        </IonContent>
        <IonToast
          isOpen={!_.isEmpty(error)}
          message={error}
          duration={2000}
          onDidDismiss={() => {
            this.setState({ error: "" });
          }}
          color={EWPCOLORS.danger}
        />

        <IonToast
          isOpen={!_.isEmpty(success)}
          message={success}
          duration={1000}
          onDidDismiss={() => {
            this.setState({ success: "" });
          }}
          color={EWPCOLORS.success}
        />
        <IonLoading
          spinner="circular"
          translucent={true}
          mode="ios"
          isOpen={loading}
          message={MSGS_COMMON.loading}
        />
        <EWPUserAccountFooter
          activePage="driver"
          onDriverClick={() => {}}
          onMaintenanceClick={() => {
            this.props.history.push(routes.DRIVER_MAINTENANCE);
          }}
          onJobScheduleClick={() => {
            this.props.history.push(routes.DRIVER_JOB_SCHEDULE);
          }}
          onLogout={async () => {
            await services.driverLogout();
          }}
        />

        <EWPConfirmDialog
          isOpen={confirmRedirect}
          title="You're about to leave the DUBU app."
          description="Are you sure you want to continue?"
          onConfirmLabel="Yes"
          color="primary"
          onConfirm={() => {
            window.open("https://ewptruckhire.com.au/contact-us/", "_blank");
            this.setState({
              confirmRedirect: false,
            });
          }}
          onCancel={() => {
            this.setState({
              confirmRedirect: false,
            });
          }}
          onClose={() => {
            this.setState({
              confirmRedirect: false,
            });
          }}
          onDidDismiss={() => {
            this.setState({
              confirmRedirect: false,
            });
          }}
        />
      </IonPage>
    );
  };
}

export default withIonLifeCycle(DriverAccount);
