import * as _ from "lodash";
import moment from "moment";
import Bugsnag from "@bugsnag/js";
import React from "react";
import { IonSlides, IonSlide, IonContent } from "@ionic/react";

import "./TruckMainPage.scss";
import * as routes from "../../../constants/routes";
import * as services from "../../../services";
import { daysSinceDate } from "../../../functions/common";
import { EWPFormPage } from "../../../components/EWPFormPage/EWPFormPage";
import { EWPFormPageTitleDescription } from "../../../components/EWPFormPageTitleDescription/EWPFormPageTitleDescription";
import { TruckDashboard } from "../../../components/Web/TruckDashboard/TruckDashboard";
import { TruckForm } from "../../../components/TruckForm/TruckForm";
import { TruckList } from "../../../components/Web/TruckList/TruckList";
import { TruckView } from "../../../models/drivers";
import { EWPProps, DetailIdParam } from "../../../config/global";
import { JOBS_WITH_KEYS } from "../../../models";
import { NON_REMOVEABLE_TRUCK_EXPIRIES } from "../../../constants/config";

class TruckMainPage extends React.Component<EWPProps> {
  sliderRef: React.RefObject<HTMLIonSlidesElement>;

  state = {
    truckId: "",
    trucks: null as null | TruckView[],
    unsubscribeTrucks: () => {},

    error: "",
  };

  constructor(props: EWPProps) {
    super(props);
    this.sliderRef = React.createRef();
  }
  componentDidMount = () => {
    this.getTrucks();
    if (!!this.sliderRef && !!this.sliderRef.current) {
      this.sliderRef.current.lockSwipes(true);
    }

    const { detailId } = this.props.match.params as DetailIdParam;
    if (!!detailId && !_.isEmpty(detailId)) {
      this.goToPage("next");
    }
  };
  goToPage = (page: "next" | "prev") => {
    if (!!this.sliderRef && !!this.sliderRef.current) {
      this.sliderRef.current.lockSwipes(false);
      if (page === "next") this.sliderRef.current.slideNext();
      else this.sliderRef.current.slidePrev();
      this.sliderRef.current.lockSwipes(true);
    }
  };

  getTrucks = async () => {
    try {
      const depots = await services.getDepots();
      let depotsMap = {} as { [docId: string]: string };
      depots.forEach(
        (depot) => (depotsMap[depot.docId as string] = depot.name)
      );

      services.getTruckExpiryReminders((truckExpiryReminders, error) => {
        const unsubscribeTrucks = services.getTrucksRealTime(
          (trucks, error) => {
            console.log("GOT TRUCKS?? ", trucks);
            const truckListView = trucks.map((truck) => {
              const regoExpiryDateStringView = _.find(
                truck.truckExpiries,
                (expiry) =>
                  expiry.expiryId === NON_REMOVEABLE_TRUCK_EXPIRIES[0].docId
              );

              const coiExpiryDateStringView = _.find(
                truck.truckExpiries,
                (expiry) =>
                  expiry.expiryId === NON_REMOVEABLE_TRUCK_EXPIRIES[1].docId
              );

              const rmsExpiryDateStringView = _.find(
                truck.truckExpiries,
                (expiry) =>
                  expiry.expiryId === NON_REMOVEABLE_TRUCK_EXPIRIES[2].docId ||
                  expiry.expiryId === NON_REMOVEABLE_TRUCK_EXPIRIES[3].docId
              );

              const toExpire = _.find(truckExpiryReminders, (truckExpiry) => {
                const existingExpiry = _.find(
                  truck.truckExpiries || [],
                  (expiry) => expiry.expiryId === truckExpiry.docId
                );

                return (
                  !_.isEmpty(existingExpiry) &&
                  daysSinceDate(
                    new Date(),
                    existingExpiry!.expirationDate.toDate()
                  ) <=
                    truckExpiry!.expiryReminder * 7
                );
              });

              const expired = _.find(truckExpiryReminders, (truckExpiry) => {
                const existingExpiry = _.find(
                  truck.truckExpiries || [],
                  (expiry) => expiry.expiryId === truckExpiry.docId
                );

                return (
                  !_.isEmpty(existingExpiry) &&
                  daysSinceDate(
                    new Date(),
                    existingExpiry!.expirationDate.toDate()
                  ) <= 0
                );
              });

              return {
                ...truck,
                rmsTransportNumber:
                  !!truck.rmsTransportNumber &&
                  !_.isEmpty(truck.rmsTransportNumber)
                    ? truck.rmsTransportNumber
                    : "N/A",
                regoExpiryDateStringView: !_.isEmpty(regoExpiryDateStringView)
                  ? moment(
                      regoExpiryDateStringView!.expirationDate.toDate()
                    ).format("DD MMM YYYY")
                  : "N/A",
                coiExpiryDateStringView: !_.isEmpty(coiExpiryDateStringView)
                  ? moment(
                      coiExpiryDateStringView!.expirationDate.toDate()
                    ).format("DD MMM YYYY")
                  : "N/A",
                rmsExpiryDateStringView: !_.isEmpty(rmsExpiryDateStringView)
                  ? moment(
                      rmsExpiryDateStringView!.expirationDate.toDate()
                    ).format("DD MMM YYYY")
                  : "N/A",
                depotName: `${
                  !!depotsMap[truck.depotId] ? depotsMap[truck.depotId] : "N/A"
                }`,
                hasWarningExpiry: !_.isEmpty(toExpire),
                hasDueExpiry: !_.isEmpty(expired),
              };
            });

            this.setState({
              trucks: truckListView,
              error,
            });
          }
        );

        this.setState({
          unsubscribeTrucks,
        });
      });
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      this.setState(error);
      Bugsnag.notify(new Error(error));
    }
  };

  componentWillUnmount = () => {
    this.state.unsubscribeTrucks();
  };

  render = () => {
    const { trucks } = this.state;
    const { detailId, pageParam } = this.props.match.params as DetailIdParam;

    let truck = {} as TruckView;
    if (!_.isEmpty(detailId)) {
      truck =
        _.find(trucks, (truck) => truck.docId === detailId) ||
        ({} as TruckView);
    }
    return (
      <IonContent className="truck-register-container">
        <IonSlides ref={this.sliderRef}>
          <IonSlide className="truck-register-slide">
            {!detailId && !pageParam ? (
              // URL FALL BACK FOR TRUCK LIST
              <TruckList {...this.props} trucks={trucks} />
            ) : (
              // URL FALL BACK FOR CREATE TRUCK
              !!pageParam && (
                <EWPFormPage
                  {...this.props}
                  backURL={routes.HOME_TRUCK_REGISTER}
                  pageTitle={"Create New Truck"}
                >
                  <div className="truck-register-form-page-container">
                    <div className="truck-register-form-input-container">
                      <TruckForm
                        {...this.props}
                        onSuccess={() => {
                          this.props.history.push(routes.HOME_TRUCK_REGISTER);
                        }}
                      />
                    </div>
                  </div>
                </EWPFormPage>
              )
            )}
          </IonSlide>
          <IonSlide className="truck-register-slide">
            {!!detailId && pageParam === "edit" && !_.isEmpty(truck) ? (
              // URL FALL BACK FOR EDIT TRUCK
              <EWPFormPage
                {...this.props}
                backURL={routes.HOME_TRUCK_REGISTER_DETAILS.replace(
                  ":truckId",
                  detailId
                )}
                pageTitle={"Edit Truck"}
              >
                <div className="truck-register-form-page-container">
                  <div className="truck-register-form-input-container">
                    <EWPFormPageTitleDescription
                      icon="truck"
                      title={truck.name}
                      subtitle={`${JOBS_WITH_KEYS[truck.type].name} ${
                        truck.size
                      }`}
                    />
                    <div className="truck-register-form-input-container">
                      <TruckForm
                        {...(this.props as any)}
                        editData={truck}
                        onSuccess={() => {
                          this.props.history.push(
                            routes.HOME_TRUCK_REGISTER_DETAILS.replace(
                              ":truckId",
                              detailId
                            )
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </EWPFormPage>
            ) : (
              // URL FALL BACK FOR VIEW TRUCK
              !!detailId && (
                <TruckDashboard
                  {...this.props}
                  truckView={!_.isNull(trucks) ? truck : null}
                />
              )
            )}
          </IonSlide>
        </IonSlides>
      </IonContent>
    );
  };
}

export default TruckMainPage;
