import * as _ from "lodash";
import React, { useState } from "react";
import { IonToast, IonLoading } from "@ionic/react";

import "./LoginForm.scss";
// import * as api from "../../api";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { EWPInput } from "../EWPInput/EWPInput";
import { EWPButton } from "../EWPButton/EWPButton";
import { EWPPageTitleAndDescription } from "../EWPPageTitleAndDescription/EWPPageTitleAndDescription";
import { EWPPasswordInput } from "../EWPPasswordInput/EWPPasswordInput";
import { EWPLink } from "../EWPLink/EWPLInk";
import { EWPProps, USER_ROLE } from "../../config/global";
import { EWPCOLORS } from "../../constants/config";
import { MSGS_COMMON, MSGS_SIGNUP } from "../../constants/messages";
import Bugsnag from "@bugsnag/js";
import { getServerTimestamp } from "../../functions/common";

export const LoginForm = (props: EWPProps) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const login = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      setLoading(true);

      let errorMessage = "";
      if (emailAddress.length === 0) {
        errorMessage = MSGS_SIGNUP.email.required;
        setErrorEmail(errorMessage);
      } else if (password.length === 0) {
        errorMessage = MSGS_SIGNUP.password.required;
        setErrorPassword(errorMessage);
      }

      if (!_.isEmpty(errorMessage)) {
        setLoading(false);
        return;
      }
      const result = await services.signIn(emailAddress, password);
      if (!_.isEmpty(result.user)) {
        await services.updateAccountDetails(
          result.user!.uid,
          USER_ROLE.admins,
          {
            lastSignInDt: getServerTimestamp(new Date()),
          }
        );
      }
      console.log("LOGIN RESULT");

      // await services.backupData();
      setLoading(false);
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      console.log("error?", error);
      setLoading(false);
      setError(
        error.code === "auth/wrong-password"
          ? "Incorrect username or password, please try again"
          : error.message
      );
      Bugsnag.notify(new Error(error));
    }
  };

  return (
    <>
      <div className="login-form-container">
        <EWPPageTitleAndDescription
          title="Login"
          description="Login to your DUBU account."
        />
        <div className="login-form-input-container">
          <EWPInput
            inputLabel="Email"
            inputValue={emailAddress}
            errorMessage={errorEmail}
            name="emailAddress"
            inputType="text"
            onInputChange={(emailAddress) => {
              setEmailAddress(emailAddress);
            }}
          />
          <EWPPasswordInput
            passwordLabel="Password"
            className="login-form-password-input"
            passwordValue={password}
            errorMessage={errorPassword}
            onChange={(password) => {
              setPassword(password);
            }}
          />
          <EWPButton
            title="Log In"
            className="login-form-login-button"
            onClick={login}
          />
          <div className="login-form-forgot-password-container">
            <EWPLink
              color={EWPCOLORS.primary}
              title="Forgot Password?"
              className="login-form-forgot-password-link ewp-paragraph"
              routerLink={routes.FORGOT_PASSWORD}
            />
          </div>
        </div>
      </div>
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />
      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />
    </>
  );
};
