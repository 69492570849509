import * as _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import {
  alertCircle,
  checkmarkCircle,
  close,
  fileTrayFullOutline,
} from "ionicons/icons";
import {
  IonItem,
  IonIcon,
  IonButton,
  IonModal,
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
  IonThumbnail,
  IonCheckbox,
  IonLoading,
  IonToast,
} from "@ionic/react";

import "./JobScheduleDetailDialog.scss";
import * as routes from "../../constants/routes";
import * as services from "../../services";
import { EWPToolTip } from "../EWPToolTip/EWPToolTip";
import { EWPProps } from "../../config/global";
import { JobScheduleLocationInterface } from "../../pages/Home/Home";
import {
  getDateFromTimezoneIgnoredTimestamp,
  isDayShift,
  isHistoricalDate,
  isToday,
  useEffectOnlyOnce,
} from "../../functions/common";
import {
  JobAdminNotesView,
  JobSchedule,
  JobScheduleView,
  JOBS_WITH_KEYS,
  NOTIFICATION_STATUS,
  NOTIFICATION_STATUS_WITH_KEYS,
} from "../../models";
import { AttachmentWithIndex } from "../Web/EWPNoteCard/EWPNoteCard";
import { EWPCOLORS } from "../../constants/config";
import { updateJobScheduleGeneric } from "../../services";
import { MSGS_COMMON } from "../../constants/messages";
import { EWPInput } from "../EWPInput/EWPInput";
// import { EWPButton } from "../EWPButton/EWPButton";
import { JobAdminNotesDialog } from "../JobAdminNotesDialog/JobAdminNotesDialog";
// import { SoftDeleteJobSchedule } from "../SoftDeleteJobSchedule/SoftDeleteJobSchedule";

interface JobScheduleDetailDialogProps extends EWPProps {
  jobSchedule: JobScheduleView;
  isOpen: boolean;
  onDidDismiss: () => void;
  showSiteDockets: () => void;
  contentClassName?: string;
  onAttachmentToOpen?: (attachmentIndex: number) => void;
  onShowAttachmentDialog?: (isOpen: boolean) => void;
  onCancel: (jobSchedule: JobSchedule) => void;
  onUncancel: (jobSchedule: JobSchedule) => void;
  scrollToDriverNotes?: boolean;
}

export const JobScheduleDetailDialog = (
  props: JobScheduleDetailDialogProps
) => {
  const {
    isOpen,
    onDidDismiss,
    contentClassName,
    jobSchedule: jobSchdule,
    showSiteDockets,
    onCancel,
    onUncancel,
    scrollToDriverNotes,
  } = props;
  let notificationStatusId = jobSchdule.notificationStatusId; //change the way how status being check based on the model
  const [toolTipAnchor, setToolTipAnchor] = useState(
    null as Event | undefined | null
  );
  const [loading, setLoading] = useState(false);
  const [attachmentsWithIndexList, setAttachmentsWithIndexList] = useState(
    [] as AttachmentWithIndex[]
  );
  const [invoiced, setInvoiced] = useState(!!jobSchdule.invoiced);
  const [invoiceNumber, setInvoiceNumber] = useState(
    jobSchdule.invoiceNumber || ""
  );
  const [invoiceChecked, setInvoiceChecked] = useState(
    !!jobSchdule.invoiceChecked
  );
  const [errorMsgInvoiceNumber, setErrorMsgInvoiceNumber] = useState(
    jobSchdule.invoiced && _.isEmpty(jobSchdule.invoiceNumber)
      ? "Invoice Number is required"
      : ""
  );

  const [showJobAdminNotes, setShowJobAdminNotes] = useState(false);
  const [jobAdminNotes, setjobAdminNotes] = useState(
    null as null | JobAdminNotesView[]
  );
  const [success, setSuccess] = useState("");
  // const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  // const [attachmentDialogOpen, setAttachmentDialogOpen] = useState(false);
  // const [attachmentIndexToOpen, setAttachmentIndexToOpen] = useState(
  //   null as null | number
  // );
  const noteRef = useRef<HTMLDivElement>(null);

  useEffectOnlyOnce(() => {
    getAttachments();
    getJobAdminNotes();
  });

  useEffect(() => {
    if (
      !!scrollToDriverNotes &&
      !_.isNull(scrollToDriverNotes) &&
      noteRef.current
    ) {
      setTimeout(() => {
        noteRef.current!.scrollIntoView();
      }, 500);
    }
  });

  const getAttachments = async () => {
    if (jobSchdule.attachments !== undefined) {
      setAttachmentsWithIndexList(
        jobSchdule.attachments.map((value: string, index: number) => {
          return { index, attachment: value };
        })
      );
    }
  };

  const getJobAdminNotes = () => {
    if (_.isNull(jobAdminNotes) && !!jobSchdule.docId) {
      services.getJobAdminNotes(
        jobSchdule.docId as string,
        (jobAdminNote, error) => {
          setjobAdminNotes(jobAdminNote);
        }
      );
    }
  };

  const sendDriverNotif = async () => {
    try {
      setLoading(true);
      await services.notifyJobScheduleDriver(
        jobSchdule.docId || "",
        getDateFromTimezoneIgnoredTimestamp(jobSchdule.startDateNoTimeZone),
        jobSchdule.driverId || "",
        jobSchdule.clientDetails.name
      );
      notificationStatusId = NOTIFICATION_STATUS.pending.id;
      setLoading(false);
    } catch (eUnknown) {
      const e = eUnknown as any;
      setLoading(false);
      console.log("ERROR SENDING NOTIF", e);
    }
  };

  const isCancellable =
    !_.isEmpty(jobSchdule) &&
    !isHistoricalDate(
      getDateFromTimezoneIgnoredTimestamp(jobSchdule.startDateNoTimeZone)
    ) &&
    !isToday(
      getDateFromTimezoneIgnoredTimestamp(jobSchdule.startDateNoTimeZone)
    ) &&
    !jobSchdule.cancelled;
  const isUncancellable =
    !_.isEmpty(jobSchdule) &&
    !!jobSchdule.cancelled &&
    !isHistoricalDate(
      getDateFromTimezoneIgnoredTimestamp(jobSchdule.startDateNoTimeZone)
    );

  const updateInvoicing = async (invoiced: boolean) => {
    try {
      setLoading(true);

      await updateJobScheduleGeneric(jobSchdule.docId || "", { invoiced });
      setInvoiced(invoiced);
      setLoading(false);
      // onDidDismiss();
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      console.log("SILENT ERROR", error);
      setLoading(false);
    }
  };

  const updateInvoiceChecked = async (invoiceChecked: boolean) => {
    try {
      setLoading(true);

      await updateJobScheduleGeneric(jobSchdule.docId || "", {
        invoiceChecked,
      });
      setInvoiceChecked(invoiceChecked);
      setLoading(false);
      onDidDismiss();
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      console.log("SILENT ERROR", error);
      setLoading(false);
    }
  };

  const updateInvoiceNumber = _.debounce(async () => {
    try {
      setLoading(true);

      await updateJobScheduleGeneric(jobSchdule.docId || "", {
        invoiceNumber,
      });
      setLoading(false);
      setSuccess("Invoice No. Saved");
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      console.log("SILENT ERROR", error);
      setLoading(false);
    }
  }, 500);

  const dismissModal = () => {
    updateInvoiceNumber();
    onDidDismiss();
  };

  return (
    <>
      <IonModal
        className="job-schedule-detail-dialog"
        isOpen={isOpen}
        onDidDismiss={dismissModal}
      >
        <div className="modal-content">
          <IonItem
            className="job-schedule-detail-dialog-header-item ion-no-margin ion-no-padding"
            lines="none"
          >
            <IonButton
              className="job-schedule-detail-dialog-header-close ion-no-padding ion-no-margin"
              color={EWPCOLORS.light}
              fill="clear"
              slot="end"
              onClick={() => {
                if (invoiced && _.isEmpty(invoiceNumber)) {
                  setErrorMsgInvoiceNumber("Invoice Number is required");
                } else {
                  setErrorMsgInvoiceNumber("");
                  dismissModal();
                }
              }}
            >
              <IonIcon color={EWPCOLORS.light} icon={close} />
            </IonButton>
          </IonItem>
          <div
            className={`job-schedule-detail-dialog-container ${
              contentClassName !== undefined ? contentClassName : ""
            }`}
          >
            <div className="job-schedule-detail-header-container">
              <div className="job-detail-title-notes-container">
                <IonLabel className="job-schedule-detail-header-title ewp-h1 ion-no-padding ion-no-margin">
                  Job Details
                </IonLabel>
                <IonButton
                  fill="clear"
                  color={EWPCOLORS.light}
                  className="admin-notes-button"
                  onClick={() => {
                    // faultAndDefect.setNotesDialogOpen(faultAndDefect);
                    setShowJobAdminNotes(true);
                  }}
                >
                  <IonIcon icon={fileTrayFullOutline}></IonIcon>
                  <div className="admin-notes-counter">
                    <IonLabel className="ewp-paragraph bold white">
                      {jobAdminNotes ? jobAdminNotes.length : 0}
                    </IonLabel>
                  </div>
                </IonButton>
              </div>
              <IonLabel className="job-schedule-detail-header-id ewp-h4 light ion-no-padding ion-no-margin">
                <b>Job Id:</b> {jobSchdule.docId}
              </IonLabel>

              {!!jobSchdule.cancelledBy &&
                !_.isEmpty(jobSchdule.cancelledBy) && (
                  <>
                    <div className="job-schedule-status-icon-label-container">
                      <IonLabel className="ewp-paragraph danger ion-no-padding ion-no-margin">
                        {`Cancelled By: ${jobSchdule.cancelledByName}`}
                      </IonLabel>
                    </div>
                    {jobSchdule.updatedDt !== undefined && (
                      <div className="job-schedule-status-icon-label-container">
                        <IonLabel className="ewp-paragraph danger ion-no-padding ion-no-margin">
                          {`Cancelled Date: ${moment(
                            jobSchdule.updatedDt.toDate()
                          ).format("hh:mm A, dddd, DD MMM YYYY")}`}
                        </IonLabel>
                      </div>
                    )}
                  </>
                )}
              {!_.isEmpty(jobSchdule) && !jobSchdule.cancelled && (
                <div className="job-schedule-status-icon-label-container">
                  <IonLabel className="job-schedule-detail-header-read-status ewp-paragraph ion-no-padding ion-no-margin">
                    {notificationStatusId === NOTIFICATION_STATUS.notSent.id
                      ? "Notification Not Sent"
                      : !!jobSchdule.notificationReadAtNoTimeZone
                      ? `Read ${moment(
                          getDateFromTimezoneIgnoredTimestamp(
                            jobSchdule.notificationReadAtNoTimeZone
                          )
                        ).format("hh:mm A, dddd, DD MMM YYYY")}`
                      : "Not Read"}
                  </IonLabel>
                  {notificationStatusId !== NOTIFICATION_STATUS.notSent.id ? (
                    <div
                      className={`job-schedule-status-container ${NOTIFICATION_STATUS_WITH_KEYS[notificationStatusId].key}`}
                    >
                      <IonIcon
                        className="job-schedule-status-icon ion-no-padding ion-no-margin"
                        color={
                          notificationStatusId ===
                          NOTIFICATION_STATUS.accepted.id
                            ? EWPCOLORS.success
                            : EWPCOLORS.warning
                        }
                        icon={
                          notificationStatusId ===
                          NOTIFICATION_STATUS.accepted.id
                            ? checkmarkCircle
                            : alertCircle
                        }
                      />
                      <IonLabel
                        className={`job-schedule-status-label ewp-h5 ${NOTIFICATION_STATUS_WITH_KEYS[notificationStatusId].key} ion-no-padding ion-no-margin`}
                      >
                        {
                          NOTIFICATION_STATUS_WITH_KEYS[notificationStatusId]
                            .name
                        }
                      </IonLabel>
                    </div>
                  ) : (
                    <IonButton
                      disabled={loading}
                      mode="ios"
                      className="job-schedule-status-button ewp-web-gray-button ewp-h5 light ion-no-padding ion-no-margin"
                      onClick={() => {
                        sendDriverNotif();
                      }}
                    >
                      Send Driver Notification
                    </IonButton>
                  )}
                </div>
              )}
            </div>
            <IonGrid className="job-schedule-detail-grid ion-no-padding ion-no-margin">
              {/* <IonLabel className="job-schedule-detail-title ewp-h3 ion-no-padding ion-no-margin">
                {jobSchdule.truckDetails.name}
              </IonLabel> */}
              {/* <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Remote Job
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col value ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                    {jobSchdule.isRemote ? "Yes" : "No"}
                  </IonLabel>
                </IonCol>
              </IonRow> */}
              <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Site Docket
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col value ion-no-padding ion-no-margin"
                >
                  {/* //todo: create site docket attachments dialogs */}
                  <IonButton
                    className="job-schedule-detail-site-docket-button ewp-web-gray-button ion-no-padding ion-no-margin"
                    onClick={() => {
                      showSiteDockets();
                    }}
                  >
                    <IonIcon className="attachment-icon" />
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Start
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col value ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                    {moment(
                      getDateFromTimezoneIgnoredTimestamp(
                        jobSchdule.startDateNoTimeZone
                      )
                    ).format("dddd, DD MMM YYYY")}
                  </IonLabel>
                  {/* //todo: create & use the checking of the booking schdule, enable code below after */}
                  {!!jobSchdule.truckHasBooking && (
                    <IonIcon
                      onMouseOver={(event) => {
                        setToolTipAnchor(event.nativeEvent);
                      }}
                      className="job-schedule-detail-warning-icon ewp-warning-icon"
                    />
                  )}
                </IonCol>
              </IonRow>
              <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Finish
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col value ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                    {moment(
                      getDateFromTimezoneIgnoredTimestamp(
                        jobSchdule.endDateNoTimeZone
                      )
                    ).format("dddd, DD MMM YYYY")}
                  </IonLabel>
                  {/* //todo: create & use the checking of the booking schdule, enable code below after */}
                  {!!jobSchdule.truckHasBooking && (
                    <IonIcon
                      onMouseOver={(event) => {
                        setToolTipAnchor(event.nativeEvent);
                      }}
                      className="job-schedule-detail-warning-icon ewp-warning-icon"
                    />
                  )}
                </IonCol>
              </IonRow>
              <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Shift
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col  value ion-no-padding ion-no-margin"
                >
                  <div
                    className={`job-schedule-shift-container ${
                      isDayShift(
                        getDateFromTimezoneIgnoredTimestamp(
                          jobSchdule.onsiteTimeNoTimeZone
                        )
                      )
                        ? "day-shift"
                        : "night-shift"
                    }`}
                  >
                    <IonLabel className="ewp-paragraph medium-weight white">
                      {isDayShift(
                        getDateFromTimezoneIgnoredTimestamp(
                          jobSchdule.onsiteTimeNoTimeZone
                        )
                      )
                        ? "Day"
                        : "Night"}
                    </IonLabel>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Onsite Time
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col  value ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                    {moment(
                      getDateFromTimezoneIgnoredTimestamp(
                        jobSchdule.onsiteTimeNoTimeZone
                      )
                    ).format("HH:mm")}
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Finish Time
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col  value ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                    {!_.isEmpty(jobSchdule.finishTime)
                      ? moment(
                          getDateFromTimezoneIgnoredTimestamp(
                            jobSchdule.finishTime!
                          )
                        ).format("HH:mm")
                      : "N/A"}
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Client
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col  value ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                    {jobSchdule.clientDetails.name}
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Contact Person
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col  value ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                    {!_.isEmpty(jobSchdule.contactPersonDetails)
                      ? `${jobSchdule.contactPersonDetails.firstName} ${jobSchdule.contactPersonDetails.lastName}`
                      : "N/A"}
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Booking Contact Name
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col  value ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                    {!_.isEmpty(jobSchdule.bookingContactName)
                      ? jobSchdule.bookingContactName
                      : "N/A"}
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Booking Contact Method
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col  value ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                    {!_.isEmpty(jobSchdule.bookingContactNumber)
                      ? jobSchdule.bookingContactNumber
                      : "N/A"}
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Depot
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col  value ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                    {!_.isEmpty(jobSchdule.depotDetails)
                      ? jobSchdule.depotDetails.name
                      : "N/A"}
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Job Type
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col  value ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                    {!_.isEmpty(JOBS_WITH_KEYS[jobSchdule.jobType])
                      ? JOBS_WITH_KEYS[jobSchdule.jobType].name
                      : "N/A"}
                  </IonLabel>
                </IonCol>
              </IonRow>
              {/* <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Truck size
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col  value ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                    {jobSchdule.truckSize}
                  </IonLabel>
                </IonCol>
              </IonRow> */}
              <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Truck
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col  value ion-no-padding ion-no-margin"
                >
                  {!_.isEmpty(jobSchdule.truckDetails) ? (
                    <Link
                      className="job-schedule-link"
                      to={routes.HOME_TRUCK_REGISTER_DETAILS.replace(
                        ":truckId",
                        jobSchdule.truckId || ""
                      )}
                    >
                      <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                        {jobSchdule.truckDetails.name}
                      </IonLabel>
                    </Link>
                  ) : (
                    <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                      N/A
                    </IonLabel>
                  )}
                </IonCol>
              </IonRow>

              <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Driver
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col  value ion-no-padding ion-no-margin"
                >
                  {!_.isEmpty(jobSchdule.driverDetails) ? (
                    <Link
                      className="job-schedule-link"
                      to={routes.HOME_DRIVER_REGISTER_DETAILS.replace(
                        ":driverId",
                        jobSchdule.driverId || ""
                      )}
                    >
                      <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                        {`${jobSchdule.driverDetails.firstName} ${jobSchdule.driverDetails.lastName}`}
                      </IonLabel>
                    </Link>
                  ) : (
                    <IonLabel className="job-schedule-detail-value ewp-h4 normal danger ion-no-padding ion-no-margin">
                      No Driver Assigned
                    </IonLabel>
                  )}
                </IonCol>
              </IonRow>
              <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Address
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col  value ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                    {jobSchdule.address}
                  </IonLabel>
                </IonCol>
              </IonRow>
            </IonGrid>

            {!_.isEmpty(jobSchdule.notes) && (
              <div className="job-schedule-detail-note-container">
                <IonLabel className="job-schedule-detail-note-title ewp-h3 ion-no-padding ion-no-margin">
                  Notes
                </IonLabel>
                <IonLabel className="job-schedule-detail-note-value ewp-h4 normal light ion-no-padding ion-no-margin">
                  {jobSchdule.notes}
                </IonLabel>
                <div className="daily-diary-card-attachment-list-container">
                  <IonGrid className="ion-no-margin ion-no-padding">
                    {!!attachmentsWithIndexList &&
                      attachmentsWithIndexList.length > 0 &&
                      _.chunk(attachmentsWithIndexList, 6).map(
                        (row: any, indexRow: any) => {
                          return (
                            <IonRow
                              key={`row_${indexRow}`}
                              className="ion-no-margin ion-no-padding"
                            >
                              {row.map(
                                (
                                  attachmentWithIndex: AttachmentWithIndex,
                                  indexCol: any
                                ) => {
                                  return (
                                    <IonCol
                                      size="4"
                                      className="ion-no-margin ion-no-padding"
                                    >
                                      <div
                                        key={indexCol}
                                        className="daily-diary-card-attachment-container"
                                      >
                                        <IonButton
                                          className="daily-diary-card-attachment-avatar-button ion-no-margin ion-no-padding"
                                          fill="clear"
                                          color={EWPCOLORS.light}
                                        >
                                          <IonThumbnail
                                            className="daily-diary-card-attachment-avatar ion-no-margin ion-no-padding"
                                            slot="start"
                                            onClick={() => {
                                              if (
                                                props.onAttachmentToOpen &&
                                                props.onShowAttachmentDialog
                                              ) {
                                                props.onAttachmentToOpen(
                                                  attachmentWithIndex.index
                                                );
                                                props.onShowAttachmentDialog(
                                                  true
                                                );
                                              }
                                            }}
                                          >
                                            <img
                                              id="attachmentPhoto"
                                              src={
                                                attachmentWithIndex.attachment
                                              }
                                              alt="attachmentPhoto"
                                            />
                                          </IonThumbnail>
                                        </IonButton>
                                      </div>
                                    </IonCol>
                                  );
                                }
                              )}
                            </IonRow>
                          );
                        }
                      )}
                  </IonGrid>
                </div>
              </div>
            )}

            {jobSchdule.driverNotes &&
              !_.isEmpty(jobSchdule.driverNotes.description) && (
                <div
                  ref={noteRef}
                  className="job-schedule-detail-note-container"
                >
                  <IonLabel className="job-schedule-detail-note-title ewp-h3 ion-no-padding ion-no-margin">
                    Driver Notes
                  </IonLabel>
                  <IonLabel className="job-schedule-detail-note-value ewp-h4 normal light ion-no-padding ion-no-margin">
                    {jobSchdule.driverNotes.description}
                  </IonLabel>
                  <IonLabel className="job-schedule-detail-note-value sent ewp-paragraph small italic ion-no-padding ion-no-margin">
                    {`Sent on ${moment(
                      jobSchdule.driverNotes.createdDate.toDate()
                    ).format("dddd, DD MMM YYYY, hh:mm:A")}`}
                  </IonLabel>
                  {jobSchdule.driverNotes.updateDate && (
                    <IonLabel className="job-schedule-detail-note-value updated ewp-paragraph small italic ion-no-padding ion-no-margin">
                      {`Updated on ${moment(
                        jobSchdule.driverNotes.createdDate.toDate()
                      ).format("dddd, DD MMM YYYY, hh:mm:A")}`}
                    </IonLabel>
                  )}
                </div>
              )}

            {/* <IonGrid className="job-schedule-detail-grid remote-job ion-no-padding ion-no-margin">
              <IonLabel className="job-schedule-detail-title ewp-h3 ion-no-padding ion-no-margin">
                Departure & Travel Details
              </IonLabel>
              <IonRow className="job-schedule-detail-row remote-job-row-one ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Depot Departure Time
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col value ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                    {!!jobSchdule.travelJobTime
                      ? jobSchdule.travelJobTime
                      : "N/A"}
                  </IonLabel>
                </IonCol>
              </IonRow>

              <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Distance and ETA to Job
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col value ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                    {!!jobSchdule.distanceJob ? jobSchdule.distanceJob : "N/A"}
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Travel Back Time
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col value ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-value ewp-h4 normal light ion-no-padding ion-no-margin">
                    {!!jobSchdule.travelBackTime
                      ? jobSchdule.travelBackTime
                      : "N/A"}
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow className="job-schedule-detail-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Recommended Stop Points
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col  value ion-no-padding ion-no-margin"
                >
                  <div className="job-schedule-detail-stop-points-container">
                    {!!jobSchdule.stopPoints &&
                      jobSchdule.stopPoints.map((stopPoint) => {
                        return (
                          <IonLabel className="job-schedule-detail-value-stop-point ewp-h4 normal light ion-no-padding ion-no-margin">
                            {stopPoint}
                          </IonLabel>
                        );
                      })}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid> */}

            <IonGrid className="job-schedule-detail-grid remote-job ion-no-padding ion-no-margin">
              <IonLabel className="job-schedule-detail-title ewp-h3 ion-no-padding ion-no-margin">
                Invoicing
              </IonLabel>
              <IonRow className="job-schedule-detail-row remote-job-row-one ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Job Invoiced?
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col value ion-no-padding ion-no-margin"
                >
                  <IonCheckbox
                    checked={!!invoiced}
                    onClick={() => {
                      updateInvoicing(!invoiced);
                    }}
                  />
                </IonCol>
              </IonRow>
              {!!invoiceChecked &&
                !!jobSchdule.checkedBy &&
                !_.isEmpty(jobSchdule.checkedBy) && (
                  <div>
                    <div className="job-schedule-status-icon-label-container">
                      <IonLabel className="ewp-paragraph ion-no-padding ion-no-margin">
                        {`Invoiced By: ${jobSchdule.checkedByName}`}
                      </IonLabel>
                    </div>
                    <div>
                      <IonLabel className="ewp-paragraph ion-no-padding ion-no-margin">
                        {`Invoice Date: ${moment(
                          jobSchdule.checkedDt!.toDate()
                        ).format("hh:mm A, dddd, DD MMM YYYY")}`}
                      </IonLabel>
                    </div>
                  </div>
                )}
              <IonRow className="job-schedule-detail-row remote-job-row-one ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <EWPInput
                    errorMessage={errorMsgInvoiceNumber}
                    inputLabel="Invoice Number"
                    inputValue={invoiceNumber}
                    disabled={!invoiced}
                    inputType="text"
                    name="invoiceNumber"
                    onInputChange={(invoiceNumber: string) => {
                      setInvoiceNumber(invoiceNumber);
                      if (!!invoiceNumber) {
                        setErrorMsgInvoiceNumber("");
                      }
                    }}
                  />
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col value ion-no-padding ion-no-margin"
                >
                  {/* <EWPButton
                    title={"Save"}
                    type="submit"
                    onClick={() => updateInvoiceNumber()}
                    disabled={!invoiceNumber}
                  /> */}
                </IonCol>
              </IonRow>
              <IonRow className="job-schedule-detail-row remote-job-row-one ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="job-schedule-detail-col ion-no-padding ion-no-margin"
                >
                  <IonLabel className="job-schedule-detail-label ewp-h4 bold light ion-no-padding ion-no-margin">
                    Invoice Checked?
                  </IonLabel>
                </IonCol>
                <IonCol
                  size="6"
                  className="job-schedule-detail-col value ion-no-padding ion-no-margin"
                >
                  <IonCheckbox
                    checked={!!invoiceChecked}
                    onClick={() => {
                      updateInvoiceChecked(!invoiceChecked);
                    }}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
            <div className="job-schedule-detail-buttons-container">
              {/* <IonButton
                mode="ios"
                className="job-schedule-detail-button close ewp-h4 ion-no-margin"
                fill="clear"
                color={EWPCOLORS.medium}
                onClick={onDidDismiss}
              >
                Close
              </IonButton> */}

              {isCancellable && (
                <IonButton
                  mode="ios"
                  className="job-schedule-detail-button cancel ewp-h3 small  white ion-no-padding ion-no-margin"
                  color={EWPCOLORS.danger}
                  onClick={() => {
                    if (!_.isEmpty(jobSchdule)) {
                      // setShowDeleteDialog(true);
                      onCancel(jobSchdule);
                    }
                  }}
                >
                  <IonLabel className="ewp-title-detail-page-new-button-label ewp-h3 small white">
                    Cancel
                  </IonLabel>
                </IonButton>
              )}
              {isUncancellable && (
                <IonButton
                  mode="ios"
                  className="job-schedule-detail-button cancel ewp-h3 small  white ion-no-padding ion-no-margin"
                  color={EWPCOLORS.primary}
                  onClick={() => {
                    if (!_.isEmpty(jobSchdule)) {
                      // setShowDeleteDialog(true);
                      onUncancel(jobSchdule);
                    }
                  }}
                >
                  <IonLabel className="ewp-title-detail-page-new-button-label ewp-h3 small white">
                    Uncancel Job
                  </IonLabel>
                </IonButton>
              )}
              <div className="job-schedule-detail-buttons-container end">
                <IonButton
                  mode="ios"
                  fill="clear"
                  className="job-schedule-detail-button duplicate ewp-h3 small primary ion-no-padding ion-no-margin"
                  onClick={() => {
                    if (!_.isEmpty(jobSchdule)) {
                      props.history.push(routes.CREATE_JOB_SCHEDULE, {
                        jobSchedule: jobSchdule as JobScheduleView,
                        isEdit: false,
                      } as JobScheduleLocationInterface);
                    }
                  }}
                >
                  Duplicate Job
                </IonButton>
                {!_.isEmpty(jobSchdule) &&
                  !jobSchdule.cancelled &&
                  !isHistoricalDate(
                    getDateFromTimezoneIgnoredTimestamp(
                      jobSchdule.endDateNoTimeZone
                    )
                  ) && (
                    <IonButton
                      mode="ios"
                      className="job-schedule-detail-button edit ewp-h3 small  white ion-no-padding ion-no-margin"
                      onClick={() => {
                        if (!_.isEmpty(jobSchdule)) {
                          props.history.push(routes.EDIT_JOB_SCHEDULE, {
                            jobSchedule: jobSchdule as JobScheduleView,
                            isEdit: true,
                          } as JobScheduleLocationInterface);
                        }
                      }}
                    >
                      <IonLabel className="ewp-title-detail-page-new-button-label ewp-h3 small white">
                        Edit
                      </IonLabel>
                    </IonButton>
                  )}
              </div>
            </div>
          </div>
        </div>
      </IonModal>

      {showJobAdminNotes && (
        <JobAdminNotesDialog
          {...props}
          isOpen={showJobAdminNotes}
          onDidDismiss={() => {
            setShowJobAdminNotes(false);
          }}
          jobScheduleId={jobSchdule.docId || ""}
          jobAdminNotes={jobAdminNotes as JobAdminNotesView[]}
        />
      )}

      {!_.isNull(toolTipAnchor) && !_.isEmpty(toolTipAnchor) && (
        <EWPToolTip
          anchor={toolTipAnchor}
          text="This truck is currently booked under this same date and time. Please review your job and choose another date and time."
          removeAnchor={() => {
            setToolTipAnchor(null);
          }}
        />
      )}
      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.saving}
      />
      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          setSuccess("");
        }}
        color={EWPCOLORS.success}
      />

      {/* {showDeleteDialog && !_.isEmpty(jobSchdule) && (
        <SoftDeleteJobSchedule
          isCancelled={true}
          isOpen={showDeleteDialog}
          notify={true}
          jobSchedule={jobSchdule!}
          onDidDismiss={() => {
            setShowDeleteDialog(false);
          }}
          onSuccess={() => {
            onDidDismiss();
          }}
        />
      )} */}
    </>
  );
};
