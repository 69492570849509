import * as _ from "lodash";
import React, { useState } from "react";
import {
  IonToast,
  IonLabel,
  IonButton,
  IonAvatar,
  IonLoading,
} from "@ionic/react";

import "./UserList.scss";
import * as services from "../../../services";
import { useEffectOnlyOnce } from "../../../functions/common";
import { MSGS_COMMON } from "../../../constants/messages";
import {
  AdminAccess,
  ADMIN_ACCESS,
  ADMIN_ACCESS_KEYS,
  EWPProps,
  EWPTableProps,
} from "../../../config/global";
import { EWPTitleSearchWithButton } from "../../../components/EWPTitleSearchWithButton/EWPTitleSearchWithButton";
import { EWPDataTable } from "../../../components/EWPDataTable/EWPDataTable";
import { EWPCOLORS } from "../../../constants/config";
import { EWPWebDialog } from "../../../components/EWPWebDialog/EWPWebDialog";
import {
  EWPCreateAdminForm,
  InvitedAdmin,
} from "../../../components/EWPCreateAdminForm/EWPCreateAdminForm";
import { UserInviteSuccessDialog } from "../../../components/UserInviteSuccessDialog/UserInviteSuccessDialog";
import { ArchiveOrRestoreUserDialog } from "../../../components/ArchiveOrRestoreUserDialog/ArchiveOrRestoreUserDialog";

export interface AdminRow {
  id?: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  profilePhoto?: string | null;
  status: "archived" | "active";
  access: AdminAccess;
  archived?: boolean;
}

interface SortKeys {
  [key: string]: "asc" | "desc";
}
export interface UserPhoto {
  userId: string;
  url: string;
}

type UsersColumns = "firstName" | "access" | "status";

export const UserList = (props: EWPProps) => {
  const [filteredUsers, setFilteredUsers] = useState(null as AdminRow[] | null);
  const [sortLabelsAndDirection, setSortLabelsAndDirection] = useState({
    subContractorCompanyName: "asc",
  } as SortKeys);

  const [showCreateNewAdminModal, setShowCreateNewAdminModal] = useState(false);
  const [invitedUser, setInvitedUser] = useState({} as InvitedAdmin);
  const [userTableDataSource, setUserTableDataSource] = useState(
    null as AdminRow[] | null
  );
  const [forArchiveRestoreUser, setForArchiveRestoreUser] = useState(
    null as null | AdminRow
  );

  const [showArchive, setShowArchive] = useState(false);

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffectOnlyOnce(() => {
    getAdminUsers();
    console.log("ONLY ONCE");
  });

  const getAdminUsers = () => {
    if (_.isEmpty(userTableDataSource)) {
      services.getAllAdminUser(async (adminUsers) => {
        const tableData = adminUsers.map((adminUser) => {
          return {
            firstName: adminUser.firstName,
            lastName: adminUser.lastName,
            emailAddress: adminUser.emailAddress,
            status: adminUser.archived ? "archived" : "active",
            phoneNumber: adminUser.phoneNumber || "",
            access: adminUser.access,
            id: adminUser.docId,
            archived: adminUser.archived || false,
            profilePhoto: adminUser.profilePhoto || "",
          } as AdminRow;
        });

        setUserTableDataSource(_.orderBy(tableData, "firstName", "asc"));
        setLoading(false);
      });
    }
  };

  // const searchCompanyRep = (query: string) => {
  //   if (!_.isEmpty(query)) {
  //     const dataSource = !_.isEmpty(filteredUsers)
  //       ? filteredUsers
  //       : showNotArchivedUsers(userTableDataSource);
  //     const filteredCompanyRepsData = _.filter(
  //       dataSource,
  //       (companyRep: AdminRow) => {
  //         if (!_.isEmpty(companyRep)) {
  //           return (
  //             companyRep.firstName
  //               .toLowerCase()
  //               .indexOf(query.toLowerCase()) !== -1 ||
  //             companyRep.lastName.toLowerCase().indexOf(query.toLowerCase()) !==
  //               -1 ||
  //             companyRep.emailAddress
  //               .toLowerCase()
  //               .indexOf(query.toLowerCase()) !== -1 ||
  //             companyRep.status.toLowerCase().indexOf(query.toLowerCase()) !==
  //               -1 ||
  //             `${companyRep.firstName} ${companyRep.lastName}`
  //               .toLowerCase()
  //               .indexOf(query.toLowerCase()) !== -1
  //           );
  //         }
  //       }
  //     );
  //     setFilteredUsers(filteredCompanyRepsData as AdminRow[]);
  //   } else {
  //     setFilteredUsers(null);
  //   }
  // };

  const onModalDidDismiss = () => {
    setShowCreateNewAdminModal(false);
  };

  const sort = (key: string) => {
    const sortBy = sortLabelsAndDirection[key] === "desc" ? "asc" : "desc";
    const updateSort = {} as SortKeys;
    updateSort[key] = sortBy;
    const dataSource = !_.isEmpty(filteredUsers)
      ? filteredUsers
      : showNotArchivedUsers(userTableDataSource);

    const sortedData = _.orderBy(dataSource, key, sortBy);
    setFilteredUsers(sortedData);

    setSortLabelsAndDirection({
      ...sortLabelsAndDirection,
      ...updateSort,
    });
  };

  const showNotArchivedUsers = (data: AdminRow[] | null) => {
    return _.filter(data, (companyRep: AdminRow) => {
      return companyRep.archived === false || companyRep.archived === undefined;
    });
  };

  const tableDef = {
    headers: [
      { label: "Name", key: "firstName", sortable: true },
      { label: "Access", key: "access", sortable: true },
      { label: "Status", key: "status", sortable: true },
    ],
    columns: ["firstName", "access", "status"],
  } as EWPTableProps;

  const renderCell = (value: string, rowData: AdminRow) => {
    switch (value) {
      case "firstName":
        return (
          <div className="admin-list-name-container ion-no-margin ion-no-padding">
            {!_.isEmpty(rowData.profilePhoto) ? (
              <IonAvatar className="admin-list-picture">
                <img
                  src={rowData.profilePhoto!}
                  alt={"supervisor-profile-detail"}
                />
              </IonAvatar>
            ) : (
              <div className="admin-list-initials-container">
                <IonLabel className="admin-list-initials ewp-h5 white ion-text-uppercase ion-no-margin ion-text-center">
                  {rowData!.firstName[0] + rowData!.lastName[0]}
                </IonLabel>
              </div>
            )}
            <div className="admin-list-name-email-container">
              <IonLabel
                className={`admin-list-name-label ewp-h3 ion-no-margin ion-no-padding ion-text-capitalize ${
                  rowData.archived ? "danger" : ""
                }`}
              >{`${rowData.firstName} ${rowData.lastName}`}</IonLabel>
              <IonLabel
                className={`admin-list-email-label ewp-paragraph ion-no-margin ion-no-padding ${
                  rowData.archived ? "danger" : ""
                }`}
              >
                {rowData.emailAddress}
              </IonLabel>
            </div>
          </div>
        );
      case "access":
        return (
          <IonLabel
            className={`ewp-paragraph ${rowData.archived ? "danger" : ""}`}
          >
            {ADMIN_ACCESS[ADMIN_ACCESS_KEYS[rowData.access]].access}
          </IonLabel>
        );
      case "status" || "":
        return (
          <div className="admin-list-status-and-button-container">
            <IonLabel
              className={`admin-list-status-label ${
                rowData[value] === "archived" && "is-archived"
              } ewp-paragraph ${
                rowData.archived ? "danger" : ""
              } ion-text-capitalize`}
            >
              {rowData.archived ? "Archived" : "Active"}
            </IonLabel>
            {(rowData.firstName || "" + rowData.lastName || "")
              .replace(" ", "")
              .toLowerCase() !== "markelliot" ? (
              rowData[value] === "active" ? (
                <IonButton
                  onClick={() => {
                    setForArchiveRestoreUser(rowData);
                  }}
                  className="admin-list-edit-button ewp-web-gray-button ewp-h6 dark ion-text-capitalize ion-no-margin"
                >
                  Archive User
                </IonButton>
              ) : (
                <div>
                  <IonButton
                    onClick={() => {
                      setForArchiveRestoreUser(rowData);
                    }}
                    className="admin-list-edit-button ewp-web-gray-button ewp-h6 dark ion-text-capitalize ion-no-margin"
                  >
                    Restore User
                  </IonButton>
                </div>
              )
            ) : (
              <></>
            )}
          </div>
        );
      default:
        return (
          <IonLabel className="ewp-paragraph">
            {rowData[value as UsersColumns]}
          </IonLabel>
        );
    }
  };
  // const showArchivedUsers = (toggled: boolean) => {
  //   if (toggled) {
  //     const filtertedArchivedUsers = _.filter(
  //       userTableDataSource,
  //       (companyRep: AdminRow) => {
  //         return companyRep.archived === true;
  //       }
  //     );
  //     setFilteredUsers(
  //       _.orderBy(filtertedArchivedUsers as AdminRow[], "firstName", "asc")
  //     );
  //   } else {
  //     setFilteredUsers(null);
  //   }
  // };

  return (
    <div className="admin-list-container">
      <EWPTitleSearchWithButton
        {...props}
        pageTitle="Users"
        actionButtonLabel={"Create New User"}
        actionCallBack={() => {
          setShowCreateNewAdminModal(true);
        }}
        toggleButtonFunction={(isChecked) => {
          setShowArchive(isChecked);
        }}
        toggleButtonLabel="Show Archived Users"
        disabled={!_.isNull(userTableDataSource) ? false : true}
      />

      <div className="admin-list-table-container">
        <EWPDataTable
          tableDef={tableDef}
          tableData={
            !_.isNull(userTableDataSource)
              ? _.filter(userTableDataSource, (userData) =>
                  showArchive ? userData : userData.status === "active"
                )
              : null
          }
          tableFilteredData={filteredUsers}
          renderCell={renderCell}
          sortLabelsAndDirection={sortLabelsAndDirection}
          onSort={sort}
        />
      </div>
      {showCreateNewAdminModal && (
        <EWPWebDialog
          {...props}
          headerTitle="Create New Admin"
          isOpen={showCreateNewAdminModal}
          onDidDismiss={onModalDidDismiss}
        >
          {showCreateNewAdminModal && (
            <EWPCreateAdminForm
              {...props}
              onModalDidDismiss={onModalDidDismiss}
              onSuccess={(user: InvitedAdmin) => {
                onModalDidDismiss();
                setInvitedUser(user);
                setSuccessDialogOpen(true);
              }}
            />
          )}
        </EWPWebDialog>
      )}

      {!_.isEmpty(invitedUser) && (
        <UserInviteSuccessDialog
          isOpen={successDialogOpen}
          name={`${invitedUser.firstName} ${invitedUser.lastName}`}
          access={invitedUser.access}
          emailAddress={invitedUser.emailAddress}
          password={invitedUser.password}
          onSuccess={() => {
            setSuccessDialogOpen(false);
          }}
          onDidDismiss={() => {
            setSuccessDialogOpen(false);
          }}
        />
      )}

      {!_.isNull(forArchiveRestoreUser) && (
        <ArchiveOrRestoreUserDialog
          isOpen={!_.isEmpty(forArchiveRestoreUser)}
          admin={forArchiveRestoreUser}
          restore={!!forArchiveRestoreUser.archived}
          onSuccess={() => {
            setForArchiveRestoreUser(null);
          }}
          onDidDismiss={() => {
            setForArchiveRestoreUser(null);
          }}
        />
      )}

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => {
          setError("");
        }}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={2000}
        onDidDismiss={() => {
          setSuccess("");
        }}
        color={EWPCOLORS.success}
      />

      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />
    </div>
  );
};
