import * as _ from "lodash";
import moment from "moment";
import { warning } from "ionicons/icons";
import React, { useState } from "react";
import { IonIcon, IonLabel, IonToast } from "@ionic/react";

import "./DriverList.scss";
import * as routes from "../../../constants/routes";
import * as services from "../../../services";
import {
  daysSinceDate,
  isTablet,
  useEffectOnlyOnce,
} from "../../../functions/common";
import { Driver } from "../../../models/drivers";
import { DriverForm } from "../../DriverForm/DriverForm";
import { EWPDataTable } from "../../EWPDataTable/EWPDataTable";
import { EWPWebDialog } from "../../EWPWebDialog/EWPWebDialog";
import { EWPTitleSearchWithButton } from "../../EWPTitleSearchWithButton/EWPTitleSearchWithButton";
import { EWPTableProps, EWPProps } from "../../../config/global";
import {
  EWPCOLORS,
  NON_REMOVEABLE_DRIVER_EXPIRIES,
} from "../../../constants/config";

interface DriverListProps extends EWPProps {
  drivers: Driver[];
}

export interface DriverListView extends Driver {
  driverName: string;
  depotName: string;
  licenceExpiry: string;
  whiteCardExpiry: string;
  ewpTicketExpiry: string;
  hasWarningExpiry?: boolean;
  hasDueExpiry?: boolean;
}

interface SortKeys {
  driverName: "asc" | "desc";
  [key: string]: "asc" | "desc";
}

export const DriverList = (props: DriverListProps) => {
  const { drivers } = props;

  // const [driverListView, setDriverListView] = useState(null as null | DriverListView[])

  const [filteredDriverData, setFilteredDriverData] = useState(
    null as null | DriverListView[]
  );
  const [sortLabelsAndDirection, setSortLabelsAndDirection] = useState({
    driverName: "asc",
  } as SortKeys);
  const [driverListView, setDriverListView] = useState(
    null as null | DriverListView[]
  );

  const [showCreateNewDriver, setShowCreateNewDriver] = useState(false);

  const [error, setError] = useState("");

  useEffectOnlyOnce(() => {
    getDriverListView();
  });

  const getDriverListView = async () => {
    try {
      const depots = await services.getDepots();
      let depotsMap = {} as { [docId: string]: string };
      depots.forEach(
        (depot) => (depotsMap[depot.docId as string] = depot.name)
      );
      services.getDriverExpiryReminders((driverExpiryList, error) => {
        // setDriverExpiryList(driverExpiryList);
        const driverListView = drivers.map((driver) => {
          const licenceNonRemovable = _.find(
            driver.driverExpiries,
            (expiry) =>
              expiry.expiryId === NON_REMOVEABLE_DRIVER_EXPIRIES[0].docId
          );

          const whiteCardNonRemovable = _.find(
            driver.driverExpiries,
            (expiry) =>
              expiry.expiryId === NON_REMOVEABLE_DRIVER_EXPIRIES[1].docId
          );
          const ewpTicketNonRemovable = _.find(
            driver.driverExpiries,
            (expiry) =>
              expiry.expiryId === NON_REMOVEABLE_DRIVER_EXPIRIES[2].docId
          );

          // const isDueForReminder =
          // !_.isEmpty(licenceNonRemovable) &&
          // daysSinceDate(new Date(), licenceNonRemovable!.expirationDate.toDate()) <=
          // licenceNonRemovable!.expiryReminder * 7
          const toExpire = _.find(driverExpiryList, (driverExpiry) => {
            const existingExpiry = _.find(
              driver.driverExpiries || [],
              (expiry) => expiry.expiryId === driverExpiry.docId
            );

            return (
              !_.isEmpty(existingExpiry) &&
              daysSinceDate(
                new Date(),
                existingExpiry!.expirationDate.toDate()
              ) <=
                driverExpiry!.expiryReminder * 7
            );
          });

          return {
            ...driver,
            driverName: `${driver.firstName} ${driver.lastName}`,
            depotName: `${
              !!depotsMap[driver.depotId] &&
              !_.isEmpty(depotsMap[driver.depotId])
                ? depotsMap[driver.depotId]
                : "N/A"
            }`,
            licenceExpiry: !_.isEmpty(licenceNonRemovable)
              ? moment(licenceNonRemovable!.expirationDate.toDate()).format(
                  "DD MMM YYYY"
                )
              : "N/A",
            whiteCardExpiry: !_.isEmpty(whiteCardNonRemovable)
              ? moment(whiteCardNonRemovable!.expirationDate.toDate()).format(
                  "DD MMM YYYY"
                )
              : "N/A",
            ewpTicketExpiry: !_.isEmpty(ewpTicketNonRemovable)
              ? moment(ewpTicketNonRemovable!.expirationDate.toDate()).format(
                  "DD MMM YYYY"
                )
              : "N/A",
            hasFirstAid: driver.hasFirstAid || false,
            hasDefibrillator: driver.hasDefibrillator || false,
            hasWarningExpiry: !_.isEmpty(toExpire),
          };
        });

        setDriverListView(driverListView);

        setError(error || "");
      });
    } catch (eUnknown) {
      const e = eUnknown as any;
      setError(e);
    }
  };

  const showArchived = (toggled: boolean) => {
    if (toggled) {
      // const filteredDriverData = _.filter(
      //   driverListView,
      //   (driver: DriverListView) => {
      //     return driver.archived === true;
      //   }
      // );
      setFilteredDriverData(driverListView);
    } else {
      setFilteredDriverData(null);
    }
  };

  const onRowRedirect = (rowData: DriverListView) => {
    if (!_.isEmpty(rowData) && !!rowData.docId) {
      props.history.push(
        routes.HOME_DRIVER_REGISTER_DETAILS.replace(
          ":driverId",
          rowData.docId || ""
        )
      );
    }
  };

  const sort = (key: string) => {
    const sortBy = sortLabelsAndDirection[key] === "desc" ? "asc" : "desc";
    const updateSort = {} as SortKeys;
    const toBeSort =
      !!filteredDriverData && !_.isEmpty(filteredDriverData)
        ? filteredDriverData
        : _.filter(driverListView, (driver: any) => {
            return !driver.archived;
          });
    updateSort[key] = sortBy;
    const sortedData = _.orderBy(toBeSort, key, sortBy);
    setFilteredDriverData(sortedData);

    setSortLabelsAndDirection({
      ...sortLabelsAndDirection,
      ...updateSort,
    });
  };

  const getTableDef = () => {
    let headers = [
      { label: "Name", key: "driverName", sortable: true },
      {
        label: "Phone Number",
        key: "phoneNumber",
        sortable: true,
      },
      { label: "Depot", key: "depotName", sortable: true },
      { label: "Licence Expiry", key: "licenceExpiry", sortable: true },
      { label: "White Card Expiry", key: "whiteCardExpiry", sortable: true },
      { label: "EWP Ticket Expiry", key: "ewpTicketExpiry", sortable: true },
      { label: "First Aid", key: "hasFirstAid", sortable: true },
      { label: "Defibrillator", key: "hasDefibrillator", sortable: true },
    ];

    let columns = [
      "driverName",
      "phoneNumber",
      "depotName",
      "licenceExpiry",
      "whiteCardExpiry",
      "ewpTicketExpiry",
      "hasFirstAid",
      "hasDefibrillator",
    ];

    return { headers, columns } as EWPTableProps;
  };

  const renderCell = (value: string, rowData: DriverListView) => {
    const columnName = value as keyof DriverListView;
    switch (value) {
      case "driverName":
        return (
          <IonLabel
            className={`ewp-h5 bold light ${rowData.archived ? "danger" : ""}`}
          >
            {rowData[columnName]}
          </IonLabel>
        );
      case "phoneNumber":
        return (
          <IonLabel
            className={`ewp-paragraph ${rowData.archived ? "danger" : ""}`}
          >
            {rowData[columnName]}
          </IonLabel>
        );
      case "depotName":
        return (
          <IonLabel
            className={`ewp-paragraph ${rowData.archived ? "danger" : ""}`}
          >
            {!!rowData[columnName] && !_.isEmpty(rowData[columnName])
              ? rowData[columnName]
              : "N/A"}
          </IonLabel>
        );
      case "licenceExpiry":
        return (
          <IonLabel
            className={`ewp-paragraph ${rowData.archived ? "danger" : ""}`}
          >
            {rowData[columnName]}
          </IonLabel>
        );
      case "whiteCardExpiry":
        return (
          <IonLabel
            className={`ewp-paragraph ${rowData.archived ? "danger" : ""}`}
          >
            {rowData[columnName]}
          </IonLabel>
        );
      case "ewpTicketExpiry":
        return (
          <IonLabel
            className={`ewp-paragraph ${rowData.archived ? "danger" : ""}`}
          >
            {rowData[columnName]}
          </IonLabel>
        );
      case "hasFirstAid":
        return (
          <IonLabel
            className={`ewp-paragraph ${rowData.archived ? "danger" : ""}`}
          >
            {rowData[columnName] ? "Yes" : "No"}
          </IonLabel>
        );
      case "hasDefibrillator":
        return (
          <IonLabel
            className={`ewp-paragraph global-flex ion-justify-content-between  ${
              rowData.archived ? "danger" : ""
            }`}
          >
            {rowData[columnName] ? "Yes" : "No"}
            {rowData.hasDueExpiry ? (
              <IonIcon color={EWPCOLORS.danger} icon={warning} />
            ) : (
              rowData.hasWarningExpiry && (
                <IonIcon color={EWPCOLORS.warning} icon={warning} />
              )
            )}
          </IonLabel>
        );
      default:
        return (
          <IonLabel
            className={`ewp-paragraph ${rowData.archived ? "danger" : ""}`}
          >
            {rowData[columnName]}
          </IonLabel>
        );
    }
  };

  return (
    <>
      <div className="driver-list-container">
        <div className="driver-register-header-container">
          <EWPTitleSearchWithButton
            {...props}
            toggleButtonFunction={showArchived}
            toggleButtonLabel="View Archived"
            pageTitle="Driver Register"
            actionCallBack={() => {
              setShowCreateNewDriver(true);
            }}
            actionButtonLabel="New Driver"
          />
        </div>
        <div className="driver-list-table-container">
          {console.log("filteredDriverData -- ", filteredDriverData)}
          <EWPDataTable
            tableDef={getTableDef()}
            tableData={_.filter(driverListView, (driver) => !driver.archived)}
            tableFilteredData={filteredDriverData}
            renderCell={renderCell}
            sortLabelsAndDirection={sortLabelsAndDirection}
            onSort={sort}
            onRowRedirect={onRowRedirect}
            isComponentPage={true}
          />
        </div>
      </div>
      <EWPWebDialog
        contentClassName={`create-new-driver-dialog ${
          isTablet() ? "tablet" : ""
        }`}
        headerTitle="Create New Driver"
        isOpen={showCreateNewDriver}
        onDidDismiss={() => {
          setShowCreateNewDriver(false);
        }}
      >
        <DriverForm
          onSuccess={() => {}}
          onDidDismiss={() => {
            setShowCreateNewDriver(false);
          }}
        />
      </EWPWebDialog>
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => {
          setError("");
        }}
        color={EWPCOLORS.danger}
      />
    </>
  );
};
