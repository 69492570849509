import * as _ from "lodash";
import Bugsnag from "@bugsnag/js";
import { fileTrayOutline } from "ionicons/icons";
import React, { useState } from "react";
import {
  IonItem,
  IonButton,
  IonLoading,
  IonToast,
  IonLabel,
  IonIcon,
  IonCard,
  IonCardHeader,
} from "@ionic/react";

import "./FaultsAndDefectsNotesDialog.scss";
import * as services from "../../services";
import { storage } from "../../firebase";
import {
  getServerTimestamp,
  uploadAttachments,
  useEffectOnlyOnce,
} from "../../functions/common";
import { EWPWebDialog } from "../EWPWebDialog/EWPWebDialog";
import { EWPNoteWithAttachment } from "../EWPNoteWithAttachment/EWPNoteWithAttachment";
import { EWPNoteCard } from "../Web/EWPNoteCard/EWPNoteCard";
import { EWPProps, TextEditorEdit } from "../../config/global";
import { FaultDefectListView, FaultDefectNotes, Notes } from "../../models";
import { EWPCOLORS } from "../../constants/config";
import { MSGS_COMMON, MSGS_NOTE_ENTRY } from "../../constants/messages";

interface FaultsAndDefectsNotesDialogProps extends EWPProps {
  onDidDismiss: () => void;
  isOpen: boolean;
  faultAndDefect: FaultDefectListView;
  existingNotes: FaultDefectNotes[];
}

export const FaultsAndDefectsNotesDialog = (
  props: FaultsAndDefectsNotesDialogProps
) => {
  const { isOpen, onDidDismiss, existingNotes, faultAndDefect } = props;

  const [selectedEditIndex, setSelectedEditIndex] = useState(
    null as null | number
  );

  const [noteTitle, setNoteTitle] = useState("");
  const [noteDescription, setNoteDescription] = useState("");
  const [listOfAttachment, setListOfAttachment] = useState([] as any[]);
  const [removedUrl, setRemovedUrl] = useState([] as any[]);

  const [showAddNote, setShowAddNote] = useState(false);
  const [editNoteData, setEditNoteData] = useState({} as FaultDefectNotes);
  const [willEditNoteEntry, setwillEditNoteEntry] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const saveNote = async () => {
    // const formattedserviceType = formatString(serviceType);
    setLoading(true);
    const { docId, lastName } = props.authUser;
    const noteEntry = {
      title: noteTitle,
      description: noteDescription,
    } as Notes;
    let errorMsg = "";

    if (!_.isEmpty(editNoteData)) {
      let updatedEditData = _.clone(editNoteData);
      const updatedTile = !_.isEmpty(noteTitle)
        ? noteTitle
        : updatedEditData!.notes!.title;
      const updatedDescription = !_.isEmpty(noteDescription)
        ? noteDescription
        : updatedEditData!.notes!.description;
      const updatedNotes = {
        title: updatedTile,
        description: updatedDescription,
      };
      try {
        let attachmentUrlList = [] as string[];
        if (listOfAttachment.length > 0) {
          const urlList = await uploadAttachments(
            listOfAttachment,
            lastName,
            docId,
            "faultDefectNotes"
          );
          attachmentUrlList = urlList;
        }
        const updateAttachments = _.concat(
          updatedEditData.attachments,
          attachmentUrlList
        ) as string[];
        updatedEditData = {
          ...updatedEditData,
          notes: updatedNotes,
          updatedDate: getServerTimestamp(),
          attachments: updateAttachments,
          updatedBy: docId,
        };
        await services.updateFaultDefectNotes(
          updatedEditData.docId!,
          updatedEditData
        );
        //remove the file in our storage
        await Promise.resolve(
          removedUrl.forEach((url) => {
            new Promise(async (resolve) => {
              try {
                const refFromUrl = storage.refFromURL(url);
                const deletedUrl = await refFromUrl.delete();
                resolve(deletedUrl);
              } catch (errorUnknown) {
                const error = errorUnknown as any;
                resolve(null);
                Bugsnag.notify(new Error(error));
              }
            });
          })
        );
        setSuccess(MSGS_COMMON.success);
        setListOfAttachment([]);
        setEditNoteData({} as FaultDefectNotes);
        setwillEditNoteEntry(false);
        setSelectedEditIndex(null);
        setRemovedUrl([]);
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        setError(error);
        Bugsnag.notify(new Error(error));
      }
    } else {
      if (!noteEntry.title) {
        errorMsg = MSGS_NOTE_ENTRY.required;
      } else if (!noteEntry.description) {
        errorMsg = MSGS_NOTE_ENTRY.required;
      }
      if (errorMsg) {
        setError(errorMsg);
      } else {
        try {
          let attachmentUrlList = [] as string[];
          if (listOfAttachment.length > 0) {
            const urlList = await uploadAttachments(
              listOfAttachment,
              lastName,
              docId,
              "faultDefectNotes"
            );
            attachmentUrlList = urlList;
          }
          const faultDefectNoteEntry = {
            faultDefectId: faultAndDefect.docId || "",
            createdBy: docId,
            attachments: attachmentUrlList,
            notes: noteEntry,
          } as FaultDefectNotes;
          await services.createFaultDefectNotes(faultDefectNoteEntry);
          setSuccess(MSGS_COMMON.success);
          setListOfAttachment([]);
          setRemovedUrl([]);
          setShowAddNote(false);
        } catch (errorUnknown) {
          const error = errorUnknown as any;
          setError(error);
          Bugsnag.notify(new Error(error));
        }
      }
    }
  };

  const clearFields = () => {};

  const clearErrorMsgs = () => {};

  useEffectOnlyOnce(() => {
    console.log("GOT EXISTING NOTES", existingNotes);
  });

  const onAttachPhoto = async (file: any) => {
    if (!!file && file.target.files.length > 0) {
      const attachments = file.target.files;
      const toBeUploadedAttachments = _.concat(
        Object.values(attachments),
        listOfAttachment
      );
      setListOfAttachment(toBeUploadedAttachments);
    }
  };

  const onRemoveAttachment = (attachment: any) => {
    let updatedEditData = _.clone(editNoteData);

    const updatedListOfEditDataAttachment = _.remove(
      updatedEditData.attachments as string[],
      (item) => {
        return item !== attachment;
      }
    );
    updatedEditData = {
      ...updatedEditData,
      attachments: updatedListOfEditDataAttachment,
    };

    const updatedListOfAttachment = _.remove(listOfAttachment, (item) => {
      return item !== attachment;
    });

    setListOfAttachment(updatedListOfAttachment);
    setEditNoteData(updatedEditData);
    setRemovedUrl([...removedUrl, attachment]);
  };

  const onEditNoteEntry = (
    noteEntry: FaultDefectNotes,
    selectedEditIndex: number
  ) => {
    const editNoteEntryData = _.cloneDeep(noteEntry);
    setwillEditNoteEntry(true);
    setEditNoteData(editNoteEntryData);
    setSelectedEditIndex(selectedEditIndex);
  };

  const editData =
    !_.isEmpty(editNoteData) && willEditNoteEntry
      ? {
          title: editNoteData!.notes!.title,
          description: editNoteData!.notes!.description,
          listOfAttachment: editNoteData.attachments,
        }
      : {};
  return (
    <>
      <EWPWebDialog
        headerTitle={`Notes for ${faultAndDefect.truck.name}`}
        isOpen={isOpen}
        onDidDismiss={() => {
          onDidDismiss();
        }}
      >
        {!!faultAndDefect && (
          <div>
            {showAddNote && (
              <IonCard
                className="fault-defect-note-card ion-no-padding ion-no-margin"
                color={EWPCOLORS.tertiary}
              >
                <IonCardHeader className="fault-defect-note-card-header ion-no-padding ion-no-margin">
                  <IonLabel className="fault-defect-note-card-headerlabel ewp-h4 bold ion-no-padding ion-no-margin">
                    New Note
                  </IonLabel>
                </IonCardHeader>
                <div className="fault-defect-note-container">
                  <EWPNoteWithAttachment
                    onChangeTitle={(titleData) => {
                      setNoteTitle(titleData);
                    }}
                    onChangeBody={(bodyData) => {
                      setNoteDescription(bodyData);
                    }}
                    listOfAttachment={listOfAttachment} //callback here to display attachment
                    onAttachPhoto={onAttachPhoto}
                    onRemoveAttachment={onRemoveAttachment}
                  />

                  <IonItem
                    lines="none"
                    className="fault-defect-note-action-buttons-item ewp-item-no-horizontal-padding"
                    color={EWPCOLORS.tertiary}
                  >
                    <IonButton
                      mode="ios"
                      slot="start"
                      className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
                      fill="clear"
                      color={EWPCOLORS.medium}
                      onClick={() => {
                        setShowAddNote(false);
                      }}
                    >
                      Cancel
                    </IonButton>
                    <IonButton
                      slot="end"
                      mode="ios"
                      className="fault-defect-note-save-button ewp-h6 ion-no-margin ion-no-padding"
                      color={EWPCOLORS.primary}
                      onClick={() => {
                        saveNote();
                      }}
                    >
                      Save
                    </IonButton>
                  </IonItem>
                </div>
              </IonCard>
            )}

            <div
              className={`fault-defect-main-container ${
                showAddNote ? "ion-margin-top" : ""
              }`}
            >
              {!!existingNotes && !_.isEmpty(existingNotes) ? (
                existingNotes.map((existingNote, index) => {
                  return index === selectedEditIndex ? (
                    <IonCard
                      className="fault-defect-note-card ion-no-padding ion-no-margin"
                      color={EWPCOLORS.tertiary}
                    >
                      <IonCardHeader className="fault-defect-note-card-header ion-no-padding ion-no-margin">
                        <IonLabel className="fault-defect-note-card-headerlabel ewp-h4 bold ion-no-padding ion-no-margin">
                          Edit Note
                        </IonLabel>
                      </IonCardHeader>
                      <div className="fault-defect-note-container">
                        <EWPNoteWithAttachment
                          onChangeTitle={(titleData) => {
                            setNoteTitle(titleData);
                          }}
                          onChangeBody={(bodyData) => {
                            setNoteDescription(bodyData);
                          }}
                          listOfAttachment={listOfAttachment} //callback here to display attachment
                          onAttachPhoto={onAttachPhoto}
                          onRemoveAttachment={onRemoveAttachment}
                          editData={editData as TextEditorEdit}
                        />

                        <IonItem
                          lines="none"
                          className="fault-defect-note-action-buttons-item ewp-item-no-horizontal-padding"
                          color={EWPCOLORS.tertiary}
                        >
                          <IonButton
                            mode="ios"
                            slot="start"
                            className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
                            fill="clear"
                            color={EWPCOLORS.medium}
                            onClick={() => {
                              setSelectedEditIndex(null);
                              setEditNoteData({} as FaultDefectNotes);
                            }}
                          >
                            Cancel
                          </IonButton>
                          <IonButton
                            slot="end"
                            mode="ios"
                            className="fault-defect-note-save-button ewp-h6 ion-no-margin ion-no-padding"
                            color={EWPCOLORS.primary}
                            onClick={() => {
                              saveNote();
                            }}
                          >
                            Save
                          </IonButton>
                        </IonItem>
                      </div>
                    </IonCard>
                  ) : (
                    <div className="note-card-container">
                      <EWPNoteCard
                        {...(existingNote.createdBy ===
                          props.authUser.docId && {
                          onEdit: () => {
                            onEditNoteEntry(existingNote, index);
                          },
                        })}
                        noteEntry={existingNote}
                        index={index}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="fault-defect-empty-container">
                  <IonIcon
                    icon={fileTrayOutline}
                    className="fault-defect-empty-icon"
                  />
                  <IonLabel className="ewp-h3">Notes is empty.</IonLabel>
                </div>
              )}
            </div>

            <div className="fault-defect-note-add-container">
              <IonButton
                mode="ios"
                className="faults-and-defects-add-notes-button ewp-h5 white ion-text-capitalize ion-no-margin"
                fill="clear"
                color={EWPCOLORS.dark}
                onClick={() => {
                  onDidDismiss();
                }}
              >
                Cancel
              </IonButton>
              <IonButton
                mode="ios"
                className={`faults-and-defects-add-notes-button ewp-h5 white ion-text-capitalize ion-no-margin ${
                  showAddNote ? "ion-hide" : ""
                }`}
                onClick={() => {
                  setShowAddNote(true);
                }}
              >
                <IonIcon className="faults-and-defects-add-icon" />
                New Note
              </IonButton>
            </div>
          </div>
        )}
      </EWPWebDialog>

      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          setSuccess("");
          onDidDismiss();
          clearFields();
          clearErrorMsgs();
        }}
        color={EWPCOLORS.success}
      />
    </>
  );
};
