import _ from "lodash";
import moment from "moment";
import React from "react";
import {
  IonButton,
  IonIcon,
  IonLabel,
  IonLoading,
  IonPopover,
  IonToast,
} from "@ionic/react";

import "./JobScheduleMainPage.scss";
import * as routes from "../../../constants/routes";
import * as services from "../../../services";
import {
  getDateFromTimezoneIgnoredTimestamp,
  getDateRange,
  isTablet,
  TimezoneIgnoredTimeStamp,
  // timezoneIgnoredTimestamp,
  // toDateTimeFromSecs,
} from "../../../functions/common";
import { EWPProps } from "../../../config/global";
import { JobScheduleCalendar } from "../../../components/JobScheduleCalendar/JobScheduleCalendar";
import { JobScheduleDetailDialog } from "../../../components/JobScheduleDetailDialog/JobScheduleDetailDialog";
import { JobScheduleView, NotifiedDriverNotes } from "../../../models";
import { EWPCOLORS } from "../../../constants/config";
import { MSGS_COMMON } from "../../../constants/messages";
import { AttachmentDialog } from "../../../components/Web/AttachmentDialog/AttachmentDialog";
import { SoftDeleteJobSchedule } from "../../../components/SoftDeleteJobSchedule/SoftDeleteJobSchedule";
import { UncancelJobSchedule } from "../../../components/UncancelJobSchedule/UncancelJobSchedule";
import { notificationsSharp } from "ionicons/icons";
import { EWPDivider } from "../../../components/EWPDivider/EWPDivider";

class JobScheduleMainPage extends React.Component<EWPProps> {
  state = {
    jobSchedules: null as JobScheduleView[] | null,
    unsubscribeJobSchdules: () => {},
    lastDataUpdate: new Date().valueOf(),
    loading: true,
    selectedSchdule: {} as JobScheduleView,
    showSiteDocketsDialog: false,
    showJobScheduleDetail: false,
    attachmentDialogOpen: false,
    attachmentIndexToOpen: null as null | number,
    jobToCancel: null as null | JobScheduleView,
    showCancelDialog: false,
    jobToUncancel: null as null | JobScheduleView,
    showUncancelDialog: false,
    scrollToDriverNotes: false,

    showNotificationPopOver: false,
    notificationDropdownEvent: undefined,

    error: "",
  };

  componentDidMount = () => {
    const newDate = _.cloneDeep(new Date());
    const { startDate, endDate } = getDateRange(
      newDate,
      "week" // default
    );
    this.getJobSchedules(startDate, endDate);
  };

  componentWillUnmount = () => {
    this.state.unsubscribeJobSchdules();
  };

  getJobSchedules = async (_startDate: Date, _endDate: Date) => {
    const { match } = this.props;
    const jobSchedId = (match.params as any).pageParam;
    this.state.unsubscribeJobSchdules();
    // console.log("GETTING JOBS --- ");
    // TO paginate, use sstart and end date in create job schedule, the one not parsed to unreadable date

    let timerId: NodeJS.Timeout | null = null;
    let jobSchedThrottled = [] as JobScheduleView[];
    let lastDateUpdateThrottled = new Date().valueOf();
    let errorThrottled = undefined as undefined | string;
    const unsubscribeJobSchdules = await services.getJobSchedulesRealTimeView(
      (jobSchedules, error) => {
        // this.setState({
        //   jobSchedules,
        //   error,
        // });
        // this.setState({
        //   lastDataUpdate: new Date().valueOf(),
        // });
        jobSchedThrottled = jobSchedules;
        errorThrottled = error;
        lastDateUpdateThrottled = new Date().valueOf();
        if (!_.isNull(timerId)) {
          clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
          this.setState({
            jobSchedules: jobSchedThrottled,
            error: errorThrottled,
          });
          this.setState({
            lastDataUpdate: lastDateUpdateThrottled,
          });
        }, 5000);

        if (!_.isEmpty(jobSchedules)) {
          if (!_.isEmpty(match.params) && jobSchedId !== undefined) {
            this.showSelectedScheduleDetail(jobSchedId);
          }
        }
      }
    );

    this.setState({
      unsubscribeJobSchdules,
      loading: false,
    });
  };
  showSelectedScheduleDetail = async (scheduleId: string) => {
    this.setState({ loading: true });
    const { jobSchedules } = this.state;
    const selectedSchdule = _.find(
      jobSchedules,
      (jobSchedule) => jobSchedule.docId === scheduleId
    );
    if (!!selectedSchdule) {
      const listOfBookedTrucks = await services.getBookedTrucks(
        getDateFromTimezoneIgnoredTimestamp(
          selectedSchdule.startDateNoTimeZone
        ),
        getDateFromTimezoneIgnoredTimestamp(selectedSchdule.endDateNoTimeZone)
        // toDateTimeFromSecs(selectedSchdule.startDate.seconds) as Date,
        // toDateTimeFromSecs(selectedSchdule.endDate.seconds) as Date
      );
      const numberOfTruckBookings = _.filter(
        listOfBookedTrucks,
        (bookedTruck) => bookedTruck.docId === selectedSchdule.truckId
      ).length;

      this.setState({
        selectedSchdule: {
          ...selectedSchdule,
          truckHasBooking: numberOfTruckBookings > 1, //if truck already have more than 1 booking, this display warning
        },
        showJobScheduleDetail: true,
        loading: false,
      });
    } else {
      this.setState({ selectedSchdule: {}, loading: false });
    }
  };
  updateSeenNotification = async (
    jobScheduleId: string,
    driverNotes: NotifiedDriverNotes
  ) => {
    if (jobScheduleId) {
      await services.updateNotificationJobSeen(jobScheduleId, driverNotes);
    }
  };
  render = () => {
    const {
      jobSchedules,
      loading,
      selectedSchdule,
      lastDataUpdate,
      error,
      showSiteDocketsDialog,
      showJobScheduleDetail,
      jobToCancel,
      showCancelDialog,
      jobToUncancel,
      showUncancelDialog,
      showNotificationPopOver,
      notificationDropdownEvent,
      scrollToDriverNotes,
    } = this.state;
    const notifiedJobSchedules = _.compact(
      (jobSchedules || []).map((schedule) => {
        return !!schedule.driverNotes &&
          !_.isEmpty(schedule.driverNotes) &&
          schedule.driverNotes.adminSeen === false
          ? schedule
          : null;
      })
    );
    return (
      <>
        <div className="job-schedule-container">
          <div className="job-schedule-header-container">
            <div className="title-notifcation-container">
              <IonLabel className="ewp-h2">Job Schedule</IonLabel>
              <IonButton
                fill="clear"
                color={
                  notifiedJobSchedules.length
                    ? EWPCOLORS.primary
                    : EWPCOLORS.light
                }
                className="notification-button"
                onClick={(e) => {
                  // faultAndDefect.setNotesDialogOpen(faultAndDefect);
                  // setShowJobAdminNotes(true);
                  // @TODO: add props onShow Notification show the job details modal dialog
                  this.setState({
                    showNotificationPopOver: true,
                    notificationDropdownEvent: e.nativeEvent,
                  });
                }}
              >
                <IonIcon icon={notificationsSharp}></IonIcon>
                <div
                  className={`notification-counter ${
                    notifiedJobSchedules.length && "has-notifications"
                  }`}
                >
                  <IonLabel className="ewp-paragraph small white">
                    {notifiedJobSchedules.length}
                  </IonLabel>
                </div>
              </IonButton>
            </div>
            <IonButton
              mode="ios"
              className="job-schedule-add-button ewp-h5 white ion-text-capitalize ion-no-margin"
              routerLink={routes.CREATE_JOB_SCHEDULE}
            >
              <IonIcon className="job-schedule-add-icon" />
              New Job
            </IonButton>
          </div>

          <div
            className={`job-schedule-calendar-container ${
              isTablet() && "ipad"
            }`}
          >
            <JobScheduleCalendar
              key={
                !!jobSchedules
                  ? jobSchedules
                      .map((schedule, index) => {
                        return `jobScheduleCalendar_${index}`;
                      })
                      .join()
                  : "job_calendar_key"
              }
              jobSchedules={jobSchedules}
              lastDataUpdate={lastDataUpdate}
              onChangeDates={(startDate, endDate) => {
                // this.getJobSchedules(startDate, endDate);
                console.log("CHANGE DATE CALLED -- ", {
                  startDate,
                  endDate,
                });
              }}
              showSelectedScheduleDetail={this.showSelectedScheduleDetail}
            />
          </div>
        </div>
        {!!selectedSchdule && !!showJobScheduleDetail && (
          <JobScheduleDetailDialog
            onCancel={(jobToCancel) => {
              this.setState({
                jobToCancel,
                showJobScheduleDetail: false,
                showCancelDialog: true,
              });
            }}
            onUncancel={(jobToUncancel) => {
              this.setState({
                jobToUncancel,
                showJobScheduleDetail: false,
                showUncancelDialog: true,
              });
            }}
            isOpen={showJobScheduleDetail}
            jobSchedule={selectedSchdule}
            showSiteDockets={() => {
              if (
                !!selectedSchdule.siteDockets &&
                !_.isEmpty(selectedSchdule.siteDockets)
              ) {
                this.setState({
                  showSiteDocketsDialog: true,
                  showJobScheduleDetail: false,
                });
              } else {
                this.setState({
                  error: "No site dockets uploaded for this job schedule.",
                });
              }
            }}
            onDidDismiss={() => {
              this.setState({
                showJobScheduleDetail: false,
                scrollToDriverNotes: false,
              });
            }}
            onShowAttachmentDialog={(isOpen: boolean) => {
              this.setState({
                attachmentDialogOpen: isOpen,
                showJobScheduleDetail: null,
              });
            }}
            onAttachmentToOpen={(attachmentIndex: number) => {
              this.setState({ attachmentIndexToOpen: attachmentIndex });
            }}
            scrollToDriverNotes={scrollToDriverNotes}
            {...this.props}
          />
        )}
        {!!showSiteDocketsDialog &&
          !!selectedSchdule.siteDockets &&
          !_.isEmpty(selectedSchdule.siteDockets) && (
            <AttachmentDialog
              attachments={selectedSchdule.siteDockets.map((siteDocket) => {
                return {
                  notApplicable: !!siteDocket.notApplicable,
                  attachmentUrl: siteDocket.attachmentURL,
                  photoName: siteDocket.siteDocketName,
                  title: `Job date: ${moment(
                    // toDateTimeFromSecs(
                    //   (siteDocket.siteDocketDate as firebase.firestore.Timestamp)
                    //     .seconds
                    // )
                    getDateFromTimezoneIgnoredTimestamp(
                      siteDocket.siteDocketDateNoTimeZone as TimezoneIgnoredTimeStamp
                    )
                  ).format("ddd DD MMM YYYY")} / Upload date: ${moment(
                    (
                      siteDocket.uploadDate as firebase.firestore.Timestamp
                    ).toDate()
                  ).format("DD MMM YYYY, hh:mm:A")}`,
                  brokenFile: !!siteDocket.brokenFile,
                };
              })}
              showModal={showSiteDocketsDialog}
              onModalDidDismiss={() => {
                this.setState({
                  showSiteDocketsDialog: false,
                  showJobScheduleDetail: true,
                });
              }}
            />
          )}
        {!_.isEmpty(selectedSchdule) &&
          !!selectedSchdule &&
          !!selectedSchdule.attachments &&
          selectedSchdule.attachments.length > 0 && (
            <AttachmentDialog
              attachments={selectedSchdule.attachments.map((url) => {
                return { attachmentUrl: url };
              })}
              title="Job Schedule Attachments"
              showModal={this.state.attachmentDialogOpen}
              isTitleHTML={true}
              {...(!_.isNull(this.state.attachmentIndexToOpen) && {
                selectedIndex: this.state.attachmentIndexToOpen,
              })}
              onModalDidDismiss={() => {
                this.setState({
                  attachmentDialogOpen: false,
                  showJobScheduleDetail: true,
                });
              }}
            />
          )}

        {showCancelDialog && !_.isEmpty(jobToCancel) && (
          <SoftDeleteJobSchedule
            isCancelled={true}
            isOpen={showCancelDialog}
            notify={jobToCancel!.notifyDriver}
            jobSchedule={jobToCancel!}
            onDidDismiss={() => {
              this.setState({
                showCancelDialog: false,
              });
            }}
            onCancel={() => {
              this.setState({
                showCancelDialog: false,
                showJobScheduleDetail: true,
              });
            }}
            onSuccess={() => {
              this.setState({
                showCancelDialog: false,
              });
            }}
          />
        )}
        {showUncancelDialog && !_.isEmpty(jobToUncancel) && (
          <UncancelJobSchedule
            isCancelled={true}
            isOpen={showUncancelDialog}
            notify={jobToUncancel!.notifyDriver}
            jobSchedule={jobToUncancel!}
            onDidDismiss={() => {
              this.setState({
                showUncancelDialog: false,
              });
            }}
            onCancel={() => {
              this.setState({
                showUncancelDialog: false,
                showJobScheduleDetail: true,
              });
            }}
            onSuccess={() => {
              this.setState({
                showUncancelDialog: false,
              });
            }}
          />
        )}
        {/* NOTIFICATION POP OVER */}
        <IonPopover
          isOpen={showNotificationPopOver}
          event={notificationDropdownEvent}
          className="ewp-header-notification-popover-container"
          showBackdrop={false}
          onDidDismiss={(e) =>
            this.setState({
              showNotificationPopOver: false,
            })
          }
        >
          <div className="pop-over-content">
            {notifiedJobSchedules.length ? (
              notifiedJobSchedules.map(
                (notifiedJob: JobScheduleView, index) => {
                  const { driverNotes } = notifiedJob;
                  return (
                    <React.Fragment key={`notif-job-${index}`}>
                      {driverNotes && (
                        <>
                          <div
                            className="notifications-container"
                            onClick={() => {
                              this.showSelectedScheduleDetail(
                                notifiedJob.docId as string
                              );
                              this.updateSeenNotification(
                                notifiedJob.docId as string,
                                driverNotes
                              );
                              this.setState({ scrollToDriverNotes: true });
                            }}
                          >
                            <IonLabel className="ewp-paragraph primary bold">{`${notifiedJob.driverDetails.firstName} ${notifiedJob.driverDetails.lastName}`}</IonLabel>
                            <IonLabel className="ewp-paragraph bold">{`${
                              driverNotes.updateDate ? "Updated" : "Sent"
                            } a Note`}</IonLabel>
                            <IonLabel className="note-message ewp-paragraph">
                              {driverNotes.description}
                            </IonLabel>
                            <IonLabel className="ewp-paragraph small">
                              Job: {notifiedJob.docId}
                            </IonLabel>
                          </div>
                          {notifiedJobSchedules.length !== index + 1 && (
                            <EWPDivider />
                          )}
                        </>
                      )}
                    </React.Fragment>
                  );
                }
              )
            ) : (
              <div className="empty-notification">
                <IonLabel className="ewp-paragraph bold">
                  No new notifications
                </IonLabel>
              </div>
            )}
          </div>
        </IonPopover>

        <IonLoading
          spinner="circular"
          translucent={true}
          mode="ios"
          isOpen={_.isNull(jobSchedules) || loading}
          message={MSGS_COMMON.loading}
        />
        <IonToast
          isOpen={!_.isEmpty(error)}
          message={error}
          duration={2000}
          onDidDismiss={() => {
            this.setState({ error: "" });
          }}
          color={EWPCOLORS.danger}
        />
      </>
    );
  };
}

export default JobScheduleMainPage;
