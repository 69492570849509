import * as _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import {
  IonLabel,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCardHeader,
  IonButton,
  IonIcon,
  IonSpinner,
} from "@ionic/react";

import "./InductionCard.scss";
import { isTablet, daysSinceDate } from "../../functions/common";
import { AttachmentDialog } from "../Web/AttachmentDialog/AttachmentDialog";
import { EWPProps } from "../../config/global";
import { EWPCOLORS } from "../../constants/config";
import { Inductions } from "../../models/drivers";

const setColumns = [
  {
    name: "Name",
    key: "name",
    colSize: isTablet() ? "5" : "7",
  },
  {
    name: "DATE INDUCTED",
    key: "dateIssued",
    colSize: isTablet() ? "6" : "4",
  },
  // {
  //   name: "Expiry Date",
  //   key: "expiryDate",
  //   colSize: isTablet() ? "3" : "2",
  // },
];

interface InductionCardProps extends EWPProps {
  inductionData: Inductions[] | null; //todo: update interface once model is okay
  onClickEdit?: (induction: Inductions) => void;
  onAdd?: () => void;
}
export const InductionCard = (props: InductionCardProps) => {
  const { inductionData, onAdd, onClickEdit } = props;
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);
  const [selectedInduction, setSelectedInduction] = useState(
    null as null | Inductions
  );

  return (
    <>
      <IonCard
        className={`induction-card ${
          isTablet() ? "tablet" : ""
        } ion-no-padding ion-no-margin`}
        color={EWPCOLORS.tertiary}
      >
        <IonCardContent className="induction-card-content ion-no-padding ion-no-margin">
          <IonCardHeader className="induction-card-header ion-no-padding ion-no-margin">
            <IonLabel className="induction-card-header-label ewp-h4 bold ion-no-padding ion-no-margin">
              Certifications that Don't Expire
            </IonLabel>
            <IonButton
              mode="ios"
              fill="clear"
              color={EWPCOLORS.primary}
              onClick={onAdd}
              className="induction-card-header-add-induction-button ewp-h5 ion-no-padding ion-no-margin"
            >
              <u>Add Induction</u>
            </IonButton>
          </IonCardHeader>
          <IonGrid className="induction-card-grid ion-no-padding ion-no-margin">
            <IonRow
              className={`induction-card-row-content-header ${
                _.isEmpty(inductionData) && "no-border-bottom"
              } ion-no-padding ion-no-margin`}
            >
              {setColumns.map((inductionColumn) => {
                return (
                  <IonCol
                    className="ion-no-padding ion-text-start"
                    size={inductionColumn.colSize}
                  >
                    <IonLabel className="ewp-h6 medium bold ion-text-uppercase ion-text-start">
                      {inductionColumn.name}
                    </IonLabel>
                  </IonCol>
                );
              })}
              <IonCol className="ion-no-padding ion-text-start" size="1">
                {/* for button container */}
              </IonCol>
            </IonRow>

            {!_.isNull(inductionData) ? (
              inductionData.map((induction) => {
                const isDueForReminder =
                  !!induction.expiryDate &&
                  daysSinceDate(new Date(), induction.expiryDate.toDate()) <=
                    4 * 7; // 4 weeks

                const isExpired =
                  !!induction.expiryDate &&
                  daysSinceDate(new Date(), induction.expiryDate.toDate()) <= 0;
                return (
                  <IonRow className="induction-card-row-content-data ion-no-padding ion-no-margin">
                    {setColumns.map((inductionColumn, index) => {
                      return (
                        <IonCol
                          className="ion-no-padding ion-text-start"
                          size={inductionColumn.colSize}
                        >
                          <IonLabel
                            className={`ewp-h6 medium ${
                              inductionColumn.key === "expiryDate"
                                ? isExpired
                                  ? "danger"
                                  : isDueForReminder
                                  ? "exp-warning"
                                  : ""
                                : ""
                            } ion-text-start`}
                          >
                            {(inductionColumn.key === "dateIssued" ||
                              inductionColumn.key === "expiryDate") &&
                            !!induction[inductionColumn.key]
                              ? moment(
                                  induction[inductionColumn.key]!.toDate()
                                ).format("DD MMM, YYYY")
                              : induction[
                                  inductionColumn.key as "name" | "dateIssued"
                                ] || "N/A"}
                          </IonLabel>
                        </IonCol>
                      );
                    })}
                    <IonCol className="ion-no-padding ion-text-start" size="1">
                      <div className="induction-card-buttons-container">
                        {!!induction &&
                          !!induction.attachments &&
                          !_.isEmpty(induction.attachments) && (
                            <IonButton
                              fill="clear"
                              mode="ios"
                              className="induction-attachment-button ewp-web-gray-button ion-no-padding ion-no-margin"
                              onClick={() => {
                                setShowAttachmentDialog(true);
                                setSelectedInduction(induction);
                              }}
                            >
                              <IonIcon className="induction-attachment-icon" />
                            </IonButton>
                          )}
                        {!!onClickEdit && (
                          <IonButton
                            fill="clear"
                            mode="ios"
                            className="induction-edit-button ewp-web-gray-button ion-no-padding ion-no-margin"
                            onClick={() => {
                              onClickEdit(induction);
                            }}
                          >
                            <IonIcon className="induction-edit-icon" />
                          </IonButton>
                        )}
                      </div>
                    </IonCol>
                  </IonRow>
                );
              })
            ) : (
              <div className="ewp-table-spinner-container">
                <IonSpinner className="ewp-spinner" color={EWPCOLORS.primary} />
              </div>
            )}
          </IonGrid>
        </IonCardContent>
      </IonCard>
      {!!showAttachmentDialog &&
        !!selectedInduction &&
        !!selectedInduction.attachments &&
        !_.isEmpty(selectedInduction.attachments) && (
          <AttachmentDialog
            title="Inductions"
            attachments={selectedInduction.attachments.map((attachmentUrl) => {
              return {
                attachmentUrl,
              };
            })}
            showModal={showAttachmentDialog}
            onModalDidDismiss={() => {
              setShowAttachmentDialog(false);
              setSelectedInduction(null);
            }}
          />
        )}
    </>
  );
};
