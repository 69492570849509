import * as _ from "lodash";
import Bugsnag from "@bugsnag/js";
import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonToast,
} from "@ionic/react";

import "./ClientDashboard.scss";
import * as routes from "../../../constants/routes";
import * as services from "../../../services";
import { ArchiveOrRestoreTruckDialog } from "../../ArchiveOrRestoreTruckDialog/ArchiveOrRestoreTruckDialog";
import { storage } from "../../../firebase";
import { EWPDetailCard } from "../../EWPDetailCard/EWPDetailCard";
import { EWPJobHistoryCard } from "../../EWPJobHistoryCard/EWPJobHistoryCard";
import { EWPTitleDetailPage } from "../../EWPTitleDetailPage/EWPTitleDetailPage";
import { EWPNoteWithAttachment } from "../../EWPNoteWithAttachment/EWPNoteWithAttachment";
import { EWPNoteCard } from "../EWPNoteCard/EWPNoteCard";
import { JobScheduleFor } from "../../../pages/Home/Home";
import {
  uploadAttachments,
  getServerTimestamp,
  getDateFromTimezoneIgnoredTimestamp,
  isHistoricalDate,
} from "../../../functions/common";
import { EWPProps, TextEditorEdit } from "../../../config/global";
import {
  ClientContactPersonDetailedView,
  ClientNotes,
  ContactPerson,
  JobScheduleView,
  Notes,
} from "../../../models";
import { EWPCOLORS } from "../../../constants/config";
import { MSGS_COMMON, MSGS_NOTE_ENTRY } from "../../../constants/messages";
import { ClientContactsCard } from "../../ClientContactsCard/ClientContactsCard";
import { ClientContactForm } from "../../ClientContactForm/ClientContactForm";
import { EWPWebDialog } from "../../EWPWebDialog/EWPWebDialog";

interface ClientDashboardProps extends EWPProps {
  client: ClientContactPersonDetailedView | null;
}
export const ClientDashboard = (props: ClientDashboardProps) => {
  const { client } = props;

  const [showAddNote, setShowAddNote] = useState(false);
  const [clientNotes, setClientNotes] = useState(null as null | ClientNotes[]);
  const [noteTitle, setNoteTitle] = useState("");
  const [noteDescription, setNoteDescription] = useState("");
  const [listOfAttachment, setListOfAttachment] = useState([] as any[]);
  const [editClientNoteData, setEditClientNoteData] = useState(
    {} as ClientNotes
  );
  const [clientContacts, setClientContacts] = useState(
    null as null | ContactPerson[]
  );
  const [removedUrl, setRemovedUrl] = useState([] as any[]);
  const [willEditNoteEntry, setwillEditNoteEntry] = useState(false);

  const [selectedEditIndex, setSelectedEditIndex] = useState(
    null as null | number
  );
  const [showArchivedRestoreDialog, setShowArchivedRestore] = useState(false);
  const [jobHistory, setJobHistory] = useState(
    null as null | JobScheduleView[]
  );
  const [jobUpcoming, setJobUpcoming] = useState(
    null as null | JobScheduleView[]
  );

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedContactPerson, setSelectedContactPerson] = useState(
    null as null | ContactPerson
  );
  const [showContactPersonDialog, setShowContactPersonDialog] = useState(false);

  // eslint-disable-next-line
  useEffect(() => {
    if (!_.isNull(client)) {
      if (!_.isEmpty(client)) {
        getJobHistory();
      } else {
        setJobHistory([]);
        setJobUpcoming([]);
      }
    }
    if (_.isNull(clientNotes)) {
      getClientNotes();
    }
    if (!_.isNull(client) && _.isNull(clientContacts)) {
      getClientContacts();
    }
    // eslint-disable-next-line
  });
  const onAttachPhoto = async (file: any) => {
    if (!!file && file.target.files.length > 0) {
      const attachments = file.target.files;
      const toBeUploadedAttachments = _.concat(
        Object.values(attachments),
        listOfAttachment
      );
      setListOfAttachment(toBeUploadedAttachments);
    }
  };

  const onRemoveAttachment = (attachment: any) => {
    let updatedEditData = _.clone(editClientNoteData);

    const updatedListOfEditDataAttachment = _.remove(
      updatedEditData.attachments as string[],
      (item) => {
        return item !== attachment;
      }
    );
    updatedEditData = {
      ...updatedEditData,
      attachments: updatedListOfEditDataAttachment,
    };

    const updatedListOfAttachment = _.remove(listOfAttachment, (item) => {
      return item !== attachment;
    });

    setListOfAttachment(updatedListOfAttachment);
    setEditClientNoteData(updatedEditData);
    setRemovedUrl([...removedUrl, attachment]);
  };
  const getClientContacts = () => {
    if (!!client && !_.isEmpty(client)) {
      services.getClientsContactsRealTime(
        client.docId || "",
        (clientContacts) => {
          setClientContacts(clientContacts);
        }
      );
    }
  };
  const getJobHistory = () => {
    if (_.isNull(jobHistory)) {
      services.getJobSchedulesRealTimeView((jobSchedules, error) => {
        setJobHistory(
          _.filter(
            jobSchedules,
            (job) =>
              !_.isEmpty(job.siteDockets) && job.clientId === client!.docId
          )
        );

        setJobUpcoming(
          _.filter(
            jobSchedules,
            (job) =>
              job.clientId === client!.docId &&
              !isHistoricalDate(
                getDateFromTimezoneIgnoredTimestamp(job.startDateNoTimeZone)
              )
          )
        );
        setError(error || "");
      });
    }
  };

  const getClientNotes = () => {
    if (_.isNull(clientNotes) && !!client) {
      services.getClientNotes(client.docId as string, (clientNote, error) => {
        setClientNotes(clientNote);
        setError(error || "");
      });
    }
  };
  const onSaveClientNotes = async () => {
    setLoading(true);
    const { docId, lastName } = props.authUser;
    const noteEntry = {
      title: noteTitle,
      description: noteDescription,
    } as Notes;
    let errorMsg = "";

    if (!_.isEmpty(editClientNoteData)) {
      let updatedEditData = _.clone(editClientNoteData);
      const updatedTile = !_.isEmpty(noteTitle)
        ? noteTitle
        : updatedEditData!.notes!.title;
      const updatedDescription = !_.isEmpty(noteDescription)
        ? noteDescription
        : updatedEditData!.notes!.description;
      const updatedNotes = {
        title: updatedTile,
        description: updatedDescription,
      };
      try {
        let attachmentUrlList = [] as string[];
        if (listOfAttachment.length > 0) {
          const urlList = await uploadAttachments(
            listOfAttachment,
            lastName,
            docId,
            "clientNotes"
          );
          attachmentUrlList = urlList;
        }
        const updateAttachments = _.concat(
          updatedEditData.attachments,
          attachmentUrlList
        ) as string[];
        updatedEditData = {
          ...updatedEditData,
          notes: updatedNotes,
          updatedDate: getServerTimestamp(),
          attachments: updateAttachments,
          updatedBy: docId,
        };
        await services.updateClientNoteEntry(
          updatedEditData.docId!,
          updatedEditData
        );
        //remove the file in our storage
        await Promise.resolve(
          removedUrl.forEach((url) => {
            new Promise(async (resolve) => {
              try {
                const refFromUrl = storage.refFromURL(url);
                const deletedUrl = await refFromUrl.delete();
                resolve(deletedUrl);
              } catch (errorUnknown) {
                const error = errorUnknown as any;
                resolve(null);
                Bugsnag.notify(new Error(error));
              }
            });
          })
        );
        setSuccess(MSGS_COMMON.success);
        setListOfAttachment([]);
        setEditClientNoteData({} as ClientNotes);
        setwillEditNoteEntry(false);
        setSelectedEditIndex(null);
        setRemovedUrl([]);
        // this.setState({
        //   success: MSGS_COMMON.success,
        //   willEditDailyEntry: false,
        //   listOfAttachment: [],
        //   editDiaryEntryData: {} as ClientNotes,
        //   selectedEditIndex: null,
        //   removedUrl: [],
        // });
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        setError(error);
        Bugsnag.notify(new Error(error));
      }
    } else {
      if (!noteEntry.title) {
        errorMsg = MSGS_NOTE_ENTRY.required;
      } else if (!noteEntry.description) {
        errorMsg = MSGS_NOTE_ENTRY.required;
      }
      if (errorMsg) {
        setError(errorMsg);
      } else {
        try {
          let attachmentUrlList = [] as string[];
          if (listOfAttachment.length > 0) {
            const urlList = await uploadAttachments(
              listOfAttachment,
              lastName,
              docId,
              "clientNotes"
            );
            attachmentUrlList = urlList;
          }
          const clientNoteEntry = {
            clientId: client!.docId as string,
            createdBy: docId,
            attachments: attachmentUrlList,
            notes: noteEntry,
          } as ClientNotes;
          await services.createClientNotes(clientNoteEntry);
          setSuccess(MSGS_COMMON.success);
          setListOfAttachment([]);
          setRemovedUrl([]);
          setShowAddNote(false);
        } catch (errorUnknown) {
          const error = errorUnknown as any;
          setError(error);
          Bugsnag.notify(new Error(error));
        }
      }
    }
  };

  const onEditNoteEntry = (
    noteEntry: ClientNotes,
    selectedEditIndex: number
  ) => {
    const editNoteEntryData = _.cloneDeep(noteEntry);
    setwillEditNoteEntry(true);
    setEditClientNoteData(editNoteEntryData);
    setSelectedEditIndex(selectedEditIndex);
  };

  // console.log("this is the clientNotes --- ", clientNotes);

  const editData =
    !_.isEmpty(editClientNoteData) && willEditNoteEntry
      ? {
          title: editClientNoteData!.notes!.title,
          description: editClientNoteData!.notes!.description,
          listOfAttachment: editClientNoteData.attachments,
        }
      : {};

  return (
    <>
      <div className="client-dashboard-container">
        <EWPTitleDetailPage
          pageTitle="Client"
          {...props}
          createButtonLabel="Create Job For Client"
          newButtonLabel="Edit Client"
          disableCreate={_.isEmpty(client)}
          onClickCreateButton={() => {
            if (!_.isEmpty(client)) {
              props.history.push(routes.CREATE_JOB_SCHEDULE, {
                clientId: client!.docId || "",
              } as JobScheduleFor);
            }
          }}
          backURL={routes.HOME_CLIENT_REGISTER}
          onClickEditButton={() => {
            if (!_.isEmpty(client)) {
              props.history.push(
                routes.HOME_EDIT_CLIENT.replace(
                  ":clientId",
                  client!.docId || ""
                )
              );
            }
          }}
        />
        <div className="client-dashboard-detail-card-container">
          <EWPDetailCard
            {...props}
            mode="client"
            cardDetailTitle="Client Details"
            // cardButtonLabel="Archived Client"
            cardData={client}
            onArchived={() => {
              setShowArchivedRestore(true);
            }}
          />
        </div>
        <div className="client-dashboard-job-history-card-container">
          <EWPJobHistoryCard
            {...props}
            jobHistories={jobUpcoming}
            isUpcoming={true}
          />
        </div>
        <div className="client-dashboard-job-history-card-container">
          <EWPJobHistoryCard {...props} jobHistories={jobHistory} />
        </div>
        <div className="client-dashboard-contact-people-card-container">
          <IonGrid className="contact-people-grid ion-no-padding ion-no-margin">
            <IonRow className="contact-people-row ion-no-padding ion-no-margin">
              <IonCol
                className="contact-people-col ion-no-padding ion-no-margin"
                size="6"
              >
                <ClientContactsCard
                  {...props}
                  contactData={clientContacts}
                  onClickEdit={(contactPerson) => {
                    console.log("this is selectedContact -- ", contactPerson);
                    setSelectedContactPerson(contactPerson);
                    setShowContactPersonDialog(true);
                  }}
                  onAdd={() => {
                    setShowContactPersonDialog(true);
                  }}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        {!!clientNotes && (
          <div>
            {showAddNote ? (
              <IonCard
                className="client-dashboard-note-card ion-no-padding ion-no-margin"
                color={EWPCOLORS.tertiary}
              >
                <IonCardHeader className="client-dashboard-note-card-header ion-no-padding ion-no-margin">
                  <IonLabel className="client-dashboard-note-card-headerlabel ewp-h4 bold ion-no-padding ion-no-margin">
                    Notes
                  </IonLabel>
                </IonCardHeader>
                <div className="client-dashboard-note-container">
                  <EWPNoteWithAttachment
                    onChangeTitle={(titleData) => {
                      setNoteTitle(titleData);
                    }}
                    onChangeBody={(bodyData) => {
                      setNoteDescription(bodyData);
                    }}
                    listOfAttachment={listOfAttachment} //callback here to display attachment
                    onAttachPhoto={onAttachPhoto}
                    onRemoveAttachment={onRemoveAttachment}
                  />

                  <IonItem
                    lines="none"
                    className="client-dashboard-note-action-buttons-item ewp-item-no-horizontal-padding"
                    color={EWPCOLORS.tertiary}
                  >
                    <IonButton
                      mode="ios"
                      slot="start"
                      className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
                      fill="clear"
                      color={EWPCOLORS.medium}
                      onClick={() => {
                        setShowAddNote(false);
                      }}
                    >
                      Cancel
                    </IonButton>
                    <IonButton
                      slot="end"
                      mode="ios"
                      className="client-dashboard-note-save-button ewp-h6 ion-no-margin ion-no-padding"
                      color={EWPCOLORS.primary}
                      onClick={() => {
                        onSaveClientNotes();
                      }}
                    >
                      Save
                    </IonButton>
                  </IonItem>
                </div>
              </IonCard>
            ) : (
              _.isEmpty(clientNotes) && (
                <div className="client-add-note-button-container">
                  <IonButton
                    mode="ios"
                    className="client-add-note-button ewp-h3 ion-no-margin ion-no-padding"
                    color={EWPCOLORS.primary}
                    onClick={() => {
                      setShowAddNote(true);
                    }}
                  >
                    Add New Note
                  </IonButton>
                </div>
              )
            )}

            {!!clientNotes &&
              !_.isEmpty(clientNotes) &&
              clientNotes.map((clientNote, index) => {
                return index === selectedEditIndex ? (
                  <IonCard
                    className="client-dashboard-note-card ion-no-padding ion-no-margin"
                    color={EWPCOLORS.tertiary}
                  >
                    <IonCardHeader className="client-dashboard-note-card-header ion-no-padding ion-no-margin">
                      <IonLabel className="client-dashboard-note-card-headerlabel ewp-h4 bold ion-no-padding ion-no-margin">
                        Edit Note
                      </IonLabel>
                    </IonCardHeader>
                    <div className="client-dashboard-note-container">
                      <EWPNoteWithAttachment
                        onChangeTitle={(titleData) => {
                          setNoteTitle(titleData);
                        }}
                        onChangeBody={(bodyData) => {
                          setNoteDescription(bodyData);
                        }}
                        listOfAttachment={listOfAttachment} //callback here to display attachment
                        onAttachPhoto={onAttachPhoto}
                        onRemoveAttachment={onRemoveAttachment}
                        editData={editData as TextEditorEdit}
                      />

                      <IonItem
                        lines="none"
                        className="client-dashboard-note-action-buttons-item ewp-item-no-horizontal-padding"
                        color={EWPCOLORS.tertiary}
                      >
                        <IonButton
                          mode="ios"
                          slot="start"
                          className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
                          fill="clear"
                          color={EWPCOLORS.medium}
                          onClick={() => {
                            setSelectedEditIndex(null);
                            setEditClientNoteData({} as ClientNotes);
                          }}
                        >
                          Cancel
                        </IonButton>
                        <IonButton
                          slot="end"
                          mode="ios"
                          className="client-dashboard-note-save-button ewp-h6 ion-no-margin ion-no-padding"
                          color={EWPCOLORS.primary}
                          onClick={() => {
                            onSaveClientNotes();
                          }}
                        >
                          Save
                        </IonButton>
                      </IonItem>
                    </div>
                  </IonCard>
                ) : (
                  <div className="note-card-container">
                    <EWPNoteCard
                      onEdit={() => {
                        onEditNoteEntry(clientNote, index);
                      }}
                      noteEntry={clientNote}
                      index={index}
                    />
                    {index + 1 === clientNotes.length && (
                      <IonButton
                        mode="ios"
                        className="note-entry-action-button ewp-web-gray-button ewp-h6 dark ion-text-capitalize ion-no-margin"
                        onClick={() => {
                          setShowAddNote(true);
                        }}
                      >
                        <IonIcon className="note-entry-add-icon" />
                        Add New Entry
                      </IonButton>
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </div>

      {showContactPersonDialog && !!client && (
        <EWPWebDialog
          headerTitle={
            !_.isNull(selectedContactPerson)
              ? "Edit Contact Person"
              : "Create New Contact Person"
          }
          isOpen={showContactPersonDialog}
          onDidDismiss={() => {
            setShowContactPersonDialog(false);
            setSelectedContactPerson(null);
          }}
        >
          <ClientContactForm
            clientId={client.docId as string}
            isEdit={false}
            onModalDidDismiss={() => {
              setShowContactPersonDialog(false);
              setClientContacts(null);
            }}
            editData={selectedContactPerson as ContactPerson}
          />
        </EWPWebDialog>
      )}
      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.saving}
      />
      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          setSuccess("");
          setLoading(false);
        }}
        color={EWPCOLORS.success}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />
      {showArchivedRestoreDialog && (
        <ArchiveOrRestoreTruckDialog
          mode="client"
          isOpen={showArchivedRestoreDialog}
          data={client as ClientContactPersonDetailedView}
          onDidDismiss={() => {
            setShowArchivedRestore(false);
          }}
          onSuccess={() => {}}
        />
      )}
    </>
  );
};
