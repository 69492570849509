import * as _ from "lodash";
import React from "react";
import { IonContent, IonGrid, IonRow, IonCol, IonPage } from "@ionic/react";

import "./Home.scss";
import { isTablet } from "../../functions/common";
import Admin from "./Admin/Admin";
import Client from "./Client/Client";
import SearchPage from "./SearchPage/SearchPage";
import DriverMainPage from "./DriverMainPage/DriverMainPage";
import EWPHeader from "../../components/EWPHeader/EWPHeader";
import Job from "../../components/Web/Job/Job";
import JobScheduleMainPage from "./JobScheduleMainPage/JobScheduleMainPage";
import TruckMainPage from "./TruckMainPage/TruckMainPage";
import { EWPWebSideNavigator } from "../../components/EWPWebSideNavigator/EWPWebSideNavigator";
import { EWPSideMenu } from "../../components/EWPSideMenu/EWPSideMenu";
import { JobScheduleView } from "../../models";
import { EWPProps, HomeSubPages } from "../../config/global";
import { HOME_PAGES } from "../../constants/navPages";
import { UserList } from "./UserList/UserList";
import { FaultsAndDefects } from "./FaultsAndDefects/FaultsAndDefects";

export interface SubPageParam {
  subPage: HomeSubPages;
}
export interface JobScheduleFor {
  clientId?: string;
  truckId?: string;
  driverId?: string;
}

export interface JobScheduleLocationInterface {
  jobSchedule: JobScheduleView;
  isEdit: boolean;
}

interface HomeState {
  loading: boolean;
  error: string;
  initialized: boolean;
}

class Home extends React.Component<EWPProps> {
  state: HomeState = {
    loading: false,
    error: "",
    initialized: false,
  };

  renderPage = (subPage: string) => {
    const state = this.props.location.state;
    const { clientId, truckId, driverId } = (
      !_.isEmpty(state) ? state : { clientId: "", truckId: "", driverId: "" }
    ) as JobScheduleFor;
    const { jobSchedule, isEdit } = (
      !_.isEmpty(state) ? state : { jobSchedule: {}, isEdit: false }
    ) as JobScheduleLocationInterface;

    switch (subPage) {
      case HOME_PAGES.jobSchedule:
        return <JobScheduleMainPage {...this.props} />;
      case HOME_PAGES.createNewJobSchedule:
        return (
          <Job
            jobFor={{ clientId, truckId, driverId }}
            {...this.props}
            {...(!!jobSchedule &&
              !_.isEmpty(jobSchedule) && { jobScheduleData: jobSchedule })}
            {...(!!jobSchedule && !_.isEmpty(jobSchedule) && { isEdit })}
          />
        );
      case HOME_PAGES.editJobSchedule:
        return (
          <Job
            jobFor={{ clientId, truckId, driverId }}
            {...this.props}
            {...(!!jobSchedule &&
              !_.isEmpty(jobSchedule) && { jobScheduleData: jobSchedule })}
            {...(!!jobSchedule && !_.isEmpty(jobSchedule) && { isEdit })}
          />
        );

      case HOME_PAGES.clientRegister:
        return <Client {...this.props} />;
      case HOME_PAGES.truckRegister:
        return <TruckMainPage {...this.props} />;
      case HOME_PAGES.driverRegister:
        return <DriverMainPage {...this.props} />;
      case HOME_PAGES.users:
        return <UserList {...this.props} />;
      case HOME_PAGES.admin:
        return <Admin {...this.props} />;
      case HOME_PAGES.search:
        return <SearchPage {...this.props} />;
      case HOME_PAGES.faultsAndDefects:
        return <FaultsAndDefects {...this.props} />;
      default:
        return <div>404</div>;
    }
  };

  render = () => {
    const { subPage } = this.props.match.params as SubPageParam;
    // const { loading, error } = this.state;
    return (
      <>
        {isTablet() && (
          <EWPSideMenu
            activeSubPage={subPage}
            {...this.props}
            mode="homePage"
          />
        )}
        <IonPage id="homePage">
          <EWPHeader {...this.props} />
          <IonContent>
            <IonGrid className="nav-content-grid-container ion-no-padding">
              <IonRow className="nav-content-row-container">
                {!isTablet() && (
                  <IonCol className="nav-content-col-container" size="2">
                    <EWPWebSideNavigator
                      activeSubPage={subPage}
                      {...this.props}
                      mode="homePage"
                    />
                  </IonCol>
                )}
                <IonCol
                  size={isTablet() ? "12" : "9.5"}
                  className={`home-container ${
                    isTablet() ? "is-tablet" : ""
                  } ion-no-padding`}
                >
                  {this.renderPage(subPage)}
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonPage>
      </>
    );
  };
}

export default Home;
