import * as _ from "lodash";
import moment from "moment";
import React from "react";
import {
  IonLabel,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCardHeader,
  IonButton,
  IonIcon,
  IonSpinner,
} from "@ionic/react";

import "./ExpiriesCard.scss";
import { daysSinceDate, isTablet } from "../../functions/common";
import { ExpiryWithDate } from "../../models/drivers";
import { EWPProps } from "../../config/global";
import { EWPCOLORS } from "../../constants/config";

interface ExpiriesCardProps extends EWPProps {
  expiriesData: null | ExpiryWithDate[]; //todo: update interface once model is okay
  onClickEdit?: (expiryId: string) => void;
  onClickViewAttachment?: (driverExpiryId: string) => void;
  hasAttachment?: (driverExpiryId: string) => boolean;
  cardLabel?: string;
  cardColumnName?: string;
  forTruck?: boolean;
}

export const ExpiriesCard = (props: ExpiriesCardProps) => {
  const {
    expiriesData,
    forTruck,
    onClickEdit,
    onClickViewAttachment,
    hasAttachment,
    cardLabel,
    cardColumnName,
  } = props;
  const setColumns = forTruck
    ? [
        {
          name: cardColumnName ? cardColumnName : "EXPIRY NAME",
          key: "name",
          colSize: !!onClickViewAttachment ? "4.5" : "5",
        },
        {
          name: cardColumnName ? cardColumnName : "REMARKS",
          key: "remarks",
          colSize: !!onClickViewAttachment ? "4" : "4",
        },
        {
          name: "EXPIRY DATE",
          key: "expiryDate",
          colSize: !!onClickViewAttachment ? "2.5" : "2",
        },
      ]
    : [
        {
          name: cardColumnName ? cardColumnName : "EXPIRY NAME",
          key: "name",
          colSize: !!onClickViewAttachment ? "8.5" : "9",
        },
        {
          name: "EXPIRY DATE",
          key: "expiryDate",
          colSize: !!onClickViewAttachment ? "2.5" : "2",
        },
      ];

  return (
    <>
      <IonCard
        className={`expiries-card ${
          isTablet() ? "tablet" : ""
        } ion-no-padding ion-no-margin`}
        color={EWPCOLORS.tertiary}
      >
        <IonCardContent className="expiries-card-content ion-no-padding ion-no-margin">
          <IonCardHeader className="expiries-card-header ion-no-padding ion-no-margin">
            <IonLabel className="expiries-card-header-label ewp-h4 bold ion-no-padding ion-no-margin">
              {cardLabel ? cardLabel : "Expiries"}
            </IonLabel>
          </IonCardHeader>
          <IonGrid className="expiries-card-grid ion-no-padding ion-no-margin">
            <IonRow
              className={`expiries-card-row-content-header ${
                _.isEmpty(expiriesData) && "no-border-bottom"
              } ion-no-padding ion-no-margin`}
            >
              {setColumns.map((expiresColumn) => {
                return (
                  <IonCol
                    className="ion-no-padding ion-text-start"
                    size={expiresColumn.colSize}
                  >
                    <IonLabel className="ewp-h6 medium bold ion-text-uppercase ion-text-start">
                      {expiresColumn.name}
                    </IonLabel>
                  </IonCol>
                );
              })}
              <IonCol className="ion-no-padding ion-text-start" size="1">
                {/* for button container */}
              </IonCol>
            </IonRow>

            {!_.isNull(expiriesData) ? (
              expiriesData.map((expiryData) => {
                const isDueForReminder =
                  !!expiryData.expiryDate &&
                  daysSinceDate(new Date(), expiryData.expiryDate.toDate()) <=
                    expiryData.expiryReminder * 7;

                const isExpired =
                  !!expiryData.expiryDate &&
                  daysSinceDate(new Date(), expiryData.expiryDate.toDate()) <=
                    0;

                return (
                  <IonRow className="expiries-card-row-content-data ion-no-padding ion-no-margin">
                    {setColumns.map((expiresColumn) => {
                      return (
                        <IonCol
                          className="ion-no-padding ion-text-start"
                          size={expiresColumn.colSize}
                        >
                          <IonLabel
                            className={`ewp-h6 medium ${
                              expiresColumn.key === "expiryDate"
                                ? isExpired
                                  ? "danger"
                                  : isDueForReminder
                                  ? "exp-warning"
                                  : ""
                                : ""
                            } ion-text-start`}
                          >
                            {expiresColumn.key === "expiryDate" &&
                            !!expiryData[expiresColumn.key]
                              ? moment(
                                  expiryData[expiresColumn.key]!.toDate()
                                ).format("DD MMM YYYY")
                              : expiryData[
                                  expiresColumn.key as "name" | "expiryDate"
                                ] || "N/A"}
                          </IonLabel>
                        </IonCol>
                      );
                    })}

                    <IonCol
                      className="ion-no-padding ion-text-start"
                      size={!!onClickViewAttachment ? "1" : "1"}
                    >
                      <div className="expiries-card-buttons-container">
                        {!!onClickViewAttachment &&
                          hasAttachment !== undefined &&
                          hasAttachment(expiryData.expiryId) && (
                            <IonButton
                              fill="clear"
                              mode="ios"
                              className="expiries-attachment-button ewp-web-gray-button ion-no-padding ion-no-margin"
                              onClick={() => {
                                onClickViewAttachment(expiryData.expiryId);
                              }}
                            >
                              <IonIcon className="expiries-attachment-icon" />
                            </IonButton>
                          )}
                        {!!onClickEdit && (
                          <IonButton
                            fill="clear"
                            mode="ios"
                            className="expiries-edit-button ewp-web-gray-button ion-no-padding ion-no-margin"
                            onClick={() => {
                              onClickEdit(expiryData.expiryId);
                            }}
                          >
                            <IonIcon className="expiries-edit-icon" />
                          </IonButton>
                        )}
                      </div>
                    </IonCol>
                  </IonRow>
                );
              })
            ) : (
              <div className="ewp-table-spinner-container">
                <IonSpinner className="ewp-spinner" color={EWPCOLORS.primary} />
              </div>
            )}
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  );
};
