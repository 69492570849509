export const EWPCOLORS = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
  success: "success",
  warning: "warning",
  danger: "danger",
  dark: "dark",
  medium: "medium",
  light: "light",
  white: "white",
  gray: "gray",
};

export const LIMITS = {
  name: {
    single: 50,
    full: 100,
  },
  serviceTypeAttachments: {
    count: 6,
    size: 5000000, // 5mb
    limitSizePretty: "5mb",
  },
  faultsAndDefectsAttachments: {
    count: 10,
    size: 5000000, // 5mb
    limitSizePretty: "5mb",
  },
  inactivity: {
    timeOut: 60000 * 1 * 30,
  },
};

export const APP_DOWNLOAD_LINK = "https://il1nk.app.link/download";

export const FIREBASE_EMAIL_MODES = {
  verifyEmail: "verifyEmail",
  resetPassword: "resetPassword",
  recoverEmail: "recoverEmail",
};

export const FIREBASE_ERROR_CODES = {
  invalidActionCode: "auth/invalid-action-code",
  userNotFound: "auth/user-not-found",
  incorrectPassword: "auth/wrong-password",
};

export const JOB_TIME_SHIFTS = {
  day: 6,
  night: 18,
};

export const NON_REMOVEABLE_DRIVER_EXPIRIES = [
  {
    docId: "LICENCE_EXPIRY_EWP_2020",
    name: "Licence",
    expiryReminder: 0,
  },
  {
    docId: "WHITE_CARD_EXPIRY_EWP_2020",
    name: "White Card",
    expiryReminder: 0,
  },
  {
    docId: "EWP_TICKET_EXPIRY_EWP_2020",
    name: "EWP Ticket",
    expiryReminder: 0,
  },
];

export const NON_REMOVEABLE_TRUCK_EXPIRIES = [
  {
    docId: "REGO_EXPIRY_EWP_2020",
    name: "Rego Expiry",
    expiryReminder: 0,
  },
  {
    docId: "COI_EXPIRY_EWP_2020",
    name: "COI Expiry",
    expiryReminder: 0,
  },
  {
    //! DEV, WILL REFACTOR SOON
    docId: "9LnBFbkKieR1xLN8h6wz",
    name: "RMS Expiry",
    expiryReminder: 3,
  },
  {
    //! PROD, WILL REFACTOR SOON
    docId: "54hdt3vUwxZgchpFmOPO",
    name: "RMS Expiry",
    expiryReminder: 6,
  },
];
