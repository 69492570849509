import * as _ from "lodash";
import moment from "moment";
import Bugsnag from "@bugsnag/js";
import { Camera, CameraResultType } from "@capacitor/camera";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
  withIonLifeCycle,
} from "@ionic/react";
import React from "react";

import "./DriverMaintenance.scss";
import * as routes from "../../../constants/routes";
import * as services from "../../../services";
import { EWPInput } from "../../../components/EWPInput/EWPInput";
import { EWPHeader } from "../../../components/Mobile/EWPHeader/EWPHeader";
import { EWPProps, FaultDefectUrgency } from "../../../config/global";
import { EWPUserAccountFooter } from "../../../components/Mobile/EWPUserAccountFooter/EWPUserAccountFooter";
import { EWPCOLORS } from "../../../constants/config";
import {
  DropdownItem,
  EWPWebDropdownSelect,
} from "../../../components/EWPWebDropdownSelect/EWPWebDropdownSelect";
import { storage } from "../../../firebase";
import { close, closeCircleOutline } from "ionicons/icons";
import { EWPButton } from "../../../components/EWPButton/EWPButton";
import { MSGS_COMMON } from "../../../constants/messages";

class DriverMaintenance extends React.Component<EWPProps> {
  contentRef: React.RefObject<HTMLIonContentElement>;
  attachRef: React.RefObject<HTMLInputElement>;

  state = {
    safeToView: false,
    loading: false,
    error: "",
    success: "",
    truckOptions: null as null | DropdownItem[],
    selectedTruckId: "",
    errorMsgTruck: "",
    errorMsgDescription: "",
    defectDescription: "",
    errorMsgHours: "",
    defectHours: "",
    errorMsgKilometres: "",
    defectKilometres: "",
    listOfAttachment: [] as any[],
  };

  constructor(props: EWPProps) {
    super(props);
    this.contentRef = React.createRef();
    this.attachRef = React.createRef();
  }

  ionViewDidEnter = () => {
    setTimeout(() => {
      this.setState({ safeToView: true });
    });
    this.getTruckOptions();
  };
  onClickUploadPhoto = () => {
    const { listOfAttachment } = this.state;
    if (!!listOfAttachment) {
      if (listOfAttachment.length <= 10 && !!this.attachRef) {
        this.attachRef.current!.click(); // ref for input field file
      } else {
        this.setState({ error: "Exceeds Attachements Limit." });
      }
    }
  };

  getTruckOptions = async () => {
    try {
      const trucks = await services.getTrucks();
      this.setState({
        truckOptions: trucks.map((truck) => {
          return {
            id: truck.docId || "",
            name: truck.name,
          };
        }),
      });
    } catch (eUnknown) {
      const e = eUnknown as any;
      this.setState({ error: e, truckOptions: [] });
    }
  };

  submitDefect = async () => {
    this.setState({ loading: true });
    try {
      let errorMsg = "";
      const {
        selectedTruckId,
        defectDescription,
        defectHours,
        defectKilometres,
        listOfAttachment,
      } = this.state;

      if (_.isEmpty(selectedTruckId)) {
        errorMsg = "Please Select a Truck";
        this.setState({ error: errorMsg, errorMsgTruck: errorMsg });
      }
      if (_.isEmpty(defectDescription)) {
        errorMsg = "Please input the description";
        this.setState({ error: errorMsg, errorMsgTruck: errorMsg });
      }

      if (!errorMsg) {
        await services.createFaultAndDefects(
          selectedTruckId,
          defectDescription,
          defectKilometres,
          defectHours,
          listOfAttachment as string[],
          this.props.authUser.docId
        );
        this.setState({
          success: "Successfully, submitted a defect!",
          loading: false,
          selectedTruckId: "",
          errorMsgTruck: "",
          errorMsgDescription: "",
          defectDescription: "",
          errorMsgHours: "",
          defectHours: "",
          errorMsgKilometres: "",
          defectKilometres: "",
          urgency: 1 as FaultDefectUrgency,
          listOfAttachment: [] as any[],
        });
      }
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      this.setState({ error, loading: false });
      Bugsnag.notify(new Error(error));
    }
  };

  addDefectAttachment = async (event: React.MouseEvent | CustomEvent) => {
    event.preventDefault();
    // Take a photo
    const { listOfAttachment } = this.state;
    const clonedListOfAttachment = _.cloneDeep(listOfAttachment);
    // const clonedSelectedSiteDockets = _.cloneDeep(siteDocket);
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      quality: 50,
    });
    this.setState({ loading: true });
    console.log("WILL TRY TO UPLOAD--- - defect attachment");
    const attachmentURL = !_.isNull(capturedPhoto)
      ? await this.uploadAttachments(capturedPhoto.webPath || "")
      : "";

    if (!_.isEmpty(attachmentURL)) {
      console.log(
        "addDefectAttachment ----- attachmentURL ------ ",
        attachmentURL
      );
      clonedListOfAttachment.push(attachmentURL);

      this.setState({
        listOfAttachment: clonedListOfAttachment,
        loading: false,
      });
    } else {
      this.setState({
        error: "error uploading please try again.",
        loading: false,
      });
    }
  };
  removeDefectAttachment = async (attachmentUrl: string) => {
    this.setState({ loading: true });
    try {
      const clonedListOfAttachment = _.cloneDeep(this.state.listOfAttachment);
      await services.deleteAttachment(attachmentUrl);
      const updatedUrls = _.remove(
        clonedListOfAttachment,
        (attachment) => attachment !== attachmentUrl
      );
      this.setState({
        loading: false,
        listOfAttachment: _.isEmpty(updatedUrls) ? [] : updatedUrls,
      });
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      console.log("error - removeJobSiteDocket -- ", error);
      this.setState({ loading: false });
      Bugsnag.notify(new Error(error));
    }
  };

  uploadAttachments = async (attachmentBlobRefs: string) => {
    if (_.isEmpty(attachmentBlobRefs)) return [];
    const ref = storage.ref();
    const { docId, lastName } = this.props.authUser;

    // const uploadPromiseList = await new Promise(async (resolve) => {
    //   console.log("IN UPLOAD PROMISE --");
    //   const attachmentPath = `siteDockets/${lastName
    //     .replace(" ", "")
    //     .toLowerCase()}_${docId}/site_docket_image_${new Date().valueOf()}`;
    //   try {
    //     console.log("WILL TRY TO UPLOAD BLOB");
    //     let blob = await fetch(attachmentBlobRefs).then((r) => r.blob());
    //     console.log("BLOB RESULT", blob);
    //     ref
    //       .child(attachmentPath)
    //       .put(blob)
    //       .then(async (result) => {
    //         console.log("DONE UPLOADING", result);
    //         const downloadUrl = await result.ref.getDownloadURL();
    //         console.log("WILL RETURN URL", downloadUrl);
    //         return resolve(downloadUrl);
    //       })
    //       .catch((error) => {
    //         console.log("ERROR UPLOADING BLOB", error);
    //         resolve(null);
    //       });
    //   } catch (errorUnknown) {
    //     const error = errorUnknown as any;
    //     console.log("ERROR UPLOADING BLOB", error);
    //     resolve(null);
    //     Bugsnag.notify(new Error(error));
    //   }
    // });
    // console.log("will return upload promise", uploadPromiseList);

    console.log("IN UPLOAD PROMISE --", {
      lastName,
      docId,
    });
    const attachmentPath = `siteDockets/${
      lastName || "no_last_name".replace(" ", "").toLowerCase()
    }_${docId || "no_doc_id"}/site_docket_image_${new Date().valueOf()}`;
    console.log("WILL TRY TO UPLOAD --- ", attachmentPath);
    try {
      console.log("WILL TRY TO UPLOAD BLOB");
      let blob = await fetch(attachmentBlobRefs).then((r) => r.blob());
      console.log("BLOB RESULT", blob);
      const result = (await new Promise((resolve) => {
        ref
          .child(attachmentPath)
          .put(blob)
          .then(async (result) => {
            console.log("DONE UPLOADING", result);
            const downloadUrl = await result.ref.getDownloadURL();
            console.log("WILL RETURN URL", downloadUrl);
            return resolve(downloadUrl);
          })
          .catch((error) => {
            console.log("ERROR UPLOADING BLOB", error);
            resolve(null);
          });
      })) as null | string;
      return result;
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      console.log("ERROR UPLOADING BLOB", error);
      Bugsnag.notify(new Error(error));
      return null;
    }
  };
  componentWillUnmount = () => {
    this.clearAllForms();
  };

  clearAllForms = async () => {
    const { listOfAttachment } = this.state;
    this.setState({
      selectedTruckId: "",
      errorMsgTruck: "",
      errorMsgDescription: "",
      defectDescription: "",
      errorMsgHours: "",
      defectHours: "",
      errorMsgKilometres: "",
      defectKilometres: "",
      urgency: 1 as FaultDefectUrgency,
      listOfAttachment: [] as any[],
    });
    if (!_.isEmpty(listOfAttachment)) {
      await Promise.all(
        listOfAttachment.map((attachment) =>
          this.removeDefectAttachment(attachment)
        )
      );
    }
  };

  render = () => {
    const {
      safeToView,
      error,
      success,
      errorMsgTruck,
      truckOptions,
      selectedTruckId,
      errorMsgDescription,
      defectDescription,
      errorMsgHours,
      defectHours,
      errorMsgKilometres,
      defectKilometres,
      loading,
      listOfAttachment,
    } = this.state;

    return (
      <IonPage
        className={`driver-maintenance ${safeToView ? "safe-to-view" : ""}`}
      >
        <EWPHeader
          title="Report a Defect"
          hideBackButton={true}
          color={EWPCOLORS.primary}
        />
        <IonContent ref={this.contentRef}>
          <div className="submit-defect-page-content">
            <div className="submit-defect-label-value">
              <IonLabel className="submit-defect-label-value label ewp-paragraph bold">
                Date:
              </IonLabel>
              <IonLabel className="submit-defect-label-value value ewp-paragraph">
                {moment(new Date()).format("DD MMMM YYYY, hh:mm A")}
              </IonLabel>
            </div>
            <div className="defect-truck-dropdown-container">
              <EWPWebDropdownSelect
                widthSize={"mobile-dropdown"}
                disabled={_.isNull(truckOptions)}
                dropdownItems={truckOptions || []}
                onSelectItem={(id) => {
                  this.setState({
                    selectedTruckId: id,
                    errorMsgTruck: "",
                  });
                }}
                isSelectMultiple={false}
                placeholder="Choose truck to report"
                value={selectedTruckId}
                errorMsg={errorMsgTruck}
                hasSearch={true}
              />
            </div>
            <div className="submit-defect-input-container">
              <EWPInput
                errorMessage={errorMsgDescription}
                inputLabel="Description"
                inputValue={defectDescription}
                inputType="text"
                name="description"
                mode="textArea"
                onInputChange={(description: string) => {
                  this.setState({
                    errorMsgDescription: "",
                    defectDescription: description,
                  });
                }}
              />
            </div>
            <div className="submit-defect-input-container">
              <EWPInput
                errorMessage={errorMsgHours}
                inputLabel="Hours"
                inputValue={defectHours}
                inputType="text"
                name="defectHours"
                onInputChange={(hours: string) => {
                  this.setState({
                    errorMsgHours: "",
                    defectHours: hours,
                  });
                }}
              />
            </div>
            <div className="submit-defect-input-container">
              <EWPInput
                errorMessage={errorMsgKilometres}
                inputLabel="Kilometres"
                inputValue={defectKilometres}
                inputType="text"
                name="defectKilometres"
                onInputChange={(kilometres: string) => {
                  this.setState({
                    errorMsgKilometres: "",
                    defectKilometres: kilometres,
                  });
                }}
              />
            </div>
            {!_.isEmpty(listOfAttachment) && (
              <div className="driver-job-detail-site-docket-details-container">
                <IonGrid className="site-docket-attachment-grid ion-no-padding ion-no-margin">
                  {_.chunk(listOfAttachment, 3).map((row, index) => {
                    return (
                      <IonRow
                        key={`row_${index}`}
                        className="site-docket-attachment-row ion-no-padding ion-no-margin"
                      >
                        {row.map((rowAttachment, rowAttachmentIndex) => {
                          return (
                            <IonCol
                              key={`site-docket_${rowAttachmentIndex}`}
                              size="4"
                            >
                              {!!rowAttachment ? (
                                <div className="site-docket-attachment-img-container">
                                  <IonImg
                                    src={rowAttachment as string}
                                    className="site-docket-attachment-img ion-no-padding ion-no-margin"
                                  />
                                  <IonButton
                                    mode="ios"
                                    color={EWPCOLORS.primary}
                                    className="site-docket-delete-attachment ion-no-padding ion-no-margin"
                                    onClick={() => {
                                      this.removeDefectAttachment(
                                        rowAttachment as string
                                      );
                                    }}
                                  >
                                    <IonIcon icon={close} />
                                  </IonButton>
                                </div>
                              ) : (
                                <IonIcon
                                  size="large"
                                  icon={closeCircleOutline}
                                  color={EWPCOLORS.danger}
                                />
                              )}
                            </IonCol>
                          );
                        })}
                      </IonRow>
                    );
                  })}
                </IonGrid>
              </div>
            )}
            <div className="submit-defect-input-container">
              <IonButton
                mode="ios"
                fill="clear"
                className="defect-add-files-button ion-no-margin ion-no-padding"
                onClick={(event) => {
                  this.addDefectAttachment(event);
                }}
                disabled={listOfAttachment.length >= 9}
              >
                <IonIcon className="add-files-icon" />
                <IonLabel className="add-files-label ewp-h5 light bold ion-no-margin ion-no-padding">
                  Add Files
                </IonLabel>
              </IonButton>
            </div>

            <div className="submit-defect-input-container button-submit">
              <EWPButton
                title="Submit"
                size="large"
                type="button"
                onClick={() => {
                  this.submitDefect();
                }}
              />
            </div>
          </div>
        </IonContent>
        <IonToast
          isOpen={!_.isEmpty(error)}
          message={error}
          duration={2000}
          onDidDismiss={() => {
            this.setState({ error: "" });
          }}
          color={EWPCOLORS.danger}
        />

        <IonToast
          isOpen={!_.isEmpty(success)}
          message={success}
          duration={1000}
          onDidDismiss={() => {
            this.setState({
              success: "",
              selectedTruckId: "",
              errorMsgTruck: "",
              errorMsgDescription: "",
              defectDescription: "",
              errorMsgHours: "",
              defectHours: "",
              errorMsgKilometres: "",
              defectKilometres: "",
              urgency: 1 as FaultDefectUrgency,
              listOfAttachment: [] as any[],
            });
          }}
          color={EWPCOLORS.success}
        />
        <IonLoading
          spinner="circular"
          translucent={true}
          mode="ios"
          isOpen={loading}
          message={MSGS_COMMON.loading}
        />
        <EWPUserAccountFooter
          activePage="maintenance"
          onDriverClick={() => {
            this.props.history.push(routes.DRIVER_ACCOUNT);
          }}
          onMaintenanceClick={() => {}}
          onJobScheduleClick={() => {
            this.props.history.push(routes.DRIVER_JOB_SCHEDULE);
          }}
          onLogout={async () => {
            await services.driverLogout();
          }}
        />
      </IonPage>
    );
  };
}

export default withIonLifeCycle(DriverMaintenance);
