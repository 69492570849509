import * as _ from "lodash";
import * as EmailValidator from "email-validator";
import React, { useState } from "react";
import {
  IonItem,
  IonTitle,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonRippleEffect,
} from "@ionic/react";

import "./EWPContactForm.scss";
import * as services from "../../services";
import { formatPhoneNumber } from "../../functions/phoneNumber";
import { formatString } from "../../functions/common";
import { EWPContactFormProps } from "./EWPContactForm.interface";
import { MSGS_COMMON, MSGS_CONTACT_PERSON } from "../../constants/messages";
import { EWPCOLORS, LIMITS } from "../../constants/config";
import { EWPInput } from "../EWPInput/EWPInput";
import { ContactPerson } from "../../models";
import { EWPNumberFieldWithCountryCode } from "../EWPNumberFieldWithCountryCode/EWPNumberFieldWithCountryCode";

export const EWPContactForm = (props: EWPContactFormProps) => {
  const { isEdit, editData, clientId } = props;
  let existingData = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
  } as ContactPerson;

  if (!_.isEmpty(editData) && !!editData) {
    existingData = _.clone(editData!) as ContactPerson;
  }

  const [firstName, setFirstName] = useState(existingData.firstName);
  const [lastName, setLastName] = useState(existingData.lastName || "");
  const [emailAddress, setEmailAddress] = useState(existingData.emailAddress);
  const [contactNumber, setContactNumber] = useState(existingData.phoneNumber);

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [errorMsgFirstName, setErrorMsgFirstName] = useState("");
  const [errorMsgLastName, setErrorMsgLastName] = useState("");
  const [errorMsgEmailAddress, setErrorMsgEmailAddress] = useState("");
  const [errorMsgContactNumber] = useState("");

  const submitForm = async (event: React.FormEvent) => {
    event.preventDefault();

    const formattedFirstName = formatString(firstName || "");
    const formattedEmailAddress = formatString(emailAddress || "");
    const formattedLastName = formatString(lastName || "");
    const formattedPhoneNumber = formatPhoneNumber(
      (contactNumber as string) || ""
    );

    let errorMessage = null;
    if (formattedFirstName.length === 0) {
      errorMessage = MSGS_CONTACT_PERSON.firstName.required;
      setErrorMsgFirstName(errorMessage);
    }
    // if (formattedLastName.length === 0) {
    //   errorMessage = MSGS_CONTACT_PERSON.lastName.required;
    //   setErrorMsgLastName(errorMessage);
    // }
    if (formattedFirstName.length > LIMITS.name.single) {
      errorMessage = MSGS_CONTACT_PERSON.firstName.max;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedLastName.length > LIMITS.name.single) {
      errorMessage = MSGS_CONTACT_PERSON.lastName.max;
      setErrorMsgLastName(errorMessage);
    }
    if (
      !_.isEmpty(formattedEmailAddress) &&
      !EmailValidator.validate(formattedEmailAddress)
    ) {
      errorMessage = MSGS_CONTACT_PERSON.email.invalid;
      setErrorMsgEmailAddress(errorMessage);
    }

    if (_.isEmpty(errorMessage)) {
      setSaving(true);
      try {
        await services.createContactPerson(
          clientId,
          formattedFirstName,
          formattedLastName,
          (formattedPhoneNumber || "") as string,
          formattedEmailAddress
        );

        setSaving(false);
        setSuccess("New Contact Created");
      } catch (eUnknown) {
        const e = eUnknown as any;
        setError(e);
        setSaving(false);
      }
    }
  };

  const clearFields = () => {
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setContactNumber("");
  };

  console.log("RENDERED outside submit");
  return (
    <>
      <form onSubmit={submitForm}>
        <IonTitle className="sub-contractor-personal-details-title ewp-h3 ion-no-padding">
          Contact Person
        </IonTitle>
        <IonGrid className="ion-no-padding ion-no-margin">
          <IonRow className="sub-conractor-contact-person-details-row ion-no-padding ion-no-margin">
            <IonCol
              size="6"
              className="ewp-sub-contractor-start-col ion-no-padding"
            >
              <EWPInput
                errorMessage={errorMsgFirstName}
                inputLabel="First Name"
                inputValue={firstName}
                inputType="text"
                name="firstName"
                onInputChange={(firstName: string) => {
                  setFirstName(firstName);
                }}
              />
            </IonCol>
            <IonCol
              size="6"
              className="ewp-sub-contractor-end-col ion-no-padding"
            >
              <EWPInput
                errorMessage={errorMsgLastName}
                inputLabel="Last Name"
                inputValue={lastName}
                inputType="text"
                name="lastName"
                onInputChange={(lastName: string) => {
                  setLastName(lastName);
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow className="sub-conractor-contact-person-details-row ion-no-padding ion-no-margin">
            <IonCol className="ewp-sub-contractor-start-col ion-no-padding">
              {/* <EWPInput
                errorMessage={errorMsgContactNumber}
                inputLabel="Contact Number"
                inputValue={contactNumber}
                inputType="text"
                name="contactNumber"
                onInputChange={(contactNumber: string) => {
                  setContactNumber(contactNumber);
                }}
              /> */}
              <EWPNumberFieldWithCountryCode
                errorMessage={errorMsgContactNumber}
                phoneNumber={contactNumber}
                onChange={(contactNumber: string) => {
                  setContactNumber(contactNumber);
                }}
              />
            </IonCol>

            <IonCol className="ewp-sub-contractor-end-col ion-no-padding">
              <EWPInput
                errorMessage={errorMsgEmailAddress}
                inputLabel="Email"
                inputValue={emailAddress}
                inputType="text"
                name="emailAddress"
                onInputChange={(emailAddress: string) => {
                  setEmailAddress(emailAddress);
                }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonItem
          lines="none"
          className="ewp-item-no-horizontal-padding ewp-button-padding-top"
        >
          <IonButton
            mode="ios"
            slot="start"
            className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
            fill="clear"
            color={EWPCOLORS.medium}
            onClick={() => {
              if (props.onModalDidDismiss) {
                props.onModalDidDismiss();
                clearFields();
              }
            }}
          >
            Cancel
          </IonButton>
          <IonButton
            mode="md"
            slot="end"
            className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
            color={EWPCOLORS.primary}
            type="submit"
          >
            {isEdit ? "Save" : "Add Contact"}
            <IonRippleEffect />
          </IonButton>
        </IonItem>
      </form>
      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={saving}
        message={MSGS_COMMON.saving}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          setSuccess("");
          props.onModalDidDismiss();
          clearFields();
        }}
        color={EWPCOLORS.success}
      />
    </>
  );
};
