import * as _ from "lodash";
import * as EmailValidator from "email-validator";
import React, { useState } from "react";
import {
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonRippleEffect,
  IonLabel,
  IonToggle,
  IonContent,
} from "@ionic/react";

import "./DriverMobileForm.scss";

import * as services from "../../services";
import { formatPhoneNumber } from "../../functions/phoneNumber";
import { formatString, useEffectOnlyOnce } from "../../functions/common";
import { Driver } from "../../models/drivers";
import { EWPInput } from "../EWPInput/EWPInput";
import {
  DropdownItem,
  EWPWebDropdownSelect,
} from "../EWPWebDropdownSelect/EWPWebDropdownSelect";
import { EWPCOLORS, LIMITS } from "../../constants/config";
import { MSGS_COMMON, MSGS_DRIVER } from "../../constants/messages";
import { EWPNumberFieldWithCountryCode } from "../EWPNumberFieldWithCountryCode/EWPNumberFieldWithCountryCode";
import { EWPPageTitleAndDescription } from "../EWPPageTitleAndDescription/EWPPageTitleAndDescription";

interface DriverMobileFormInterface {
  onSuccess: () => void;
  onDidDismiss: () => void;
}
export const DriverMobileForm = (props: DriverMobileFormInterface) => {
  const { onSuccess, onDidDismiss } = props;
  let presetData = {
    docId: "",
    // truckPreferenceId: "",
    licenceNumber: "",
    depotId: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    password: "",
    hasDefibrillator: false,
    hasFirstAid: false,
  } as Driver;

  const [firstName, setFirstName] = useState(presetData.firstName);
  const [lastName, setLastName] = useState(presetData.lastName || "");
  const [emailAddress, setEmailAddress] = useState(presetData.emailAddress);
  const [contactNumber, setContactNumber] = useState(presetData.phoneNumber);
  // const [truckPreferenceId, setTruckPreferenceId] = useState(
  //   existingData.truckPreferenceId
  // );
  const [licenceNumber, setLicenceNumber] = useState(presetData.licenceNumber);
  const [depotId, setDepotId] = useState(presetData.depotId);
  const [password, setPassword] = useState(presetData.password);

  const [hasDefibrillator, setHasDefibrillator] = useState(
    presetData.hasDefibrillator
  );
  const [hasFirstAid, setHasFirstAid] = useState(presetData.hasFirstAid);

  const [depotOptions, setDepotOptions] = useState([] as DropdownItem[]);
  // const [truckOptions, setTruckOptions] = useState([] as DropdownItem[]);

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [errorMsgFirstName, setErrorMsgFirstName] = useState("");
  const [errorMsgLastName, setErrorMsgLastName] = useState("");
  const [errorMsgEmailAddress, setErrorMsgEmailAddress] = useState("");
  const [errorMsgContactNumber, setErrorMsgContactNumber] = useState("");
  // const [errorMsgTruckPreference, setErrorMsgTruckPreference] = useState("");
  const [errorMsgDepot, setErrorMsgDepot] = useState("");
  const [errorMsgLicenceNumber, setErrorMsgLicenceNumber] = useState("");
  const [errorMsgPassword, setErrorMsgPassword] = useState("");
  const submitForm = async (event: React.FormEvent) => {
    event.preventDefault();
    // const formattedTruckPreference = formatString(truckPreferenceId || "");
    const formattedLicenceNumber = formatString(licenceNumber || "");
    const formattedDepot = formatString(depotId || "");
    const formattedFirstName = formatString(firstName || "");
    const formattedEmailAddress = formatString(emailAddress || "");
    const formattedLastName = formatString(lastName || "");
    const formattedPhoneNumber = formatPhoneNumber(
      (contactNumber as string) || ""
    );
    const formattedPassword = formatString(password);

    let errorMessage = null;
    // if (_.isEmpty(formattedTruckPreference)) {
    //   errorMessage = MSGS_DRIVER.truckPreference;
    //   setErrorMsgTruckPreference(errorMessage);
    // }
    if (formattedLicenceNumber.length === 0) {
      errorMessage = MSGS_DRIVER.licenceNumber;
      setErrorMsgLicenceNumber(errorMessage);
    }
    if (_.isEmpty(formattedDepot)) {
      errorMessage = MSGS_DRIVER.depot;
      setErrorMsgDepot(errorMessage);
    }
    if (formattedFirstName.length === 0) {
      errorMessage = MSGS_DRIVER.firstName.required;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedLastName.length === 0) {
      errorMessage = MSGS_DRIVER.lastName.required;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedFirstName.length > LIMITS.name.single) {
      errorMessage = MSGS_DRIVER.firstName.max;
      setErrorMsgFirstName(errorMessage);
    }
    if (formattedLastName.length > LIMITS.name.single) {
      errorMessage = MSGS_DRIVER.lastName.max;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedEmailAddress.length === 0) {
      errorMessage = MSGS_DRIVER.email.required;
      setErrorMsgEmailAddress(errorMessage);
    }
    if (!formattedPhoneNumber || _.isEmpty(formattedPhoneNumber)) {
      errorMessage = MSGS_DRIVER.contactNumber;
      setErrorMsgContactNumber(errorMessage);
    }
    if (formattedPassword.length === 0) {
      errorMessage = MSGS_DRIVER.password;
      setErrorMsgPassword(errorMessage);
    }
    if (!EmailValidator.validate(formattedEmailAddress)) {
      errorMessage = MSGS_DRIVER.email.invalid;
      setErrorMsgEmailAddress(errorMessage);
    }

    if (_.isEmpty(errorMessage)) {
      setSaving(true);
      try {
        let existingPin = await services.isPinExisting(formattedPassword);

        if (existingPin) {
          setSaving(false);
          setErrorMsgPassword("Pin already existing");
        } else {
          console.log("call create Driver ");
          await services.createDriver(
            formattedFirstName,
            formattedLastName,
            formattedEmailAddress,
            formattedPhoneNumber,
            // formattedTruckPreference,
            formattedDepot,
            formattedLicenceNumber,
            formattedPassword,
            hasFirstAid,
            hasDefibrillator
          );

          // try {
          //   await api.sendSMS(
          //     formattedPhoneNumber as string,
          //     newDriver(formattedPassword)
          //   );
          // } catch (eUnknown) {
          //   const e = eUnknown as any;
          //   console.log("ERROR SENDING NOTIF", e);
          //   setError(`Sending notifications failed: ${e}`);
          // }
          setSuccess("New Driver Added");
          setSaving(false);
        }
      } catch (eUnknown) {
        const e = eUnknown as any;
        setSaving(false);
        setError(e);
      }
    }
  };

  useEffectOnlyOnce(() => {
    // getTruckOptions();
    getDepotOptions();
  });
  // const getTruckOptions = async () => {
  //   try {
  //     const trucks = await services.getTrucks();
  //     setTruckOptions(
  //       trucks.map((truck) => {
  //         return {
  //           id: truck.docId,
  //           name: truck.name,
  //         } as DropdownItem;
  //       })
  //     );
  //   } catch (eUnknown) {
  // const e = eUnknown as any;
  //     setError(e);
  //   }
  // };

  const getDepotOptions = async () => {
    try {
      const depots = await services.getDepots();
      const depotOptions = depots.map((depot) => {
        return {
          id: depot.docId,
          name: depot.name,
        } as DropdownItem;
      });
      setDepotOptions([...depotOptions]);
    } catch (eUnknown) {
      const e = eUnknown as any;
      setError(e);
    }
  };

  const clearFields = () => {
    // setErrorMsgTruckPreference("");
    setErrorMsgLicenceNumber("");
    setErrorMsgDepot("");
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setContactNumber("");
  };

  return (
    <IonContent>
      <form onSubmit={submitForm}>
        <IonGrid
          className={`driver-form-grid page ion-no-padding ion-no-margin`}
        >
          <IonRow
            className={`driver-contact-person-details-row page ion-no-padding ion-no-margin`}
          >
            <IonCol
              size="12"
              className={`ewp-driver-start-col page ion-no-padding`}
            >
              <EWPPageTitleAndDescription
                className="sign-in-title-container"
                title="Sign up to DUBU"
                // description="If you are a Tradesmen, please log on using the form below"
              />
            </IonCol>
          </IonRow>
          <IonRow
            className={`driver-contact-person-details-row page ion-no-padding ion-no-margin`}
          >
            <IonCol
              size="12"
              className={`ewp-driver-start-col page ion-no-padding`}
            >
              <EWPInput
                errorMessage={errorMsgFirstName}
                inputLabel="First Name"
                inputValue={firstName}
                inputType="text"
                name="firstName"
                onInputChange={(firstName: string) => {
                  setFirstName(firstName);
                  if (!!firstName) setErrorMsgFirstName("");
                }}
              />
            </IonCol>
            <IonCol
              size="12"
              className={`ewp-driver-end-col page ion-no-padding`}
            >
              <EWPInput
                errorMessage={errorMsgLastName}
                inputLabel="Last Name"
                inputValue={lastName}
                inputType="text"
                name="lastName"
                onInputChange={(lastName: string) => {
                  setLastName(lastName);
                  if (!!lastName) setErrorMsgLastName("");
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow
            className={`driver-contact-person-details-row page ion-no-padding ion-no-margin`}
          >
            <IonCol
              size="12"
              className={`ewp-driver-start-col page ion-no-padding`}
            >
              <EWPInput
                errorMessage={errorMsgEmailAddress}
                inputLabel="Email"
                inputValue={emailAddress}
                inputType="text"
                name="emailAddress"
                onInputChange={(emailAddress: string) => {
                  setEmailAddress(emailAddress);
                  if (!!emailAddress) setErrorMsgEmailAddress("");
                }}
              />
            </IonCol>
            <IonCol
              size="12"
              className={`ewp-driver-end-col page ion-no-padding`}
            >
              <EWPNumberFieldWithCountryCode
                errorMessage={errorMsgContactNumber}
                phoneNumber={contactNumber as string}
                onChange={(contactNumber: string) => {
                  setContactNumber(contactNumber);
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow
            className={`driver-contact-person-details-row page ion-no-padding ion-no-margin`}
          >
            <IonCol
              size="12"
              className={`ewp-driver-start-col page ion-no-padding`}
            >
              <EWPInput
                errorMessage={errorMsgLicenceNumber}
                inputLabel="Licence Number"
                inputValue={licenceNumber}
                inputType="text"
                name=";icenceNumber"
                onInputChange={(licenceNumber: string) => {
                  setLicenceNumber(licenceNumber);
                  if (!!licenceNumber) setErrorMsgLicenceNumber("");
                }}
              />
            </IonCol>
            <IonCol
              size="12"
              className={`ewp-driver-end-col page ion-no-padding`}
            >
              <EWPInput
                errorMessage={errorMsgPassword}
                inputLabel="Pin"
                inputValue={password}
                inputType="text"
                name="password"
                onInputChange={(password: string) => {
                  setPassword(password);
                  if (!!password) setErrorMsgPassword("");
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow
            className={`driver-contact-person-details-row page ion-no-padding ion-no-margin`}
          >
            <IonCol
              size="12"
              className="ewp-driver-end-col page ion-no-padding"
            >
              <EWPWebDropdownSelect
                widthSize="half-dialog-form"
                dropdownItems={depotOptions}
                onSelectItem={(id) => {
                  setErrorMsgDepot("");
                  setDepotId(id as string);
                }}
                placeholder="Depot"
                value={depotId}
                errorMsg={errorMsgDepot}
                hasSearch={true}
              />
            </IonCol>
          </IonRow>

          <IonRow
            className={`driver-contact-person-details-row page ion-no-padding ion-no-margin`}
          >
            <IonCol
              size="12"
              className={`ewp-driver-start-col page ion-no-padding`}
            >
              <IonItem
                lines="none"
                className="job-schedule-remote-item ion-no-padding ion-no-margin"
              >
                <IonLabel className="ewp-h4">First Aid </IonLabel>
                <IonToggle
                  mode="ios"
                  onIonChange={(event) => {
                    setHasFirstAid(event.detail.checked);
                  }}
                  checked={hasFirstAid}
                />
              </IonItem>
            </IonCol>
            <IonCol
              size="12"
              className={`ewp-driver-start-col page ion-no-padding`}
            >
              <IonItem
                lines="none"
                className="job-schedule-remote-item ion-no-padding ion-no-margin"
              >
                <IonLabel className="ewp-h4">Defibrillator </IonLabel>
                <IonToggle
                  mode="ios"
                  onIonChange={(event) => {
                    setHasDefibrillator(event.detail.checked);
                  }}
                  checked={hasDefibrillator}
                />
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonItem
          lines="none"
          className="driver-mobile-form-submit-container ewp-item-no-horizontal-padding ewp-button-padding-top"
        >
          <IonButton
            mode="ios"
            slot="start"
            className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
            fill="clear"
            color={EWPCOLORS.medium}
            onClick={() => {
              onDidDismiss();
              clearFields();
            }}
          >
            Cancel
          </IonButton>
          <IonButton
            mode="md"
            slot="end"
            className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
            color={EWPCOLORS.primary}
            type="submit"
          >
            Sign up
            <IonRippleEffect />
          </IonButton>
        </IonItem>
      </form>
      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={saving}
        message={MSGS_COMMON.saving}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          setSuccess("");
          onSuccess();
          onDidDismiss();
          clearFields();
        }}
        color={EWPCOLORS.success}
      />
    </IonContent>
  );
};
