import * as _ from "lodash";
import { add, close } from "ionicons/icons";
import React, { useState, DragEvent } from "react";
import {
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonRippleEffect,
  IonIcon,
  IonLabel,
  IonThumbnail,
} from "@ionic/react";

import "./EWPInudctionForm.scss";
import * as services from "../../services";
import {
  formatString,
  removedUploadedAttachements,
  uploadAttachments,
} from "../../functions/common";
import { EWPInudctionFormProps } from "./EWPInudctionForm.interface";
import { EWPInput } from "../EWPInput/EWPInput";
import { EWPDatePicker } from "../EWPDatePicker/EWPDatePicker";
import { Inductions } from "../../models/drivers";
import { MSGS_COMMON, MSGS_DRIVER } from "../../constants/messages";
import { EWPCOLORS, LIMITS } from "../../constants/config";
import Bugsnag from "@bugsnag/js";

export const EWPInudctionForm = (props: EWPInudctionFormProps) => {
  const { driverId, isEdit, editData, onModalDidDismiss } = props;
  let existingData = {
    name: "",
    notes: "",
    attachments: [] as string[],
    expiryDate: null,
  } as Inductions;

  if (!_.isEmpty(editData) && !!editData) {
    existingData = _.clone(editData!) as Inductions;
  }
  console.log("GOT EXISTING DATA", existingData);
  const [inductionName, setInductionName] = useState(existingData.name);
  const [notes, setNotes] = useState(existingData.notes);
  const [dateIssued, setDateIssued] = useState(
    !!existingData.dateIssued ? existingData.dateIssued.toDate() : new Date()
  );
  const [listOfAttachment, setListOfAttachment] = useState(
    !!editData && !!editData.attachments ? editData.attachments : []
  ) as any[];
  const [removedAttachments, setRemovedAttachments] = useState([] as string[]);
  const [expiryDate] = useState(
    !_.isEmpty(existingData.expiryDate)
      ? existingData.expiryDate!.toDate()
      : null
  );

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [errorMsgName, setErrorMsgName] = useState("");

  const ref: React.RefObject<HTMLInputElement> = React.createRef();
  const onClickUploadPhoto = () => {
    console.log("CLICK UPLOAD -- ", listOfAttachment);
    if (!!listOfAttachment) {
      if (
        listOfAttachment.length < LIMITS.serviceTypeAttachments.count &&
        !!ref
      ) {
        ref.current!.click(); // ref for input field file
      } else {
        setError(MSGS_DRIVER.vocAttachmentsExceed);
      }
    }
  };

  const submitForm = async (event: React.FormEvent) => {
    event.preventDefault();
    console.log("RENDERED submit");
    const formattedName = formatString(inductionName || "");
    let errorMessage = null;

    if (formattedName.length === 0) {
      errorMessage = "Induction Name is required";
      setErrorMsgName(errorMessage);
    }

    if (_.isEmpty(errorMessage)) {
      setSaving(true);
      let attachmentUrlList = [] as string[];
      try {
        if (isEdit && !_.isEmpty(editData)) {
          const newFileAttach = _.compact(
            listOfAttachment.map((item: any) => {
              return typeof item !== "string" && item;
            })
          ) as any[];
          const prevousFileAttach = _.compact(
            listOfAttachment.map((item: any) => {
              return typeof item === "string" && item;
            })
          );
          if (newFileAttach.length > 0) {
            const urlList = await uploadAttachments(
              newFileAttach,
              formattedName,
              driverId,
              "driverInductions"
            );
            attachmentUrlList = urlList;
          }
          const updatedAttachment = prevousFileAttach.concat(
            attachmentUrlList
          ) as string[];

          await services.updateInduction(
            editData!.docId || "",
            formattedName,
            dateIssued,
            expiryDate,
            (notes || "").trim(),
            driverId,
            updatedAttachment
          );
          await removedUploadedAttachements(removedAttachments);
          setSaving(false);
          setSuccess("Update Success");
        } else {
          if (listOfAttachment.length > 0) {
            const urlList = await uploadAttachments(
              listOfAttachment,
              formattedName,
              driverId,
              "driverInductions"
            );
            attachmentUrlList = urlList;
          }
          await services.addInduction(
            driverId,
            formattedName,
            dateIssued,
            expiryDate,
            (notes || "").trim(),
            attachmentUrlList
          );
          setSaving(false);
          setSuccess("Create Success");
        }
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        await removedUploadedAttachements(attachmentUrlList);
        setSaving(false);
        setError(error);
        Bugsnag.notify(new Error(error));
      }
    }
  };

  const onAttachPhoto = async (file: any) => {
    if (
      listOfAttachment.length + file.target.files.length <=
      LIMITS.serviceTypeAttachments.count
    ) {
      if (!!file && file.target.files.length) {
        const attachments = file.target.files;
        const fileSizes = Object.values(attachments).map((attachment: any) => {
          return attachment.size <= LIMITS.serviceTypeAttachments.size;
        });
        const hasExceededFileSize = _.includes(fileSizes, false);
        if (!hasExceededFileSize) {
          const toBeUploadedAttachments = _.concat(
            Object.values(attachments),
            listOfAttachment
          );
          setListOfAttachment(toBeUploadedAttachments as []);
        } else {
          setError(MSGS_DRIVER.attachmentFileSizeExceed);
        }
      }
    } else {
      setError(MSGS_DRIVER.vocAttachmentsExceed);
    }
  };

  const onDrop = (event: DragEvent) => {
    event.preventDefault();
    if (listOfAttachment.length <= LIMITS.serviceTypeAttachments.count) {
      if (event.dataTransfer.files) {
        // Use DataTransferItemList interface to access the file(s)
        if (
          event.dataTransfer.files.length <= LIMITS.serviceTypeAttachments.count
        ) {
          const files = Object.values(event.dataTransfer.files);
          const fileSizes = Object.values(files).map((attachment: any) => {
            return attachment.size <= LIMITS.serviceTypeAttachments.size;
          });
          const hasExceededFileSize = _.includes(fileSizes, false);
          if (!hasExceededFileSize) {
            files.forEach((file) => {
              const imageType = /image.*/;
              if (
                file &&
                (file.type.match(imageType) || file.type === "application/pdf")
              ) {
                const attachments = Object.values(event.dataTransfer.files);
                const toBeUploadedAttachments = _.concat(
                  attachments,
                  listOfAttachment
                );
                setListOfAttachment(toBeUploadedAttachments as []);
              } else {
                setError(MSGS_DRIVER.invalidFileType);
              }
            });
          } else {
            setError(MSGS_DRIVER.attachmentFileSizeExceed);
          }
        } else {
          setError(MSGS_DRIVER.vocAttachmentsExceed);
        }
      }
    } else {
      setError(MSGS_DRIVER.vocAttachmentsExceed);
    }
  };

  const onRemoveAttachment = (attachment: any) => {
    const updatedListOfAttachment = _.remove(listOfAttachment, (item) => {
      return item !== attachment;
    });

    if (typeof attachment === "string") {
      setRemovedAttachments([...removedAttachments, attachment]);
    }
    setListOfAttachment(updatedListOfAttachment);
  };

  const clearFields = () => {
    setInductionName("");
    setNotes("");
    setDateIssued(new Date());
  };

  console.log("RENDERED outside submit");
  return (
    <>
      <form onSubmit={submitForm}>
        <IonGrid className="ion-no-padding ion-no-margin">
          <IonRow className="ewp-induction-row ion-no-padding ion-no-margin">
            <IonCol size="6" className="ewp-induction-start-col ion-no-padding">
              <EWPInput
                errorMessage={errorMsgName}
                inputLabel="Induction Name"
                inputValue={inductionName}
                inputType="text"
                name="inductionName"
                onInputChange={(inductionName: string) => {
                  setInductionName(inductionName);
                }}
              />
            </IonCol>
            <IonCol size="6" className="ewp-induction-end-col ion-no-padding">
              <EWPDatePicker
                className="expiries-date-input-date"
                datePickerLabel="Date Issued"
                selectedDate={dateIssued}
                {...(!!dateIssued && { label: "Date Issued" })}
                format="DD MMM, YYYY"
                onDateChange={(expiriesDate: Date) => {
                  setDateIssued(expiriesDate);
                }}
                maxDate={new Date()}
              />
            </IonCol>
          </IonRow>
          {/* <IonRow className="ewp-induction-row ion-no-padding ion-no-margin">
            <IonCol size="7" className="ewp-induction-start-col ion-no-padding">
              <div className="date-with-remove-container">
                <EWPDatePicker
                  className="expiry-date-input-date"
                  datePickerLabel="Expiry Date"
                  {...(!!expiryDate && { label: "Expiry Date" })}
                  selectedDate={expiryDate}
                  format="DD MMM, YYYY"
                  onDateChange={(expiriesDate: Date) => {
                    setExpiryDate(expiriesDate);
                  }}
                  minDate={new Date()}
                />
                {!_.isNull(expiryDate) && (
                  <IonButton
                    className="remove-expiry"
                    fill="clear"
                    color={EWPCOLORS.light}
                    onClick={() => {
                      setExpiryDate(null);
                    }}
                  >
                    <IonIcon icon={closeOutline} color={EWPCOLORS.light} />
                  </IonButton>
                )}
              </div>
            </IonCol>
          </IonRow> */}
          <IonRow className="ewp-induction-row ion-no-padding ion-no-margin">
            <IonCol size="12" className="ion-no-padding">
              <EWPInput
                inputLabel="Notes"
                inputValue={notes}
                inputType="text"
                name="notes"
                onInputChange={(notes: string) => {
                  setNotes(notes);
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow className="ewp-induction-row ion-no-padding ion-no-margin">
            <IonCol size="12" className="ion-no-padding">
              <div className="induction-upload-container">
                <div className="induction-title-button-container">
                  <IonLabel className="induction-upload-title-label ewp-h3 ion-no-padding ion-no-margin">
                    Upload Images
                  </IonLabel>
                  {!_.isEmpty(listOfAttachment) && (
                    <IonButton
                      mode="ios"
                      className="induction-attachments-add-another-button ewp-h6 ion-no-margin ion-no-padding"
                      color={EWPCOLORS.gray}
                      onClick={onClickUploadPhoto}
                    >
                      <IonIcon
                        className="induction-small-add-icon"
                        icon={add}
                      />
                      Add Another
                    </IonButton>
                  )}
                </div>
                {!_.isEmpty(listOfAttachment) ? (
                  <div className="web-upload-list-attachment-container">
                    <IonGrid className="ion-no-margin ion-no-padding">
                      {_.chunk(listOfAttachment, 8).map(
                        (row: any, index: any) => {
                          return (
                            <IonRow
                              key={`row_${index}`}
                              className="ion-no-margin ion-no-padding"
                            >
                              {row.map((attachment: any, index: any) => {
                                const fileUrl =
                                  typeof attachment === "string"
                                    ? attachment
                                    : "";
                                const fileSplit = fileUrl
                                  .split("?")[0]
                                  .split(".");
                                const fileTypeAttachment =
                                  fileSplit[fileSplit.length - 1];
                                return (
                                  <IonCol
                                    key={`col_${index}`}
                                    size="1.5"
                                    className="ion-no-margin ion-no-padding"
                                  >
                                    <div className="web-upload-attachment-container">
                                      <IonButton
                                        className="remove-attachment-button ion-no-margin ion-no-padding"
                                        shape="round"
                                        color="primary"
                                        onClick={() =>
                                          onRemoveAttachment(attachment)
                                        }
                                      >
                                        <IonIcon
                                          className="web-upload-attachment-close-icon ion-no-margin ion-no-padding"
                                          icon={close}
                                        />
                                      </IonButton>
                                      {typeof attachment !== "string" &&
                                      !!attachment.type.match("image/*") ? (
                                        <IonThumbnail
                                          className="web-upload-attachment-avatar ion-no-margin ion-no-padding"
                                          slot="start"
                                        >
                                          <img
                                            id="attachmentPhoto"
                                            src={
                                              typeof attachment === "string"
                                                ? attachment
                                                : URL.createObjectURL(
                                                    attachment
                                                  )
                                            }
                                            alt="attachmentPhoto"
                                          />
                                        </IonThumbnail>
                                      ) : fileTypeAttachment !== "pdf" &&
                                        typeof attachment === "string" ? (
                                        <IonThumbnail
                                          className="web-upload-attachment-avatar ion-no-margin ion-no-padding"
                                          slot="start"
                                        >
                                          <img
                                            id="attachmentPhoto"
                                            src={
                                              typeof attachment === "string"
                                                ? attachment
                                                : URL.createObjectURL(
                                                    attachment
                                                  )
                                            }
                                            alt="attachmentPhoto"
                                          />
                                        </IonThumbnail>
                                      ) : (
                                        <div className="attachment-file-link-container">
                                          <a
                                            target="blank"
                                            href={
                                              typeof attachment === "string"
                                                ? attachment
                                                : URL.createObjectURL(
                                                    attachment
                                                  )
                                            }
                                          >
                                            View File
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </IonCol>
                                );
                              })}
                            </IonRow>
                          );
                        }
                      )}
                    </IonGrid>
                  </div>
                ) : (
                  <IonItem
                    lines="none"
                    className="induction-upload-item ewp-body ion-no-margin"
                    onClick={onClickUploadPhoto}
                    onDragOver={(event) => {
                      event.preventDefault();
                    }}
                    onDrop={onDrop}
                  >
                    <div className="web-upload-icon-label-container">
                      <IonIcon className="induction-upload-icon" />
                      <IonLabel className="induction-upload-label ewp-paragraph ion-no-margin ion-no-padding">
                        Drop files here to upload or <u>choose file</u>
                      </IonLabel>
                    </div>
                  </IonItem>
                )}
                <input
                  hidden
                  type="file"
                  ref={ref}
                  accept="image/*,.pdf"
                  onChange={onAttachPhoto}
                  multiple
                />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonItem
          lines="none"
          className="ewp-item-no-horizontal-padding ewp-button-padding-top"
        >
          <IonButton
            mode="ios"
            slot="start"
            className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
            fill="clear"
            color={EWPCOLORS.medium}
            onClick={() => {
              clearFields();
              props.onModalDidDismiss();
            }}
          >
            Cancel
          </IonButton>
          <IonButton
            mode="md"
            slot="end"
            className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
            color={EWPCOLORS.primary}
            type="submit"
          >
            Save
            <IonRippleEffect />
          </IonButton>
        </IonItem>
      </form>

      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={saving}
        message={MSGS_COMMON.saving}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          setSuccess("");
          clearFields();
          onModalDidDismiss();
        }}
        color={EWPCOLORS.success}
      />
    </>
  );
};
