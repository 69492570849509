import rp from "request-promise";
import { InvitedAdmin } from "../components/EWPCreateAdminForm/EWPCreateAdminForm";

import { getEnv } from "../env";
import { encodeQueryData, getRequestHeaders } from "../functions/common";
import { FaultDefectListView } from "../models";
import { Truck } from "../models/drivers";

var env = getEnv();

let url = env.firebase.cloud_functions_url;

export const backup = async () => {
  const headers = await getRequestHeaders();
  return rp({
    method: "POST",
    url: url + `users/backup`,
    body: {},
    headers,
    json: true,
  });
};

export const sendSMS = async (to: string, message: string) => {
  const headers = await getRequestHeaders();
  return rp({
    method: "POST",
    url: url + `users/sendSMS`,
    body: {
      to,
      message,
    },
    headers,
    json: true,
  });
};

export const sendEmailForCompletedJob = async (jobId: string) => {
  const headers = await getRequestHeaders();
  return rp({
    method: "POST",
    url: url + `users/sendEmailForCompletedJob`,
    body: {
      jobId,
    },
    headers,
    json: true,
  });
};

export const requestParts = async (
  requestedParts: string,
  truckData: Truck,
  mechanicFullName: string,
  recipient: string
) => {
  const headers = await getRequestHeaders();
  return rp({
    method: "POST",
    url: url + `users/requestParts`,
    body: {
      requestedParts,
      truckData,
      mechanicFullName,
      recipient,
    },
    headers,
    json: true,
  });
};

export const newUrgentDefect = async (
  faultDefectData: FaultDefectListView,
  truckName: string,
  reportedBy: string,
  reportedDtString: string,
  urgencyLastChangedBy: string
) => {
  const headers = await getRequestHeaders();
  return rp({
    method: "POST",
    url: url + `users/newUrgentDefect`,
    body: {
      faultDefectData,
      truckName,
      reportedBy,
      reportedDtString,
      urgencyLastChangedBy,
    },
    headers,
    json: true,
  });
};

export const checkIfEmailIsUnique = async (emailAddress: string) => {
  return rp({
    method: "GET",
    url:
      url +
      `users/emailUnique?${encodeQueryData({
        emailAddress,
      })}`,
    headers: {
      "Content-Type": "application/json",
    },
    json: true,
  });
};

export const createUser = async (invitedAdmin: InvitedAdmin) => {
  const headers = await getRequestHeaders();
  return rp({
    method: "POST",
    url: url + "users/createUser",
    body: invitedAdmin,
    headers,
    json: true,
  });
};
