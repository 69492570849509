import * as _ from "lodash";
import React, { useState } from "react";
import { camera, close } from "ionicons/icons";
import {
  IonButton,
  IonIcon,
  IonThumbnail,
  IonGrid,
  IonRow,
  IonCol,
  IonTextarea,
  IonInput,
} from "@ionic/react";

import "./EWPNoteWithAttachment.scss";
import { TextEditorEdit } from "../../config/global";
import { EWPCOLORS } from "../../constants/config";

interface EWPNoteWithAttachmentProps {
  onChangeBody: (data: string) => void;
  onChangeTitle: (data: string) => void;
  listOfAttachment?: any[];
  onAttachPhoto?: (file: any) => void;
  onRemoveAttachment?: (attachment: any) => void;
  isDisabledEditor?: boolean;
  editData?: TextEditorEdit;
}

export const EWPNoteWithAttachment = (props: EWPNoteWithAttachmentProps) => {
  const {
    onChangeBody,
    onChangeTitle,
    listOfAttachment,
    onAttachPhoto,
    onRemoveAttachment,
    editData,
  } = props;
  const [notesHasValue, setNotesHasValue] = useState(false);
  const [ewpNoteTitle, setEwpNoteTitle] = useState(
    !!editData ? editData.title : (null as null | string)
  );
  const [ewpNoteDescription, setEwpNoteDescription] = useState(
    !!editData ? editData.description : (null as null | string)
  );
  const [hasFocusBody, setHasFocusBody] = useState(
    editData && editData.description ? "has-value" : ""
  );

  const ref: React.RefObject<HTMLInputElement> = React.createRef();

  const onClickUploadPhoto = () => {
    if (!!ref) {
      ref.current!.click(); // ref for input field file
    }
  };

  const attachmentsData =
    !_.isEmpty(editData) && !_.isEmpty(editData!.listOfAttachment)
      ? _.concat(editData!.listOfAttachment, listOfAttachment)
      : listOfAttachment;
  return (
    <>
      <div className="ion-no-padding ion-no-margin">
        <IonInput
          className={`ewp-note-attachement-note-title-input`}
          mode="md"
          {...(!!editData && { value: ewpNoteTitle })}
          onIonChange={(data) => {
            const value = data.detail.value;
            setEwpNoteTitle(value as string);
            onChangeTitle(value as string);
          }}
        />
      </div>
      <div
        className={`ewp-note-attachment-body-container ${hasFocusBody} ion-no-padding ion-no-margin`}
        onFocus={() => {
          setHasFocusBody("has-focus");
        }}
        onBlur={() => {
          if (notesHasValue || (editData && editData.description)) {
            setHasFocusBody("");
          } else {
            setHasFocusBody("");
          }
        }}
      >
        <IonTextarea
          {...(!!editData && { value: ewpNoteDescription })}
          className="ewp-note-attachement-note-input"
          autoGrow={true}
          onIonChange={(data) => {
            const value = data.detail.value;
            setNotesHasValue(!!value);
            setEwpNoteDescription(value as string);
            onChangeBody(value as string);
          }}
        />
        <div className="ewp-note-attachment-list-attachment-container">
          <IonGrid>
            {!!attachmentsData &&
              !_.isEmpty(attachmentsData) &&
              _.chunk(attachmentsData, 6).map((row: any, indexRow: any) => {
                return (
                  <IonRow key={`row_${indexRow}`}>
                    {row.map((attachment: any, indexCol: any) => {
                      return (
                        <IonCol size="2">
                          <div
                            key={`col_${indexCol}`}
                            className="ewp-note-attachment-attachment-container"
                          >
                            <IonButton
                              className="remove-attachment-button ion-no-margin ion-no-padding"
                              shape="round"
                              color="primary"
                              onClick={() => onRemoveAttachment!(attachment)}
                            >
                              <IonIcon
                                className="ewp-note-attachment-attachment-close-icon ion-no-margin ion-no-padding"
                                icon={close}
                              />
                            </IonButton>
                            <IonThumbnail
                              className="ewp-note-attachment-attachment-avatar ion-no-margin ion-no-padding"
                              slot="start"
                            >
                              <img
                                id="attachmentPhoto"
                                src={
                                  typeof attachment === "string"
                                    ? attachment
                                    : URL.createObjectURL(attachment)
                                }
                                alt="attachmentPhoto"
                              />
                            </IonThumbnail>
                          </div>
                        </IonCol>
                      );
                    })}
                  </IonRow>
                );
              })}
          </IonGrid>
        </div>
        <input
          hidden
          type="file"
          ref={ref}
          accept="image/*,.pdf"
          onChange={onAttachPhoto}
          multiple
        />
        <IonButton
          fill="clear"
          color={EWPCOLORS.light}
          className="ewp-note-attachment-attachment-button ion-no-padding ion-no-margin"
          onClick={onClickUploadPhoto}
        >
          <IonIcon
            className="ion-no-padding ion-no-margin"
            color={EWPCOLORS.light}
            icon={camera}
          />
        </IonButton>
      </div>
    </>
  );
};
