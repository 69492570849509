import React from "react";
import { close } from "ionicons/icons";
import { IonButton, IonIcon, IonLabel, IonModal } from "@ionic/react";

import "./EWPConfirmDialog.scss";
import { EWPCOLORS } from "../../../constants/config";

interface EWPConfirmDialogProps {
  isOpen: boolean;
  title: string;
  description: string;
  onConfirmLabel: string;
  color: "primary" | "danger";
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
  onDidDismiss: () => void;
}

export const EWPConfirmDialog = (props: EWPConfirmDialogProps) => {
  const {
    isOpen,
    title,
    description,
    onConfirmLabel,
    color,
    onConfirm,
    onCancel,
    onClose,
    onDidDismiss,
  } = props;
  return (
    <IonModal
      className="confirm-dialog"
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
    >
      <div className="confirm-dialog-header">
        <IonButton
          className="confirm-dialog-header-close ion-no-padding ion-no-margin"
          color={EWPCOLORS.light}
          fill="clear"
          slot="end"
          onClick={onClose}
        >
          <IonIcon color={EWPCOLORS.medium} icon={close} />
        </IonButton>
      </div>
      <div className="confirm-dialog-title-container">
        <IonLabel className="confirm-dialog-title ewp-h1">{title}</IonLabel>
        <IonLabel className="confirm-dialog-description ewp-body">
          {description}
        </IonLabel>
      </div>
      <div className="confirm-dialog-button-container">
        <IonButton
          className="ewp-h5"
          fill="clear"
          onClick={onCancel}
          mode="ios"
        >
          Cancel
        </IonButton>
        <IonButton
          mode="ios"
          className="confirm-button ewp-h5 white"
          color={color}
          onClick={onConfirm}
        >
          {onConfirmLabel}
        </IonButton>
      </div>
    </IonModal>
  );
};
