import * as _ from "lodash";
import moment from "moment";
import React, { useState } from "react";

import {
  IonLabel,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCardHeader,
  IonSpinner,
  IonToast,
} from "@ionic/react";

import "./EWPJobHistoryCard.scss";
import {
  getDateFromTimezoneIgnoredTimestamp,
  TimezoneIgnoredTimeStamp,
  // toDateTimeFromSecs,
} from "../../functions/common";
import { AttachmentDialog } from "../Web/AttachmentDialog/AttachmentDialog";
import { EWPProps } from "../../config/global";
import { EWPCOLORS } from "../../constants/config";
import { JobScheduleView, JOBS_WITH_KEYS } from "../../models";
import { JobScheduleDetailDialog } from "../JobScheduleDetailDialog/JobScheduleDetailDialog";

type RowKey =
  | "truck"
  | "driver"
  | "startDate"
  | "endDate"
  | "jobType"
  | "truckSize"
  | "contactPerson"
  | "location";

const setColumns = [
  {
    name: "Truck",
    key: "truck" as RowKey,
    colSize: "1.25",
  },
  {
    name: "Driver",
    key: "driver" as RowKey,
    colSize: "1.25",
  },
  {
    name: "Start",
    key: "startDate" as RowKey,
    colSize: "1.5",
  },
  {
    name: "Finish",
    key: "endDate" as RowKey,
    colSize: "1.5",
  },
  {
    name: "Job Type",
    key: "jobType" as RowKey,
    colSize: "1",
  },
  {
    name: "Contact Person",
    key: "contactPerson" as RowKey,
    colSize: "1.5",
  },
  {
    name: "Location",
    key: "location" as RowKey,
    colSize: "3",
  },
];

interface EWPJobHistoryCardProps extends EWPProps {
  jobHistories: null | JobScheduleView[]; //todo: update interface once model is okay
  isUpcoming?: boolean;
}

export const EWPJobHistoryCard = (props: EWPJobHistoryCardProps) => {
  const { jobHistories, isUpcoming } = props;
  const [showSiteDocketsDialog, setShowSiteDocketsDialog] = useState(false);
  const [selectedJobHistory, setSelectedJobHistory] = useState(
    {} as JobScheduleView
  );
  const [showJobScheduleDetail, setShowJobScheduleDetail] = useState(false);
  const [attachmentIndexToOpen, setAttachmentIndexToOpen] = useState(
    null as null | number
  );
  const [flagShowDetail, setFlagShowDetail] = useState(false);

  const [error, setError] = useState("");

  const getRowData = (colName: RowKey, rowData: JobScheduleView) => {
    if (colName === "truck") {
      return rowData.truckDetails.name;
    } else if (colName === "driver") {
      const { firstName, lastName } = rowData.driverDetails;
      return `${firstName} ${lastName}`;
    } else if (colName === "startDate") {
      return moment(
        getDateFromTimezoneIgnoredTimestamp(rowData.startDateNoTimeZone)
      ).format("DD MMM YYYY");
    } else if (colName === "endDate") {
      return moment(
        getDateFromTimezoneIgnoredTimestamp(rowData.endDateNoTimeZone)
      ).format("DD MMM YYYY");
    } else if (colName === "jobType") {
      return JOBS_WITH_KEYS[rowData.jobType].name;
    } else if (colName === "contactPerson") {
      return !!rowData.contactPersonDetails &&
        !_.isEmpty(rowData.contactPersonDetails)
        ? `${rowData.contactPersonDetails.firstName} ${rowData.contactPersonDetails.lastName}`
        : "N/A";
    } else if (colName === "location") {
      return rowData.address;
    } else return "N/A";
  };

  return _.isNull(jobHistories) ? (
    <div className="ewp-table-spinner-container">
      <IonSpinner className="ewp-spinner" color={EWPCOLORS.primary} />
    </div>
  ) : (
    <>
      <IonCard
        className="ewp-job-history-card ion-no-padding ion-no-margin"
        color={EWPCOLORS.tertiary}
      >
        <IonCardContent className="ewp-job-history-card-content ion-no-padding ion-padding-bottom ion-no-margin">
          <IonCardHeader className="ewp-job-history-card-header ion-no-padding ion-no-margin">
            <IonLabel className="ewp-job-history-card-header-label ewp-h4 bold ion-no-padding ion-no-margin">
              {!isUpcoming ? "Job History" : "Upcoming Jobs"}
            </IonLabel>
          </IonCardHeader>
          {!_.isEmpty(jobHistories) ? (
            <IonGrid className="ewp-job-history-card-grid ion-no-padding ion-no-margin">
              <IonRow
                className={`ewp-job-history-card-row-content-header ${
                  _.isEmpty(jobHistories) && "no-border-bottom"
                } ion-no-padding ion-no-margin`}
              >
                {setColumns.map((jobColumn) => {
                  return (
                    <IonCol
                      className="ion-no-padding ion-text-start"
                      size={jobColumn.colSize}
                    >
                      <IonLabel className="ewp-h6 medium bold ion-text-uppercase ion-text-start">
                        {jobColumn.name}
                      </IonLabel>
                    </IonCol>
                  );
                })}
                {!isUpcoming && (
                  <IonCol className="ion-no-padding ion-text-center" size="1">
                    <IonLabel className="ewp-h6 medium bold ion-text-uppercase ion-text-center">
                      Site Docket
                    </IonLabel>
                  </IonCol>
                )}
              </IonRow>

              {jobHistories.map((jobHistory) => {
                return (
                  <IonRow className="ewp-job-history-card-row-content-data ion-no-padding ion-no-margin">
                    {setColumns.map((jobColumn) => {
                      return (
                        <IonCol
                          className={`${jobColumn.key} ion-no-padding ion-text-start`}
                          size={jobColumn.colSize}
                          onClick={() => {
                            console.log("THIS IS JOB HISTORY --- ", jobHistory);
                            setSelectedJobHistory(jobHistory);
                            setShowJobScheduleDetail(true);
                            setFlagShowDetail(true);
                          }}
                        >
                          <IonLabel className="ewp-h6 medium ion-text-start">
                            {getRowData(jobColumn.key, jobHistory)}
                          </IonLabel>
                        </IonCol>
                      );
                    })}
                    {!isUpcoming && (
                      <IonCol
                        className="ion-no-padding ion-text-center"
                        size="1"
                      >
                        <IonLabel
                          className="ewp-job-history-view-site-docket ewp-h5 primary ion-text-center"
                          onClick={() => {
                            if (
                              !!jobHistory.siteDockets &&
                              !_.isEmpty(jobHistory.siteDockets)
                            ) {
                              setSelectedJobHistory(jobHistory);
                              setFlagShowDetail(false);
                              setShowSiteDocketsDialog(true);
                            } else {
                              setError(
                                "No site dockets uploaded for this job history"
                              );
                            }
                          }}
                        >
                          <u>View</u>
                        </IonLabel>
                      </IonCol>
                    )}
                  </IonRow>
                );
              })}
            </IonGrid>
          ) : (
            <>
              <IonLabel className="no-data-label ewp-h4 bold light ion-padding">
                No Data
              </IonLabel>
            </>
          )}
        </IonCardContent>
      </IonCard>

      {!!selectedJobHistory && !!showJobScheduleDetail && (
        <JobScheduleDetailDialog
          onCancel={(jobToCancel) => {}}
          onUncancel={(jobToUncancel) => {}}
          isOpen={showJobScheduleDetail}
          jobSchedule={selectedJobHistory}
          showSiteDockets={() => {
            if (
              !!selectedJobHistory.siteDockets &&
              !_.isEmpty(selectedJobHistory.siteDockets)
            ) {
              setSelectedJobHistory(selectedJobHistory);
              setShowSiteDocketsDialog(true);
              setShowJobScheduleDetail(false);
            } else {
              setError("No site dockets uploaded for this job history");
            }
          }}
          onDidDismiss={() => {
            setShowJobScheduleDetail(false);
          }}
          onShowAttachmentDialog={(isOpen: boolean) => {
            setShowSiteDocketsDialog(isOpen);
            setShowJobScheduleDetail(false);
          }}
          onAttachmentToOpen={(attachmentIndex: number) => {
            setAttachmentIndexToOpen(attachmentIndex);
          }}
          {...props}
        />
      )}

      {!!showSiteDocketsDialog &&
        !!selectedJobHistory.siteDockets &&
        !_.isEmpty(selectedJobHistory.siteDockets) && (
          <AttachmentDialog
            attachments={selectedJobHistory.siteDockets.map((siteDocket) => {
              return {
                notApplicable: !!siteDocket.notApplicable,
                attachmentUrl: siteDocket.attachmentURL,
                photoName: siteDocket.siteDocketName,
                title: `${moment(
                  // toDateTimeFromSecs(
                  //   (siteDocket.siteDocketDate as firebase.firestore.Timestamp)
                  //     .seconds
                  // )
                  getDateFromTimezoneIgnoredTimestamp(
                    siteDocket.siteDocketDateNoTimeZone as TimezoneIgnoredTimeStamp
                  )
                ).format("ddd DD MMM YYYY")}${
                  !!siteDocket.notApplicable ? " - Not Applicable" : ""
                }`,
              };
            })}
            {...(!_.isNull(attachmentIndexToOpen) && {
              selectedIndex: attachmentIndexToOpen,
            })}
            showModal={showSiteDocketsDialog}
            onModalDidDismiss={() => {
              setShowSiteDocketsDialog(false);
              if (!!flagShowDetail) {
                setShowJobScheduleDetail(true);
              }
            }}
          />
        )}
      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => {
          setError("");
        }}
        color={EWPCOLORS.danger}
      />
    </>
  );
};
