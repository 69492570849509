import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Truck } from "../../../models/drivers";

import "./JobScheduleWeekly.scss";
import { EWPRbCalendarEventInterface } from "../JobScheduleCalendar";
import * as services from "../../../services";
import { useEffectOnlyOnce } from "../../../functions/common";
import {
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { EWPCOLORS } from "../../../constants/config";
import {
  alertCircle,
  checkmarkCircle,
  closeCircle,
  documentText,
  ellipseOutline,
  location,
} from "ionicons/icons";
import {
  NOTIFICATION_STATUS,
  NOTIFICATION_STATUS_WITH_KEYS,
} from "../../../models";

interface JobScheduleWeeklyProps {
  calendarJobSchedules: null | EWPRbCalendarEventInterface[];
  lastDataUpdate: number;
  startDate: Date;
  endDate: Date;
  onClick: (jobId: string) => void;
}
export const JobScheduleWeekly = (props: JobScheduleWeeklyProps) => {
  const { calendarJobSchedules, startDate, endDate, lastDataUpdate, onClick } =
    props;
  const noteRef = useRef<HTMLIonGridElement>(null);
  const [trucks, setTrucks] = useState(null as null | Truck[]);
  const [jobSchedules, setJobSchedules] = useState(
    null as null | EWPRbCalendarEventInterface[]
  );
  const [startRange, setStartRange] = useState(startDate);
  const [endRange, setEndRange] = useState(endDate);
  const [colWidth, setColWidth] = useState(null as null | number);
  const [lastDataReload, setLastDataReload] = useState(0);

  useEffectOnlyOnce(() => {
    getAllTrucks();
    getGridWidth();
  });

  const getGridWidth = () => {
    if (!_.isNull(noteRef.current) && noteRef.current.clientWidth !== 0) {
      setColWidth((noteRef.current.clientWidth * 0.83) / 7);
    } else {
      setTimeout(() => {
        getGridWidth();
      }, 500);
    }
  };

  const filterJobSchedule = (
    schedules: EWPRbCalendarEventInterface[],
    startDate: number,
    endDate: number
  ) => {
    return _.filter(schedules, (job) => {
      let result = false;
      const startSched = _.cloneDeep(job.start);
      startSched.setHours(0);
      startSched.setMinutes(0);
      startSched.setSeconds(0);
      startSched.setMilliseconds(0);

      const endSched = _.cloneDeep(job.end);
      endSched.setHours(23);
      endSched.setMinutes(59);
      endSched.setSeconds(59);
      endSched.setMilliseconds(59);

      const startSchedDate = startSched.valueOf();
      const endSchedDate = endSched.valueOf();

      // startSchedDate is before end date and after start date of the week
      if (
        startSchedDate.valueOf() <= endDate &&
        startSchedDate.valueOf() >= startDate
      ) {
        result = true;
      }

      // endSchedDate is after start of the week but before end date
      if (
        endSchedDate.valueOf() >= startDate &&
        endSchedDate.valueOf() <= endDate
      ) {
        result = true;
      }

      // startSchedDatee is before the start of the week and endSchedDate is after the end of the week
      if (
        startSchedDate.valueOf() <= startDate &&
        endSchedDate.valueOf() >= endDate
      ) {
        result = true;
      }
      return result;
    });
  };

  // useEffect(() => {
  //   setJobSchedules(calendarJobSchedules);
  // }, [calendarJobSchedules]);

  useEffect(() => {
    setStartRange(startDate);
    setEndRange(endDate);
    if (
      !_.isNull(calendarJobSchedules) ||
      (!_.isNull(calendarJobSchedules) && lastDataReload !== lastDataUpdate)
    ) {
      const newJobSchedules = filterJobSchedule(
        calendarJobSchedules,
        startDate.valueOf(),
        endDate.valueOf()
      );
      // console.log(
      //   "GOT NEW JOBS --",
      //   calendarJobSchedules.map((sched) => {
      //     return { truck: sched.resource.truckName, ...sched };
      //   }),
      //   newJobSchedules
      // );
      const oldJobSchedMap = (jobSchedules || [])
        .map(
          (job) =>
            `${job.resource.jobSchduleId}-${job.resource.invoiceNumber || ""}`
        )
        .join("_");
      const newJobSchedMap = (newJobSchedules || [])
        .map(
          (job) =>
            `${job.resource.jobSchduleId}-${job.resource.invoiceNumber || ""}`
        )
        .join("_");
      if (oldJobSchedMap !== newJobSchedMap || _.isNull(jobSchedules)) {
        setJobSchedules(newJobSchedules);
      }

      setLastDataReload(lastDataUpdate);
    }
    // if (!_.isNull(calendarJobSchedules)) {
    //   filterJobSchedule(jobSchedules, startDate.valueOf(), endDate.valueOf());
    // }
  }, [
    startDate,
    endDate,
    jobSchedules,
    calendarJobSchedules,
    lastDataUpdate,
    lastDataReload,
  ]);

  const getAllTrucks = async () => {
    const trucks = _.filter(
      await services.getTrucks(),
      (truck) => !truck.archived
    );
    setTrucks(
      _.sortBy(trucks, (truck, index) => {
        const truckName = truck.name.toLowerCase();
        return truckName.indexOf("ubm") !== -1
          ? `a - ${truckName}`
          : truckName.indexOf("ubi") !== -1
          ? `b - ${truckName}`
          : truckName.indexOf("unit") !== -1
          ? `c - ${truckName}`
          : `z - ${index} - ${truckName}`;
      })
    );
  };

  return (
    <div className="job-sched-weekly-container">
      {_.isNull(trucks) || _.isNull(jobSchedules) ? (
        <IonSpinner className="job-sched-spinner" color={EWPCOLORS.primary} />
      ) : (
        <>
          <IonGrid className="job-sched-weekly-grid header ion-no-padding ion-no-margin">
            <IonRow className="job-sched-weekly-row header">
              <IonCol className="job-sched-weekly-col header" size="2">
                <IonLabel className="ewp-h5 bold ion-text-center">
                  Trucks
                </IonLabel>
              </IonCol>
              {_.times(7).map((increment) => {
                const dateMoment = moment(startRange).add(increment, "days");
                return (
                  <IonCol
                    key={`job-shed-day-header-${increment}`}
                    className="job-sched-weekly-col header"
                    size="1.428"
                  >
                    <IonLabel className="ewp-h5 bold ion-text-center">
                      {`${dateMoment.toDate().getDate()} ${dateMoment.format(
                        "ddd"
                      )}`}
                    </IonLabel>
                  </IonCol>
                );
              })}
            </IonRow>
          </IonGrid>
          <div className="job-sched-weekly-main">
            <IonGrid
              className="job-sched-weekly-grid contents ion-no-padding ion-no-margin"
              ref={noteRef}
            >
              {/* <IonRow className="job-sched-weekly-row header">
              <IonCol className="job-sched-weekly-col header" size="2">
                <IonLabel className="ewp-h5 bold ion-text-center">
                  Trucks
                </IonLabel>
              </IonCol>
              {_.times(7).map((increment) => {
                const dateMoment = moment(startRange).add(increment, "days");
                return (
                  <IonCol
                    key={`job-shed-day-header-${increment}`}
                    className="job-sched-weekly-col header"
                    size="1.428"
                  >
                    <IonLabel className="ewp-h5 bold ion-text-center">
                      {`${dateMoment.toDate().getDate()} ${dateMoment.format(
                        "ddd"
                      )}`}
                    </IonLabel>
                  </IonCol>
                );
              })}
            </IonRow> */}

              {trucks.map((truck, index) => {
                const jobSchedList = _.sortBy(
                  _.filter(
                    jobSchedules,
                    (sched) => sched.resource.truckId === truck.docId || ""
                  ) as EWPRbCalendarEventInterface[],
                  (job) => {
                    const date = job.start;
                    date.setHours(job.resource.onSiteTime.getHours());
                    return date;
                  }
                );

                return (
                  <IonRow
                    className="job-sched-weekly-row"
                    key={`job-sched-weekly-${index}`}
                  >
                    <IonCol className="job-sched-weekly-col name" size="2">
                      <IonLabel className="ewp-h5 bold">{truck.name}</IonLabel>
                    </IonCol>

                    {_.times(7).map((increment) => {
                      const dateMoment = moment(startRange)
                        .add(increment, "days")
                        .toDate();
                      const jobSchedsToDisplay = _.filter(
                        jobSchedList,
                        (job) => {
                          const dateMin = _.clone(job.start);
                          dateMin.setHours(0);
                          dateMin.setMinutes(0);
                          dateMin.setSeconds(0);
                          dateMin.setMilliseconds(0);

                          const dateMax = _.clone(job.end);
                          dateMax.setHours(23);
                          dateMax.setMinutes(59);
                          dateMax.setSeconds(59);
                          dateMax.setMilliseconds(59);

                          return (
                            dateMin.valueOf() <= dateMoment.valueOf() &&
                            dateMax.valueOf() >= dateMoment.valueOf()
                          );
                        }
                      );
                      return (
                        <IonCol
                          className="job-sched-weekly-col-container"
                          size="1.428"
                          key={`job-sched-has-job-container-${index}-${increment}`}
                        >
                          {_.map(jobSchedList, (jobSched, index) => {
                            const jobSchedToDisplay = _.find(
                              jobSchedsToDisplay,
                              (schedToDisplay) =>
                                schedToDisplay.resource.jobSchduleId ===
                                jobSched.resource.jobSchduleId
                            );

                            let isStartDate = false;

                            if (jobSchedToDisplay !== undefined) {
                              const dateMin = _.clone(jobSchedToDisplay.start);
                              dateMin.setHours(0);
                              dateMin.setMinutes(0);
                              dateMin.setSeconds(0);
                              dateMin.setMilliseconds(0);

                              const dateMax = _.clone(jobSchedToDisplay.end);
                              dateMax.setHours(23);
                              dateMax.setMinutes(59);
                              dateMax.setSeconds(59);
                              dateMax.setMilliseconds(59);

                              isStartDate =
                                moment(dateMoment).isSame(
                                  jobSchedToDisplay.start,
                                  "d"
                                ) ||
                                (jobSchedToDisplay.start.valueOf() <
                                  dateMoment.valueOf() &&
                                  startRange.valueOf() >
                                    jobSchedToDisplay.start.valueOf() &&
                                  increment === 0);

                              const endDate =
                                dateMax.valueOf() > endRange.valueOf()
                                  ? endRange
                                  : dateMax;

                              return (
                                <div
                                  key={`job-sched-day-header-${increment}-${index}`}
                                  className={`job-sched-weekly-col-div has-job
                                  } ${isStartDate ? "start-date" : ""} ${
                                    jobSchedToDisplay.resource.shift
                                  } ${jobSchedToDisplay.resource.jobStatus} ${
                                    jobSchedToDisplay.resource.cancelled
                                      ? "cancelled"
                                      : jobSchedToDisplay.resource.pencilledIn
                                      ? "pencilled"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    onClick(
                                      jobSchedToDisplay!.resource.jobSchduleId
                                    );
                                  }}
                                >
                                  {isStartDate &&
                                  colWidth !== 0 &&
                                  !_.isNull(colWidth) ? (
                                    <IonGrid
                                      className="job-sched-truck-grid ion-no-padding ion-no-margin"
                                      style={{
                                        width: `${
                                          colWidth *
                                          (moment(endDate).diff(
                                            dateMoment,
                                            "days"
                                          ) +
                                            1)
                                        }px`,
                                      }}
                                    >
                                      <IonRow className="job-sched-truck-grid-row ion-no-padding ion-no-margin">
                                        <IonCol
                                          size="10"
                                          className="job-sched-truck-grid-col details ion-no-padding ion-no-margin"
                                        >
                                          <IonLabel className="ewp-rbc-time-label ewp-paragraph">
                                            {`${moment(
                                              jobSchedToDisplay.resource
                                                .onSiteTime
                                            ).format("HH:mm")}`}{" "}
                                            |{" "}
                                            {`${moment(
                                              jobSchedToDisplay.start
                                            ).format("DD MMM")} - ${moment(
                                              jobSchedToDisplay.end
                                            ).format("DD MMM YYYY")}`}
                                          </IonLabel>
                                          <IonLabel className="ewp-event-week-label ewp-paragraph">
                                            {
                                              jobSchedToDisplay.resource
                                                .clientName
                                            }
                                          </IonLabel>
                                          <IonLabel
                                            className={`ewp-event-week-label ewp-paragraph ${
                                              _.isNull(
                                                jobSchedToDisplay.resource
                                                  .driverName
                                              ) && "danger"
                                            }`}
                                          >
                                            {_.isNull(
                                              jobSchedToDisplay.resource
                                                .driverName
                                            )
                                              ? "No Driver Assigned"
                                              : jobSchedToDisplay.resource
                                                  .driverName}
                                          </IonLabel>
                                        </IonCol>
                                        <IonCol
                                          size="2"
                                          className="job-sched-truck-grid-col ion-no-padding ion-no-margin"
                                        >
                                          <div className="job-schedule-calendar-status-container">
                                            {jobSchedToDisplay.resource
                                              .isRemoteJob && (
                                              <IonIcon
                                                className={`ewp-remote-icon ${jobSchedToDisplay.resource.shift}`}
                                                icon={location}
                                              />
                                            )}
                                            <IonIcon
                                              className={`ewp-notif-status-icon ${
                                                jobSchedToDisplay.resource.shift
                                              } ${
                                                NOTIFICATION_STATUS_WITH_KEYS[
                                                  jobSchedToDisplay.resource
                                                    .notificationStatus
                                                ].key
                                              }`}
                                              icon={
                                                jobSchedToDisplay.resource
                                                  .notificationStatus ===
                                                NOTIFICATION_STATUS.accepted.id
                                                  ? checkmarkCircle
                                                  : jobSchedToDisplay.resource
                                                      .notificationStatus ===
                                                    NOTIFICATION_STATUS.notSent
                                                      .id
                                                  ? ellipseOutline
                                                  : jobSchedToDisplay.resource
                                                      .notificationStatus ===
                                                    NOTIFICATION_STATUS.pending
                                                      .id
                                                  ? alertCircle
                                                  : closeCircle
                                              }
                                            />
                                            <div className="invoice-container">
                                              <IonIcon
                                                className="ewp-invoice-icon week"
                                                icon={documentText}
                                                color={
                                                  jobSchedToDisplay.resource
                                                    .invoiced
                                                    ? EWPCOLORS.primary
                                                    : EWPCOLORS.light
                                                }
                                              />
                                              {!!jobSchedToDisplay.resource
                                                .invoiceNumber && (
                                                <span className="tooltiptext">
                                                  <b>Invoice Number: </b>
                                                  {` ${jobSchedToDisplay.resource.invoiceNumber}`}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  key={`job-shed-day-header-${increment}-no-job-${index}`}
                                  className="job-sched-weekly-col-div"
                                ></div>
                              );
                            }
                          })}
                        </IonCol>
                      );
                    })}
                  </IonRow>
                );
              })}
            </IonGrid>
          </div>
        </>
      )}
    </div>
  );
};
