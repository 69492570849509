import * as _ from "lodash";
import moment from "moment";
import { close, add } from "ionicons/icons";
import React, { useState, DragEvent } from "react";
import {
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonToast,
  IonRippleEffect,
  IonLabel,
  IonIcon,
  IonThumbnail,
} from "@ionic/react";

import "./FaultsAndDefectsDialog.scss";
import * as services from "../../services";
import { uploadAttachments, useEffectOnlyOnce } from "../../functions/common";
import { EWPInput } from "../EWPInput/EWPInput";
import { EWPWebDialog } from "../EWPWebDialog/EWPWebDialog";
import {
  DropdownItem,
  EWPWebDropdownSelect,
} from "../EWPWebDropdownSelect/EWPWebDropdownSelect";
import { EWPProps, USER_ROLE } from "../../config/global";
import { EWPCOLORS, LIMITS } from "../../constants/config";
import { MSGS_COMMON, MSGS_TRUCK_REGISTER } from "../../constants/messages";
import { FaultDefect } from "../../models";

interface FaultsAndDefectsDialogProps extends EWPProps {
  onDidDismiss: () => void;
  isOpen: boolean;
  editData?: FaultDefect;
}

export const FaultsAndDefectsDialog = (props: FaultsAndDefectsDialogProps) => {
  const { isOpen, onDidDismiss, editData } = props;
  const [truckOptions, setTruckOptions] = useState(
    null as null | DropdownItem[]
  );
  const [selectedTruckId, setSelectedTruckId] = useState(
    !_.isEmpty(editData) ? editData!.truckId : ""
  );
  const [defectDescription, setdefectDescription] = useState(
    !_.isEmpty(editData) ? editData!.description : ""
  );
  const [defectHours, setdefectHours] = useState(
    !_.isEmpty(editData) ? editData!.hours : ""
  );
  const [defectKilometres, setdefectKilometres] = useState(
    !_.isEmpty(editData) ? editData!.kilometers : ""
  );
  const [errorMsgTruck, seterrorMsgTruck] = useState("");
  const [errorMsgDescription, seterrorMsgDescription] = useState("");
  const [errorMsgHours, seterrorMsgHours] = useState("");
  const [errorMsgKilometres, seterrorMsgKilometres] = useState("");

  const [listOfAttachment, setListOfAttachment] = useState(
    !_.isEmpty(editData) ? editData!.attachments : ([] as any[])
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const ref: React.RefObject<HTMLInputElement> = React.createRef();

  const onClickUploadPhoto = () => {
    if (!!listOfAttachment) {
      if (
        listOfAttachment.length < LIMITS.serviceTypeAttachments.count &&
        !!ref
      ) {
        ref.current!.click(); // ref for input field file
      } else {
        setError(MSGS_TRUCK_REGISTER.faultsAndDefectsAttachmentsExceed);
      }
    }
  };

  const submitForm = async (event: React.FormEvent) => {
    event.preventDefault();
    // const formattedserviceType = formatString(serviceType);
    if (_.isEmpty(editData)) {
      let errorMsg = "";
      if (_.isEmpty(selectedTruckId)) {
        errorMsg = "Please Select a Truck";
        seterrorMsgTruck(errorMsg);
      }
      if (_.isEmpty(defectDescription)) {
        errorMsg = "Please input the description";
        seterrorMsgDescription(errorMsg);
      }

      if (!errorMsg) {
        try {
          setLoading(true);
          let attachmentUrlList = [] as string[];
          if (listOfAttachment.length > 0) {
            const urlList = await uploadAttachments(
              listOfAttachment,
              props.authUser.lastName,
              props.authUser.docId || "",
              "faultsAndDefectsWeb"
            );
            attachmentUrlList = urlList;
          }

          await services.createFaultAndDefects(
            selectedTruckId,
            defectDescription,
            defectKilometres || "",
            defectHours || "",
            attachmentUrlList,
            props.authUser.docId,
            USER_ROLE.admins
          );

          setLoading(false);
          setSuccess("Successfully submitted a defect!");
        } catch (errorUnknown) {
          const error = errorUnknown as any;
          setLoading(false);
          setError(error.message || error);
        }
      }
    } else {
      let errorMsg = "";
      if (_.isEmpty(defectDescription)) {
        errorMsg = "Please input the description";
        seterrorMsgDescription(errorMsg);
      }

      if (!errorMsg) {
        try {
          setLoading(true);

          await services.updateFaultAndDefects(editData!.docId || "", {
            description: defectDescription,
          });

          setLoading(false);
          setSuccess("Successfully editted a defect!");
        } catch (errorUnknown) {
          const error = errorUnknown as any;
          setLoading(false);
          setError(error.message || error);
        }
      }
    }
  };

  const clearFields = () => {
    setListOfAttachment([]);
    setSelectedTruckId("");
    setdefectDescription("");
    setdefectHours("");
    setdefectKilometres("");
  };

  const clearErrorMsgs = () => {
    seterrorMsgTruck("");
    seterrorMsgDescription("");
    seterrorMsgHours("");
    seterrorMsgKilometres("");
  };

  const onAttachPhoto = async (file: any) => {
    if (
      listOfAttachment.length + file.target.files.length <=
      LIMITS.faultsAndDefectsAttachments.count
    ) {
      if (!!file && file.target.files.length) {
        const attachments = file.target.files;
        const fileSizes = Object.values(attachments).map((attachment: any) => {
          return attachment.size <= LIMITS.faultsAndDefectsAttachments.size;
        });
        const hasExceededFileSize = _.includes(fileSizes, false);
        if (!hasExceededFileSize) {
          const toBeUploadedAttachments = _.concat(
            Object.values(attachments),
            listOfAttachment
          );
          setListOfAttachment(toBeUploadedAttachments as []);
        } else {
          setError(MSGS_TRUCK_REGISTER.attachmentFileSizeExceed);
        }
      }
    } else {
      setError(MSGS_TRUCK_REGISTER.faultsAndDefectsAttachmentsExceed);
    }
  };

  const onDrop = (event: DragEvent) => {
    event.preventDefault();
    if (listOfAttachment.length <= LIMITS.faultsAndDefectsAttachments.count) {
      if (event.dataTransfer.files) {
        // Use DataTransferItemList interface to access the file(s)
        if (
          event.dataTransfer.files.length <=
          LIMITS.faultsAndDefectsAttachments.count
        ) {
          const files = Object.values(event.dataTransfer.files);
          const fileSizes = Object.values(files).map((attachment: any) => {
            return attachment.size <= LIMITS.faultsAndDefectsAttachments.size;
          });
          const hasExceededFileSize = _.includes(fileSizes, false);
          if (!hasExceededFileSize) {
            files.forEach((file) => {
              const imageType = /image.*/;
              if (
                file &&
                (file.type.match(imageType) || file.type === "application/pdf")
              ) {
                const attachments = Object.values(event.dataTransfer.files);
                const toBeUploadedAttachments = _.concat(
                  attachments,
                  listOfAttachment
                );
                setListOfAttachment(toBeUploadedAttachments as []);
              } else {
                setError(MSGS_TRUCK_REGISTER.invalidFileType);
              }
            });
          } else {
            setError(MSGS_TRUCK_REGISTER.attachmentFileSizeExceed);
          }
        } else {
          setError(MSGS_TRUCK_REGISTER.faultsAndDefectsAttachmentsExceed);
        }
      }
    } else {
      setError(MSGS_TRUCK_REGISTER.faultsAndDefectsAttachmentsExceed);
    }
  };

  const onRemoveAttachment = (attachment: any) => {
    const updatedListOfAttachment = _.remove(listOfAttachment, (item) => {
      return item !== attachment;
    });
    setListOfAttachment(updatedListOfAttachment);
  };

  const getTruckOptions = async () => {
    try {
      const trucks = await services.getTrucks();
      setTruckOptions(
        trucks.map((truck) => {
          return {
            id: truck.docId || "",
            name: truck.name,
          };
        })
      );
    } catch (eUnknown) {
      const e = eUnknown as any;
      setTruckOptions([]);
      setError(e.message || e);
    }
  };

  useEffectOnlyOnce(() => {
    getTruckOptions();
  });

  return (
    <>
      <EWPWebDialog
        headerTitle={`${!_.isEmpty(editData) ? "Edit" : "New"} Fault/Defect`}
        isOpen={isOpen}
        onDidDismiss={() => {
          onDidDismiss();
        }}
      >
        <div className="faults-and-defects-form-container">
          <form onSubmit={submitForm}>
            <IonGrid className="ion-no-padding">
              <IonRow className="faults-and-defects-row ion-no-padding ion-no-margin">
                <IonCol size="12">
                  <IonLabel className="submit-defect-label-value label ewp-paragraph bold">
                    Date:
                  </IonLabel>
                  <IonLabel className="submit-defect-label-value value ewp-paragraph">
                    {` ${moment(new Date()).format("DD MMMM YYYY, hh:mm A")}`}
                  </IonLabel>
                </IonCol>
              </IonRow>
              <IonRow className="faults-and-defects-row ion-no-padding ion-no-margin">
                <IonCol
                  size="12"
                  className="faults-and-defects-full-col ion-no-padding"
                >
                  <div className="defect-truck-dropdown-container">
                    <EWPWebDropdownSelect
                      // widthSize={"mobile-dropdown"}
                      disabled={_.isNull(truckOptions) || !_.isEmpty(editData)}
                      dropdownItems={truckOptions || []}
                      onSelectItem={(id) => {
                        setSelectedTruckId(id as string);
                        seterrorMsgTruck("");
                      }}
                      isSelectMultiple={false}
                      placeholder="Choose truck to report"
                      value={selectedTruckId}
                      errorMsg={errorMsgTruck}
                      hasSearch={true}
                    />
                  </div>
                </IonCol>
              </IonRow>
              <IonRow className="faults-and-defects-row ion-no-padding ion-no-margin">
                <IonCol
                  size="6"
                  className="faults-and-defects-start-col ion-no-padding"
                >
                  <EWPInput
                    errorMessage={errorMsgHours}
                    inputLabel="Hours"
                    inputValue={defectHours}
                    inputType="text"
                    inputMode="text"
                    name="defectHours"
                    onInputChange={(hours: string) => {
                      seterrorMsgHours("");
                      setdefectHours(hours);
                    }}
                    disabled={!_.isEmpty(editData)}
                  />
                </IonCol>
                <IonCol
                  size="6"
                  className="faults-and-defects-end-col ion-no-padding"
                >
                  <EWPInput
                    errorMessage={errorMsgKilometres}
                    inputLabel="Kilometers"
                    inputValue={defectKilometres}
                    inputType="text"
                    inputMode="text"
                    name="defectKilometers"
                    onInputChange={(kilometers: string) => {
                      seterrorMsgKilometres("");
                      setdefectKilometres(kilometers);
                    }}
                    disabled={!_.isEmpty(editData)}
                  />
                </IonCol>
              </IonRow>
              <IonRow className="faults-and-defects-row ion-no-padding ion-no-margin">
                <IonCol
                  size="12"
                  className="faults-and-defects-full-col ion-no-padding"
                >
                  <EWPInput
                    errorMessage={errorMsgDescription}
                    inputLabel="Description"
                    inputValue={defectDescription}
                    inputType="text"
                    inputMode="text"
                    mode="textArea"
                    name="defectDescription"
                    onInputChange={(defectDescription: string) => {
                      seterrorMsgDescription("");
                      setdefectDescription(defectDescription);
                    }}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>

            <div className="faults-and-defects-dialog-upload-container">
              {_.isEmpty(editData) && (
                <div className="faults-and-defects-dialog-title-button-container">
                  <IonLabel className="faults-and-defects-dialog-upload-title-label ewp-h3 ion-no-padding ion-no-margin">
                    Upload Images
                  </IonLabel>
                  {!_.isEmpty(listOfAttachment) && (
                    <IonButton
                      mode="ios"
                      className="faults-and-defects-dialog-attachments-add-another-button ewp-h6 ion-no-margin ion-no-padding"
                      color={EWPCOLORS.gray}
                      onClick={onClickUploadPhoto}
                    >
                      <IonIcon
                        className="faults-and-defects-dialog-small-add-icon"
                        icon={add}
                      />
                      Add Another
                    </IonButton>
                  )}
                </div>
              )}

              {!_.isEmpty(listOfAttachment) ? (
                <div className="web-upload-list-attachment-container">
                  <IonGrid className="ion-no-margin ion-no-padding">
                    {_.chunk(listOfAttachment, 5).map(
                      (row: any, index: any) => {
                        return (
                          <IonRow
                            key={`row_${index}`}
                            className="ion-no-margin ion-no-padding"
                          >
                            {row.map((attachment: any, index: any) => {
                              const fileUrl =
                                typeof attachment === "string"
                                  ? attachment
                                  : "";
                              const fileSplit = fileUrl
                                .split("?")[0]
                                .split(".");
                              const fileTypeAttachment =
                                fileSplit[fileSplit.length - 1];
                              return (
                                <IonCol
                                  key={`col_${index}`}
                                  size="2.4"
                                  className="ion-no-margin ion-no-padding"
                                >
                                  <div className="web-upload-attachment-container">
                                    {_.isEmpty(editData) && (
                                      <IonButton
                                        className="remove-attachment-button ion-no-margin ion-no-padding"
                                        shape="round"
                                        color="primary"
                                        onClick={() =>
                                          onRemoveAttachment(attachment)
                                        }
                                      >
                                        <IonIcon
                                          className="web-upload-attachment-close-icon ion-no-margin ion-no-padding"
                                          icon={close}
                                        />
                                      </IonButton>
                                    )}
                                    {typeof attachment !== "string" &&
                                    !!attachment.type.match("image/*") ? (
                                      <IonThumbnail
                                        className="web-upload-attachment-avatar ion-no-margin ion-no-padding"
                                        slot="start"
                                      >
                                        <img
                                          id="attachmentPhoto"
                                          src={
                                            typeof attachment === "string"
                                              ? attachment
                                              : URL.createObjectURL(attachment)
                                          }
                                          alt="attachmentPhoto"
                                        />
                                      </IonThumbnail>
                                    ) : fileTypeAttachment !== "pdf" &&
                                      typeof attachment === "string" ? (
                                      <IonThumbnail
                                        className="web-upload-attachment-avatar ion-no-margin ion-no-padding"
                                        slot="start"
                                      >
                                        <img
                                          id="attachmentPhoto"
                                          src={
                                            typeof attachment === "string"
                                              ? attachment
                                              : URL.createObjectURL(attachment)
                                          }
                                          alt="attachmentPhoto"
                                        />
                                      </IonThumbnail>
                                    ) : (
                                      <div className="attachment-file-link-container">
                                        <a
                                          target="blank"
                                          href={
                                            typeof attachment === "string"
                                              ? attachment
                                              : URL.createObjectURL(attachment)
                                          }
                                        >
                                          View File
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                </IonCol>
                              );
                            })}
                          </IonRow>
                        );
                      }
                    )}
                  </IonGrid>
                </div>
              ) : (
                _.isEmpty(editData) && (
                  <IonItem
                    lines="none"
                    className="faults-and-defects-dialog-upload-item ewp-body ion-no-margin"
                    onClick={onClickUploadPhoto}
                    onDragOver={(event) => {
                      event.preventDefault();
                    }}
                    onDrop={onDrop}
                  >
                    <div className="web-upload-icon-label-container">
                      <IonIcon className="faults-and-defects-dialog-upload-icon" />
                      <IonLabel className="faults-and-defects-dialog-upload-label ewp-paragraph ion-no-margin ion-no-padding">
                        Drop files here to upload or <u>choose file</u>
                      </IonLabel>
                    </div>
                  </IonItem>
                )
              )}
              <input
                hidden
                type="file"
                ref={ref}
                accept="image/*,.pdf"
                onChange={onAttachPhoto}
                multiple
              />
            </div>

            <IonItem
              lines="none"
              className="ewp-item-no-horizontal-padding ewp-button-padding-top"
            >
              <IonButton
                mode="ios"
                slot="start"
                className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
                fill="clear"
                color={EWPCOLORS.medium}
                onClick={() => {
                  if (onDidDismiss) {
                    onDidDismiss();
                    clearFields();
                    clearErrorMsgs();
                  }
                }}
              >
                Cancel
              </IonButton>
              <IonButton
                mode="md"
                slot="end"
                className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
                color={EWPCOLORS.primary}
                type="submit"
              >
                Save
                <IonRippleEffect />
              </IonButton>
            </IonItem>
          </form>
          <input
            hidden
            type="file"
            ref={ref}
            accept="image/*,.pdf"
            onChange={onAttachPhoto}
            multiple
          />
        </div>
      </EWPWebDialog>

      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <IonToast
        isOpen={!_.isEmpty(success)}
        message={success}
        duration={500}
        onDidDismiss={() => {
          setSuccess("");
          onDidDismiss();
          clearFields();
          clearErrorMsgs();
        }}
        color={EWPCOLORS.success}
      />
    </>
  );
};
