import * as _ from "lodash";
import React, { useState } from "react";
import { eye, eyeOff } from "ionicons/icons";
import { IonButton, IonIcon, IonItem, IonLabel, IonInput } from "@ionic/react";

import "./EWPPasswordInput.scss";
import { isMobile } from "../../functions/common";

interface EWPPasswordInterface {
  onChange?: (password: string) => void;
  passwordLabel: string;
  passwordValue: string;
  errorMessage?: string;
  className?: string;
  disabled?: boolean;
  disableTab?: boolean;
  readonly?: boolean;
  autocomplete?: "on" | "off";
}

export const EWPPasswordInput = (props: EWPPasswordInterface) => {
  const { readonly, onChange } = props;
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  return (
    <>
      <IonItem
        mode="md"
        className={`${props.className} ewp-input-item-container ${
          !_.isEmpty(props.errorMessage) && "item-has-error-class"
        }`}
        lines="none"
      >
        <IonLabel
          mode="md"
          position="floating"
          className={`ewp-web-label-input ${isMobile() && "mobile"} `}
        >
          {props.passwordLabel}
        </IonLabel>
        <IonInput
          mode="md"
          readonly={!!readonly ? readonly : false}
          className={`ewp-web-input ${
            !_.isEmpty(props.errorMessage) && "has-error-class"
          }`}
          {...(props.autocomplete !== undefined && {
            autocomplete: props.autocomplete,
          })}
          name="password"
          inputmode="text"
          type={passwordVisibility ? "text" : "password"}
          value={props.passwordValue}
          onIonChange={(event) => {
            if (!!onChange) {
              onChange(event.detail.value || "");
            }
          }}
          disabled={!!props.disabled}
          {...(props.disableTab && {
            onKeyDown: (event: React.KeyboardEvent) => {
              if (event.keyCode === 9) {
                event.preventDefault();
              }
            },
          })}
        >
          <IonButton
            slot="end"
            className="password-visibility"
            fill="clear"
            expand="block"
            tabIndex={-1}
            onClick={() => {
              setPasswordVisibility(!passwordVisibility);
            }}
          >
            <IonIcon icon={passwordVisibility ? eye : eyeOff} color="primary" />
          </IonButton>
        </IonInput>
      </IonItem>
      {!_.isEmpty(props.errorMessage) && (
        <div className="ewp-input-error-container">
          <IonLabel className="ewp-input-error-msg">
            {props.errorMessage}
          </IonLabel>
        </div>
      )}
    </>
  );
};
