import moment from "moment";
import { APP_DOWNLOAD_LINK, LIMITS } from "./config";

export const MSGS_SIGNUP = {
  firstName: {
    required: "Your first name is required.",
    max: `Your first name cannot exceed ${LIMITS.name.single} characters in length.`,
  },
  lastName: {
    required: "Your surname is required.",
    max: `Your surname cannot exceed ${LIMITS.name.single} characters in length.`,
  },
  email: {
    required: "An email address is required.",
    invalid: "You have entered an invalid email address.",
  },
  country: "Your country is required.",
  password: {
    required: "You must enter a password.",
    invalid:
      "Your password must contain at least one letter and one number and be a minimum of 8 characters long.",
  },
  mobileNumber: "Your mobile number is required",
  invalidMobileNumber: "Your mobile number is invalid",
  smsVerificationPin: "The verification code was incorrect. Please try again.",
  pendingSMSVerification: "Please enter the verification code.",
  userRole: "No role given to this user.",
  success: "You have successfully created an account.",
};

export const MSGS_COMPANY = {
  companyName: {
    required: "Company name is required.",
    max: `Company name cannot exceed ${LIMITS.name.full} characters in length.`,
  },
  updateSuccess: "New company details saved successfully.",
};

export const MSGS_SIGNIN = {
  success: "You have successfully signed in.",
};

export const MSGS_FIREBASE = {
  invalidActionCode: "The link is invalid. Please try again.",
  userNotFound: "There was no account found with this email address.",
  incorrectPassword: "The password you have entered is incorrect.",
  emailVerificationFailedNoOOB: "Email verification failed. Please try again.",
  emailRecoveryFailedNoOOB: "Email recovery failed. Please try again.",
};

export const MSGS_GENERIC_ERROR =
  "Sorry, an unexpected error has occurred. Please try again later.";

export const MSGS_PHONE = {
  required: "A phone number is required.",
  invalid: "Please enter a valid phone number.",
  notUnique:
    "An account already exists with this phone number. Please use a different number.",
  same: "This phone number is already saved to your account.",
  verification: {
    required: "Please enter all 4 digits of the verification code.",
    incorrect: "The verification code is incorrect. Please try again.",
    resent: "A new verification code has been sent.",
    smsCodeSent: "A new verification code has been sent",
    errorResendingPin:
      "An error occured while re-sending verification code. Please try again",
  },
};

export const MSGS_RESET_PASSWORD = {
  email: {
    required: "Your email address is required.",
    notRegistered: "This email address is not registered.",
  },
  password: {
    required: "A password is required.",
    confirmRequired: "Confirm Password field is required.",
    retypePasswordRequired: "Confirm Password field is required.",
    mismatch: "Passwords do not match.",
    invalid:
      "Your new password must contain at least one letter and one number and be a minimum of 8 characters long.",
  },
};

export const MSGS_CHANGE_PASSWORD = {
  currentRequired: "Current password field is required.",
  newRequired: "New password field is required.",
  confirmRequired: "Confirm password field is required.",
  mismatch: "Passwords do not match.",
  invalid:
    "Your new password must contain at least one letter and one number and be a minimum of 8 characters long.",
  success: "Change Password Success!",
};

export const MSGS_COMMON = {
  saving: "Saving...",
  loading: "Loading...",
  uploading: "Uploading...",
  notApplicable: "NA",
  success: "Saving success!",
  successSending: "Email Verification Sent!",
  successUpdate: "Update success!",
  successDelete: "Delete success!",
  noInternet:
    "No internet connection found. Make sure you have an active connection and try again.",
};

export const MSGS_ARCHIVE_ADMIN = {
  archived: "User has been archived",
  archivedFailed: "Failed to archive User",
  restored: "User has been restored",
  restoreFailed: "Failed to restore User",
};

export const MSGS_NOTE_ENTRY = {
  required: "Input fields can't be empty",
  checkUnSave: "Please check the unsave changes to proceed.",
};

export const MSGS_USER_INVITE = {
  firstName: {
    required: "Your first name is required.",
    max: `Your name cannot exceed ${LIMITS.name.single} characters in length.`,
  },
  lastName: {
    required: "Your last name is required.",
    max: `Your position cannot exceed ${LIMITS.name.single} characters in length.`,
  },
  email: {
    required: "An email address is required.",
    invalid: "You have entered an invalid email address.",
  },
  contactNumber: "Your mobile number is required",
  userRole: "No role given to this user.",
  userAccess: "No Access selected for this user.",
  siteSelected: "No sites selected for this user.",
  success: "Invite Sent",
  search: "Search Sub Contractor",
  invalid: "Please enter a valid contact number with country code.",
};

export const MSGS_ADMIN_INVITATION = {
  used: "Invitation link is already used. Please log into your account.",
  cancelled: "Sorry, Invitation link is already cancelled.",
  logOutToRegister: "Please logout first to use this invite link.",
};

export const MSGS_CONTACT_PERSON = {
  companyName: {
    required: "Company name is required.",
    max: `Company name cannot exceed ${LIMITS.name.full} characters in length.`,
  },
  companyABN: {
    required: "Company ABN is required.",
    max: `Company ABN cannot exceed ${LIMITS.name.full} characters in length.`,
  },
  preferredTruck: "Truck is Required.",
  driver: "Driver is Required.",
  firstName: {
    required: "Your first name is required.",
    max: `Your name cannot exceed ${LIMITS.name.single} characters in length.`,
  },
  lastName: {
    required: "Your last name is required.",
    max: `Your position cannot exceed ${LIMITS.name.single} characters in length.`,
  },
  email: {
    required: "An email address is required.",
    invalid: "You have entered an invalid email address.",
  },
  mobileNumber: "Your mobile number is required",
  invalidMobileNumber: "Your mobile number is invalid",
};

export const MSGS_JOB_SCHEDULE = {
  startDate: {
    required: "Start Date is Required.",
  },
  invalidScheduleDate: "End Date can't be later than Start Date.",
  endDate: {
    required: "End Date is Required.",
  },
  onSiteTime: "Onsite Time is Required.",
  finishTime: "Finish Time is Required.",
  client: "Client is Required.",
  contactPerson: "Contact Person is Required.",
  depot: "Depot is Required.",
  truckSize: "Truck Size is Required.",
  jobType: "Job Type is Required.",
  truckNumber: "Truck Number is Required.",
  driver: "Driver is Required.",
  address: "Address is Required.",
};

export const MSGS_DRIVER = {
  firstName: {
    required: "Your first name is required.",
    max: `Your name cannot exceed ${LIMITS.name.single} characters in length.`,
  },
  lastName: {
    required: "Your last name is required.",
    max: `Your position cannot exceed ${LIMITS.name.single} characters in length.`,
  },
  email: {
    required: "An email address is required.",
    invalid: "You have entered an invalid email address.",
  },
  contactNumber: "Your contact number is required",
  depot: "Your Depot is required",
  truckPreference: "Your Truck Preference is required",
  licenceNumber: "Your Licence Number is required.",
  password: "Driver's password is required.",
  vocAttachmentsExceed: "Attachment exceeds the maximum number.",
  attachmentFileSizeExceed: "Attachment exceeds the maximum number.",
  invalidFileType: "File is invalid.",
  vocTruck: "Truck is required.",
  vocDateIssued: "Date Issued is Required.",
};

export const MSGS_DEPOT = {
  depotName: {
    required: "Your depot name is required.",
    max: `Your name cannot exceed ${LIMITS.name.single} characters in length.`,
  },
  contactPerson: {
    required: "Your contact person is required.",
    max: `Your position cannot exceed ${LIMITS.name.single} characters in length.`,
    invalid: "You have entered an invalid address.",
  },
  address: {
    required: "An  address is required.",
    invalid: "You have entered an invalid address.",
  },
  contactNumber: {
    required: "Your contact number is required",
    invalid: "Your contact number is invalid",
  },
};

export const MSGS_TRUCK = {
  name: "Truck Name is required.",
  size: "Size is required.",
  type: "Type is required.",
  make: "Make is required.",
  model: "Model is required.",
  year: "Year is required.",
  regoNumber: "Rego Number is required.",
  vinNumber: "VIN Number is required.",
  rmsTransportNumber: "RMS ID is required.",
  depotId: "Depot is required.",
  description: "Description is required.",
};

export const MSGS_TRUCK_REGISTER = {
  truckName: {
    required: "Truck Name is required.",
    max: `Truck Name cannot exceed ${LIMITS.name.full} characters in length.`,
  },
  size: {
    required: "Size is required.",
    max: `Size cannot exceed ${LIMITS.name.full} characters in length.`,
  },
  truckType: "Type is Required.",
  make: "Make is Required.",
  depot: {
    required: "Depot is required.",
    max: `Your name cannot exceed ${LIMITS.name.single} characters in length.`,
  },
  model: {
    required: "Model is required.",
  },
  year: {
    required: "Year is required.",
  },
  regoNumber: "Your Rego number is required",
  vinNumber: "Your Vin number is required",
  description: "Description is required",
  serviceTypeAttachmentsExceed: "Attachment exceeds the maximum number.",
  faultsAndDefectsAttachmentsExceed: "Attachment exceeds the maximum number.",
  attachmentFileSizeExceed: "Attachment exceeds the maximum number.",
  invalidFileType: "File is invalid.",
  serviceType: "Service Type is required.",
  serviceDate: "Service Date is Required.",
};

export const driverNewJobSchedule = (date: Date, clientName: string) => {
  return `You have a new job for ${clientName} on ${moment(date).format(
    "DD MMM YYYY"
  )}. Open the DUBU app to approve the job.`;
};

export const driverJobScheduleUpdated = (
  date: Date,
  action: "updated" | "cancelled",
  clientName: string
) => {
  return `A job for ${clientName} on ${moment(date).format(
    "DD MMM YYYY"
  )} has been ${action}. Open the DUBU app to view more.`;
};

export const newDriver = (pin: string) => {
  return `Your new DUBU account login pin is ${pin} . Download the DUBU app here ${APP_DOWNLOAD_LINK}`;
};
