import * as _ from "lodash";
import React from "react";
import { IonPopover, IonLabel } from "@ionic/react";

import "./EWPToolTip.scss";

interface EWPToolTipInterface {
  anchor: null | undefined | Event;
  text: string;
  removeAnchor: () => void;
}
export const EWPToolTip = (props: EWPToolTipInterface) => {
  const { anchor, text, removeAnchor } = props;
  return (
    <IonPopover
      isOpen={!_.isEmpty(anchor)}
      event={!_.isNull(anchor) ? anchor : undefined}
      className="ewp-tool-tip ion-padding"
      showBackdrop={false}
      onDidDismiss={() => {
        removeAnchor();
      }}
    >
      <div
        className="ewp-tooltip-container"
        onMouseOut={() => {
          removeAnchor();
        }}
      >
        <IonLabel className="ewp-tooltip-label ewp-paragraph">{text}</IonLabel>
      </div>
    </IonPopover>
  );
};
