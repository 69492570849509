import * as _ from "lodash";
import { close, eye } from "ionicons/icons";
import moment from "moment";
import React from "react";
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonSearchbar,
} from "@ionic/react";

import "./SearchPage.scss";
import * as services from "../../../services";
import * as routes from "../../../constants/routes";
import { EWPProps, EWPTableProps } from "../../../config/global";
import { EWPWebDropdownSelect } from "../../../components/EWPWebDropdownSelect/EWPWebDropdownSelect";
import { EWPDataTable } from "../../../components/EWPDataTable/EWPDataTable";
import { AttachmentDialog } from "../../../components/Web/AttachmentDialog/AttachmentDialog";
import { JobScheduleDetailDialog } from "../../../components/JobScheduleDetailDialog/JobScheduleDetailDialog";
import {
  getDateFromTimezoneIgnoredTimestamp,
  TimezoneIgnoredTimeStamp,
} from "../../../functions/common";
import {
  DriverExpiryWithDateView,
  DriverInductionView,
  DriverInfo,
  TruckExpiryWithDateView,
  TruckInfo,
} from "../../../models/drivers";
import { JobScheduleView, JOBS_WITH_KEYS, SiteDocket } from "../../../models";
import { EWPDatePicker } from "../../../components/EWPDatePicker/EWPDatePicker";

const searchOptions = [
  {
    id: "vocs",
    name: "VOCs",
  },
  {
    id: "inductions",
    name: "Inductions",
  },
  {
    id: "truck-expiries",
    name: "Truck Expiries",
  },
  {
    id: "driver-expiries",
    name: "Driver Expiries",
  },
  {
    id: "truck-info",
    name: "Truck Info",
  },
  {
    id: "driver-info",
    name: "Driver Info",
  },
  {
    id: "jobs",
    name: "Jobs",
  },
  {
    id: "site-dockets",
    name: "Site Dockets",
  },
];

interface DriverSearchVOCsView {
  driverName: string;
  truckName: string;
  dateIssue: string;
  attachments?: string[];
  driverId: string;
  truckId: string;
}

interface SortKeys {
  name: "asc" | "desc";
  [key: string]: "asc" | "desc";
}

interface JobScheduleViewSearch extends JobScheduleView {
  jobId: string;
  driverName: string;
  truckName: string;
  startDateString: string;
  finishDateString: string;
  contactPerson: string;
  jobTypeString: string;
  invoicedCheckedString: string;
}

interface JobSiteDocketSearch extends SiteDocket {
  jobId: string;
  driverName: string;
  truckName: string;
  clientName: string;
  jobSiteDocketDate: string;
  uploadedDate: string;
  truckId: string;
  driverId: string;
  clientId: string;
}

class SearchPage extends React.Component<EWPProps> {
  state = {
    searchQuery: "",
    searchCategory: "" as
      | "vocs"
      | "inductions"
      | "truck-expiries"
      | "driver-expiries"
      | "truck-info"
      | "driver-info"
      | "jobs"
      | "site-dockets",
    errorMsgSearchCategory: "",

    listOfVOcs: null as null | DriverSearchVOCsView[],
    filteredVocs: null as null | DriverSearchVOCsView[],

    listOfDriverExpiry: null as null | DriverExpiryWithDateView[],
    filteteredListOfDriverExpiry: null as null | DriverExpiryWithDateView[],

    listOfDriverInductions: null as null | DriverExpiryWithDateView[],
    filteteredListOfDriverInductions: null as null | DriverExpiryWithDateView[],

    listOfTruckExpiry: null as null | TruckExpiryWithDateView[],
    filteteredListOfTruckExpiry: null as null | TruckExpiryWithDateView[],

    listOfTruckInfo: null as null | TruckInfo[],
    filteteredListOfTruckInfo: null as null | TruckInfo[],

    listOfDriverInfo: null as null | DriverInfo[],
    filteteredListOfDriverInfo: null as null | DriverInfo[],

    dataAttachments: null as null | string[],
    showAttachmentDialog: false,

    jobHistory: null as null | JobScheduleViewSearch[],
    selectedJobHistory: null as null | JobScheduleView,
    attachmentIndexToOpen: null as null | number,
    showJobScheduleDetail: false as boolean,
    showSiteDockets: false as boolean,
    flagShowDetail: false,
    filteteredJobHistory: null as null | JobScheduleViewSearch[],

    jobSiteDockets: null as null | JobSiteDocketSearch[],
    selectedJobSiteDocket: null as null | JobSiteDocketSearch[],
    filteredJobSiteDockets: null as null | JobSiteDocketSearch[],

    selectedStartDate: null as Date | null,
    selectedFinishDate: null as Date | null,
    showUninvoicedJobs: false,

    sortVOCLabelsAndDirection: {
      name: "asc",
    } as SortKeys,
    sortInductionsLabelsAndDirection: {
      name: "asc",
    } as SortKeys,
    sortTruckExpiriesLabelsAndDirection: {
      name: "asc",
    } as SortKeys,
    sortDriverExpiriesLabelsAndDirection: {
      name: "asc",
    } as SortKeys,
    sortTruckInfoLabelsAndDirection: {
      name: "asc",
    } as SortKeys,
    sortDriverInfoLabelsAndDirection: {
      name: "asc",
    } as SortKeys,
    sortJobHistoryLabelsAndDirection: {
      name: "asc",
    } as SortKeys,
    sortJobSiteDocketLabelsAndDirection: {
      name: "asc",
    } as SortKeys,
  };

  componentDidMount = () => {
    this.getVocsData();
    this.getDriverInductions();
    this.getDriverExpiriesWithDateView();
    this.getTruckExpiriesWithDateView();
    this.getTruckInfo();
    this.getDriverInfo();
    this.getJobHistory();
    this.getJobSiteDockets();
  };

  sortVOC = (key: string) => {
    const { listOfVOcs, filteredVocs, sortVOCLabelsAndDirection } = this.state;
    const sortBy = sortVOCLabelsAndDirection[key] === "desc" ? "asc" : "desc";
    const updateSort = {} as SortKeys;
    updateSort[key] = sortBy;
    const willSortData = !_.isNull(filteredVocs) ? filteredVocs : listOfVOcs;
    const sortedData = _.orderBy(willSortData, key, sortBy);
    this.setState({
      filteredVocs: sortedData,
      sortVOCLabelsAndDirection: {
        ...sortVOCLabelsAndDirection,
        ...updateSort,
      },
    });
  };

  sortInductions = (key: string) => {
    const {
      listOfDriverInductions,
      filteteredListOfDriverInductions,
      sortInductionsLabelsAndDirection,
    } = this.state;

    const sortBy =
      sortInductionsLabelsAndDirection[key] === "desc" ? "asc" : "desc";
    const updateSort = {} as SortKeys;
    updateSort[key] = sortBy;
    const willSortData = !_.isNull(filteteredListOfDriverInductions)
      ? filteteredListOfDriverInductions
      : listOfDriverInductions;
    const sortedData = _.orderBy(willSortData, key, sortBy);
    this.setState({
      filteteredListOfDriverInductions: sortedData,
      sortInductionsLabelsAndDirection: {
        ...sortInductionsLabelsAndDirection,
        ...updateSort,
      },
    });
  };
  getJobSiteDockets = () => {
    if (_.isNull(this.state.jobHistory)) {
      services.getJobSchedulesRealTimeView((jobSchedules, error) => {
        const jobSiteDockets = jobSchedules.map((schedule) => {
          return {
            siteDockets: (schedule.siteDockets || []).map((docket) => {
              return {
                ...docket,
                jobId: schedule.docId,
                driverName:
                  !!schedule.driverDetails && !_.isEmpty(schedule.driverDetails)
                    ? `${schedule.driverDetails.firstName} ${schedule.driverDetails.lastName}`
                    : "N/A",
                truckName:
                  !!schedule.truckDetails && !_.isEmpty(schedule.truckDetails)
                    ? schedule.truckDetails.name
                    : "N/A",
                clientName:
                  !!schedule.clientDetails && !_.isEmpty(schedule.clientDetails)
                    ? schedule.clientDetails.name
                    : "N/A",
                jobSiteDocketDate: moment(
                  (
                    docket.siteDocketDate as firebase.firestore.Timestamp
                  ).toDate()
                ).format("DD MMM YYYY"),
                uploadedDate: moment(
                  (docket.uploadDate as firebase.firestore.Timestamp).toDate()
                ).format("DD MMM YYYY, hh:mm:A"),
                siteDocketName: !!docket.siteDocketName
                  ? docket.siteDocketName
                  : "N/A",
                truckId: schedule.truckId,
                driverId: schedule.driverId,
                clientId: schedule.clientId,
              };
            }),
          };
        });

        const allSiteDockets = _.compact(
          _.flatMap(jobSiteDockets.map((docket) => docket.siteDockets))
        ) as JobSiteDocketSearch[];
        this.setState({ jobSiteDockets: allSiteDockets });
        if (!!error) {
          this.setState({ error });
        }
      });
    }
  };

  getJobHistory = () => {
    if (_.isNull(this.state.jobHistory)) {
      services.getJobSchedulesRealTimeView((jobSchedules, error) => {
        this.setState({
          jobHistory: jobSchedules.map((schedule) => {
            return {
              ...schedule,
              jobId: schedule.docId,
              driverName:
                !!schedule.driverDetails && !_.isEmpty(schedule.driverDetails)
                  ? `${schedule.driverDetails.firstName} ${schedule.driverDetails.lastName}`
                  : "N/A",
              truckName:
                !!schedule.truckDetails && !_.isEmpty(schedule.truckDetails)
                  ? schedule.truckDetails.name
                  : "N/A",
              startDateString: !!schedule.startDate
                ? moment(schedule.startDate.toDate()).format("DD MMM YYYY")
                : "N/A",
              finishDateString: !!schedule.endDate
                ? moment(schedule.endDate.toDate()).format("DD MMM YYYY")
                : "N/A",
              jobTypeString:
                !!schedule.jobType && !!JOBS_WITH_KEYS[schedule.jobType]
                  ? JOBS_WITH_KEYS[schedule.jobType].name
                  : "N/A",
              invoicedCheckedString: !!schedule.invoiceChecked ? "YES" : "NO",
              invoiceNumber: !!schedule.invoiceNumber
                ? schedule.invoiceNumber
                : "N/A",
              contactPerson: !!schedule.contactPersonDetails
                ? `${schedule.contactPersonDetails.firstName} ${schedule.contactPersonDetails.lastName}`
                : "N/A",
            } as JobScheduleViewSearch;
          }),
        });
        if (!!error) {
          this.setState({ error });
        }
      });
    }
  };

  sortTruckExpiries = (key: string) => {
    const {
      listOfTruckExpiry,
      filteteredListOfTruckExpiry,
      sortTruckExpiriesLabelsAndDirection,
    } = this.state;

    const sortBy =
      sortTruckExpiriesLabelsAndDirection[key] === "desc" ? "asc" : "desc";
    const updateSort = {} as SortKeys;
    updateSort[key] = sortBy;
    const willSortData = !_.isNull(filteteredListOfTruckExpiry)
      ? filteteredListOfTruckExpiry
      : listOfTruckExpiry;
    const sortedData = _.orderBy(willSortData, key, sortBy);
    this.setState({
      filteteredListOfTruckExpiry: sortedData,
      sortTruckExpiriesLabelsAndDirection: {
        ...sortTruckExpiriesLabelsAndDirection,
        ...updateSort,
      },
    });
  };

  sortDriverExpiries = (key: string) => {
    const {
      listOfDriverExpiry,
      filteteredListOfDriverExpiry,
      sortDriverExpiriesLabelsAndDirection,
    } = this.state;

    const sortBy =
      sortDriverExpiriesLabelsAndDirection[key] === "desc" ? "asc" : "desc";
    const updateSort = {} as SortKeys;
    updateSort[key] = sortBy;
    const willSortData = !_.isNull(filteteredListOfDriverExpiry)
      ? filteteredListOfDriverExpiry
      : listOfDriverExpiry;
    const sortedData = _.orderBy(willSortData, key, sortBy);
    this.setState({
      filteteredListOfDriverExpiry: sortedData,
      sortDriverExpiriesLabelsAndDirection: {
        ...sortDriverExpiriesLabelsAndDirection,
        ...updateSort,
      },
    });
  };

  sortTruckInfo = (key: string) => {
    const {
      listOfTruckInfo,
      filteteredListOfTruckInfo,
      sortTruckInfoLabelsAndDirection,
    } = this.state;

    const sortBy =
      sortTruckInfoLabelsAndDirection[key] === "desc" ? "asc" : "desc";
    const updateSort = {} as SortKeys;
    updateSort[key] = sortBy;
    const willSortData = !_.isNull(filteteredListOfTruckInfo)
      ? filteteredListOfTruckInfo
      : listOfTruckInfo;
    const sortedData = _.orderBy(willSortData, key, sortBy);
    this.setState({
      filteteredListOfTruckInfo: sortedData,
      sortTruckInfoLabelsAndDirection: {
        ...sortTruckInfoLabelsAndDirection,
        ...updateSort,
      },
    });
  };

  sortDriverInfo = (key: string) => {
    const {
      listOfDriverInfo,
      filteteredListOfDriverInfo,
      sortDriverInfoLabelsAndDirection,
    } = this.state;

    const sortBy =
      sortDriverInfoLabelsAndDirection[key] === "desc" ? "asc" : "desc";
    const updateSort = {} as SortKeys;
    updateSort[key] = sortBy;
    const willSortData = !_.isNull(filteteredListOfDriverInfo)
      ? filteteredListOfDriverInfo
      : listOfDriverInfo;
    const sortedData = _.orderBy(willSortData, key, sortBy);
    this.setState({
      filteteredListOfDriverInfo: sortedData,
      sortDriverInfoLabelsAndDirection: {
        ...sortDriverInfoLabelsAndDirection,
        ...updateSort,
      },
    });
  };

  getTruckInfo = () => {
    services.getTruckInfoForSearch((list, error) => {
      console.log("getTruckInfoForSearch", list);
      this.setState({
        listOfTruckInfo: list,
        error: error || "",
      });
    });
  };
  getDriverInfo = () => {
    services.getDriverInfoForSearch((list, error) => {
      console.log("getDriverInfoForSearch", list);
      this.setState({
        listOfDriverInfo: list,
        error: error || "",
      });
    });
  };
  getVocsData = () => {
    services.getAllDriverVOCsViewRealtime((driverVOCs) => {
      this.setState({
        listOfVOcs: driverVOCs.map((voc) => {
          return {
            driverName: `${voc.driverDetails.firstName} ${voc.driverDetails.lastName}`,
            truckName: voc.truckDetails.name,
            dateIssue: moment(voc.dateIssued.toDate()).format("DD MMM YYYY"),
            attachments: !_.isEmpty(voc.attachments) ? voc.attachments : [],
            driverId: voc.driverId,
            truckId: voc.truckId,
          };
        }) as DriverSearchVOCsView[],
      });
    });
  };

  getDriverInductions = () => {
    services.getDriverInductionsForSearch((list, error) => {
      console.log("getDriverInductionsForSearch", list);
      this.setState({
        listOfDriverInductions: list,
        error: error || "",
      });
    });
  };

  getDriverExpiriesWithDateView = () => {
    services.getDriverExpiriesForSearch((list, error) => {
      console.log("getDriverExpiriesForSearch", list);
      this.setState({
        listOfDriverExpiry: list,
        error: error || "",
      });
    });
  };

  getDriverExpiriesTableDef = () => {
    let headers = [
      { label: "Driver Name", key: "driverName", sortable: true },
      { label: "Expiry Name", key: "name", sortable: true },
      { label: "Expiry Reminder", key: "expiryReminder", sortable: true },
      { label: "Expiry Date", key: "driverExpiryDate", sortable: true },
    ];

    let columns = ["driverName", "name", "expiryReminder", "driverExpiryDate"];

    return { headers, columns } as EWPTableProps;
  };

  sortJobHistory = (key: string) => {
    const {
      jobHistory,
      filteteredJobHistory,
      sortJobHistoryLabelsAndDirection,
    } = this.state;
    const sortBy =
      sortJobHistoryLabelsAndDirection[key] === "desc" ? "asc" : "desc";
    const updateSort = {} as SortKeys;
    updateSort[key] = sortBy;
    const willSortData = !_.isNull(filteteredJobHistory)
      ? filteteredJobHistory
      : jobHistory;
    let sortedData = [];
    if (key === "startDateString") {
      sortedData = _.orderBy(willSortData, "startDate", sortBy);
    } else if (key === "finishDateString") {
      sortedData = _.orderBy(willSortData, "endDate", sortBy);
    } else {
      sortedData = _.orderBy(willSortData, key, sortBy);
    }
    this.setState({
      filteteredJobHistory: sortedData,
      sortJobHistoryLabelsAndDirection: {
        ...sortJobHistoryLabelsAndDirection,
        ...updateSort,
      },
    });
  };

  sortJobSiteDockets = (key: string) => {
    const {
      jobSiteDockets,
      filteredJobSiteDockets,
      sortJobSiteDocketLabelsAndDirection,
    } = this.state;

    const sortBy =
      sortJobSiteDocketLabelsAndDirection[key] === "desc" ? "asc" : "desc";
    const updateSort = {} as SortKeys;
    updateSort[key] = sortBy;
    const willSortData = !_.isNull(filteredJobSiteDockets)
      ? filteredJobSiteDockets
      : jobSiteDockets;
    const sortedData = _.orderBy(willSortData, key, sortBy);
    this.setState({
      filteredJobSiteDockets: sortedData,
      sortJobSiteDocketLabelsAndDirection: {
        ...sortJobSiteDocketLabelsAndDirection,
        ...updateSort,
      },
    });
  };

  getJobHistoryTableDef = () => {
    let headers = [
      { label: "Job Id", key: "jobId", sortable: true },
      { label: "Truck", key: "truckName", sortable: true },
      { label: "Driver", key: "driverName", sortable: true },
      { label: "Start", key: "startDateString", sortable: true },
      { label: "Finish", key: "finishDateString", sortable: true },
      {
        label: "Invoiced Checked?",
        key: "invoicedCheckedString",
        sortable: true,
      },
      { label: "Invoice #", key: "invoiceNumber", sortable: true },
      { label: "Job Type", key: "jobTypeString", sortable: true },
      { label: "Contact Person", key: "contactPerson", sortable: true },
      { label: "Location", key: "address", sortable: true },
    ];

    let columns = [
      "jobId",
      "truckName",
      "driverName",
      "startDateString",
      "finishDateString",
      "invoicedCheckedString",
      "invoiceNumber",
      "jobTypeString",
      "contactPerson",
      "address",
    ];

    return { headers, columns } as EWPTableProps;
  };

  getJobSiteDocketsTableDef = () => {
    let headers = [
      { label: "Job Id", key: "jobId", sortable: true },
      { label: "Site Docket Name", key: "siteDocketName", sortable: true },
      { label: "Driver", key: "driverName", sortable: true },
      { label: "Truck Name", key: "truckName", sortable: true },
      { label: "Client Name", key: "clientName" },
      {
        label: "Job Site Docket Date",
        key: "jobSiteDocketDate",
        sortable: true,
      },
      { label: "Uploaded Date", key: "uploadedDate", sortable: true },
    ];

    let columns = [
      "jobId",
      "siteDocketName",
      "driverName",
      "truckName",
      "clientName",
      "jobSiteDocketDate",
      "uploadedDate",
    ];

    return { headers, columns } as EWPTableProps;
  };

  renderJobSiteDocketsCell = (value: string, rowData: JobSiteDocketSearch) => {
    switch (value) {
      case "jobId":
        return (
          <IonLabel className="ewp-paragraph bold">{`${rowData.jobId}`}</IonLabel>
        );
      case "siteDocketName":
        return (
          <IonLabel className="ewp-paragraph bold">{`${rowData.siteDocketName}`}</IonLabel>
        );
      case "truckName":
        return (
          <IonLabel
            className="label-as-link ewp-paragraph"
            onClick={() => {
              this.props.history.push(
                routes.HOME_TRUCK_REGISTER_DETAILS.replace(
                  ":truckId",
                  rowData.truckId || ""
                )
              );
            }}
          >
            <u>{rowData.truckName}</u>
          </IonLabel>
        );
      case "driverName":
        return (
          <IonLabel
            className="label-as-link ewp-paragraph"
            onClick={() => {
              this.props.history.push(
                routes.HOME_DRIVER_REGISTER_DETAILS.replace(
                  ":driverId",
                  rowData.driverId || ""
                )
              );
            }}
          >
            <u>{rowData.driverName}</u>
          </IonLabel>
        );
      case "clientName":
        return (
          <IonLabel
            className="label-as-link ewp-paragraph"
            onClick={() => {
              this.props.history.push(
                routes.HOME_TRUCK_REGISTER_DETAILS.replace(
                  ":truckId",
                  rowData.clientId || ""
                )
              );
            }}
          >
            <u>{rowData.truckName}</u>
          </IonLabel>
        );
      case "jobSiteDocketDate":
        return (
          <IonLabel className="ewp-paragraph">
            {rowData.jobSiteDocketDate}
          </IonLabel>
        );

      case "uploadedDate":
        return (
          <div className="date-attachment-container">
            <IonLabel className="ewp-paragraph">
              {rowData.uploadedDate}
            </IonLabel>
            <IonButton
              fill="clear"
              mode="ios"
              className="attachment-button ewp-web-gray-button ion-no-padding ion-no-margin"
              onClick={() => {
                const arrDocket = [rowData];
                this.setState({
                  selectedJobSiteDocket: arrDocket,
                });
              }}
            >
              <IonIcon icon={eye} />
            </IonButton>
          </div>
        );
    }
  };
  renderJobHistoryCell = (value: string, rowData: JobScheduleViewSearch) => {
    switch (value) {
      case "jobId":
        return (
          <IonLabel className="ewp-paragraph bold">{`${rowData.docId}`}</IonLabel>
        );
      case "truckName":
        return (
          <IonLabel
            className="label-as-link ewp-paragraph"
            onClick={() => {
              this.props.history.push(
                routes.HOME_TRUCK_REGISTER_DETAILS.replace(
                  ":truckId",
                  rowData.truckDetails.docId || ""
                )
              );
            }}
          >
            <u>{rowData.truckName}</u>
          </IonLabel>
        );
      case "driverName":
        return (
          <IonLabel
            className="label-as-link ewp-paragraph"
            onClick={() => {
              this.props.history.push(
                routes.HOME_DRIVER_REGISTER_DETAILS.replace(
                  ":driverId",
                  rowData.driverDetails.docId || ""
                )
              );
            }}
          >
            <u>{rowData.driverName}</u>
          </IonLabel>
        );
      case "startDateString":
        return (
          <IonLabel className="ewp-paragraph spacing">
            {rowData.startDateString}
          </IonLabel>
        );

      case "finishDateString":
        return (
          <IonLabel className="ewp-paragraph spacing">
            {rowData.finishDateString}
          </IonLabel>
        );
      case "invoicedCheckedString":
        return (
          <IonLabel className="ewp-paragraph spacing">
            {rowData.invoicedCheckedString}
          </IonLabel>
        );
      case "invoiceNumber":
        return (
          <IonLabel className="ewp-paragraph spacing">
            {rowData.invoiceNumber}
          </IonLabel>
        );
      case "jobTypeString":
        return (
          <IonLabel className="ewp-paragraph spacing">
            {rowData.jobTypeString}
          </IonLabel>
        );
      case "contactPerson":
        return (
          <div className="date-attachment-container">
            <IonLabel className="ewp-paragraph spacing">
              {rowData.contactPerson}
            </IonLabel>
          </div>
        );
      case "address":
        return (
          <div className="date-attachment-container">
            <IonLabel className="ewp-paragraph">{`${rowData.address}`}</IonLabel>
            <IonButton
              fill="clear"
              mode="ios"
              className="attachment-button ewp-web-gray-button ion-no-padding ion-no-margin"
              onClick={() => {
                this.setState({
                  showJobScheduleDetail: true,
                  selectedJobHistory: rowData as JobScheduleView,
                  flagShowDetail: true,
                });
              }}
            >
              <IonIcon icon={eye} />
            </IonButton>
          </div>
        );
    }
  };

  rendeDriverExpiryCell = (
    value: string,
    rowData: DriverExpiryWithDateView
  ) => {
    const columnName = value as keyof DriverExpiryWithDateView;
    switch (value) {
      case "driverName":
        return (
          <IonLabel
            className="label-as-link ewp-paragraph"
            onClick={() => {
              this.props.history.push(
                routes.HOME_DRIVER_REGISTER_DETAILS.replace(
                  ":driverId",
                  rowData.driverId || ""
                )
              );
            }}
          >
            <u>{rowData[columnName]}</u>
          </IonLabel>
        );
      case "name":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      case "expiryReminder":
        const week = rowData[columnName];
        return (
          <IonLabel className="ewp-paragraph">
            {week !== 0
              ? week !== 1
                ? `${week} Weeks`
                : `${week} Week`
              : "N/A"}
          </IonLabel>
        );

      case "driverExpiryDate":
        const expiryDate = rowData[columnName];
        return (
          <div className="date-attachment-container">
            <IonLabel className="ewp-paragraph">{expiryDate}</IonLabel>
            {!_.isEmpty(rowData.attachments) && (
              <IonButton
                fill="clear"
                mode="ios"
                className="attachment-button ewp-web-gray-button ion-no-padding ion-no-margin"
                onClick={() => {
                  this.setState({
                    showAttachmentDialog: true,
                    dataAttachments: rowData.attachments,
                  });
                }}
              >
                <IonIcon className="attachment-icon" />
              </IonButton>
            )}
          </div>
        );
      default:
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
    }
  };

  getDriverInductionsTableDef = () => {
    let headers = [
      { label: "Driver Name", key: "driverName", sortable: true },
      { label: "Induction Name", key: "name", sortable: true },
      { label: "Expiry Date", key: "inductionExpiryDate", sortable: true },
      { label: "Date Issued", key: "inductionDateIssued", sortable: true },
    ];

    let columns = [
      "driverName",
      "name",
      "inductionExpiryDate",
      "inductionDateIssued",
    ];

    return { headers, columns } as EWPTableProps;
  };

  rendeDriverInductionsCell = (value: string, rowData: DriverInductionView) => {
    const columnName = value as keyof DriverInductionView;
    switch (value) {
      case "driverName":
        return (
          <IonLabel
            onClick={() => {
              this.props.history.push(
                routes.HOME_DRIVER_REGISTER_DETAILS.replace(
                  ":driverId",
                  rowData.driverId || ""
                )
              );
            }}
            className="label-as-link ewp-paragraph"
          >
            <u>{rowData[columnName]}</u>
          </IonLabel>
        );
      case "name":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      case "inductionExpiryDate":
        const expiryDate = rowData[columnName];
        return <IonLabel className="ewp-paragraph">{expiryDate}</IonLabel>;
      case "inductionDateIssued":
        const dateIssued = rowData[columnName];
        return (
          <div className="date-attachment-container">
            <IonLabel className="ewp-paragraph">{dateIssued}</IonLabel>
            {!_.isEmpty(rowData.attachments) && (
              <IonButton
                fill="clear"
                mode="ios"
                className="attachment-button ewp-web-gray-button ion-no-padding ion-no-margin"
                onClick={() => {
                  this.setState({
                    showAttachmentDialog: true,
                    dataAttachments: rowData.attachments,
                  });
                }}
              >
                <IonIcon className="attachment-icon" />
              </IonButton>
            )}
          </div>
        );
      default:
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
    }
  };

  getTruckExpiriesTableDef = () => {
    let headers = [
      { label: "Truck Name", key: "truckName", sortable: true },
      { label: "Expiry Name", key: "name", sortable: true },
      { label: "Expiry Reminder", key: "expiryReminder", sortable: true },
      { label: "Expiry Date", key: "truckExpiryDate", sortable: true },
    ];

    let columns = ["truckName", "name", "expiryReminder", "truckExpiryDate"];

    return { headers, columns } as EWPTableProps;
  };

  getTruckInfoTableDef = () => {
    let headers = [
      { label: "Truck Name", key: "name", sortable: true },
      { label: "Truck Size", key: "size", sortable: true },
      { label: "Type", key: "typeName", sortable: true },
      { label: "Make", key: "make", sortable: true },
      { label: "Model", key: "model", sortable: true },
      { label: "Year", key: "year", sortable: true },
      { label: "Rego Number", key: "regoNumber", sortable: true },
      { label: "Vin Number", key: "vinNumber", sortable: true },
      { label: "Depot", key: "depotName", sortable: true },
    ];

    let columns = [
      "name",
      "size",
      "typeName",
      "make",
      "model",
      "year",
      "regoNumber",
      "vinNumber",
      "depotName",
    ];

    return { headers, columns } as EWPTableProps;
  };

  getDriverInfoTableDef = () => {
    let headers = [
      { label: "Driver Name", key: "name", sortable: true },
      { label: "Truck Preference", key: "truckPreferenceName", sortable: true },
      { label: "Depot", key: "depotName", sortable: true },
      { label: "Licence Number", key: "licenceNumber", sortable: true },
      { label: "Phone Number", key: "phoneNumber", sortable: true },
      { label: "Email Address", key: "emailAddress", sortable: true },
    ];

    let columns = [
      "name",
      "truckPreferenceName",
      "depotName",
      "licenceNumber",
      "phoneNumber",
      "emailAddress",
    ];

    return { headers, columns } as EWPTableProps;
  };

  rendeDriverInfoCell = (value: string, rowData: DriverInfo) => {
    const columnName = value as keyof DriverInfo;
    switch (value) {
      case "name":
        return (
          <IonLabel
            className="label-as-link ewp-paragraph"
            onClick={() => {
              this.props.history.push(
                routes.HOME_DRIVER_REGISTER_DETAILS.replace(
                  ":driverId",
                  rowData.docId || ""
                )
              );
            }}
          >
            <u>{rowData[columnName]}</u>
          </IonLabel>
        );
      case "truckPreferenceName":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      case "depotName":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      case "licenceNumber":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      case "phoneNumber":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      case "emailAddress":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      default:
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
    }
  };

  rendeTruckInfoCell = (value: string, rowData: TruckInfo) => {
    const columnName = value as keyof TruckInfo;
    switch (value) {
      case "name":
        return (
          <IonLabel
            className="label-as-link ewp-paragraph"
            onClick={() => {
              this.props.history.push(
                routes.HOME_TRUCK_REGISTER_DETAILS.replace(
                  ":truckId",
                  rowData.docId || ""
                )
              );
            }}
          >
            <u>{rowData[columnName]}</u>
          </IonLabel>
        );
      case "size":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      case "typeName":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      case "make":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      case "model":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      case "year":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      case "regoNumber":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      case "vinNumber":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      case "depotName":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      default:
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
    }
  };

  rendeTruckExpiryCell = (value: string, rowData: TruckExpiryWithDateView) => {
    const columnName = value as keyof TruckExpiryWithDateView;
    switch (value) {
      case "truckName":
        return (
          <IonLabel
            className="label-as-link ewp-paragraph"
            onClick={() => {
              this.props.history.push(
                routes.HOME_TRUCK_REGISTER_DETAILS.replace(
                  ":truckId",
                  rowData.truckId || ""
                )
              );
            }}
          >
            <u>{rowData[columnName]}</u>
          </IonLabel>
        );
      case "name":
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
      case "expiryReminder":
        const week = rowData[columnName];
        return (
          <IonLabel className="ewp-paragraph">
            {week !== 0
              ? week !== 1
                ? `${week} Weeks`
                : `${week} Week`
              : "N/A"}
          </IonLabel>
        );

      case "truckExpiryDate":
        const expiryDate = rowData[columnName];
        return (
          <div className="date-attachment-container">
            <IonLabel className="ewp-paragraph">{expiryDate}</IonLabel>
            {!_.isEmpty(rowData.attachments) && (
              <IonButton
                fill="clear"
                mode="ios"
                className="attachment-button ewp-web-gray-button ion-no-padding ion-no-margin"
                onClick={() => {
                  this.setState({
                    showAttachmentDialog: true,
                    dataAttachments: rowData.attachments,
                  });
                }}
              >
                <IonIcon className="attachment-icon" />
              </IonButton>
            )}
          </div>
        );
      default:
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
    }
  };

  getTruckExpiriesWithDateView = () => {
    services.getTruckExpiriesForSearch((list, error) => {
      console.log("getTruckExpiriesForSearch", list);
      this.setState({
        listOfTruckExpiry: list,
        error: error || "",
      });
    });
  };

  getVOCTableDef = () => {
    let headers = [
      { label: "Driver Name", key: "driverName", sortable: true },
      { label: "Truck Name", key: "truckName", sortable: true },
      { label: "Date Issue", key: "dateIssue", sortable: true },
    ];

    let columns = ["driverName", "truckName", "dateIssue"];

    return { headers, columns } as EWPTableProps;
  };

  renderVocsCell = (value: string, rowData: any) => {
    const columnName = value as keyof DriverSearchVOCsView;
    switch (value) {
      case "driverName":
        return (
          <IonLabel
            className="label-as-link ewp-paragraph"
            onClick={() => {
              this.props.history.push(
                routes.HOME_DRIVER_REGISTER_DETAILS.replace(
                  ":driverId",
                  rowData.driverId || ""
                )
              );
            }}
          >
            <u>{rowData[columnName]}</u>
          </IonLabel>
        );
      case "truckName":
        return (
          <IonLabel
            className="label-as-link ewp-paragraph"
            onClick={() => {
              this.props.history.push(
                routes.HOME_TRUCK_REGISTER_DETAILS.replace(
                  ":truckId",
                  rowData.truckId || ""
                )
              );
            }}
          >
            <u>{rowData[columnName]}</u>
          </IonLabel>
        );
      case "dateIssue":
        return (
          <div className="date-attachment-container">
            <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
            {!_.isEmpty(rowData.attachments) && (
              <IonButton
                fill="clear"
                mode="ios"
                className="attachment-button ewp-web-gray-button ion-no-padding ion-no-margin"
                onClick={() => {
                  this.setState({
                    showAttachmentDialog: true,
                    dataAttachments: rowData.attachments,
                  });
                }}
              >
                <IonIcon className="attachment-icon" />
              </IonButton>
            )}
          </div>
        );
      default:
        return (
          <IonLabel className="ewp-paragraph">{rowData[columnName]}</IonLabel>
        );
    }
  };
  searchVOC = (query: string) => {
    if (!_.isEmpty(query)) {
      console.log("THIS IS QUERY -- ", query);
      const filteredVocs = _.filter(
        this.state.listOfVOcs || [],
        (voc: DriverSearchVOCsView) => {
          const formattedQuery = query.trim().toLowerCase();

          return (
            voc.driverName.toLowerCase().indexOf(formattedQuery) !== -1 ||
            voc.truckName.toLowerCase().indexOf(formattedQuery) !== -1 ||
            voc.dateIssue.toLowerCase().indexOf(formattedQuery) !== -1
          );
        }
      );
      this.setState({ filteredVocs });
    } else {
      this.setState({ filteredVocs: null });
    }
  };

  searchJobHistory = (query: string) => {
    if (!_.isEmpty(query)) {
      console.log("THIS IS QUERY -- ", query);
      const filteteredJobHistory = _.filter(
        this.state.jobHistory || [],
        (schedule: JobScheduleViewSearch) => {
          const formattedQuery = query.trim().toLowerCase();

          return (
            schedule.driverName.toLowerCase().indexOf(formattedQuery) !== -1 ||
            schedule.truckName.toLowerCase().indexOf(formattedQuery) !== -1 ||
            schedule.jobId.toLowerCase().indexOf(formattedQuery) !== -1 ||
            schedule.address.toLowerCase().indexOf(formattedQuery) !== -1 ||
            schedule.contactPerson.toLowerCase().indexOf(formattedQuery) !== -1
          );
        }
      );
      this.setState({ filteteredJobHistory });
    } else {
      this.setState({ filteteredJobHistory: null });
    }
  };

  searchJobSiteDockets = (query: string) => {
    if (!_.isEmpty(query)) {
      const filteredJobSiteDockets = _.filter(
        this.state.jobSiteDockets || [],
        (schedule: JobSiteDocketSearch) => {
          const formattedQuery = query.trim().toLowerCase();

          return (
            schedule.jobId.toLowerCase().indexOf(formattedQuery) !== -1 ||
            schedule.siteDocketName.toLowerCase().indexOf(formattedQuery) !==
              -1 ||
            schedule.uploadedDate.toLowerCase().indexOf(formattedQuery) !==
              -1 ||
            schedule.jobSiteDocketDate.toLowerCase().indexOf(formattedQuery) !==
              -1 ||
            schedule.clientName.toLowerCase().indexOf(formattedQuery) !== -1
          );
        }
      );
      this.setState({ filteredJobSiteDockets });
    } else {
      this.setState({ filteredJobSiteDockets: null });
    }
  };

  searchDriverExpiries = (query: string) => {
    if (!_.isEmpty(query)) {
      console.log("THIS IS QUERY -- ", query);
      const filteteredListOfDriverExpiry = _.filter(
        this.state.listOfDriverExpiry || [],
        (driverExp: DriverExpiryWithDateView) => {
          const formattedQuery = query.trim().toLowerCase();

          return (
            driverExp.driverName.toLowerCase().indexOf(formattedQuery) !== -1 ||
            driverExp.name.toLowerCase().indexOf(formattedQuery) !== -1 ||
            driverExp.expiryReminder
              .toString()
              .toLowerCase()
              .indexOf(formattedQuery) !== -1 ||
            driverExp.driverExpiryDate.toLowerCase().indexOf(formattedQuery) !==
              -1
          );
        }
      );
      this.setState({ filteteredListOfDriverExpiry });
    } else {
      this.setState({ filteteredListOfDriverExpiry: null });
    }
  };

  searchDriverInductions = (query: string) => {
    if (!_.isEmpty(query)) {
      console.log("THIS IS QUERY -- ", query);
      const filteteredListOfDriverInductions = _.filter(
        this.state.listOfDriverInductions || [],
        (driverInduction: DriverInductionView) => {
          const formattedQuery = query.trim().toLowerCase();

          return (
            driverInduction.driverName.toLowerCase().indexOf(formattedQuery) !==
              -1 ||
            driverInduction.name.toLowerCase().indexOf(formattedQuery) !== -1 ||
            driverInduction.inductionExpiryDate
              .toLowerCase()
              .indexOf(formattedQuery) !== -1 ||
            driverInduction.inductionDateIssued
              .toLowerCase()
              .indexOf(formattedQuery) !== -1
          );
        }
      );
      this.setState({ filteteredListOfDriverInductions });
    } else {
      this.setState({ filteteredListOfDriverInductions: null });
    }
  };

  searchTruckExpiries = (query: string) => {
    if (!_.isEmpty(query)) {
      console.log("THIS IS QUERY -- ", query);
      const filteteredListOfTruckExpiry = _.filter(
        this.state.listOfTruckExpiry || [],
        (driverExp: TruckExpiryWithDateView) => {
          const formattedQuery = query.trim().toLowerCase();

          return (
            driverExp.truckName.toLowerCase().indexOf(formattedQuery) !== -1 ||
            driverExp.name.toLowerCase().indexOf(formattedQuery) !== -1 ||
            driverExp.expiryReminder
              .toString()
              .toLowerCase()
              .indexOf(formattedQuery) !== -1 ||
            driverExp.truckExpiryDate.toLowerCase().indexOf(formattedQuery) !==
              -1
          );
        }
      );
      this.setState({ filteteredListOfTruckExpiry });
    } else {
      this.setState({ filteteredListOfTruckExpiry: null });
    }
  };

  searchTruckInfo = (query: string) => {
    if (!_.isEmpty(query)) {
      console.log("THIS IS QUERY -- ", query);
      const filteteredListOfTruckInfo = _.filter(
        this.state.listOfTruckInfo || [],
        (truckInfo: TruckInfo) => {
          const formattedQuery = query.trim().toLowerCase();

          return (
            truckInfo.name.toLowerCase().indexOf(formattedQuery) !== -1 ||
            truckInfo.size.toLowerCase().indexOf(formattedQuery) !== -1 ||
            truckInfo.typeName.toLowerCase().indexOf(formattedQuery) !== -1 ||
            truckInfo.make.toLowerCase().indexOf(formattedQuery) !== -1 ||
            truckInfo.model.toLowerCase().indexOf(formattedQuery) !== -1 ||
            truckInfo.year.toLowerCase().indexOf(formattedQuery) !== -1 ||
            truckInfo.regoNumber.toLowerCase().indexOf(formattedQuery) !== -1 ||
            truckInfo.vinNumber.toLowerCase().indexOf(formattedQuery) !== -1 ||
            truckInfo.depotName.toLowerCase().indexOf(formattedQuery) !== -1
          );
        }
      );
      this.setState({ filteteredListOfTruckInfo });
    } else {
      this.setState({ filteteredListOfTruckInfo: null });
    }
  };

  searchDriverInfo = (query: string) => {
    if (!_.isEmpty(query)) {
      console.log("THIS IS QUERY -- ", query);
      const filteteredListOfDriverInfo = _.filter(
        this.state.listOfDriverInfo || [],
        (driverInfo: DriverInfo) => {
          const formattedQuery = query.trim().toLowerCase();

          return (
            driverInfo.name.toLowerCase().indexOf(formattedQuery) !== -1 ||
            driverInfo.truckPreferenceName
              .toLowerCase()
              .indexOf(formattedQuery) !== -1 ||
            driverInfo.depotName.toLowerCase().indexOf(formattedQuery) !== -1 ||
            driverInfo.licenceNumber.toLowerCase().indexOf(formattedQuery) !==
              -1 ||
            driverInfo.phoneNumber!.toLowerCase().indexOf(formattedQuery) !==
              -1 ||
            driverInfo.emailAddress.toLowerCase().indexOf(formattedQuery) !== -1
          );
        }
      );
      this.setState({ filteteredListOfDriverInfo });
    } else {
      this.setState({ filteteredListOfDriverInfo: null });
    }
  };

  filterDateJobHistory = (
    startDate?: Date | null,
    finishDate?: Date | null
  ) => {
    const { jobHistory, filteteredJobHistory } = this.state;
    const jobHistoriesFiltered = !_.isEmpty(filteteredJobHistory)
      ? filteteredJobHistory
      : null;
    if (startDate && finishDate) {
      const resultDateJobHistory = _.filter(
        jobHistoriesFiltered || jobHistory || [],
        (schedule: JobScheduleViewSearch) => {
          return (
            moment(startDate).isSameOrBefore(
              getDateFromTimezoneIgnoredTimestamp(schedule.startDateNoTimeZone)
            ) &&
            moment(finishDate).isSameOrAfter(
              getDateFromTimezoneIgnoredTimestamp(schedule.endDateNoTimeZone)
            )
          );
        }
      );
      this.setState({ filteteredJobHistory: resultDateJobHistory });
    }
  };

  toggleShowUninvoicedJobs = (isUninvoiced: boolean) => {
    const { jobHistory, filteteredJobHistory } = this.state;
    console.log("TOGGLE UNINVOICE -- jobHistory -- ", jobHistory);
    console.log("filteteredJobHistory -- ", filteteredJobHistory);
    const uninvoicedJobs = _.filter(
      filteteredJobHistory || jobHistory,
      (job) => !job.invoiced
    );

    this.setState({
      showUninvoicedJobs: isUninvoiced,
      filteteredJobHistory: isUninvoiced ? uninvoicedJobs : jobHistory,
    });
  };

  render = () => {
    const {
      searchQuery,
      searchCategory,
      errorMsgSearchCategory,
      showAttachmentDialog,

      listOfVOcs,
      filteredVocs,
      dataAttachments,

      listOfTruckExpiry,
      filteteredListOfTruckExpiry,

      listOfDriverExpiry,
      filteteredListOfDriverExpiry,

      listOfDriverInductions,
      filteteredListOfDriverInductions,

      listOfTruckInfo,
      filteteredListOfTruckInfo,

      listOfDriverInfo,
      filteteredListOfDriverInfo,

      jobHistory,
      selectedJobHistory,
      showJobScheduleDetail,
      attachmentIndexToOpen,
      showSiteDockets,
      filteteredJobHistory,

      sortVOCLabelsAndDirection,
      sortInductionsLabelsAndDirection,
      sortTruckExpiriesLabelsAndDirection,
      sortDriverExpiriesLabelsAndDirection,
      sortTruckInfoLabelsAndDirection,
      sortDriverInfoLabelsAndDirection,
      flagShowDetail,
      sortJobHistoryLabelsAndDirection,

      jobSiteDockets,
      filteredJobSiteDockets,
      sortJobSiteDocketLabelsAndDirection,
      selectedJobSiteDocket,

      selectedStartDate,
      selectedFinishDate,
      showUninvoicedJobs,
    } = this.state;

    return (
      <IonContent className="search-register-container">
        <div className="search-title-label-container">
          <IonLabel className="ewp-h2 ion-no-padding ion-padding-end">
            Search
          </IonLabel>
        </div>
        <div className="search-button-input-container">
          <EWPWebDropdownSelect
            className="search-category-button"
            widthSize="half-dialog-form"
            dropdownItems={searchOptions}
            onSelectItem={(id) => {
              this.setState({ searchCategory: id, searchQuery: "" });
            }}
            placeholder="Choose Category"
            value={searchCategory}
            errorMsg={errorMsgSearchCategory}
          />
          <IonSearchbar
            mode="md"
            value={searchQuery}
            disabled={_.isEmpty(searchCategory)}
            className="ewp-title-search-button-search-bar ewp-body ion-text-left ion-no-padding"
            onIonChange={(event: CustomEvent) => {
              this.setState({ searchQuery: event.detail.value });
              if (searchCategory === "vocs") {
                this.searchVOC(event.detail.value);
              } else if (searchCategory === "truck-expiries") {
                this.searchTruckExpiries(event.detail.value);
              } else if (searchCategory === "driver-expiries") {
                this.searchDriverExpiries(event.detail.value);
              } else if (searchCategory === "inductions") {
                this.searchDriverInductions(event.detail.value);
              } else if (searchCategory === "truck-info") {
                this.searchTruckInfo(event.detail.value);
              } else if (searchCategory === "driver-info") {
                this.searchDriverInfo(event.detail.value);
              } else if (searchCategory === "jobs") {
                this.searchJobHistory(event.detail.value);
              } else if (searchCategory === "site-dockets") {
                this.searchJobSiteDockets(event.detail.value);
              }
            }}
          />
          {searchCategory === "jobs" && (
            <div className="search-job-date-pickers">
              <div className="toggle-container">
                <IonItem
                  lines="none"
                  className="toggle-box-item ion-no-padding"
                >
                  <IonCheckbox
                    className="toggle-checkbox"
                    slot="end"
                    color="success"
                    checked={showUninvoicedJobs}
                    onIonChange={() => {
                      this.toggleShowUninvoicedJobs(!showUninvoicedJobs);
                    }}
                  />
                  <IonLabel className="toggle-checkbox-label ewp-h6" slot="end">
                    Show Uninvoiced Jobs
                  </IonLabel>
                </IonItem>
              </div>
              <EWPDatePicker
                className="start-date-input-date"
                datePickerLabel="Select Date"
                label="Start Date"
                selectedDate={selectedStartDate}
                onDateChange={(startDate: Date) => {
                  this.setState({ selectedStartDate: startDate });
                  this.filterDateJobHistory(startDate, selectedFinishDate);
                }}
              />
              <EWPDatePicker
                className="finish-date-input-date"
                datePickerLabel="Select Date"
                label="Finish Date"
                selectedDate={selectedFinishDate}
                onDateChange={(finishDate: Date) => {
                  this.setState({ selectedFinishDate: finishDate });
                  this.filterDateJobHistory(selectedStartDate, finishDate);
                }}
              />
              <IonButton
                disabled={!selectedStartDate && !selectedFinishDate}
                fill="clear"
                onClick={() => {
                  this.setState({
                    selectedStartDate: null,
                    selectedFinishDate: null,
                    filteteredJobHistory: null,
                    searchQuery: "",
                  });
                }}
              >
                <IonIcon icon={close} />
              </IonButton>
            </div>
          )}
        </div>
        <div className="search-table-container">
          {searchCategory === "vocs" && (
            <EWPDataTable
              tableDef={this.getVOCTableDef()}
              tableData={listOfVOcs}
              tableFilteredData={filteredVocs}
              renderCell={this.renderVocsCell}
              isComponentPage={true}
              sortLabelsAndDirection={sortVOCLabelsAndDirection}
              onSort={this.sortVOC}
            />
          )}
          {searchCategory === "truck-expiries" && (
            <EWPDataTable
              tableDef={this.getTruckExpiriesTableDef()}
              tableData={listOfTruckExpiry}
              tableFilteredData={filteteredListOfTruckExpiry}
              renderCell={this.rendeTruckExpiryCell}
              isComponentPage={true}
              sortLabelsAndDirection={sortTruckExpiriesLabelsAndDirection}
              onSort={this.sortTruckExpiries}
            />
          )}
          {searchCategory === "driver-expiries" && (
            <EWPDataTable
              tableDef={this.getDriverExpiriesTableDef()}
              tableData={listOfDriverExpiry}
              tableFilteredData={filteteredListOfDriverExpiry}
              renderCell={this.rendeDriverExpiryCell}
              isComponentPage={true}
              sortLabelsAndDirection={sortDriverExpiriesLabelsAndDirection}
              onSort={this.sortDriverExpiries}
            />
          )}
          {searchCategory === "inductions" && (
            <EWPDataTable
              tableDef={this.getDriverInductionsTableDef()}
              tableData={listOfDriverInductions}
              tableFilteredData={filteteredListOfDriverInductions}
              renderCell={this.rendeDriverInductionsCell}
              isComponentPage={true}
              sortLabelsAndDirection={sortInductionsLabelsAndDirection}
              onSort={this.sortInductions}
            />
          )}
          {searchCategory === "truck-info" && (
            <EWPDataTable
              tableDef={this.getTruckInfoTableDef()}
              tableData={listOfTruckInfo}
              tableFilteredData={filteteredListOfTruckInfo}
              renderCell={this.rendeTruckInfoCell}
              isComponentPage={true}
              sortLabelsAndDirection={sortTruckInfoLabelsAndDirection}
              onSort={this.sortTruckInfo}
            />
          )}
          {searchCategory === "driver-info" && (
            <EWPDataTable
              tableDef={this.getDriverInfoTableDef()}
              tableData={listOfDriverInfo}
              tableFilteredData={filteteredListOfDriverInfo}
              renderCell={this.rendeDriverInfoCell}
              isComponentPage={true}
              sortLabelsAndDirection={sortDriverInfoLabelsAndDirection}
              onSort={this.sortDriverInfo}
            />
          )}
          {searchCategory === "jobs" && (
            <EWPDataTable
              tableDef={this.getJobHistoryTableDef()}
              tableData={jobHistory}
              tableFilteredData={filteteredJobHistory}
              renderCell={this.renderJobHistoryCell}
              isComponentPage={true}
              sortLabelsAndDirection={sortJobHistoryLabelsAndDirection}
              onSort={this.sortJobHistory}
            />
          )}
          {searchCategory === "site-dockets" && (
            <EWPDataTable
              tableDef={this.getJobSiteDocketsTableDef()}
              tableData={jobSiteDockets}
              tableFilteredData={filteredJobSiteDockets}
              renderCell={this.renderJobSiteDocketsCell}
              isComponentPage={true}
              sortLabelsAndDirection={sortJobSiteDocketLabelsAndDirection}
              onSort={this.sortJobSiteDockets}
            />
          )}
        </div>
        {!!selectedJobHistory && !!showJobScheduleDetail && (
          <JobScheduleDetailDialog
            onCancel={(jobToCancel) => {}}
            onUncancel={(jobToUncancel) => {}}
            isOpen={showJobScheduleDetail}
            jobSchedule={selectedJobHistory}
            showSiteDockets={() => {
              if (
                !!selectedJobHistory.siteDockets &&
                !_.isEmpty(selectedJobHistory.siteDockets)
              ) {
                // setSelectedJobHistory(selectedJobHistory);
                // setShowSiteDocketsDialog(true);
                // setShowJobScheduleDetail(false);
                this.setState({
                  selectedJobHistory,
                  showSiteDockets: true,
                  showJobScheduleDetail: false,
                });
              } else {
                this.setState({
                  error: "No site dockets uploaded for this job history",
                });
              }
            }}
            onDidDismiss={() => {
              this.setState({ showJobScheduleDetail: false });
            }}
            onShowAttachmentDialog={(isOpen: boolean) => {
              this.setState({
                showSiteDockets: isOpen,
                showJobScheduleDetail: false,
              });
            }}
            onAttachmentToOpen={(attachmentIndex: number) => {
              this.setState({ attachmentIndexToOpen: attachmentIndex });
            }}
            {...this.props}
          />
        )}

        {selectedJobSiteDocket && !_.isEmpty(selectedJobSiteDocket) && (
          <AttachmentDialog
            attachments={selectedJobSiteDocket.map((siteDocket) => {
              return {
                notApplicable: !!siteDocket.notApplicable,
                attachmentUrl: siteDocket.attachmentURL,
                photoName: siteDocket.siteDocketName,
                title: `job date: ${moment(
                  getDateFromTimezoneIgnoredTimestamp(
                    siteDocket.siteDocketDateNoTimeZone as TimezoneIgnoredTimeStamp
                  )
                ).format("ddd DD MMM YYYY")}${
                  !!siteDocket.notApplicable ? " - Not Applicable" : ""
                } / Upload date: ${moment(
                  (
                    siteDocket.uploadDate as firebase.firestore.Timestamp
                  ).toDate()
                ).format("DD MMM YYYY, hh:mm:A")}`,
              };
            })}
            {...(!_.isNull(attachmentIndexToOpen) && {
              selectedIndex: attachmentIndexToOpen,
            })}
            showModal={!_.isEmpty(selectedJobSiteDocket)}
            onModalDidDismiss={() => {
              this.setState({ selectedJobSiteDocket: null });
            }}
          />
        )}

        {!!showSiteDockets &&
          !!selectedJobHistory &&
          !!selectedJobHistory.siteDockets &&
          !_.isEmpty(selectedJobHistory.siteDockets) && (
            <AttachmentDialog
              attachments={selectedJobHistory.siteDockets.map((siteDocket) => {
                return {
                  notApplicable: !!siteDocket.notApplicable,
                  attachmentUrl: siteDocket.attachmentURL,
                  photoName: siteDocket.siteDocketName,
                  title: `Job date: ${moment(
                    // toDateTimeFromSecs(
                    //   (siteDocket.siteDocketDate as firebase.firestore.Timestamp)
                    //     .seconds
                    // )
                    getDateFromTimezoneIgnoredTimestamp(
                      siteDocket.siteDocketDateNoTimeZone as TimezoneIgnoredTimeStamp
                    )
                  ).format("ddd DD MMM YYYY")}${
                    !!siteDocket.notApplicable ? " - Not Applicable" : ""
                  } / Upload date: ${moment(
                    (
                      siteDocket.uploadDate as firebase.firestore.Timestamp
                    ).toDate()
                  ).format("DD MMM YYYY, hh:mm:A")}`,
                };
              })}
              {...(!_.isNull(attachmentIndexToOpen) && {
                selectedIndex: attachmentIndexToOpen,
              })}
              showModal={showSiteDockets}
              onModalDidDismiss={() => {
                // setShowSiteDocketsDialog(false);
                this.setState({ showSiteDockets: false });
                if (!!flagShowDetail) {
                  // setShowJobScheduleDetail(true);
                  this.setState({ showJobScheduleDetail: true });
                }
              }}
            />
          )}
        {!!showAttachmentDialog &&
          !!dataAttachments &&
          !_.isEmpty(dataAttachments) && (
            <AttachmentDialog
              title="Attachments"
              attachments={dataAttachments.map((attachmentUrl) => {
                return {
                  attachmentUrl,
                };
              })}
              showModal={showAttachmentDialog}
              onModalDidDismiss={() => {
                this.setState({
                  showAttachmentDialog: false,
                  dataAttachments: null,
                });
              }}
            />
          )}
      </IonContent>
    );
  };
}

export default SearchPage;
