import * as _ from "lodash";
import * as EmailValidator from "email-validator";
import React, { useState } from "react";
import {
  IonItem,
  IonTitle,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonToast,
  IonRippleEffect,
  IonLoading,
} from "@ionic/react";

import "./EWPCreateAdminForm.scss";
import * as api from "../../api";
import { formatPhoneNumber } from "../../functions/phoneNumber";
import {
  formatString,
  getServerTimestamp,
  validatePassword,
} from "../../functions/common";
import { Admin } from "../../models/global";
import { EWPInput } from "../EWPInput/EWPInput";
import { EWPNumberFieldWithCountryCode } from "../EWPNumberFieldWithCountryCode/EWPNumberFieldWithCountryCode";
import { EWPPasswordInput } from "../EWPPasswordInput/EWPPasswordInput";
import { EWPWebDropdownSelect } from "../EWPWebDropdownSelect/EWPWebDropdownSelect";
import { AdminAccess, ADMIN_ACCESS, AuthUser } from "../../config/global";
import { EWPCOLORS, LIMITS } from "../../constants/config";
import {
  MSGS_COMMON,
  MSGS_SIGNUP,
  MSGS_USER_INVITE,
} from "../../constants/messages";
import Bugsnag from "@bugsnag/js";

interface EWPCreateAdminFormProps {
  authUser: AuthUser;
  onSuccess: (user: InvitedAdmin) => void;
  onModalDidDismiss: () => void;
}
export interface InvitedAdmin extends Admin {
  password: string;
}

export const EWPCreateAdminForm = (props: EWPCreateAdminFormProps) => {
  console.log("GOT PROPS", props);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [adminAccess, setAdminAccess] = useState(1 as AdminAccess);
  const [contactNumber, setContactNumber] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const [errorMsgFirstName, seterrorMsgFirstName] = useState("");
  const [errorMsgLastName, setErrorMsgLastName] = useState("");
  const [errorMsgEmailAddress, setErrorMsgEmailAddress] = useState("");
  const [errorMsgAdminAccess, setErrorMsgAdminAccess] = useState("");
  const [errorMsgContactNumber, setErrorMsgContactNumber] = useState("");
  const [errorMsgPassword, setErrorMsgPassword] = useState("");

  const [invitedAdmin, setInvitedAdmin] = useState({} as InvitedAdmin);

  const adminAccessOptions = Object.values(ADMIN_ACCESS).map((item) => {
    return { name: item.access, id: item.id.toString() };
  });

  const submitForm = async (event: React.FormEvent) => {
    event.preventDefault();

    const formattedFirstName = formatString(firstName);
    const formattedLastName = formatString(lastName);
    const formattedEmailAddress = formatString(emailAddress);
    const formattedPhoneNumber = formatPhoneNumber(
      (contactNumber as string) || ""
    );
    const formattedPassword = password || "";

    let errorMessage = null;

    if (formattedFirstName.length === 0) {
      errorMessage = MSGS_USER_INVITE.firstName.required;
      seterrorMsgFirstName(errorMessage);
    }
    if (formattedLastName.length === 0) {
      errorMessage = MSGS_USER_INVITE.lastName.required;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedFirstName.length > LIMITS.name.full) {
      errorMessage = MSGS_USER_INVITE.firstName.max;
      seterrorMsgFirstName(errorMessage);
    }
    if (formattedLastName.length > LIMITS.name.single) {
      errorMessage = MSGS_USER_INVITE.lastName.max;
      setErrorMsgLastName(errorMessage);
    }
    if (formattedEmailAddress.length === 0) {
      errorMessage = MSGS_USER_INVITE.email.required;
      setErrorMsgEmailAddress(errorMessage);
    }
    if (!EmailValidator.validate(formattedEmailAddress)) {
      errorMessage = MSGS_USER_INVITE.email.invalid;
      setErrorMsgEmailAddress(errorMessage);
    }
    if (password.length === 0) {
      errorMessage = MSGS_SIGNUP.password.required;
      setErrorMsgPassword(errorMessage);
    }
    if (!validatePassword(formattedPassword)) {
      errorMessage = MSGS_SIGNUP.password.invalid;
      setErrorMsgPassword(errorMessage);
    }
    if (_.isEmpty(formattedPhoneNumber)) {
      errorMessage = MSGS_SIGNUP.mobileNumber;
      setErrorMsgContactNumber(errorMessage);
    }

    setLoading(true);
    try {
      await api.checkIfEmailIsUnique(formattedEmailAddress);
    } catch (errorUnknown) {
      const error = errorUnknown as any;
      errorMessage = error.error;
      setErrorMsgEmailAddress(errorMessage);
      Bugsnag.notify(new Error(error));
    }

    if (_.isEmpty(errorMessage)) {
      const { authUser } = props;
      try {
        const newAdmin = {
          createdBy: authUser.docId || "",
          createdDt: getServerTimestamp(),
          firstName: formattedFirstName,
          lastName: formattedLastName,
          phoneNumber: formattedPhoneNumber as string,
          emailAddress: formattedEmailAddress,
          access: adminAccess,
          password: formattedPassword,
        } as InvitedAdmin;

        await api.createUser(newAdmin);
        setInvitedAdmin(newAdmin);
        setTimeout(() => {
          setSuccess(MSGS_USER_INVITE.success);
          setLoading(false);
        }, 100);
      } catch (errorUnknown) {
        const error = errorUnknown as any;
        setError(error);
        setLoading(false);
        Bugsnag.notify(new Error(error));
      }
    } else {
      setLoading(false);
    }
  };

  const clearFields = () => {
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setAdminAccess(1 as AdminAccess);
  };

  const clearErrorMsgs = () => {
    seterrorMsgFirstName("");
    setErrorMsgLastName("");
    setErrorMsgEmailAddress("");
  };

  return (
    <>
      <form onSubmit={submitForm}>
        <IonTitle className="user-details-title ewp-h3 ion-no-padding">
          User Details
        </IonTitle>
        <IonGrid className="ion-no-padding ion-no-margin">
          <IonRow className="user-details-row ion-no-padding ion-no-margin">
            <IonCol size="6" className="user-start-col ion-no-padding">
              <EWPInput
                errorMessage={errorMsgFirstName}
                inputLabel="First Name"
                inputValue={firstName}
                inputType="text"
                name="firstName"
                onInputChange={(firstName: string) => {
                  clearErrorMsgs();
                  setFirstName(firstName);
                }}
              />
            </IonCol>
            <IonCol size="6" className="user-end-col ion-no-padding">
              <EWPInput
                errorMessage={errorMsgLastName}
                inputLabel="Last Name"
                inputValue={lastName}
                inputType="text"
                name="lastName"
                onInputChange={(lastName: string) => {
                  clearErrorMsgs();
                  setLastName(lastName);
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow className="user-details-row ion-no-padding ion-no-margin">
            <IonCol className="user-start-col ion-no-padding">
              <EWPInput
                errorMessage={errorMsgEmailAddress}
                inputLabel="Email"
                inputValue={emailAddress}
                inputType="text"
                name="emailAddress"
                onInputChange={(emailAddress: string) => {
                  clearErrorMsgs();
                  setEmailAddress(emailAddress);
                }}
              />
            </IonCol>
            <IonCol className="user-end-col ion-no-padding">
              {/* <EWPInput
                errorMessage={errorMsgAdminRole}
                inputLabel="Role"
                inputValue={adminAccess}
                inputType="text"
                name="role"
                onInputChange={(companyRepRole: string) => {
                  clearErrorMsgs();
                  setAdminAccess(companyRepRole);
                }}
              /> */}

              <EWPWebDropdownSelect
                dropdownItems={adminAccessOptions}
                onSelectItem={(selectedItem) => {
                  console.log("GOT SELECTED ITEM", selectedItem);
                  setAdminAccess(
                    parseInt(selectedItem as string) as AdminAccess
                  );
                  setErrorMsgAdminAccess("");
                }}
                value={adminAccess.toString()}
                itemsHeader="Admin Access"
                placeholder="Select Access"
                errorMsg={errorMsgAdminAccess}
              />
            </IonCol>
          </IonRow>
          <IonRow className="user-details-row ion-no-padding ion-no-margin">
            <IonCol className="user-start-col ion-no-padding">
              <EWPNumberFieldWithCountryCode
                errorMessage={errorMsgContactNumber}
                phoneNumber={contactNumber}
                onChange={(contactNumber: string) => {
                  setContactNumber(contactNumber);
                }}
              />
            </IonCol>

            <IonCol className="user-end-col ion-no-padding">
              <EWPPasswordInput
                passwordLabel="Password"
                passwordValue={password}
                onChange={(password: string) => {
                  setPassword(password);
                  setErrorMsgPassword("");
                }}
                errorMessage={errorMsgPassword}
              />
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonItem
          lines="none"
          className="ewp-item-no-horizontal-padding ewp-button-padding-top"
        >
          <IonButton
            mode="ios"
            slot="start"
            className="ewp-modal-cancel-button ewp-h4 ion-no-margin"
            fill="clear"
            color={EWPCOLORS.medium}
            onClick={() => {
              if (props.onModalDidDismiss) {
                props.onModalDidDismiss();
                clearFields();
              }
            }}
          >
            Cancel
          </IonButton>
          <IonButton
            mode="md"
            slot="end"
            className="ewp-modal-save-form-button ewp-h5 ion-no-margin ion-text-capitalize"
            color={EWPCOLORS.primary}
            type="submit"
          >
            Save
            <IonRippleEffect />
          </IonButton>
        </IonItem>
      </form>

      <IonToast
        isOpen={!_.isEmpty(error)}
        message={error}
        duration={2000}
        onDidDismiss={() => setError("")}
        color={EWPCOLORS.danger}
      />

      <IonLoading
        spinner="circular"
        translucent={true}
        mode="ios"
        isOpen={loading}
        message={MSGS_COMMON.loading}
      />

      {!_.isEmpty(invitedAdmin) && (
        <IonToast
          isOpen={!_.isEmpty(success)}
          message={success}
          duration={500}
          onDidDismiss={() => {
            setSuccess("");
            props.onSuccess(invitedAdmin);
            clearFields();
          }}
          color={EWPCOLORS.success}
        />
      )}
    </>
  );
};
